import { BaseRequest } from "../BaseRequest";
import { TestQuestionType, ResourceState } from "Enums";

export class TestCategoryRequest extends BaseRequest {
    public id?: string;
    public title: string;
}

export class TestRequestQuestionMaterial {
    public id: string;
    public materialId: string;
}

export class TestRequestQuestionAnswer {
    public id?: string;
    public pictureId: any;
    public answerText: string;
    public isCorrect: boolean;
}

export class TestRequestQuestion {
    public id?: string;
    public logoId: any;
    public questionText: string;
    public questionType: TestQuestionType;
    public complexity: string;
    public answerNote: string;
    public correctAnswer: string;
    public materialsOnError: TestRequestQuestionMaterial[];
    public answers: TestRequestQuestionAnswer[];
}

export class TestRequest extends BaseRequest {
    public id?: string;
    public logoId?: string | null;
    public categoryId: string;
    public reviewerId: string;
    public title: string;
    public description: string;
    public state: ResourceState;
    public publicAccess: boolean;
    public quizType: string;
    public questionSkipping: boolean;
    public quizTimeLimit: number | null;
    public questionTimeLimit: number | null;
    public showMistakesDuringQuiz: boolean;
    public showMistakesAfterQuiz: boolean;
    public maxAttempts: number | null | undefined;
    public notRated: boolean;
    public passPercentage: string;
    public shuffledQuestions: boolean;
    public shuffledAnswers: boolean;
    public successMessage: string;
    public failureMessage: string;
    public approxCompletionMinutes: number;
    public isApproxCompletionMinutesManuallyChanged: boolean;
    public questions: TestRequestQuestion[];
    public publicationTime: number | null;
    public archiveTime: number | null;
    public deadlineTime: number | null;
    public modifyTime: number | null;
    public notIssueCertificateIfFailed: boolean;
}

export class TestUserValidateRequest extends BaseRequest {
    public questionId: string;
    public answerIds: string[];
    public customAnswer: string;
}

export class TestVerificatedCheckMultipleRequest extends BaseRequest {
    public solutionIds: string[];
    public approved: boolean;
}

export class TestVerificatedCheckRequestQuestion {
    public questionId: string;
    public approved: boolean;
    public comment: string;
}

export class TestVerificatedCheckRequest extends BaseRequest {
    public solutionId: string;
    public checkedQuestions: TestVerificatedCheckRequestQuestion[];
}

export class TestUnArchiveRequest extends BaseRequest {
    public categoryId: string;
    public ids: string[];
}

export class TestCategoryReorderRequest extends BaseRequest {
    public categoryIds: string[];
}
