import React from "react";
import { useParams, Outlet } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { useSelector } from "react-redux";
import { ContentContainer } from "Containers/ContentContainer";
import { Breadcrumbs } from "Uikit/index";
import { Preloader } from "Components/Preloader/Preloader";
import { useGetUIProgram } from "./Program.hooks";
import { IReducer } from "store";
import { NoAccess } from "Components/Stub/NoAccess";
import classNames from "classnames";
import { ResourceState } from "Enums";

export const Program = () => {
    const {
        programId = "",
        courseId = "",
        testId = "",
        resourceId = "",
    } = useParams<{
        programId: string;
        courseId: string;
        testId: string;
        resourceId: string;
    }>();

    const { data: program, isLoading: isProgramLoading, isFetched } = useGetUIProgram(programId);
    const { currentCourse, currentMaterial } = useSelector((state: IReducer) => state.program);

    const showNoAccess =
        (!program && isFetched) || (program && [ResourceState.HIDDEN, ResourceState.ARCHIVED].includes(program.state));

    return (
        <div className={classNames("relative min-h-full", showNoAccess && "h-full")}>
            <div className="flex flex-col pt-3 sm:pt-0 h-full mx-auto sm:max-w-[1216px] 2xl:max-w-[1506px]">
                <div className="hidden sm:block px-3 sm:pl-4 sm:pr-6.5 pb-3">
                    <Breadcrumbs id="userProgramBreadcrumbs">
                        <Breadcrumbs.Link title={"Моё обучение"} url="/training" />
                        {program && (
                            <Breadcrumbs.Link
                                url={courseId ? `/training/program/${programId}` : undefined}
                                title={program.title}
                            />
                        )}
                        {currentCourse?.id && (
                            <Breadcrumbs.Link
                                url={testId || resourceId ? `/training/program/${programId}/${courseId}` : undefined}
                                title={currentCourse.title}
                            />
                        )}
                        {currentMaterial?.id && <Breadcrumbs.Link title={currentMaterial.title} />}
                    </Breadcrumbs>
                </div>
                <Preloader className="pt-6 pl-4 pr-6 h-[calc(100vh-72px)]" isShow={isProgramLoading}>
                    <Skeleton className="mb-7" width="100%" height="244px" />
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={`card-skeleton__${p}`} className="mb-1 h-20">
                                <Skeleton width="100%" height="100%" />
                            </div>
                        );
                    })}
                </Preloader>
                {showNoAccess && <NoAccess className="flex-grow" />}
                {program && ![ResourceState.HIDDEN, ResourceState.ARCHIVED].includes(program.state) && (
                    <ContentContainer>{<Outlet />}</ContentContainer>
                )}
            </div>
        </div>
    );
};
