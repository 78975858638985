import React from "react";
import { CertificatesListResponse } from "Api/Responses/UserResponse";
import Api from "Api";
import { LogoSize } from "Api/Services/UploadApi";
import { ResourceType } from "Enums";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { formatDateTime } from "helpers/dateHelper";
import { Icon, Icons } from "Uikit";
import saveAs from "file-saver";

interface ICertificateCard {
    certificate: CertificatesListResponse;
}

export const CertificateCard = ({ certificate }: ICertificateCard) => {
    const handleDownload = async () => {
        const data = await Api.User.DownloadCertificateFile(certificate.id);

        const blob = new Blob([data], {
            type: "application/pdf",
        });

        saveAs(blob, certificate.name);
    };

    return (
        <div className="w-full h-41 sm:h-45.5 2xl:h-56.5 rounded-2xl 2xl:rounded-3xl overflow-hidden relative">
            <img
                className="w-full h-full rounded-2xl 2xl:rounded-3xl object-cover"
                src={Api.Upload.GetLogo(certificate.logoId, LogoSize.THUMB_MINI)}
                alt={certificate.name}
            />
            <div className="absolute top-3 left-4 2xl:top-3.75 2xl:left-5 py-0.5 2xl:py-0.75 px-1 sm:px-1.5 2xl:px-2 bg-black-50 text-white rounded-md 2xl:rounded-lg p4 sm:p3 2xl:text-2sm">
                {certificate.type === ResourceType.COURSE ? "Курс" : "Программа"}
            </div>
            <div className="flex flex-col justify-between bg-background-light absolute bottom-0 left-0 w-full h-27.5 sm:h-32 2xl:h-40 rounded-2xl 2xl:rounded-3xl py-3 px-4 sm:pt-6.5 sm:pb-4 sm:px-4 2xl:pt-8 2xl:pb-5 2xl:px-5">
                <MultiClumpTooltip clamp={2} label={certificate.name} textClassName="font-semibold 2xl:text-2md" />
                <div className="flex items-center gap-2.5 2xl:gap-3">
                    <div className="grow">
                        <p className="mb-0.5 text-blue-dark p3 2xl:text-2sm">Сертификат получен</p>
                        <p className="text-blue-dark p3 2xl:text-2sm">
                            {formatDateTime(certificate.certificationDate * 1000)}
                        </p>
                    </div>
                    <div>
                        <div
                            className="flex items-center justify-center w-8.5 2xl:w-11 h-8.5 2xl:h-11 rounded-full bg-black-20 hover:bg-black-60 cursor-pointer"
                            onClick={handleDownload}
                        >
                            <Icon
                                icon={Icons.Download}
                                width={18}
                                height={18}
                                color="fill-white"
                                className="2xl:!w-5.5 2xl:!h-5.5"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
