import React, { ReactNode, useMemo, useState, useEffect, Fragment } from "react";
import ReactECharts from "echarts-for-react";
import clsx from "clsx";
import uniqueId from "lodash/uniqueId";
import { TScreenSize } from "types/Screen";
import { TVoidFunction } from "types";
import { MultiClumpTooltip } from "./MultiClumpTooltip/MultiClumpTooltip";

interface WidgetProps {
    title: string;
    children: ReactNode;
    onClick?: TVoidFunction;
    classNames?: string;
}

interface IChartSimpleProps {
    count?: number | string;
    charts?: {
        color: "stroke-primary" | "stroke-gray-light" | "stroke-red" | "stroke-gray-stroke";
        size: number;
        zIndex: number;
    }[];
}

interface IChartSimpleCircleProps {
    radius: number;
    strokeWidth: number;
    strokeDasharray?: number[];
    className?: string;
    strokeColor?: string;
}

const getCircumference = (radius: number) => {
    // длина окружности = 2 * π * r
    return 2 * 3.14 * radius;
};

const ChartSimpleCircle = ({
    radius,
    strokeWidth,
    strokeDasharray,
    strokeColor = "stroke-gray-stroke",
    className = "",
}: IChartSimpleCircleProps) => {
    const [animatedDashArray, setAnimatedDashArray] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setAnimatedDashArray(strokeDasharray?.[0] ?? 0);
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className={`absolute top-0 left-0 w-full h-full ${className || ""}`}>
            <svg viewBox="0 0 180 180" xmlns="http://www.w3.org/2000/svg">
                <circle
                    r={radius}
                    className={`${strokeColor} transition-all duration-500 ease-out`}
                    strokeWidth={strokeWidth}
                    strokeDasharray={strokeDasharray ? [animatedDashArray, strokeDasharray[1]].join(",") : undefined}
                    cx="90"
                    cy="90"
                    fill="none"
                    strokeLinecap="round"
                />
            </svg>
        </div>
    );
};

const ChartSimple = ({ count, charts = [] }: IChartSimpleProps) => {
    const radius = 80;
    const strokeWidth = 18;
    const circumference = getCircumference(radius);

    return (
        <div className="relative w-22.5 2xl:w-28 h-22.5 2xl:h-28">
            {count && <div className="absolute z-[10] w-full h-full flex-center font-bold 2xl:text-md">{count}</div>}
            <ChartSimpleCircle radius={radius} strokeWidth={strokeWidth} />
            {charts?.map(({ size, color, zIndex }, index) => {
                return (
                    <>
                        {size ? (
                            <ChartSimpleCircle
                                key={uniqueId(`${color}__{index}`)}
                                radius={radius}
                                strokeWidth={strokeWidth}
                                strokeDasharray={[circumference * size, circumference]}
                                className={`-rotate-90 z-${zIndex ?? index + 1}`}
                                strokeColor={color}
                            />
                        ) : null}
                    </>
                );
            })}
        </div>
    );
};

ChartSimple.defaultProps = {
    __TYPE: "WidgetChartSimple",
};

export const Widget = ({ title, children, onClick, classNames = "" }: WidgetProps) => {
    const childrenArray = React.Children.toArray(children);

    const lines = childrenArray.filter((child) => {
        return React.isValidElement<LineProps>(child) && (child.props as any)?.__TYPE === "WidgetLine";
    });

    const chart = childrenArray.find((child) => React.isValidElement(child) && child.props?.__TYPE === "WidgetChart");

    const legendLines = childrenArray.filter(
        (child) => React.isValidElement(child) && child.props?.__TYPE === "WidgetLegendLine",
    );

    return (
        <div
            className={`flex flex-col justify-between w-full sm:w-68.5 sm:h-38.5 2xl:w-[342px] 2xl:h-[195px] bg-background rounded-xxl 2xl:rounded-3xl py-4 px-5 2xl:py-5 2xl:px-6.25 space-y-3${
                onClick ? " cursor-pointer" : ""
            } ${classNames}`}
            onClick={() => {
                onClick?.();
            }}
        >
            <MultiClumpTooltip clamp={2} label={title} textClassName="font-semibold 2xl:text-2md" />

            {lines.length > 0 && (
                <div className="flex flex-col grow justify-end space-y-2">
                    {lines.map((Line) => {
                        return <Fragment key={String(Line)}>{React.cloneElement(Line as React.ReactElement)}</Fragment>;
                    })}
                </div>
            )}

            {chart && (
                <div className="flex grow space-x-5">
                    {React.cloneElement(chart as React.ReactElement)}
                    <div className="flex flex-col grow space-y-1.5 self-center">
                        {legendLines.map((Line) => {
                            return (
                                <Fragment key={String(Line)}>{React.cloneElement(Line as React.ReactElement)}</Fragment>
                            );
                        })}
                    </div>
                </div>
            )}

            {!lines.length && !chart && <>{children}</>}
        </div>
    );
};

interface LineProps {
    text?: string;
    value?: string | number;
}

const Line = ({ text, value }: LineProps) => {
    return (
        <div className="space-x-3 line-clamp-1">
            <span className="text-gray 2xl:text-md">{text}</span>
            <span className="p2-accent 2xl:text-md">{value}</span>
        </div>
    );
};

Line.defaultProps = {
    __TYPE: "WidgetLine",
};

Widget.Line = Line;

interface IChartProps {
    count?: number;
    charts?: {
        state: "passed" | "processed" | "failed";
        size: number;
        color?: "blue" | "gray" | "red" | "light-gray";
    }[];
    size?: TScreenSize;
}

const circleColors: any = {
    blue: "#0084E2",
    gray: "#C1C6CA",
    "light-gray": "#E6E9ED",
    red: "#FF5963",
};
const ChartCircle = ({
    className = "",
    charts = [],
    size = "medium",
}: { className?: string } & Pick<IChartProps, "charts" | "size">) => {
    const isLarge = size === "large";
    const option = useMemo(
        () => ({
            tooltip: {
                trigger: "item",
                show: false,
            },
            legend: {
                show: false,
                top: "5%",
                left: "center",
            },
            series: [
                {
                    name: "Access From",
                    type: "pie",
                    radius: isLarge ? ["80%", "100%"] : ["40%", "70%"],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                        position: "center",
                    },
                    emphasis: {
                        label: {
                            show: false,
                            fontSize: 40,
                            fontWeight: "bold",
                        },
                    },
                    labelLine: {
                        show: false,
                    },
                    data: charts.map((chart) => ({
                        value: chart.size,
                        name: chart.state,
                        itemStyle: { color: circleColors[chart.color ?? "gray"] },
                    })),
                },
            ],
        }),
        [charts, isLarge],
    );
    return (
        <div className={`absolute top-0 left-0 w-full h-full ${className || ""}`}>
            <ReactECharts option={option} style={{ height: isLarge ? 112 : 90 }} />
        </div>
    );
};

const Chart = ({ count, charts = [], size = "medium" }: IChartProps) => {
    return (
        <div className="relative w-22.5 h-22.5">
            {count && <div className="absolute z-[10] w-full h-full flex-center font-bold">{count} шт.</div>}
            <ChartCircle charts={charts} size={size} />
        </div>
    );
};

Chart.defaultProps = {
    __TYPE: "WidgetChart",
};

Widget.Chart = Chart;
Widget.ChartSimple = ChartSimple;

interface LegendLineProps {
    value: string | number;
    text: string;
    color: "blue" | "gray" | "red" | "light-gray";
}

const LegendLine = ({ color, text, value }: LegendLineProps) => {
    return (
        <div className="flex items-center">
            <div className="flex space-x-1.5 2xl:space-x-2 grow items-center text-gray 2xl:text-md">
                <div className={clsx("w-2.5 2xl:w-3 h-2.5 2xl:h-3 rounded-full", lineColor[color])} />
                <div>{text}</div>
            </div>
            <div className="font-medium 2xl:text-md">{value}</div>
        </div>
    );
};

const lineColor: any = {
    blue: "bg-blue",
    red: "bg-red",
    gray: "bg-gray-light",
    "light-gray": "bg-gray-stroke",
};

LegendLine.defaultProps = {
    __TYPE: "WidgetLegendLine",
};

Widget.LegendLine = LegendLine;
