import { Expose, Type } from "class-transformer";
import { BaseResponse } from "../BaseResponse";
import { ResourceType, RoleName } from "Enums";
import { User } from "types";
import { JobResponse } from "./JobResponse";
import { AchievementUser } from "Api/Responses/AchievementResponse";
import intersection from "lodash/intersection";

export class UserResponse {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => String)
    public FirstName: string;

    @Expose()
    @Type(() => String)
    public LastName: string;

    @Expose()
    @Type(() => String)
    public MiddleName: string;

    @Expose()
    @Type(() => String)
    public Image: string;

    @Expose()
    @Type(() => String)
    public Login: string;

    @Expose()
    @Type(() => String)
    public Email: string;
}

export class UserActiveListResponse {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => String)
    public Image: string;

    @Expose()
    @Type(() => String)
    public FirstName: string;

    @Expose()
    @Type(() => String)
    public LastName: string;

    @Expose()
    @Type(() => String)
    public MiddleName: string;

    @Expose()
    @Type(() => String)
    public Position: string;

    @Expose()
    @Type(() => String)
    public Role: string;

    @Expose()
    @Type(() => String)
    public Commands: string[];

    @Expose()
    @Type(() => Date)
    public Online: Date;
}

export class UserInActiveListResponse {
    @Expose()
    @Type(() => Number)
    public Id: number;

    @Expose()
    @Type(() => String)
    public Image: string;

    @Expose()
    @Type(() => String)
    public FirstName: string;

    @Expose()
    @Type(() => String)
    public LastName: string;

    @Expose()
    @Type(() => String)
    public MiddleName: string;

    @Expose()
    @Type(() => String)
    public Position: string;

    @Expose()
    @Type(() => String)
    public Role: string;

    @Expose()
    @Type(() => String)
    public Commands: string[];

    @Expose()
    @Type(() => Date)
    public Block: Date;
}

export class UserAccessResponse {
    @Expose()
    @Type(() => String)
    public Id: string;

    @Expose()
    @Type(() => String)
    public Image: string;

    @Expose()
    @Type(() => String)
    public Title: string;

    @Expose()
    @Type(() => Number)
    public Grade: number;

    @Expose()
    @Type(() => Number)
    public Score: number;

    @Expose()
    @Type(() => Date)
    public Updated: Date;

    @Expose()
    @Type(() => String)
    public Progress: string;

    @Expose()
    @Type(() => String)
    public TargetItemId: string;
}

export class Achievement extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId?: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public comment: string;

    @Expose()
    @Type(() => Number)
    public rating: number;

    @Expose()
    @Type(() => AchievementUser)
    public givenBy: AchievementUser;

    @Expose()
    @Type(() => Number)
    public givenAt: number;
}

export class Team {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;
}

export class CurrentUserResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public phone?: string;

    @Expose()
    @Type(() => Achievement)
    public achievements: Achievement[];

    @Expose()
    @Type(() => String)
    public avatarId: string | null;

    @Expose()
    @Type(() => String)
    public contentManagerTeamIds?: string[];

    @Expose()
    @Type(() => String)
    public defaultAvatarColor: string | null;

    @Expose()
    @Type(() => String)
    public email: string | null;

    @Expose()
    @Type(() => String)
    public enabledNotifications?: string[];

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => JobResponse)
    public jobTitle: JobResponse | null;

    @Expose()
    @Type(() => Number)
    public lastBlockDate?: number;

    @Expose()
    @Type(() => Number)
    public lastModificationDate?: number;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public licenseId: string | null;

    @Expose()
    @Type(() => String)
    public licenseStatus: string | null;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public mentorTeamIds?: string[];

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public officeId: string | null;

    @Expose()
    @Type(() => Number)
    public rating: number;

    @Expose()
    @Type(() => Number)
    public registrationDate: number;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => Team)
    public teams: Team[];

    @Expose()
    @Type(() => Object)
    public teamRank: { [key: string]: string };

    @Expose()
    @Type(() => Number)
    public totalRank: number;

    @Expose()
    @Type(() => String)
    public role: string;

    @Expose()
    @Type(() => String)
    public companyId?: string;

    @Expose()
    @Type(() => Boolean)
    public hasEditPermission: boolean;

    isMentorOf(user: CurrentUserResponse) {
        return (
            this.id !== user.id &&
            intersection(
                this.mentorTeamIds ?? [],
                user.teams.map((team) => team.id),
            ).length > 0
        );
    }
}

export class UserListResponseTeam {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;
}

export class UserListResponse implements User {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public email: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public officeId: string;

    @Expose()
    @Type(() => String)
    public role: RoleName;

    @Expose()
    @Type(() => Number)
    public registrationDate: number;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => UserListResponseTeam)
    public teams: UserListResponseTeam[];

    @Expose()
    @Type(() => JobResponse)
    public jobTitle?: JobResponse;

    @Expose()
    @Type(() => String)
    public phone: string;

    @Expose()
    @Type(() => String)
    public enabledNotifications?: string[];

    @Expose()
    @Type(() => String)
    public mentorTeamIds?: string[];

    @Expose()
    @Type(() => String)
    public contentManagerTeamIds?: string[];

    @Expose()
    @Type(() => String)
    public avatarId?: string;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor?: string;

    @Expose()
    @Type(() => Number)
    public lastBlockDate?: number;

    @Expose()
    @Type(() => Number)
    public lastModificationDate?: number;

    @Expose()
    @Type(() => Number)
    public lastActivityDate?: number;

    @Expose()
    @Type(() => Achievement)
    public achievements?: Achievement[];

    @Expose()
    @Type(() => Number)
    public licenseApplyDate?: number;
}

export class AccountableUser extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public avatarId: string;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor?: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public email?: string;

    @Expose()
    @Type(() => JobResponse)
    public jobTitle?: JobResponse | null;

    @Expose()
    @Type(() => Number)
    public rating?: number;
}

export class UserPhoneValidationResponse {
    @Expose()
    @Type(() => String)
    public userId: string | null;

    @Expose()
    @Type(() => String)
    public state: string | null;
}

class CourseStatistics {
    @Expose()
    @Type(() => Number)
    public courseCount: number;

    @Expose()
    @Type(() => Number)
    public passedCoursesCount: number;
}

export class UserStatisticListResponse extends UserListResponse {
    @Expose()
    @Type(() => CourseStatistics)
    public courseStatistics: CourseStatistics;
}

export class MembersListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public login: string;

    @Expose()
    @Type(() => String)
    public avatarId?: string;

    @Expose()
    @Type(() => String)
    public firstName: string;

    @Expose()
    @Type(() => String)
    public lastName: string;

    @Expose()
    @Type(() => String)
    public middleName: string;

    @Expose()
    @Type(() => String)
    public state: string;

    @Expose()
    @Type(() => String)
    public role: RoleName;

    @Expose()
    @Type(() => Number)
    public lastActivityDate?: number;

    @Expose()
    @Type(() => JobResponse)
    public jobTitle?: JobResponse;

    @Expose()
    @Type(() => UserListResponseTeam)
    public teams: UserListResponseTeam[];

    @Expose()
    @Type(() => Boolean)
    public hasEditPermission: boolean;

    @Expose()
    @Type(() => String)
    public defaultAvatarColor?: string;

    @Expose()
    @Type(() => Number)
    public lastBlockDate?: number;

    @Expose()
    @Type(() => String)
    public officeId: string;
}

export class CertificatesListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public type: ResourceType;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => Number)
    public certificationDate: number;
}

export class UsersImportFileValidationResponse extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public userCount: number;
}

export class UsersImportStatusResponse extends BaseResponse {
    @Expose()
    @Type(() => Boolean)
    public isImportInProgress: boolean;

    @Expose()
    @Type(() => String)
    public userId: string | null;
}
