import { Expose, Type } from "class-transformer";
import { ResourceState, Complexity, TaskItemType } from "Enums";
import { BaseResponse } from "../BaseResponse";
import { TaskCategoryTreeResponse } from "./TaskCategoryResponse";
import { AccountableUser } from "./UserResponse";

class TaskCategory {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;
}

export class TaskListResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => Number)
    public ratingPoints: number;

    @Expose()
    @Type(() => Number)
    public lastModified: number;

    @Expose()
    @Type(() => TaskCategory)
    public category: TaskCategory;
}

export class TaskAnswerResponseItem {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public answer: string;

    @Expose()
    @Type(() => Boolean)
    public isCorrect: boolean;

    @Expose()
    @Type(() => Number)
    public orderNumber: number;

    @Expose()
    @Type(() => Boolean)
    public isUserChecked: boolean;
}

class TaskAnswerFile {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public name: string;

    @Expose()
    @Type(() => String)
    public type: string;

    @Expose()
    @Type(() => Number)
    public size: number;
}

export class TaskReadResponseItem {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public contentType: TaskItemType;

    @Expose()
    @Type(() => String)
    public question: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => Number)
    public orderNumber: number;

    @Expose()
    @Type(() => String)
    public reviewerNote: string;

    @Expose()
    @Type(() => TaskAnswerResponseItem)
    public answers: TaskAnswerResponseItem[];

    @Expose()
    @Type(() => Boolean)
    public mandatory: boolean;

    @Expose()
    @Type(() => Boolean)
    public allowScan: boolean;

    @Expose()
    @Type(() => Boolean)
    public onlyCameraPhotos: boolean;

    @Expose()
    @Type(() => String)
    public userText?: string;

    @Expose()
    @Type(() => String)
    public userNumber?: string;

    @Expose()
    @Type(() => TaskAnswerFile)
    public files?: TaskAnswerFile[];

    @Expose()
    @Type(() => TaskAnswerFile)
    public images?: TaskAnswerFile[];
}

export class TaskReadResponse extends BaseResponse {
    @Expose()
    @Type(() => String)
    public id: string;

    @Expose()
    @Type(() => String)
    public title: string;

    @Expose()
    @Type(() => String)
    public description: string;

    @Expose()
    @Type(() => String)
    public complexity: Complexity;

    @Expose()
    @Type(() => Number)
    public publicationTime?: number;

    @Expose()
    @Type(() => Number)
    public archivingTime?: number;

    @Expose()
    @Type(() => Number)
    public deadlineTime?: number;

    @Expose()
    @Type(() => Date)
    public lastModified?: Date;

    @Expose()
    @Type(() => String)
    public state: ResourceState;

    @Expose()
    @Type(() => Boolean)
    public publicAccess: boolean;

    @Expose()
    @Type(() => TaskReadResponseItem)
    public textContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public numericContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public imageContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public fileContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public singleChoiceContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => TaskReadResponseItem)
    public multipleChoiceContent: TaskReadResponseItem[];

    @Expose()
    @Type(() => Boolean)
    public mandatory: boolean;

    @Expose()
    @Type(() => TaskCategoryTreeResponse)
    public category: TaskCategoryTreeResponse;

    @Expose()
    @Type(() => String)
    public exerciseId: string;

    @Expose()
    @Type(() => String)
    public userId: string;

    @Expose()
    @Type(() => Number)
    public passingLimit: number | null | undefined;

    @Expose()
    @Type(() => Boolean)
    public geoLocationRequired: boolean;

    @Expose()
    @Type(() => String)
    public logoId: string;

    @Expose()
    @Type(() => AccountableUser)
    public accountableUser: AccountableUser;

    @Expose()
    @Type(() => Boolean)
    public hasUserAnswersOnReview?: boolean;
}

export class FilterRatingInfo extends BaseResponse {
    @Expose()
    @Type(() => Number)
    public minQuestionsCount: number;

    @Expose()
    @Type(() => Number)
    public maxQuestionsCount: number;

    @Expose()
    @Type(() => Number)
    public minRatingPoints: number;

    @Expose()
    @Type(() => Number)
    public maxRatingPoints: number;
}
