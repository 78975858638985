import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ControlledAccordion, AccordionItem, useAccordionProvider } from "@szhsin/react-accordion";
import { cloneDeep } from "lodash";
import dayjs from "dayjs";
import clsx from "clsx";
import { useQuery } from "react-query";
import { PaginationState } from "@tanstack/react-table";
import { useDispatch } from "react-redux";

import { Button, Icon, Icons } from "Uikit";
import { ProgressStatus, ResourceType, ResourceState } from "Enums";
import { Card } from "Components/Card";
import { FeedbackList } from "Components/FeedbackList/FeedbackList";
import Api from "Api";
import { ProgramReadSection } from "Api/Responses/ProgramResponse";
import { FeedbackListResponse } from "Api/Responses/FeedbackResponse";
import { FeedbackListRequest } from "Api/Requests/FeedbackRequest";
import { numCapEnd } from "helpers/numCapEnd";
import { setCurrentCourse, setCurrentMaterial } from "slices/programSlice";
import { useCurrentUser } from "hooks/useCurrentUser";
import { formatLeadTime } from "../Tasks/utils";
import { useChangeCourseFavoriteStatus, useGetUIProgram } from "./Program.hooks";
import { ProgramCourseCard } from "./ProgramCourseCard";
import { TVoidFunction } from "types";

const scrollToFeedback = () => {
    const feedbackElement = document.getElementById("feedback");
    feedbackElement?.scrollIntoView({ behavior: "smooth", block: "start" });
};

interface IActionButtonProps {
    title: string;
    onClick: TVoidFunction;
    withIcon?: boolean;
    variant?: "link" | "standard" | "outline" | undefined;
    size?: "small" | "tiny" | "medium" | "large" | "xl" | undefined;
    color?:
        | "success"
        | "common"
        | "primary"
        | "secondary"
        | "warning"
        | "caution"
        | "danger"
        | "gray"
        | "background"
        | undefined;
    className?: string;
    iconPlacement?: string;
    id?: string;
}

const ActionButton = ({
    title,
    onClick,
    withIcon = true,
    variant = undefined,
    size = "medium",
    color = undefined,
    className = "hidden sm:flex mt-3 sm:mt-0 w-full sm:w-auto rounded-lg whitespace-nowrap font-medium sm:mr-10 2xl:mr-12.5",
    id,
}: IActionButtonProps) => {
    return (
        <Button
            id={id}
            size={size}
            variant={variant}
            color={color}
            className={className}
            icon={
                withIcon ? (
                    <Icon
                        className="2xl:!w-6.25 2xl:!h-6.25"
                        icon={Icons.PlayCircle}
                        color="fill-white"
                        width={20}
                        height={20}
                    />
                ) : null
            }
            iconPlacement={withIcon ? "left" : undefined}
            onClick={onClick}
        >
            {title}
        </Button>
    );
};

interface IActionButtonsBlockProps {
    title: string;
    onClick: TVoidFunction;
    withIcon?: boolean;
    id?: string;
}

const ActionButtonsBlock = ({ title, onClick, withIcon = true, id }: IActionButtonsBlockProps) => {
    return (
        <>
            <ActionButton
                onClick={onClick}
                title={title}
                withIcon={withIcon}
                variant={"standard"}
                color={"primary"}
                id={id}
            />
            <div className="fixed bottom-14 left-0 flex sm:hidden p-2.5 w-full z-10 bg-white">
                <ActionButton onClick={onClick} title={title} withIcon={withIcon} size={"large"} className={"w-full"} />
            </div>
        </>
    );
};

interface IProgramMainProps {
    showStatistics?: boolean;
}

export const ProgramMain = ({ showStatistics = false }: IProgramMainProps) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { programId = "" } = useParams<{ programId: string }>();
    const currentUser = useCurrentUser();

    const { data: program, refetch } = useGetUIProgram(programId, { enabled: false });
    const { mutate: mutateFavoriteStatus } = useChangeCourseFavoriteStatus();
    const [urlToNavigate, setUrlToNavigate] = useState<string | null>(null);

    const [{ pageIndex, pageSize } /* setPagination */] = useState<PaginationState>({ pageIndex: 0, pageSize: 999 });
    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

    const dataQueryFeedbackList = useQuery(
        ["programFeedback", "collection", pagination, programId],
        async () => {
            const request = new FeedbackListRequest();
            request.resourceId = programId;
            request.size = pageSize;
            request.page = pageIndex;
            request.filters = {};

            return await Api.Feedback.FeedbackList(request);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const [openedAccordion, setOpenedAccordion] = useState<string>("");
    const providerValue = useAccordionProvider({
        allowMultiple: true,
        transition: true,
        transitionTimeout: 250,
    });

    const firstToOpen = useMemo(() => {
        const _sections = program?.sections ? cloneDeep(program.sections) : [];

        const section = _sections.find((section) => {
            return section.courses.some((el) =>
                el.progressStatus
                    ? [ProgressStatus.IN_PROGRESS, ProgressStatus.NOT_STARTED].includes(el.progressStatus)
                    : false,
            );
        });

        return section?.id;
    }, [program?.sections]);

    const onProgramStatusChange = useCallback(async () => {
        if (!program?.resourceId) {
            return;
        }

        try {
            const res = await Api.ProgramProgress.StartProgram(program.resourceId);

            if (!res.nextCourse?.id) {
                return;
            }

            await refetch();

            setUrlToNavigate(`/training/program/${program.resourceId}/${res.nextCourse.id}`);
        } catch (error) {
            console.log(error);
        }
    }, [program?.resourceId, refetch]);

    const toggleHandler = (section: ProgramReadSection) => {
        if (section.id === firstToOpen) {
            return;
        }

        if (openedAccordion === section.id) {
            setOpenedAccordion("");
        } else {
            providerValue.toggle(openedAccordion);
            setOpenedAccordion(section.id);
        }
    };

    const handleOnFavorite = useCallback(async () => {
        mutateFavoriteStatus(program!);
    }, [program, mutateFavoriteStatus]);

    useEffect(() => {
        dispatch(
            setCurrentCourse({
                id: "",
                title: "",
            }),
        );
        dispatch(
            setCurrentMaterial({
                id: "",
                title: "",
            }),
        );
    }, [dispatch]);

    useEffect(() => {
        if (urlToNavigate) {
            navigate(urlToNavigate, { replace: true });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [urlToNavigate]);

    // Промотка экрана к блоку с комментарием при наличии Id комментария
    useEffect(() => {
        if (dataQueryFeedbackList?.data && dataQueryFeedbackList?.data?.Content.length > 0) {
            const searchParams = new URL(window.location.href).searchParams;

            const comment = searchParams.get("comment");
            if (comment) {
                const commentBlock = document.getElementById(`comment__${comment}`);
                commentBlock?.scrollIntoView({ behavior: "smooth", block: "start" });
            }
        }
    }, [dataQueryFeedbackList?.data]);

    if (!program) {
        return null;
    }

    return (
        <div className="pb-17.5 sm:pb-20 2xl:pb-30">
            <div className="flex flex-col-reverse sm:flex-row justify-between sm:mb-8 2xl:mb-10 rounded-2xl 2xl:rounded-3xl sm:bg-background">
                <div className="block sm:hidden mt-7 mb-4 w-full h-0.25 bg-gray-blue"></div>
                <div className="flex flex-col justify-between flex-grow py-3 sm:py-8 2xl:py-10 sm:px-10 2xl:px-12.5">
                    <div>
                        <h1
                            className="mb-2 2xl:mb-2.5 break-anywhere 2xl:!text-4xl 2xl:!leading-[41px]"
                            id="userProgramTitle"
                        >
                            {program.title}
                        </h1>
                        <div
                            id="userProgramDescription"
                            className="mb-8 2xl:mb-11 text-gray-dark 2xl:text-md break-anywhere"
                        >
                            {program.description}
                        </div>
                    </div>
                    <div className="flex flex-col-reverse sm:flex-row items-center">
                        {/* Программа не пройдена */}
                        {program.progressStatus !== ProgressStatus.PASSED && !showStatistics && (
                            <ActionButtonsBlock
                                title={
                                    program.progressStatus === ProgressStatus.IN_PROGRESS
                                        ? "Продолжить"
                                        : program.progressStatus === ProgressStatus.NOT_STARTED
                                        ? "Начать прохождение"
                                        : ""
                                }
                                onClick={onProgramStatusChange}
                                id="userProgramBtn"
                            />
                        )}
                        {/* Программа пройдена */}
                        {program.progressStatus === ProgressStatus.PASSED &&
                            !program.hideUserReviews &&
                            !dataQueryFeedbackList?.data?.Content.find(({ user: { id } }: FeedbackListResponse) => {
                                return id === currentUser?.data?.id;
                            }) && (
                                <ActionButtonsBlock
                                    title={`Оценить программу`}
                                    onClick={() => {
                                        navigate(`/training/program/${programId}/complete`);
                                    }}
                                    withIcon={false}
                                    id="userProgramAddFeedbackBtn"
                                />
                            )}
                        <div className="flex flex-col sm:flex-row sm:space-x-7.5 2xl:space-x-9.5 mt-3 sm:mt-0 w-full sm:w-auto">
                            {program.progressStatus === ProgressStatus.PASSED && (
                                <div
                                    className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                    id="userProgramStatus"
                                >
                                    <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">Статус</div>
                                    <div className="rounded-2sm 2xl:rounded-lg bg-primary 2xl:p2 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 text-white">
                                        Пройдено
                                    </div>
                                </div>
                            )}
                            {program.progressStatus !== ProgressStatus.NOT_STARTED && (
                                <div
                                    className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                    id="userProgramProgress"
                                >
                                    <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">Прогресс</div>
                                    <div className="p3 2xl:p2 pt-[3px] 2xl:pt-1 text-gray-dark">
                                        {program.progressPercent}%
                                    </div>
                                </div>
                            )}
                            {program.progressStatus !== ProgressStatus.NOT_STARTED && (
                                <div
                                    className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                    id="userProgramReceivedPoints"
                                >
                                    <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">Получено</div>
                                    <div className="p3 2xl:p2 pt-[3px] 2xl:pt-1 text-gray-dark">
                                        {`${program.receivedPoints} ${numCapEnd(
                                            { one: "балл", two: "балла", few: "баллов" },
                                            program.receivedPoints,
                                        )}`}
                                    </div>
                                </div>
                            )}
                            <div
                                className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                id="userProgramCoursesCount"
                            >
                                <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">Кол-во курсов</div>
                                <div className="p3 2xl:p2 pt-[3px] 2xl:pt-1 text-gray-dark">
                                    {program.sections.reduce((acc, cur) => {
                                        return (
                                            acc +
                                            cur.courses.filter(({ state }) => state === ResourceState.ACTIVE).length
                                        );
                                    }, 0)}{" "}
                                    шт.
                                </div>
                            </div>
                            {!program.hideUserReviews && !!dataQueryFeedbackList?.data?.Content.length && (
                                <div
                                    className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                    id="userProgramRating"
                                >
                                    <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">Рейтинг</div>
                                    <div className="p3 2xl:p2 pt-[3px] 2xl:pt-1 text-gray-dark">
                                        {(
                                            dataQueryFeedbackList?.data?.Content.reduce((prev, curr) => {
                                                prev += curr.rating;
                                                return prev;
                                            }, 0) / dataQueryFeedbackList?.data?.Content.length
                                        ).toFixed(1)}{" "}
                                        (
                                        <a
                                            className="cursor-pointer text-gray-dark underline hover:no-underline"
                                            onClick={() => scrollToFeedback()}
                                        >
                                            {`${dataQueryFeedbackList?.data?.Content.length} ${numCapEnd(
                                                { one: "отзыв", two: "отзыва", few: "отзывов" },
                                                dataQueryFeedbackList?.data?.Content.length ?? 0,
                                            )}`}
                                        </a>
                                        )
                                    </div>
                                </div>
                            )}
                            {[ProgressStatus.IN_PROGRESS, ProgressStatus.PASSED].includes(
                                program.progressStatus as ProgressStatus,
                            ) &&
                                !!program.timeSpent && (
                                    <div
                                        className="flex items-center sm:flex-col sm:items-start mb-2 sm:mb-0 sm:gap-1"
                                        id="userProgramTimeSpent"
                                    >
                                        <div className="w-25 sm:w-auto p4 2xl:p3 text-gray">
                                            <span className="hidden sm:block">Время выполнения</span>
                                            <span className="block sm:hidden">Затрачено</span>
                                        </div>
                                        <div className="p3 2xl:p2 pt-[3px] 2xl:pt-1 text-gray-dark">
                                            {formatLeadTime(program?.timeSpent || 0)}
                                        </div>
                                    </div>
                                )}
                        </div>
                    </div>
                </div>
                <div className="rounded-2xl 2xl:rounded-3xl overflow-hidden flex-shrink-0" id="userProgramCover">
                    <Card
                        className="w-full h-[60vw] sm:w-100 2xl:w-125 sm:h-61 2xl:h-[305px]"
                        points={program.ratingPoints}
                        type={ResourceType.PROGRAM}
                        deadline={program.deadlineTime ? dayjs(program.deadlineTime * 1000) : undefined}
                        required={program.isRequired}
                        logoId={program.logoId}
                        progress={program.progressPercent}
                        duration={(program?.approxCompletionMinutes ?? 0) * 60}
                        isFavorite={program.isFavorite}
                        favoriteShowOnHover={false}
                        onFavoriteChange={handleOnFavorite}
                    />
                </div>
            </div>
            <ControlledAccordion providerValue={providerValue} id="userProgramCoursesBlock">
                {program.sections.map((section) => {
                    const completeCount = section.courses?.filter(
                        (course) => course.progressStatus === ProgressStatus.PASSED,
                    )?.length;

                    return (
                        <div key={section.id} className="border-b border-blue-gray">
                            <AccordionItem
                                itemKey={section.id}
                                initialEntered={section.id === firstToOpen}
                                header={(value) => {
                                    return (
                                        <div
                                            className={clsx(
                                                "flex gap-6.5 py-6 items-center",
                                                section.id !== firstToOpen && "cursor-pointer",
                                            )}
                                            onClick={() => toggleHandler(section)}
                                        >
                                            <div className="flex flex-col sm:flex-row sm:gap-6.5 w-full">
                                                <h2 className="hidden sm:flex items-center mb-1 sm:mb-0 light col-span-9 light 2xl:!text-2xl 2xl:!leading-[35px] text-left break-anywhere grow">
                                                    {section.title}
                                                </h2>
                                                <h3 className="flex sm:hidden items-center mb-1 sm:mb-0 light col-span-9 light text-left break-anywhere">
                                                    {section.title}
                                                </h3>
                                                <div className="flex items-center col-span-2 p3 2xl:text-md text-gray sm:shrink-0">
                                                    Пройдено {completeCount} из {section.courses?.length}
                                                </div>
                                            </div>
                                            <div className={`flex justify-end`}>
                                                <Button
                                                    icon={
                                                        <Icon
                                                            icon={
                                                                value.state.isEnter
                                                                    ? Icons.ChevronUp
                                                                    : Icons.ChevronDown
                                                            }
                                                            color="fill-blue-drk"
                                                            width={15}
                                                            height={15}
                                                            className="2xl:!w-4.5 2xl:!h-4.5"
                                                        />
                                                    }
                                                    iconPlacement="center"
                                                    shape="round"
                                                    size="small"
                                                    color="background"
                                                    variant="standard"
                                                    className="2xl:w-[35px] 2xl:h-[35px]"
                                                />
                                            </div>
                                        </div>
                                    );
                                }}
                                contentProps={{
                                    className: "transition-all",
                                }}
                                buttonProps={{
                                    className: "w-full",
                                }}
                            >
                                <div className="flex flex-wrap gap-x-6.5 gap-y-7 2xl:gap-x-8 2xl:gap-y-9 pb-6">
                                    {section.courses?.map((course) => {
                                        return (
                                            <ProgramCourseCard
                                                key={course.id}
                                                course={course}
                                                programId={program.resourceId}
                                                showStatistics={showStatistics}
                                            />
                                        );
                                    })}
                                </div>
                            </AccordionItem>
                        </div>
                    );
                })}
            </ControlledAccordion>
            {/* Отзывы */}
            {!program.hideUserReviews && (
                <div id="feedback">
                    <FeedbackList data={dataQueryFeedbackList?.data?.Content} />
                </div>
            )}
        </div>
    );
};
