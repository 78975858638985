import React, { SyntheticEvent, useEffect, useState } from "react";
import Api from "Api";
import { ProgramListResponse } from "Api/Responses/ProgramResponse";
import { useQuery } from "react-query";
import {
    Breadcrumbs,
    Button,
    ComboBox,
    FormGroup,
    Label,
    OptionsDropdownIndicator,
    SelectAvatarMultiValueLabel,
    SelectImgMultiValueLabel,
} from "Uikit";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { ProgressStatus, ResourceState, StatisticsReportAccess, StatisticsUsersStatus } from "Enums";
import { TasksModalTree } from "../Tasks/TasksModalTree";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { UsersModal } from "../../Modal/UsersModal";
import { numWord } from "helpers/numWord";
import { IRequestSettingsItem, ReportSettings } from "../../ReportSettings/ReportSettings";
import { ReportUsersTable } from "./ReportUsersTable";
import { StatisticProgramRequest } from "Api/Requests/StatisticRequest";
import { IStatisticValue, structurizeProgramTreeData } from "../utils";

const reportSettings = [
    {
        title: "Участник",
        key: "user",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Фамилия, Имя участника",
                value: "name",
                checked: true,
            },
            {
                title: "Логин",
                value: "login",
                checked: true,
            },
            {
                title: "Электронная почта",
                value: "email",
                checked: true,
            },
            {
                title: "Должность",
                value: "job",
                checked: true,
            },
            {
                title: "Офис",
                value: "office",
                checked: true,
            },
            {
                title: "Статус участника",
                value: "status",
                checked: true,
            },
            {
                title: "Команда",
                value: "command",
                checked: true,
            },
            {
                title: "Путь к команде",
                value: "commandPath",
                checked: true,
            },
            {
                title: "Наставник",
                value: "mentor",
                checked: true,
            },
            {
                title: "Доступ на текущий момент",
                value: "access",
                checked: true,
            },
        ],
    },
    {
        title: "Программа",
        key: "program",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Название программы",
                value: "name",
                checked: true,
            },
            {
                title: "Дата начала",
                value: "startDate",
                checked: true,
            },
            {
                title: "Дата завершения",
                value: "endDate",
                checked: true,
            },
            {
                title: "Дедлайн",
                value: "deadlineDate",
                checked: true,
            },
            {
                title: "Дата последней активности участника",
                value: "activityDate",
                checked: true,
            },
            {
                title: "Набранные баллы",
                value: "rating",
                checked: true,
            },
            {
                title: "Максимальное количество баллов",
                value: "maxRating",
                checked: true,
            },
            {
                title: "Статус программы",
                value: "status",
                checked: true,
            },
            {
                title: "Категория программы",
                value: "category",
                checked: true,
            },
            {
                title: "Статус видимости программы",
                value: "visibility",
                checked: true,
            },
            {
                title: "Ориентировочное время",
                value: "requiredTime",
                checked: true,
            },
            {
                title: "Затрачено времени",
                value: "spendTime",
                checked: true,
            },
            {
                title: "Прогресс (%)",
                value: "progress",
                checked: true,
            },
        ],
    },
    {
        title: "Курс",
        key: "course",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Пройдено курсов",
                value: "completedCourses",
                checked: true,
            },
            {
                title: "Всего курсов",
                value: "totalCourses",
                checked: true,
            },
        ],
    },
];

// Доступ на текущий момент
const accessOptions = [
    {
        value: StatisticsReportAccess.ALL,
        label: "Любой",
    },
    {
        value: StatisticsReportAccess.NO_ACCESS,
        label: "Без доступа",
    },
    {
        value: StatisticsReportAccess.HAS_ACCESS,
        label: "С доступом",
    },
];

// Статус прохождения программы
const progressOptions: { value: ProgressStatus; label: string }[] = [
    {
        value: ProgressStatus.ALL,
        label: "Любой",
    },
    {
        value: ProgressStatus.PASSED,
        label: "Пройдена",
    },
    {
        value: ProgressStatus.IN_PROGRESS,
        label: "В процессе",
    },
    {
        value: ProgressStatus.NOT_STARTED,
        label: "Не начата",
    },
];

// Статус участников
const usersStatusOptions = [
    {
        value: StatisticsUsersStatus.ALL,
        label: "Любой",
    },
    {
        value: StatisticsUsersStatus.ACTIVE,
        label: "Активные",
    },
    {
        value: StatisticsUsersStatus.BLOCKED,
        label: "Заблокированные",
    },
];

export type TTaskFilterValue = ResourceState | "ALL";

export const Programs = () => {
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [selectedAcceptedItems, setSelectedAcceptedItems] = useState<any[]>([]);
    const [showReportUsersTable, setShowReportUsersTable] = useState(false);
    const [programsValues, setProgramValues] = useState<IStatisticValue[]>([]);
    const [programsTree, setProgramsTree] = useState<any[]>([]);
    const [isModalTreeOpen, setIsModalTreeOpen] = useState(false);
    const [isFilterEmpty, setIsFilterEmpty] = useState(true);
    const [selectedCount, setSelectedCount] = useState(0);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [access, setAccess] = useState(accessOptions[0]);
    const [progress, setProgress] = useState<{ value: ProgressStatus; label: string }>(progressOptions[0]);
    const [usersStatus, setUsersStatus] = useState(usersStatusOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);

    // all programs request
    const programsDataQuery = useQuery(
        ["programsTree"],
        async () => {
            return await Api.Program.List({
                page: 0,
                size: 999,
                sort: "modifyTime,desc",
                filters: {
                    "state.notEqual": ResourceState.ARCHIVED,
                },
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: programsTreeData } = programsDataQuery;

    const { data: programsCategories } = useQuery(
        ["programCategories"],
        async () => {
            return await Api.Program.CategoryGet();
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    // all users request
    useQuery(
        ["users", "collection"],
        () =>
            Api.User.GetMembersList(0, 2000, [{ id: "firstName,lastName", desc: false }], {
                "state.in": "ACTIVE,BLOCKED",
            }),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setAllUsers(data.Content);
            },
        },
    );

    const createReport = () => {
        setShowReportUsersTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticProgramRequest = {
            programIds: programsValues.map(({ id }) => id),
            userIds: users.map(({ id }) => id),
            teamsIds: Array.from(
                new Set(
                    users.reduce((prev, { teams }) => {
                        return [...prev, ...teams.map(({ id }) => id)];
                    }, [] as string[]),
                ),
            ),
            officeIds: users.map(({ officeId }) => officeId).filter((item) => !!item),
            jobIds: users.map(({ jobTitle }) => jobTitle?.id).filter((item) => !!item) as string[],
            access: access.value,
            status: progress.value,
            userStatus: usersStatus.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateProgramStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    const onSubmitSection = () => {
        setSelectedAcceptedItems(selectedItems);
        setIsModalTreeOpen(false);
        setShowReportUsersTable(false);
    };

    // Setting programTree data
    useEffect(() => {
        if (programsTreeData?.Content && selectedAcceptedItems.length === 0) {
            setProgramsTree(
                structurizeProgramTreeData(
                    programsTreeData?.Content,
                    selectedAcceptedItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                        };
                    }),
                    programsCategories,
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programsTreeData]);

    useEffect(() => {
        setSelectedCount(selectedItems.length);
    }, [selectedItems]);

    useEffect(() => {
        setProgramsTree(
            structurizeProgramTreeData(
                programsTreeData?.Content as unknown as ProgramListResponse[],
                selectedAcceptedItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
                programsCategories,
            ),
        );

        setProgramValues(
            selectedAcceptedItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        if (selectedAcceptedItems.length === 0) {
            setUsers(allUsers);
            setAccess(accessOptions[0]);
            setProgress(progressOptions[0]);
            setUsersStatus(usersStatusOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedItems]);

    useEffect(() => {
        setUsers(allUsers);
        setAllUsersCount(allUsers.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers]);

    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Программы" />
                <Breadcrumbs.Link title="Отчет по программам" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по программам</h1>
                <div className="w-full h-full mt-5">
                    {/* Выбор программ */}
                    <ComboBox
                        className="mt-4"
                        placeholder="Выберите одну или несколько программ"
                        onChange={(options, action) => {
                            if (action && action.action === "clear") {
                                setSelectedAcceptedItems([]);
                            }
                            if (action && action.action === "remove-value") {
                                setSelectedAcceptedItems((prevItems) =>
                                    prevItems.filter(({ id }) => id !== action.removedValue.id),
                                );
                            }
                            setShowReportUsersTable(false);
                        }}
                        value={programsValues}
                        components={{
                            DropdownIndicator: OptionsDropdownIndicator({
                                onClick: (e) => {
                                    if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                        setIsModalTreeOpen((prevIsOpen) => !prevIsOpen);
                                    }
                                },
                            }),
                            ValueContainer: StackedValueContainer,
                            MultiValueLabel: SelectImgMultiValueLabel({}),
                        }}
                        onClick={(e: SyntheticEvent) => {
                            if (!(e.target as HTMLElement).closest(".ui-combo-box__multi-value__remove")) {
                                setIsModalTreeOpen((prevIsOpen) => !prevIsOpen);
                            }
                        }}
                        allPlaceholder={
                            ((programsTreeData?.Content as unknown as ProgramListResponse[]) ?? []).length > 0 &&
                            programsValues.length === programsTreeData?.Content.length
                        }
                        isSearchable={false}
                        isCreatable={false}
                        isClearable={false}
                        menuIsOpen={false}
                        isMulti
                    />
                    {/* Выбор программ - модалка */}
                    <TasksModalTree
                        isOpen={isModalTreeOpen}
                        title="Выбор программ"
                        setIsOpen={(open: boolean) => {
                            setSelectedItems(selectedAcceptedItems);
                            setProgramsTree(
                                structurizeProgramTreeData(
                                    programsTreeData?.Content as unknown as ProgramListResponse[],
                                    selectedAcceptedItems.map(({ id, state }) => {
                                        return {
                                            id,
                                            checked: state.checked,
                                        };
                                    }),
                                    programsCategories,
                                ),
                            );

                            setIsModalTreeOpen(open);
                            setIsFilterEmpty(true);
                        }}
                        treeData={programsTree}
                        checkedChange={(selectedNodes) => {
                            setSelectedItems(selectedNodes);
                        }}
                        onSubmit={onSubmitSection}
                        submitButtonTitle="Выбрать"
                        selectedCount={selectedCount}
                        onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                            setProgramsTree(
                                structurizeProgramTreeData(
                                    (programsTreeData?.Content as unknown as ProgramListResponse[]).filter(
                                        ({ state, title }) => {
                                            return (
                                                (!query ||
                                                    title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                                    query.trim() === "") &&
                                                (state === value || value === "ALL")
                                            );
                                        },
                                    ),
                                    selectedItems.map(({ id, state }) => {
                                        return {
                                            id,
                                            checked: state.checked,
                                        };
                                    }),
                                    programsCategories,
                                ),
                            );
                            setIsFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                        }}
                        emptyMessage={
                            isFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"
                        }
                    />
                    {selectedAcceptedItems.length > 0 && (
                        <>
                            <div className="mt-7 flex-wrap relative z-[70]">
                                <div className="grid items-center gap-6.5 grid-cols-2">
                                    {/* Выбор участников */}
                                    <FormGroup className="!mb-0">
                                        <Label>Участники</Label>
                                        <ComboBox
                                            placeholder="Выберите одного или нескольких участников"
                                            onChange={(options, action) => {
                                                if (action && action.action === "clear") {
                                                    setUsers([]);
                                                }
                                                if (action && action.action === "remove-value") {
                                                    setUsers(options as MembersListResponse[]);
                                                }
                                                setShowReportUsersTable(false);
                                            }}
                                            value={users}
                                            components={{
                                                DropdownIndicator: OptionsDropdownIndicator({
                                                    onClick: (e) => {
                                                        if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                                            setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                                        }
                                                    },
                                                }),
                                                // ValueContainer: SelectValueContainer({
                                                //     onClick: (e) => {
                                                //         if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                                //             setShowUsersModal((prevIsOpen) => {
                                                //                 return !prevIsOpen;
                                                //             });
                                                //         }
                                                //     },
                                                //     // oneRow: true
                                                //     allPlaceholder: users.length > 0 && allUsersCount === users.length,
                                                // }),
                                                ValueContainer: StackedValueContainer,
                                                MultiValueLabel: SelectAvatarMultiValueLabel({}),
                                            }}
                                            onClick={(e) => {
                                                if (
                                                    !(e.target as HTMLElement).closest(
                                                        ".ui-combo-box__multi-value__remove",
                                                    )
                                                ) {
                                                    setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                                }
                                            }}
                                            allPlaceholder={users.length > 0 && allUsersCount === users.length}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                            menuIsOpen={false}
                                            isMulti
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Доступ на текущий момент</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setAccess(value as { value: StatisticsReportAccess; label: string });
                                                setShowReportUsersTable(false);
                                            }}
                                            value={access}
                                            options={accessOptions}
                                            isOptionDisabled={(option) => {
                                                return (
                                                    [
                                                        StatisticsReportAccess.ALL,
                                                        StatisticsReportAccess.NO_ACCESS,
                                                    ].includes(option.value) &&
                                                    progress.value === ProgressStatus.NOT_STARTED
                                                );
                                            }}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Статус прохождения программы</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setProgress(value as { value: ProgressStatus; label: string });
                                                setShowReportUsersTable(false);
                                            }}
                                            value={progress}
                                            options={progressOptions}
                                            isOptionDisabled={(option) => {
                                                return (
                                                    [
                                                        StatisticsReportAccess.ALL,
                                                        StatisticsReportAccess.NO_ACCESS,
                                                    ].includes(access.value) &&
                                                    option.value === ProgressStatus.NOT_STARTED
                                                );
                                            }}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Статус участников</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setUsersStatus(
                                                    value as { value: StatisticsUsersStatus; label: string },
                                                );
                                                setShowReportUsersTable(false);
                                            }}
                                            value={usersStatus}
                                            options={usersStatusOptions}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            {/* Выбор участников - модалка */}
                            <UsersModal
                                isOpen={showUsersModal}
                                onClose={() => {
                                    setShowUsersModal(false);
                                }}
                                onSubmit={(users) => {
                                    setUsers(
                                        users.map((user) => {
                                            const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                            return {
                                                ...user,
                                                value: {
                                                    avatarId,
                                                    firstName,
                                                    lastName,
                                                    defaultAvatarColor,
                                                },
                                                label: `${lastName} ${firstName}`,
                                            };
                                        }),
                                    );
                                    setShowReportUsersTable(false);
                                }}
                                selectedUsersDefault={users}
                                allUsers={allUsers}
                            />
                            <div className="mt-9">
                                {showReportUsersTable ? (
                                    <>
                                        <ReportUsersTable
                                            singleProgramChoosed={selectedAcceptedItems.length === 1}
                                            onClickLoadReportFile={() => setShowReportSettings(true)}
                                            queryPayload={createPayloadParams()}
                                        />
                                        <ReportSettings
                                            isActive={showReportSettings}
                                            setIsActive={setShowReportSettings}
                                            settings={reportSettings}
                                            onSubmit={onSubmit}
                                            isReportFileFetching={false}
                                        />
                                    </>
                                ) : (
                                    <Button
                                        key={1}
                                        onClick={() => createReport()}
                                        variant="standard"
                                        size={"medium"}
                                        color={"primary"}
                                        className="w-full"
                                        disabled={!users.length}
                                    >
                                        {`Сформировать отчет по ${users.length} ${numWord(users.length, [
                                            "участнику",
                                            "участникам",
                                            "участникам",
                                        ])}`}
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
