import React, { useMemo } from "react";
import { useInfiniteQuery } from "react-query";
import Api from "Api";
import { InfiniteList } from "Components/InfiniteList/InfiniteList";
import { Empty } from "Uikit/Page/Empty";
import { Icon, Icons } from "Uikit";
import { CertificateCard } from "./CertificateCard";

interface IPersonalCertificates {
    userId: string;
}

export const PersonalCertificates = ({ userId }: IPersonalCertificates) => {
    const { data, isLoading, hasNextPage, fetchNextPage } = useInfiniteQuery(
        ["user-certificates", "collection", userId],
        async ({ pageParam = 0 }) => {
            return await Api.User.GetUserCertificates(userId, pageParam, 16);
        },
        {
            getNextPageParam: (lastPage) => {
                return lastPage.Last ? undefined : lastPage.Page + 1;
            },
        },
    );

    const certificatesList = useMemo(() => {
        const list = data?.pages.map((p) => p.Content).flat();

        return list || [];
    }, [data]);

    if (isLoading) {
        return null;
    }

    return (
        <>
            {certificatesList.length === 0 && !isLoading && (
                <Empty
                    topElement={
                        <div className="flex items-center justify-center mb-4 2xl:mb-5 w-16.5 2xl:w-20.5 h-16.5 2xl:h-20.5 bg-blue-10 rounded-full">
                            <Icon
                                icon={Icons.EmojiSad}
                                width={36}
                                height={36}
                                color="fill-primary"
                                className="2xl:!w-11.25 2xl:!h-11.25"
                            />
                        </div>
                    }
                    title="У вас нет сертификатов"
                    description={
                        <span>
                            К сожалению у вас сейчас нет сертификатов. Завершите
                            <br />
                            прохождение курса для получения
                        </span>
                    }
                />
            )}
            {certificatesList.length > 0 && (
                <InfiniteList isLoading={false} onLoadMore={fetchNextPage} hasMore={hasNextPage}>
                    <div className="grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] sm:gap-6.5 2xl:gap-8">
                        {certificatesList.map((certificate) => (
                            <CertificateCard key={certificate.id} certificate={certificate} />
                        ))}
                    </div>
                </InfiniteList>
            )}
        </>
    );
};
