import React from "react";
import { Achievement } from "Api/Responses/UserResponse";
import clsx from "clsx";
import { Tooltip } from "Uikit/Tooltip";
import { TVoidFunction } from "types";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";

const sizes = {
    small: "w-9 h-9",
    medium: "w-6 h-6",
    large: "w-11 h-11",
};

interface AchievementIconsProps {
    size?: keyof typeof sizes;
    achievements?: Achievement[];
    limit?: number;
    onClick?: TVoidFunction;
}

export const AchievementIcons = ({
    achievements: allAchievements = [],
    size = "medium",
    limit,
    onClick,
}: AchievementIconsProps) => {
    const showCap = limit && allAchievements?.length > limit;
    const achievements = showCap ? allAchievements?.slice(0, limit - 1) : allAchievements;

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };

    return (
        <div className="flex mt-3 mb-6 sm:mt-2 sm:mb-0" onClick={handleClick}>
            {achievements.map((achievement) => {
                return (
                    <div key={achievement.id} className={clsx("mr-1", sizes[size], size === "large" && "-mr-2")}>
                        <Tooltip content={achievement.name}>
                            <img
                                className={clsx(
                                    "rounded-full object-cover !opacity-1 cursor-pointer",
                                    sizes[size],
                                    size === "large" && "ring-white ring-2",
                                )}
                                src={Api.Upload.GetLogo(achievement.logoId ?? "", LogoSize.THUMB_MICRO)}
                                alt={achievement.name}
                            />
                        </Tooltip>
                    </div>
                );
            })}
            {showCap && (
                <div className={clsx("flex bg-gray rounded-full text-white items-center justify-center", sizes[size])}>
                    <div className={`${size === "large" ? "p2" : "p4"}`}>+{allAchievements?.length - limit + 1}</div>
                </div>
            )}
        </div>
    );
};
