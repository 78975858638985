import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ContentLayout } from "Containers/ContentLayout";
import { Loader } from "Uikit/Loader/Loader";
import { Breadcrumbs } from "Uikit/Breadcrumbs/Breadcrumbs";
import { TestUserFinishResponse, TestUserStartedResponse } from "Api/Responses/TestResponse";
import Api from "Api/index";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { Button } from "Uikit/Button/Button";
import clsx from "clsx";
import { Input } from "Uikit/Forms/Input";
import { Tooltip } from "Uikit/Tooltip";
import { flash } from "Uikit/Notification/flash";
import { useNavigateToSource } from "hooks/useNavigateToSouce";
import { setBackUrl, setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import { useDispatch } from "react-redux";
import { useGetUICourse } from "../Course/Course.hooks";
import { CourseStartResponse } from "Api/Responses/CourseProgressResponse";
import { ProgressStatus, TestQuestionType, ResourceType } from "Enums";
import { numCapEnd } from "helpers/numCapEnd";
import { GlobalContext } from "App";

export const TestingFinish = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { setTestingURL } = useContext(GlobalContext);

    const [test, setTest] = useState<TestUserStartedResponse>();
    const [finish, setFinish] = useState<TestUserFinishResponse>();

    const [courseId, setCourseId] = useState<string | null>(null);
    const [programId, setProgramId] = useState<string | null>(null);
    const [courseProgress, setCourseProgress] = useState<CourseStartResponse | undefined>(undefined);

    const { goSourcePage, navigateWithCurrentSource } = useNavigateToSource(
        courseId ? `/training/course/${courseId}` : "/tests",
    );

    const { data: course } = useGetUICourse(courseId ?? "");

    const onNextMaterial = async () => {
        if (!courseProgress) {
            return;
        }

        if (courseProgress.next.type === ResourceType.QUIZ) {
            navigate(
                programId
                    ? `/training/program/${programId}/${courseId}/${courseProgress.next.resourceId}`
                    : `/training/${courseId}/${courseProgress.next.resourceId}`,
                { replace: true },
            );

            dispatch(
                setBackUrl(programId ? `/training/program/${programId}/${courseId}` : `/training/course/${courseId}`),
            );
        } else {
            navigate(
                programId
                    ? `/training/program/${programId}/${courseId}/${courseProgress.next.id}/${courseProgress.next.resourceId}`
                    : `/training/course/${courseId}/${courseProgress.next.id}/${courseProgress.next.resourceId}`,
                { replace: true },
            );
        }
    };

    const getFinishMessage = () => {
        if (!finish) {
            return null;
        }

        setTestingURL(null);

        const words = finish.message.split(" ");
        const wordsWithoutLast = words.slice(0, -1);

        const lastWordColor =
            [ProgressStatus.PASSED.toString(), ProgressStatus.ON_REVIEW.toString()].indexOf(finish.progressStatus) !==
            -1
                ? "text-blue"
                : "text-red";

        return (
            <>
                {wordsWithoutLast.join(" ")}
                &nbsp;
                <span className={lastWordColor}>{words[words.length - 1]}</span>
            </>
        );
    };

    useEffect(() => {
        const testData = localStorage.getItem("test");

        if (testData === null) {
            navigate("/");
            return;
        }

        const test: TestUserStartedResponse = JSON.parse(testData);

        if (test === null) {
            navigate("/");
        }
    }, [navigate]);
    useEffect(() => {
        (async () => {
            const testData = localStorage.getItem("test");

            if (testData === null) {
                return;
            }

            const test: TestUserStartedResponse = JSON.parse(testData);

            if (test === null) {
                return;
            }

            setTest(test);
            setFinish(await Api.Test.UserFinish(test.progressId));

            const courseId = localStorage.getItem("testingCourseId");
            const programId = localStorage.getItem("testingCourseProgramId");

            if (!courseId) {
                return;
            }

            setCourseId(courseId);
            setProgramId(programId);
            setTimeout(() => {
                Api.CourseProgress.StartCourse(courseId).then((progress) => {
                    setCourseProgress(progress);
                });
            }, 2000);
        })();
    }, []);
    useEffect(() => {
        dispatch(setIsHidden(true));
        dispatch(setIsBack(true));
        dispatch(setTitle("Тест"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    // Кнопка завершения теста
    const TestingFinishButton = () => {
        let title = "Вернуться к тестам";
        let onClick = () => navigate("/tests");
        const testFailed = finish?.progressStatus === ProgressStatus.FAILED;
        const testPassed = finish?.progressStatus === ProgressStatus.PASSED;
        const testOnReview = finish?.progressStatus === ProgressStatus.ON_REVIEW;
        const courseInProgress = courseProgress?.progressStatus === ProgressStatus.IN_PROGRESS;

        // обычное прохождение теста вне курса/программы
        if (
            !courseId &&
            [ProgressStatus.PASSED, ProgressStatus.ON_REVIEW, ProgressStatus.FAILED].includes(
                finish?.progressStatus as ProgressStatus,
            ) &&
            finish?.attemptsAvailable === 0
        ) {
            title = "Вернуться к тестам";
            onClick = () => navigate("/tests");
        }
        // тест провален
        if (testFailed && (finish.attemptsAvailable === null || finish.attemptsAvailable > 0)) {
            title = "Начать тест заново";
            onClick = async () => {
                try {
                    await Api.Test.UserStart(finish.newQuizAttemptId);
                    localStorage.removeItem("test");

                    navigateWithCurrentSource("/testing");
                } catch {
                    flash.error("Вы не можете начать проходить этот тест");
                }
            };
        }
        // Прохождение теста в составе курса
        if (courseId && !(testFailed && (finish.attemptsAvailable === null || finish.attemptsAvailable > 0))) {
            const correctAnswersPercentage =
                Math.floor(
                    (finish as TestUserFinishResponse)?.answers.filter(({ approved }) => approved === true).length /
                        (finish as TestUserFinishResponse)?.answers.filter(({ approved }) => approved !== null).length,
                ) * 100;
            // Если в тесте всего один текстовый вопрос
            const isOnlyOneTextQuestion = isNaN(correctAnswersPercentage);
            // Тест набрал достаточное кол-во правильных ответов и отправлен на проверку, при этом ещё остались материалы для прохождения
            if (
                courseProgress?.next &&
                ([ProgressStatus.BLOCKED, ProgressStatus.ON_REVIEW, ProgressStatus.NOT_STARTED].includes(
                    courseProgress.next.progressStatus,
                ) ||
                    (testOnReview && correctAnswersPercentage >= finish.passPercentage))
            ) {
                title = "Следующий материал";
                onClick = onNextMaterial;
            }
            // Тест набрал достаточное кол-во правильных ответов и отправлен на проверку, при этом не осталось материалорв для прохождения
            if (!courseProgress?.next) {
                if (
                    testPassed ||
                    testFailed ||
                    (testOnReview && correctAnswersPercentage >= finish.passPercentage && courseInProgress)
                ) {
                    title = "Завершить курс";
                    onClick = () => {
                        localStorage.removeItem("testingCourseId");
                        localStorage.removeItem("testingCourseProgramId");
                        navigate(
                            programId
                                ? `/training/program/${programId}/${courseId}/complete`
                                : `/training/course/${courseId}/complete`,
                        );
                    };
                }
                // Тест отправлен на проверку, но не набрал достаточного кол-ва правильных ответов
                if (
                    testOnReview &&
                    courseInProgress &&
                    (correctAnswersPercentage < finish.passPercentage || isOnlyOneTextQuestion)
                ) {
                    title = "Вернуться в курс";
                    onClick = () => {
                        navigate(
                            programId ? `/training/program/${programId}/${courseId}` : `/training/course/${courseId}`,
                        );
                    };
                }
            }
        }

        return (
            <Button
                className="w-full text-center px-14 py-5 sm:!px-5.5 sm:!py-2.5 2xl:!px-7 2xl:!py-3 sm:text-md 2xl:!text-lg sm:w-[272px] 2xl:w-[340px] sm:!h-[66px] 2xl:!h-[82px] sm:!rounded-xxl 2xl:!rounded-2.5xl"
                onClick={onClick}
                id="userTestingFinishBtn"
            >
                {title}
            </Button>
        );
    };

    return (
        <ContentLayout className="mx-auto sm:pl-4 sm:pr-6.5 max-w-[1216px] 2xl:max-w-[1506px] h-full">
            {!(finish && test && (!courseId || (courseId && courseProgress))) && (
                <div className="w-full h-full flex-center mt-25 px-10">
                    <Loader />
                </div>
            )}
            {finish && test && (!courseId || (courseId && courseProgress)) && (
                <div className="relative flex flex-col h-full">
                    <Breadcrumbs className="hidden sm:block" id="userTestingFinishBreadcrumbs">
                        {!courseId && <Breadcrumbs.Link title="Тестирование" url="/tests" />}
                        {courseId && <Breadcrumbs.Link title="Моё обучение" url="/training" />}
                        {courseId && course && (
                            <Breadcrumbs.Link title={course.title} url={`/training/course/${course.resourceId}`} />
                        )}
                        <Breadcrumbs.Link title={finish.title} url={`/tests/${finish.id}`} />
                    </Breadcrumbs>
                    <div className="hidden sm:flex justify-end w-full mb-1 pr-7.5">
                        <div className="cursor-pointer" onClick={goSourcePage} id="userTestingFinishCloseBtn">
                            <Icon
                                icon={Icons.Close}
                                width={20}
                                height={20}
                                color="fill-blue-drk"
                                className="2xl:!w-6.25 2xl:!h-6.25"
                            />
                        </div>
                    </div>
                    <div
                        className={clsx(
                            "flex flex-col items-center mt-3 sm:mt-0 mb-5 sm:mb-12 2xl:mb-15 w-full text-center sm:text-left",
                            !finish.wrongAnswersInfo || finish.wrongAnswersInfo.length === 0 ? "sm:my-auto" : "",
                        )}
                    >
                        {[
                            ProgressStatus.PASSED,
                            ProgressStatus.FAILED,
                            ProgressStatus.ON_REVIEW,
                            ProgressStatus.RE_WORK,
                        ].includes(finish.progressStatus) && (
                            <h1
                                className="mb-5 2xl:mb-6.25 2xl:!text-4xl 2xl:!leading-[41px]"
                                id="userTestingFinishTitle"
                            >
                                {finish.progressStatus === ProgressStatus.PASSED && (
                                    <>
                                        {/* Поздравляем! Тест успешно <span className="text-blue">пройден</span> */}
                                        {getFinishMessage()}
                                    </>
                                )}
                                {finish.progressStatus === ProgressStatus.ON_REVIEW && (
                                    <>
                                        Тест отправлен <span className="text-blue">на проверку</span>
                                    </>
                                )}
                                {(finish.progressStatus === ProgressStatus.FAILED ||
                                    finish.progressStatus === ProgressStatus.RE_WORK) && (
                                    <>
                                        {/* К сожалению, тест был <span className="text-red">провален</span> */}
                                        {getFinishMessage()}
                                    </>
                                )}
                            </h1>
                        )}
                        <div className="flex flex-col sm:flex-row mb-12 2xl:mb-15" id="userTestingFinishInfo">
                            {finish.progressStatus === ProgressStatus.PASSED &&
                                (!!finish.ratingPoints || finish.ratingPoints === 0) && (
                                    <p className="mb-2 sm:mb-0 px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p3 2xl:p2 text-blue-drk">
                                        Получено:{" "}
                                        <span className="text-blue">
                                            {finish.ratingPoints}{" "}
                                            {numCapEnd(
                                                { one: "балл", two: "балла", few: "баллов" },
                                                Number(finish.ratingPoints),
                                            )}
                                        </span>
                                    </p>
                                )}
                            {finish.answers.filter((p) => p.type === "TEXT").length !== 0 && (
                                <p className="mb-2 sm:mb-0 px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p3 2xl:p2 text-blue-drk">
                                    Проверено: {finish.answers.filter((p) => p.approved !== null).length}
                                    &nbsp;из {finish.answers.length}
                                </p>
                            )}
                            <p className="mb-2 sm:mb-0 px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p3 2xl:p2 text-blue-drk">
                                Правильно: {finish.answers.filter((p) => p.approved).length}
                                &nbsp;из {finish.answers.filter((p) => p.type !== "TEXT").length}
                            </p>
                            <p className="mb-2 sm:mb-0 px-3 2xl:px-3.75 sm:border-r sm:border-gray-stroke p3 2xl:p2 text-blue-drk last:border-0">
                                Затрачено времени:
                                {finish.timeSpent >= 3600 ? " " + Math.floor(finish.timeSpent / 3600) + "ч" : ""}
                                {finish.timeSpent < 60
                                    ? " 1мин"
                                    : " " + Math.ceil((finish.timeSpent % 3600) / 60) + "мин"}
                            </p>
                            {finish.attemptsAvailable !== null && (
                                <p className="mb-2 sm:mb-0 px-3 2xl:px-3.75 p3 2xl:p2 text-blue-drk last:border-0">
                                    Попыток осталось: {finish.attemptsAvailable}
                                </p>
                            )}
                        </div>
                        <div
                            className="flex flex-wrap justify-center gap-1 sm:gap-2 2xl:gap-2.5 sm:w-175 2xl:w-[875px]"
                            id="userTestingFinishQuestions"
                        >
                            {finish.answers.map((answer, index) => {
                                return (
                                    <Tooltip
                                        key={answer.id}
                                        content={answer.approved === null ? "Ответ на проверке" : ""}
                                        disabled={answer.approved !== null}
                                    >
                                        <div
                                            className={clsx(
                                                "flex justify-center items-center w-10 2xl:w-12.5 h-10 2xl:h-12.5 rounded-lg 2xl:rounded-2lg p2-accent 2xl:text-md text-black",
                                                answer.approved && "bg-blue-10 text-blue",
                                                answer.approved === null && "border border-gray-blue",
                                                !answer.approved && answer.approved !== null && "bg-red-10 text-red",
                                            )}
                                        >
                                            {index + 1}
                                        </div>
                                    </Tooltip>
                                );
                            })}
                        </div>
                    </div>
                    <div
                        className={clsx(
                            "flex justify-center gap-2 items-center px-3 sm:pr-0 sm:!pl-16 2xl:!pl-20  w-full sm:h-50 sm:bg-background sm:py-22.5 2xl:py-[113px]",
                            !finish.wrongAnswersInfo || finish.wrongAnswersInfo.length === 0
                                ? "fixed left-0 bottom-6 sm:bottom-0"
                                : "",
                        )}
                    >
                        <TestingFinishButton />
                    </div>
                    <div className="flex flex-col items-center">
                        {finish.wrongAnswersInfo &&
                            finish.wrongAnswersInfo.length !== 0 &&
                            finish.answers
                                .filter((p) => p.approved !== null && !p.approved)
                                .map((answer, index) => {
                                    const questionIndex = finish.wrongAnswersInfo.findIndex((p) => p.id === answer.id);
                                    const question = finish.wrongAnswersInfo[questionIndex];

                                    return (
                                        <div
                                            key={answer.id}
                                            className="mb-4 sm:mb-5 2xl:mb-6.5 p-4 sm:p-6 2xl:p-7.5 w-full sm:w-175 2xl:w-[875px] bg-background rounded-xl 2xl:rounded-2xl"
                                            id={"userTestingFinishWrongAnswer" + index}
                                        >
                                            <div className="flex items-center mb-5 2xl:mb-6.25">
                                                <div className="flex justify-center items-center mr-1.5 2xl:mr-2 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-red-10 border border-red rounded-md 2xl:rounded-lg p3 2xl:p1 text-red leading-0">
                                                    {questionIndex + 1}
                                                </div>
                                                <span className="p1-accent 2xl:text-md">{question.text}</span>
                                            </div>
                                            {question.type === TestQuestionType.NUMBER && (
                                                <div className="relative">
                                                    <Input
                                                        className="text-red !bg-white border-0"
                                                        type="number"
                                                        placeholder="Введите число"
                                                        value={question.numericAnswer}
                                                        rootClassName="!bg-transparent"
                                                    />
                                                    <span className="absolute top-2 2xl:top-3 right-4.5 2xl:right-6 text-blue">
                                                        Правильно: {question.correctNumericAnswer}
                                                    </span>
                                                </div>
                                            )}
                                            {(question.type === TestQuestionType.ONE_OPTION ||
                                                question.type === TestQuestionType.MULTIPLE_OPTIONS) && (
                                                <div className="flex flex-wrap justify-between">
                                                    {question.answers.map((answer) => {
                                                        return (
                                                            <div
                                                                key={answer.id}
                                                                className={clsx(
                                                                    "flex mb-4 2xl:mb-5 cursor-pointer last:mb-0",
                                                                    answer.pictureId
                                                                        ? "flex-col"
                                                                        : "items-center w-full",
                                                                )}
                                                            >
                                                                <div
                                                                    className={
                                                                        answer.pictureId ? "flex items-center" : ""
                                                                    }
                                                                >
                                                                    {question.type !==
                                                                        TestQuestionType.MULTIPLE_OPTIONS &&
                                                                        answer.isSelected &&
                                                                        !answer.isCorrect && (
                                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-red rounded-full">
                                                                                <Icon
                                                                                    icon={Icons.Close}
                                                                                    width={24}
                                                                                    height={24}
                                                                                    color="fill-white"
                                                                                    className="2xl:!w-7.5 2xl:!h-7.5"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    {question.type !==
                                                                        TestQuestionType.MULTIPLE_OPTIONS &&
                                                                        !answer.isSelected &&
                                                                        answer.isCorrect && (
                                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-blue rounded-full">
                                                                                <Icon
                                                                                    icon={Icons.Minus}
                                                                                    width={18}
                                                                                    height={18}
                                                                                    color="fill-blue"
                                                                                    className="2xl:!w-6 2xl:!h-6"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    {question.type !==
                                                                        TestQuestionType.MULTIPLE_OPTIONS &&
                                                                        answer.isSelected &&
                                                                        answer.isCorrect && (
                                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-blue rounded-full">
                                                                                <Icon
                                                                                    icon={Icons.Check}
                                                                                    width={20}
                                                                                    height={20}
                                                                                    color="fill-white"
                                                                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    {question.type !==
                                                                        TestQuestionType.MULTIPLE_OPTIONS &&
                                                                        !answer.isSelected &&
                                                                        !answer.isCorrect && (
                                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-gray-stroke rounded-full"></div>
                                                                        )}
                                                                    {question.type ===
                                                                        TestQuestionType.MULTIPLE_OPTIONS &&
                                                                        answer.isSelected &&
                                                                        !answer.isCorrect && (
                                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-red rounded-md">
                                                                                <Icon
                                                                                    icon={Icons.Close}
                                                                                    width={24}
                                                                                    height={24}
                                                                                    color="fill-white"
                                                                                    className="2xl:!w-7.5 2xl:!h-7.5"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    {question.type ===
                                                                        TestQuestionType.MULTIPLE_OPTIONS &&
                                                                        !answer.isSelected &&
                                                                        answer.isCorrect && (
                                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-blue rounded-md">
                                                                                <Icon
                                                                                    icon={Icons.Minus}
                                                                                    width={18}
                                                                                    height={18}
                                                                                    color="fill-blue"
                                                                                    className="2xl:!w-6 2xl:!h-6"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    {question.type ===
                                                                        TestQuestionType.MULTIPLE_OPTIONS &&
                                                                        answer.isSelected &&
                                                                        answer.isCorrect && (
                                                                            <div className="flex justify-center items-center mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-blue rounded-md">
                                                                                <Icon
                                                                                    icon={Icons.Check}
                                                                                    width={20}
                                                                                    height={20}
                                                                                    color="fill-white"
                                                                                    className="2xl:!w-6.25 2xl:!h-6.25"
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    {question.type ===
                                                                        TestQuestionType.MULTIPLE_OPTIONS &&
                                                                        !answer.isSelected &&
                                                                        !answer.isCorrect && (
                                                                            <div className="mr-2 2xl:mr-2.5 w-6 2xl:w-7.5 h-6 2xl:h-7.5 bg-white border border-gray-stroke rounded-md"></div>
                                                                        )}
                                                                    {answer.pictureId && answer.isCorrect && (
                                                                        <span className="p1-accent text-blue 2xl:text-md">
                                                                            Верный ответ
                                                                        </span>
                                                                    )}
                                                                    {answer.pictureId &&
                                                                        answer.isSelected &&
                                                                        !answer.isCorrect && (
                                                                            <span className="p1-accent text-red 2xl:text-md">
                                                                                Неверно
                                                                            </span>
                                                                        )}
                                                                </div>
                                                                {answer.text && (
                                                                    <span className="text-gray-dark 2xl:text-md">
                                                                        {answer.text}
                                                                    </span>
                                                                )}
                                                                {answer.pictureId && (
                                                                    <img
                                                                        className={clsx(
                                                                            "mt-1 2xl:mt-1.25 w-32 sm:w-51 2xl:w-[255px] h-32 sm:h-51 2xl:h-[255px] rounded-2lg object-cover",
                                                                            answer.isSelected
                                                                                ? "border-2 border-blue"
                                                                                : "",
                                                                            answer.isCorrect
                                                                                ? "border-2 border-blue"
                                                                                : "",
                                                                            answer.isSelected && !answer.isCorrect
                                                                                ? "border-2 border-red"
                                                                                : "",
                                                                        )}
                                                                        src={`/service/lms-upload/api/file/download/${answer.pictureId}`}
                                                                        alt={answer.id}
                                                                    />
                                                                )}
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            )}
                                        </div>
                                    );
                                })}
                    </div>
                </div>
            )}
        </ContentLayout>
    );
};
