import React, { SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useDialog } from "hooks/useDialog";
import Api from "Api";
import { Button, Checkbox, Icon, Icons, PopoverList, SelectMultiValueRemove, SelectValueContainer, Table } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { CoursesRowActionPopover } from "./CoursesRowActionPopover";
import { CourseListResponse } from "Api/Responses/CourseResponse";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { BasePaginationResponse } from "Api/BaseResponse";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import { useQuery } from "react-query";
import { ResourceState, ResourceType, RoleName } from "Enums";
import { useCurrentUser } from "hooks/useCurrentUser";
import { CoursesEmpty } from "./CoursesEmpty";
import { TVoidFunction } from "types";
import { LogoSize } from "Api/Services/UploadApi";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

interface ICourseTable {
    categoryId?: string;
    fetchCourses: (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => Promise<BasePaginationResponse<CourseListResponse>>;
    onCopy: (id: string) => void;
    onState: (ids: string[], state: string) => void;
    pageCount: number;
    selectedCategories: string[];
    courses: CourseListResponse[];
    pagination: PaginationState;
    sorting: SortingState;
    filters: { [id: string]: any };
    setPagination: (pagination: SetStateAction<PaginationState>) => void;
    setSorting: (sort: SetStateAction<SortingState>) => void;
    setFilters: (filter: SetStateAction<{ [id: string]: any }>) => void;
    isFetching: boolean;
    onCreateCategory: TVoidFunction;
}
export const CoursesTable = ({
    categoryId,
    courses,
    onCopy,
    onState,
    pageCount,
    setFilters,
    isFetching,
    pagination,
    sorting,
    filters,
    setPagination,
    setSorting,
    onCreateCategory,
}: ICourseTable) => {
    const navigate = useNavigate();
    const currentUser = useCurrentUser();

    const { dialogState, openDialog, closeDialog } = useDialog();

    const [rowSelection, setRowSelection] = useState<{ [key: string]: boolean }>({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const [isSelectedState, setIsSelectedState] = useState<boolean>(false);
    const [isTasksArchived, setIsTasksArchived] = useState(false);

    const [isFilters, setIsFilters] = useState(false);

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const { data: ratingInfo } = useQuery(["courses/filter-rating-info"], Api.Course.FilterRatingInfo);

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const onCopyHandler = useCallback(
        async (course: CourseListResponse) => {
            openDialog({
                title: "Создание копии курса",
                description: "«" + course.title + "»",
                content: "Вы уверены, что хотите создать копию курса? Копируется весь курс со всеми настройками",
                closeBtnText: "Отмена",
                submitBtnText: "Создать копию",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onCopy(course.id);
                    closeDialog();
                },
            });
        },
        [closeDialog, openDialog, onCopy],
    );
    const onStateHandler = useCallback(
        (state: string) => {
            setIsSelectedState(false);

            const ids = Object.keys(rowSelection);
            const isMultiple = ids.length > 1;

            const singleCourseTitle = !isMultiple ? "«" + courses.find((i) => i.id === ids[0])?.title + "»" : undefined;
            let content = `У всех пользователей ${!isMultiple ? "пропадет данный курс" : "пропадут данные курсы"}`;

            if (state === ResourceState.ACTIVE) {
                content = `Все пользователи, у кого есть доступ, увидят ${
                    !isMultiple ? "данный курс" : "данные курсы"
                }`;
            }

            openDialog({
                title: `Изменить статус на «${state === ResourceState.ACTIVE ? "Активен" : "Скрыт"}»`,
                description: isMultiple ? `Выбрано курсов: ${ids.length}` : singleCourseTitle,
                content: content,
                closeBtnText: "Отмена",
                submitBtnText: "Изменить",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onState(ids, state);
                    setRowSelection({});

                    // invalidate("courses");
                    closeDialog();
                },
            });
        },
        [closeDialog, openDialog, rowSelection, onState, courses],
    );

    const onArchiveHandler = useCallback(
        async (data: CourseListResponse | string[]) => {
            const isGotChecked = Array.isArray(data);
            const isMultiple = isGotChecked && data.length > 1;

            const rows = isGotChecked ? data : [data.id];

            const onRequestSubmit = (ids: string[], state: ResourceState) => {
                setIsTasksArchived(true);

                onState(ids, state);
                setRowSelection({});

                // invalidate("courses");
                closeDialog();

                setIsTasksArchived(false);
            };

            const ids: string[] = !isGotChecked ? [data.id] : data;
            setIsSelectedState(false);

            let courseTitle = "";

            if (!isGotChecked) {
                courseTitle = data.title;
            }

            if (isGotChecked && data.length === 1) {
                courseTitle = courses.find((course) => course.id === ids[0])!.title;
            }

            // Проверка возможности архивирования курса
            const available = await Api.Course.StateCheck(rows, ResourceState.ARCHIVED, ResourceType.COURSE);

            if (available.length !== ids.length) {
                openDialog({
                    title: "Архивирование невозможно",
                    description: isMultiple ? `Выбрано курсов: ${data.length}` : `«${courseTitle}»`,
                    content: isMultiple
                        ? "Некоторые выделенные курсы нельзя архивировать, так как тест из состава курса находится в разделе «Проверка». Вы можете архивировать часть курсов или скрыть все выделенные курсы, чтобы они перестали отображаться у пользователей"
                        : "Курс нельзя архивировать, так как тест в его составе находится в разделе «Проверка». Вы можете скрыть курс для пользователей и архивировать после проверки результатов теста",
                    closeBtnText: "Отмена",
                    submitBtnText: isMultiple ? "Скрыть все" : "Скрыть",
                    submitBtnColor: "danger",
                    onRequestClose: () => closeDialog(),
                    onRequestSubmit: () => {
                        onRequestSubmit(ids, ResourceState.HIDDEN);
                    },
                    onRequestSubmitAdd:
                        isMultiple && available.length > 0
                            ? () => {
                                  onRequestSubmit(available, ResourceState.ARCHIVED);
                              }
                            : undefined,
                    submitBtnAddText: `Архивировать ${available.length} из ${ids.length}`,
                });

                return;
            }

            openDialog({
                title: isMultiple ? "Архивирование курсов" : "Архивирование курса",
                description: isMultiple ? `Выбрано курсов: ${data.length}` : `«${courseTitle}»`,
                content: isMultiple
                    ? "Вы действительно хотите архивировать курсы? Курсы переместятся в Архив и исчезнут у пользователей"
                    : "Вы действительно хотите архивировать курс? Курс переместится в Архив и исчезнет у пользователей",
                closeBtnText: "Отмена",
                submitBtnText: "Архивировать",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onState(rows, ResourceState.ARCHIVED);
                    setRowSelection({});

                    // invalidate("courses");
                    closeDialog();
                },
            });
        },
        [closeDialog, openDialog, onState, courses],
    );

    const responsibleList = useResponsibleList();
    const loadResponsibleListOptions = useCallback(
        async (inputValue: string): Promise<any> => {
            if (inputValue === "") {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const selectOptions = responsibleList
                .filter((p) => p.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
                .map((p) => ({ label: p.label, value: p.payload }));

            return {
                options: selectOptions,
                hasMore: false,
            };
        },
        [responsibleList],
    );

    const loadActiveUserOptions = async (
        inputValue: string,
        prevOptions: unknown,
        { page }: { page: number },
    ): Promise<any> => {
        if (inputValue === "") {
            return {
                options: [],
                hasMore: false,
            };
        }

        const users = await Api.User.GetActiveListOptions(inputValue, page);
        const usersSelectOptions: { label: string; value: AccountableUser | UserListResponse | undefined }[] =
            users.Content.map((p) => ({ label: p.lastName + " " + p.firstName, value: p }));

        return {
            options: usersSelectOptions,
            hasMore: users.TotalPages > page,
            additional: {
                page: page + 1,
            },
        };
    };

    const formatUserOptionsLabel = ({
        label,
        value,
    }: {
        label: string;
        value: AccountableUser | UserListResponse | undefined;
    }): React.ReactElement => {
        return (
            <div className="flex gap-2">
                {value?.avatarId ? (
                    <img
                        src={`/service/lms-upload/api/file/download/${value.avatarId}`}
                        className="w-5 h-5 rounded-full"
                    />
                ) : (
                    <div
                        className={`flex-center w-5 h-5 rounded-full`}
                        style={{ backgroundColor: value?.defaultAvatarColor }}
                    >
                        <span className="text-white text-xxs">
                            {value?.firstName[0]}
                            {value?.lastName[0]}
                        </span>
                    </div>
                )}
                <div>{label}</div>
            </div>
        );
    };

    const columns = React.useMemo<ColumnDef<CourseListResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Название",
                accessorKey: "title",
                className: "w-1/2",
                cell: (info) => {
                    return (
                        <div
                            className="group flex items-center space-x-3 cursor-pointer"
                            onClick={() => navigate(`/admin/course/${info.row.original.id}`)}
                        >
                            {info.row.original.logoId && (
                                <img
                                    className="rounded-md w-[54px] h-9 object-cover cursor-pointer"
                                    src={Api.Upload.GetLogo(info.row.original.logoId, LogoSize.THUMB_MICRO)}
                                    alt={info.row.original.title}
                                />
                            )}
                            {!info.row.original.logoId && (
                                <div className="rounded-md w-[54px] h-9 bg-gray-blue cursor-pointer" />
                            )}
                            <MultiClumpTooltip
                                className="group-hover:text-blue"
                                clamp={1}
                                label={String(info.getValue())}
                            />
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Статус",
                accessorKey: "state",
                enableResizing: true,
                size: 100,
                cell: (info) =>
                    info.getValue() === ResourceState.ACTIVE ? (
                        "Активен"
                    ) : (
                        <span className="text-gray-text">Скрыт</span>
                    ),
                footer: (props) => props.column.id,
            },
            {
                header: "Оценка",
                accessorKey: "averageReviewRating",
                enableResizing: true,
                size: 100,
                cell: (info) => {
                    return (
                        <div>
                            {info.row.original.averageReviewRating === 0
                                ? "-"
                                : info.row.original.averageReviewRating.toFixed(1)}
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Баллы",
                accessorKey: "ratingPoints",
                enableResizing: true,
                size: 100,
                cell: (info) => {
                    return <div>{info.row.original.ratingPoints}</div>;
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Обновлен",
                accessorKey: "modifyTime",
                enableResizing: true,
                size: 200,
                cell: ({
                    row: {
                        original: { modifyTimestamp },
                    },
                }) => {
                    return formatDate(new Date(modifyTimestamp * 1000), DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
            {
                id: "buttons",
                header: "",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 90,
                cell: ({ row }) => {
                    return (
                        <div className="flex" id={"adminCoursesTableGroupButton" + row.id}>
                            <Button
                                shape="round"
                                color="common"
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement="center"
                                onClick={() => navigate(`/admin/course/${row.original.id}/edit`)}
                            />
                            <CoursesRowActionPopover
                                course={row.original}
                                onCopyHandler={onCopyHandler}
                                onArchiveHandler={onArchiveHandler}
                            />
                        </div>
                    );
                },
            },
        ],
        [navigate, onArchiveHandler, onCopyHandler],
    );
    const filtersConfig = useMemo(
        () => [
            {
                label: "Дата обновления",
                fields: [
                    {
                        accessor: "modifyTimestamp",
                        type: "date-range",
                    },
                ],
            },
            {
                label: "Награда, баллы",
                fields: [
                    {
                        accessor: "ratingPoints",
                        type: "range",
                        min: ratingInfo?.minRatingPoints,
                        max: ratingInfo?.maxRatingPoints,
                    },
                ],
            },
            {
                label: "Оценка курса, баллы",
                fields: [
                    {
                        accessor: "averageReviewRating",
                        type: "range",
                        min: ratingInfo?.minAverageReviewRating,
                        max: ratingInfo?.maxAverageReviewRating,
                    },
                ],
            },
            {
                label: "Назначенные на команду",
                fields: [
                    {
                        accessor: "assignedToTeam.in",
                        type: "team-multi-select",
                    },
                ],
            },
            {
                label: "Назначенные на участника",
                fields: [
                    {
                        accessor: "assignedToUser.in",
                        type: "async-multi-select",
                        placeholder: "Выберите участника",
                        loadOptions: loadActiveUserOptions,
                        formatOptionLabel: formatUserOptionsLabel,
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            {
                label: "Ответственный",
                fields: [
                    {
                        accessor: "managerUserId.in",
                        type: "async-multi-select",
                        placeholder: "Выберите ответственного",
                        loadOptions: loadResponsibleListOptions,
                        formatOptionLabel: formatUserOptionsLabel,
                        components: {
                            MultiValueRemove: SelectMultiValueRemove,
                            ValueContainer: SelectValueContainer({}),
                        },
                        lazy: true,
                    },
                ],
            },
            // {
            //     label: "Категория",
            //     fields: [
            //         {
            //             accessor: "categories",
            //             type: "multi-select",
            //             placeholder: "Выберите категорию",
            //             options: []
            //         }
            //     ]
            // },
            {
                label: "Дополнительно",
                fields: [
                    {
                        accessor: "isRequired.equal",
                        label: "Обязательные",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "deadlineTimestamp.isNull",
                        label: "С дедлайном",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "deadlineTimestamp.lessThanOrEqual",
                        label: "Дедлайн прошел",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "state.equal",
                        label: "Скрытые",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "publicAccess.equal",
                        label: "С общим доступом",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "issueCertificate.equal",
                        label: "Выдают сертификат",
                        type: "checkbox",
                        default: false,
                    },
                    {
                        accessor: "assigned",
                        label: "Никому не назначены",
                        type: "checkbox",
                        default: false,
                    },
                ],
            },
        ],
        [ratingInfo, loadResponsibleListOptions],
    );

    const controlButtons = (
        <div className="flex justify-end items-center space-x-4">
            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement="left"
                onClick={() => setIsFilters(true)}
                id="adminCoursesBtnFilter"
            >
                Фильтры
            </Button>
            <Button
                className="rounded-lg whitespace-nowrap font-medium"
                size="medium"
                icon={<Icon icon={Icons.PlusFilled} width={20} height={20} color="fill-[#FFFFFF]" />}
                iconPlacement="left"
                onClick={() => navigate(`/admin/course${categoryId ? `?categoryId=${categoryId}` : ""}`)}
                id="adminCoursesBtnCreateCourse"
            >
                Создать курс
            </Button>
        </div>
    );

    const selectButtons = (
        <div className="flex justify-end items-center space-x-5">
            <Button
                onClick={() => onArchiveHandler(Object.keys(rowSelection))}
                color="danger"
                size="medium"
                id="adminCoursesBtnArchive"
            >
                Архивировать
            </Button>
            <PopoverList offset={[0, 10]} visible={isSelectedState} onClickOutside={() => setIsSelectedState(false)}>
                <Button
                    variant="outline"
                    size="medium"
                    color="common"
                    className="border-gray-blue"
                    icon={<Icon className="" icon={Icons.ChevronDown} width={10} height={6} />}
                    iconPlacement="right"
                    onClick={() => setIsSelectedState(true)}
                    id="adminCoursesBtnChangeStatus"
                >
                    Изменить статус
                </Button>
                <PopoverList.Item name="setStatusHide" onClickItem={() => onStateHandler(ResourceState.HIDDEN)}>
                    Скрыт
                </PopoverList.Item>
                <PopoverList.Item name="setStatusActive" onClickItem={() => onStateHandler(ResourceState.ACTIVE)}>
                    Активен
                </PopoverList.Item>
            </PopoverList>
        </div>
    );

    useEffect(() => {
        setRowSelection({});
    }, [categoryId]);

    return (
        <>
            <Confirmation
                {...dialogState}
                requestSubmitDisabled={isTasksArchived}
                requestSubmitAddDisabled={isTasksArchived}
            />

            {isFiltersEmpty && courses && courses.length === 0 && (
                <CoursesEmpty
                    isEmptyCategories={categoryId === undefined}
                    onCreateCategoryClick={onCreateCategory}
                    onCreateProgramClick={() =>
                        navigate(`/admin/course${categoryId ? `?categoryId=${categoryId}` : ""}`)
                    }
                />
            )}

            {!isFiltersEmpty || (courses && courses.length > 0) ? (
                <>
                    <Filter
                        isActive={isFilters}
                        setIsActive={setIsFilters}
                        configuration={filtersConfig as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                        hideTeamMembers={currentUser?.data?.role === RoleName.ADMIN}
                    />
                    <Table
                        id="adminTasks"
                        columns={columns}
                        controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={pageCount}
                        pagination={pagination}
                        emptyMessage="По заданным параметрам результатов нет"
                        searchTitle="Поиск по названию"
                        isFetching={isFetching}
                        data={courses}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        selectButtons={selectButtons}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                    />
                </>
            ) : null}
        </>
    );
};
