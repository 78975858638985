import React, { useEffect, useState } from "react";
import Api from "Api";
import { useQuery } from "react-query";
import { ResourceState, StatisticsAttempts } from "Enums";
import { Button, ComboBox, FormGroup, Label, OptionsDropdownIndicator } from "Uikit";
import { TasksModalTree } from "../../Tasks/TasksModalTree";
import { TestListResponse } from "Api/Responses/TestResponse";
import { attemptsOptions, TTaskFilterValue } from "../TestWithoutCourse";
import { IStatisticValue, structurizeTestTreeData } from "../../utils";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { numWord } from "helpers/numWord";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { StatisticTestTeamsRequest } from "Api/Requests/StatisticRequest";
import { ReportTeamsTable } from "./ReportTeamsTable";
import { flattenTeams } from "models/Team";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { SingleValueContainer } from "../../SingleValueContainer";

export const reportSettings = [
    {
        title: "Команда",
        key: "team",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Команда",
                value: "name",
                checked: true,
            },
            {
                title: "Путь к команде",
                value: "teamPath",
                checked: true,
            },
            {
                title: "Наставники",
                value: "mentors",
                checked: true,
            },
            {
                title: "Кол-во участников в команде",
                value: "memberCount",
                checked: true,
            },
        ],
    },
    {
        title: "Тест",
        key: "quiz",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «Пройден»",
                value: "passedCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «Провален»",
                value: "failedCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «Не начат»",
                value: "notStartedCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «В процессе»",
                value: "inProgressCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «На проверке»",
                value: "onReviewCount",
                checked: true,
            },
            {
                title: "Кол-во участников с результатом «Без доступа»",
                value: "noAccessCount",
                checked: true,
            },
            {
                title: "% участников с результатом «Пройден»",
                value: "passedPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «Провален»",
                value: "failedPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «Не начат»",
                value: "notStartedPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «В процессе»",
                value: "inProgressPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «На проверке»",
                value: "onReviewPercentage",
                checked: true,
            },
            {
                title: "% участников с результатом «Без доступа»",
                value: "noAccessPercentage",
                checked: true,
            },
            {
                title: "Необходимый % правильных ответов",
                value: "requiredPassedAnswersPercentage",
                checked: true,
            },
            {
                title: "Средний % правильных ответов",
                value: "averagePassedAnswersPercentage",
                checked: true,
            },
            {
                title: "Среднее время прохождения теста",
                value: "averageCompletionTime",
                checked: true,
            },
            {
                title: "Среднее кол-во использованных попыток",
                value: "averageUsedAttempts",
                checked: true,
            },
        ],
    },
];

export const TestTeamsWithoutCourse = () => {
    const [selectedTestItems, setSelectedTestItems] = useState<any[]>([]);
    const [selectedAcceptedTestItems, setSelectedAcceptedTestItems] = useState<any[]>([]);
    const [isModalTestTreeOpen, setIsModalTestTreeOpen] = useState(false);
    const [showReportTeamsTable, setShowReportTeamsTable] = useState(false);
    const [testsValues, setTestsValues] = useState<IStatisticValue[]>([]);
    const [testsTree, setTestsTree] = useState<any[]>([]);
    const [selectedTestsCount, setSelectedTestsCount] = useState(0);
    const [isTestFilterEmpty, setIsTestFilterEmpty] = useState(true);
    const [attempts, setAttempts] = useState(attemptsOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [selectedTeams, setSelectedTeams] = useState<string[]>([]);

    //запрашиваем список всех команд
    const { data: teams = [] as TeamTreeAllBasicInfoResponse[] } = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    // all tests request
    const testsDataQuery = useQuery(
        ["testsTree"],
        async () => {
            return await Api.Test.List(0, 999, [{ id: "modifyTime", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: testTreeData } = testsDataQuery;

    const onSubmitTestSection = () => {
        setSelectedAcceptedTestItems(selectedTestItems);
        setIsModalTestTreeOpen(false);
        setShowReportTeamsTable(false);
    };

    const createReport = () => {
        setShowReportTeamsTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTestTeamsRequest = {
            quizIds: testsValues.map(({ id }) => id),
            teamsIds: selectedTeams,
            attemptNumber: attempts.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateTestTeamsStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    //записываем id всеx команд в выбранные
    useEffect(() => {
        setSelectedTeams(flattenTeams(teams).map((team) => team.id));
    }, [teams]);

    // Setting testTree data
    useEffect(() => {
        if (testTreeData?.Content && selectedAcceptedTestItems.length === 0) {
            setTestsTree(
                structurizeTestTreeData(
                    testTreeData?.Content,
                    selectedAcceptedTestItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                            selected: true,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testTreeData]);

    useEffect(() => {
        setSelectedTestsCount(selectedTestItems.length);
    }, [selectedTestItems]);

    useEffect(() => {
        setTestsTree(
            structurizeTestTreeData(
                testTreeData?.Content as unknown as TestListResponse[],
                selectedAcceptedTestItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
            ),
        );

        setTestsValues(
            selectedAcceptedTestItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedTestItems]);

    return (
        <>
            {/* Выбор теста */}
            <ComboBox
                placeholder="Выберите тест"
                onChange={(value) => {
                    setSelectedAcceptedTestItems([value]);
                    setShowReportTeamsTable(false);
                }}
                value={testsValues[0] ?? null}
                components={{
                    DropdownIndicator: OptionsDropdownIndicator({
                        onClick: (e) => {
                            if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                setIsModalTestTreeOpen((prevIsOpen) => !prevIsOpen);
                            }
                        },
                    }),

                    ValueContainer: SingleValueContainer,
                }}
                onMenuOpen={() => setIsModalTestTreeOpen((prevIsOpen) => !prevIsOpen)}
                /*
                    allPlaceholder={
                        ((courseTreeData?.Content as unknown as CourseListResponse[]) ?? []).length > 0 &&
                        coursesValues.length === courseTreeData?.Content.length
                    }
                    */
                isSearchable={false}
                isCreatable={false}
                isClearable={false}
                menuIsOpen={false}
            />
            {/* Выбор тестов - модалка */}
            <TasksModalTree
                isOpen={isModalTestTreeOpen}
                title="Выбор теста"
                setIsOpen={(open: boolean) => {
                    setSelectedTestItems(selectedAcceptedTestItems);
                    setTestsTree(
                        structurizeTestTreeData(
                            testTreeData?.Content as unknown as TestListResponse[],
                            selectedAcceptedTestItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );

                    setIsModalTestTreeOpen(open);
                    setIsTestFilterEmpty(true);
                }}
                treeData={testsTree}
                /*
                checkedChange={(selectedNodes) => {
                    setSelectedCourseItems(selectedNodes);
                }}
                */
                onSelectNode={(node) => setSelectedTestItems([node])}
                checkable={false}
                selectable={true}
                checkOnNameClick={true}
                showSelectedCounter={false}
                onSubmit={onSubmitTestSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedTestsCount}
                disableRootSelection
                onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                    setTestsTree(
                        structurizeTestTreeData(
                            (testTreeData?.Content as unknown as TestListResponse[]).filter(({ state, title }) => {
                                return (
                                    (!query ||
                                        title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                        query.trim() === "") &&
                                    (state === value || value === "ALL")
                                );
                            }),
                            selectedTestItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );
                    setIsTestFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={isTestFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"}
            />
            {selectedAcceptedTestItems.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            <FormGroup className="!mb-0">
                                <Label>Команды</Label>
                                <TeamMultiSelect
                                    value={selectedTeams}
                                    onChange={(v) => {
                                        setSelectedTeams(v);
                                        setShowReportTeamsTable(false);
                                    }}
                                    placeholder="Выберите команды"
                                    checkable
                                    showTeams
                                    stacked={true}
                                    allPlaceholder={flattenTeams(teams).length === selectedTeams.length}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Собрать статистику</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAttempts(value as { value: StatisticsAttempts; label: string });
                                        setShowReportTeamsTable(false);
                                    }}
                                    value={attempts}
                                    options={attemptsOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="mt-9">
                        {showReportTeamsTable ? (
                            <>
                                <ReportTeamsTable
                                    queryPayload={createPayloadParams()}
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                                disabled={selectedTeams.length === 0}
                            >
                                {`Сформировать отчет по ${selectedTeams.length} ${numWord(selectedTeams.length, [
                                    "команде",
                                    "командам",
                                    "командам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
