import React, { useCallback, useContext, useEffect, useState, useRef, ReactNode } from "react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs, Button, Content, flash, Icon, Icons, Tabs, TabsWrapper, Toggle } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { AccessDummy as Access, AccessRef } from "Components/Access/AccessDummy";
import { ContentLayout, TreeWrapperContext } from "Containers";
import { CourseSettings } from "./CourseSettings";
import { CourseContent } from "./CourseContent";
import { CourseRequest } from "Api/Requests/CourseRequest";
import { CourseResponse } from "Api/Responses/CourseResponse";
import Api from "Api";
import { ValidateDescription, ValidateSections, ValidateTitle } from "helpers/Validator";
import { BadRequestResponse, BaseObjectToArrayResponse, ErrorCode } from "Api/BaseResponse";
import { ResourceType, ResourceState, FileUploadType, UIErrorMessages } from "Enums";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";
import { CurrentUserResponse } from "Api/Responses/UserResponse";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { TeamAccessRequest } from "Api/Requests/AccessRequest";
import { useInvalidate } from "hooks/useInvalidate";
import { useMutation, useQueryClient } from "react-query";
import { CancelModal } from "Components/CancelModal/CancelModal";
import { ApproxTime } from "Components/ApproxTime/ApproxTime";
import { FeedbackTable } from "Components/FeedbackTable/FeedbackTable";
import { IOption } from "types";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";
import { Empty } from "Uikit/Page/Empty";

interface ICourse {
    isEdit?: boolean;
    isCopy?: boolean;
}

function getInitialCourseValues() {
    return {
        id: undefined,
        categoryId: "",
        logoId: undefined,
        managerUserId: "",
        state: "ACTIVE",
        publicAccess: false,
        title: "",
        description: "",
        approxCompletionMinutes: 1,
        isApproxCompletionMinutesManuallyChanged: false,
        publicationDelayTimestamp: null,
        expirationTimestamp: null,
        deadlineTimestamp: null,
        isRequired: false,
        issueCertificate: true,
        hideAvgReviewScore: false,
        hideUserReviews: false,
        sections: [],
        modifyTimestamp: null,
        ratingPoints: 0,
    };
}

const getCopyTitle = (isCopy: boolean, title: string): string => {
    return `${isCopy ? "Копия 1 " : ""}${title}`;
};

export const Course = ({ isEdit, isCopy }: ICourse) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const currentUser: CurrentUserResponse | undefined = queryClient.getQueryData(["users", "current"]);
    const params = new URLSearchParams(location.search);
    const categoryId = params.get("categoryId");

    const invalidate = useInvalidate();
    const { id } = useParams();

    const { setTreeProps } = useContext(TreeWrapperContext);

    const [currentTab, setCurrentTab] = useState(0);
    const [isChanged, setIsChanged] = useState(false);
    const [isToggleChanged, setIsToggleChanged] = useState(false);

    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [isEditCancel, setIsEditCancel] = useState<boolean>(false);

    const ref = useRef();
    const accessRef = useRef<AccessRef>();

    const blocker = useBlocker((params) => {
        if ((isChanged || isAccessFormDirty) && params?.historyAction !== "REPLACE") {
            setIsCancel(true);
        } else if (params?.historyAction === "REPLACE") {
            return false;
        }

        return isChanged || isAccessFormDirty;
    });

    const [categories, setCategories] = useState<IOption[]>([]);
    const [defaultLogos, setDefaultLogos] = useState<string[]>([]);

    const [course, setCourse] = useState<CourseRequest>(getInitialCourseValues());
    const [initialCourse, setInitialCourse] = useState(course);
    const [initCategoryId, setInitCategoryId] = useState("");

    const [cover, setCover] = useState<File | null>(null);
    const [errors, setErrors] = useState<any>({});
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isAccessFormDirty, setIsAccessFormDirty] = useState(false);
    const [teamIds, setTeamIds] = useState<any[]>([]);
    const [allTeamsAccess, setAllTeamsAccess] = useState(false);

    const responsibleList = useResponsibleList(course.managerUserId);
    const [hasPermission, setHasPermission] = useState(true);

    const onChangeCover = async (data: File | null, imgBase64?: string) => {
        setCover(data);
        setCourse({ ...course, logoId: imgBase64 });
        setIsChanged(true);
    };
    const onValidate = (): boolean => {
        const errors: any = {};

        errors["title"] = ValidateTitle(course.title);
        errors["description"] = ValidateDescription(course.description);
        errors["category"] = !course.categoryId ? "Поле обязательно для заполнения" : undefined;
        errors["manager"] = !course.managerUserId ? "Поле обязательно для заполнения" : undefined;
        errors["sections"] = ValidateSections(course.sections);
        errors["time"] =
            course.publicationDelayTimestamp &&
            course.expirationTimestamp &&
            course.expirationTimestamp <= course.publicationDelayTimestamp
                ? "Дата снятия с публикации не может быть раньше даты публикации"
                : undefined;

        errors["access"] = accessRef.current?.validateAccess();

        if (Object.keys(errors).filter((p) => errors[p]).length !== 0) {
            if (errors["sections"]) {
                flash.error("Для сохранения требуется хотя бы один активный материал в содержимом курса");
            }

            if (errors["access"]) {
                flash.error(errors["access"]);
            }

            if (errors["expirationTimestamp"]) {
                flash.error("Дата снятия с публикации не может быть раньше даты публикации");
            }

            setErrors(errors);
            return false;
        }

        setErrors({});
        return true;
    };

    const { mutateAsync: setTeamAccess } = useMutation((payload: TeamAccessRequest) => {
        return Api.LMSRoles.setTeamAccess(payload);
    });

    const onSave = async () => {
        try {
            if (!onValidate()) {
                return;
            }

            if (isAccessFormDirty) {
                await setTeamAccess(
                    Object.assign(new TeamAccessRequest(), {
                        resourceId: id,
                        resourceType: ResourceType.COURSE,
                        teams: !allTeamsAccess ? teamIds.filter((id) => !id.startsWith("root:")) : [],
                        allTeams: allTeamsAccess,
                    }),
                );

                invalidate("teamAccess", id);
                setIsAccessFormDirty(false);
            }

            course.publicAccess = allTeamsAccess;

            // Upload course cover;
            let response: CourseResponse;

            try {
                if (cover) {
                    const uploadFileResponse = await Api.File.UploadFile(
                        cover,
                        undefined,
                        undefined,
                        false,
                        FileUploadType.RESOURCE_LOGO,
                    );
                    course.logoId = uploadFileResponse.id;
                }
            } catch (e: unknown) {
                const knownError = e as BadRequestResponse;
                let message = "Размер обложки слишком большой!";
                if (
                    [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                        String(knownError.errorCode) as ErrorCode,
                    )
                ) {
                    message = UIErrorMessages.FILE_LOADING_ERROR;
                }
                flash.error(message);
                return;
            }

            // Check course sections;
            course.sections = course.sections.filter((p) => p.components?.length !== 0);

            // Create or update course;
            if (course.isApproxCompletionMinutesManuallyChanged) {
                course.approxCompletionMinutes = getTime();
            } else {
                delete course.approxCompletionMinutes;
            }

            const courseRequest = structuredClone(course);
            delete courseRequest.averageReviewRating;

            if (courseRequest.id && !isCopy) {
                response = await Api.Course.Edit(courseRequest);
                flash.success("Курс успешно сохранен!");
            } else {
                response = await Api.Course.Create(courseRequest);
                flash.success("Курс успешно создан!");
            }

            courseRequest.modifyTimestamp = response.modifyTimestamp;

            setInitialCourse(courseRequest);
            setInitCategoryId(courseRequest.categoryId!);

            setIsChanged(false);
            setIsToggleChanged(false);
            setCourse((course) => ({ ...course, modifyTimestamp: response.modifyTimestamp }));

            navigate(`/admin/course/${response.id}`, { replace: true });
        } catch (e) {
            console.log(e);
            flash.error("Курс с таким именем уже существует!");
        }
    };

    const onSubmit = () => {
        if (!isToggleChanged) {
            onSave().then();
        } else {
            openDialog({
                title: `Изменить статус на ${course.state === ResourceState.ACTIVE ? "«Активен»" : "«Скрыт»"}`,
                content:
                    course.state === ResourceState.ACTIVE
                        ? "Все пользователи, у кого есть доступ, увидят данный курс"
                        : "У всех пользователей пропадёт данный курс",
                closeBtnText: "Отмена",
                submitBtnText: "Изменить",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    onSave().then();
                    closeDialog();
                },
            });
        }
    };

    const onCancelChange = () => {
        course.id ? setIsEditCancel(true) : navigate("/admin/courses");
    };

    const onCancelModalSubmit = useCallback(async () => {
        setIsChanged(false);

        if (isCancel && blocker.state === "blocked") {
            blocker.proceed();
        } else {
            setIsEditCancel(false);
            setIsAccessFormDirty(false);
            setCourse(structuredClone(initialCourse));
            const initialAccess = await Api.LMSRoles.getTeamAccess(id!);
            if (ref?.current) {
                (
                    ref.current as { resetPublicationToggles: (initialCourse: CourseRequest) => void }
                ).resetPublicationToggles(initialCourse);
            }
            if (accessRef?.current) {
                (accessRef.current as { resetAccess: (data: any) => void }).resetAccess(initialAccess);
            }
            navigate(`/admin/course/${initialCourse.id}`, { replace: true });
        }
    }, [blocker, initialCourse, isCancel, navigate, id]);

    const getTime = () => {
        if (course.isApproxCompletionMinutesManuallyChanged) {
            return course.approxCompletionMinutes!;
        } else {
            let time = 0;

            for (const element of course.sections) {
                const items = element.components;
                for (const element of items) {
                    time += element.approxCompletionMinutes;
                }
            }

            return Math.ceil(time) || 1;
        }
    };

    const tabsComponent = (): ReactNode => {
        if (currentTab !== 1 && course.modifyTimestamp) {
            return `Обновлен ${formatDate(course.modifyTimestamp * 1000, DateFormat.DATE_TIME_LONG)}`;
        } else if (currentTab === 1) {
            return (
                <ApproxTime
                    canEdit={true}
                    time={getTime()}
                    onChange={(time: number) => {
                        setCourse({
                            ...course,
                            approxCompletionMinutes: time,
                            isApproxCompletionMinutesManuallyChanged: true,
                        });
                        setIsChanged(true);
                    }}
                />
            );
        } else {
            return "";
        }
    };

    useEffect(() => {
        (async () => {
            const categories = (await Api.Course.CategoryGet()).Content.map((p) => {
                return { label: p.title, value: p.id };
            });
            setCategories(categories);

            if (!categoryId) {
                return;
            }

            setInitCategoryId(categoryId);

            setCourse((prevState) => {
                return { ...prevState, categoryId: categoryId || "" };
            });
        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (currentUser) {
            setCourse((prevState) => ({
                ...prevState,
                managerUserId: currentUser.id,
            }));
        }
    }, [currentUser]);

    useEffect(() => {
        const getDefaultLogos = async (): Promise<BaseObjectToArrayResponse<string>> => {
            const defaultLogosRes = await Api.Upload.GetDefaultLogos();

            setDefaultLogos(defaultLogosRes.Content);
            setCourse((prevState) => ({
                ...prevState,
                logoId: defaultLogosRes.Content[Math.floor(Math.random() * defaultLogosRes.Content.length)],
            }));

            return defaultLogosRes;
        };

        getDefaultLogos().then();
    }, []);

    useEffect(() => {
        if (!id) {
            return;
        }

        const fetch = async () => {
            const response = await Api.Course.Read(id);

            setCourse((prevState) => ({
                ...prevState,
                id: response.id,
                categoryId: response.categoryId,
                logoId: response.logoId,
                managerUserId: response.managerUserId,
                state: isCopy ? ResourceState.HIDDEN : response.state,
                publicAccess: response.publicAccess,
                title: getCopyTitle(Boolean(isCopy), response.title),
                description: response.description,
                approxCompletionMinutes: response.approxCompletionMinutes,
                isApproxCompletionMinutesManuallyChanged: response.isApproxCompletionMinutesManuallyChanged,
                publicationDelayTimestamp: response.publicationDelayTimestamp,
                expirationTimestamp: response.expirationTimestamp,
                deadlineTimestamp: response.deadlineTimestamp,
                isRequired: response.isRequired,
                issueCertificate: response.issueCertificate,
                hideAvgReviewScore: response.hideAvgReviewScore,
                hideUserReviews: response.hideUserReviews,
                sections: response.sections,
                modifyTimestamp: response.modifyTimestamp,
                ratingPoints: response.ratingPoints,
                averageReviewRating: response.averageReviewRating,
            }));
            setInitCategoryId(response.categoryId);
            setInitialCourse(structuredClone(response));
        };

        fetch()
            .then()
            .catch((e) => {
                if (e instanceof BadRequestResponse) {
                    if (e.errorCode === ErrorCode.NO_PERMISSION) {
                        setHasPermission(false);
                    }
                }
            });
    }, [id, isCopy]);

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps(undefined);
    }, [setTreeProps]);

    useEffect(() => {
        const uploadCover = async () => {
            try {
                if (cover) {
                    const uploadFileResponse = await Api.File.UploadFile(
                        cover,
                        undefined,
                        undefined,
                        false,
                        FileUploadType.RESOURCE_LOGO,
                    );
                    setCourse({
                        ...course,
                        logoId: uploadFileResponse.id,
                    });
                    setIsChanged(true);
                }
            } catch (e: unknown) {
                const knownError = e as BadRequestResponse;
                let message = "Размер обложки слишком большой!";
                if (
                    [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                        String(knownError.errorCode) as ErrorCode,
                    )
                ) {
                    message = UIErrorMessages.FILE_LOADING_ERROR;
                }
                flash.error(message);
                return;
            }
        };
        uploadCover().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cover]);

    useEffect(() => {
        setIsChanged(!!isEdit || !!isCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            {!hasPermission && (
                <div className="h-full flex items-center">
                    <Empty
                        title="Нет доступа"
                        description="К сожалению, у вас нет доступа к этой странице"
                        topElement={
                            <div className="flex-center mb-4">
                                <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                    <Icon icon={Icons.EmojiSad} width={"36px"} height={"36px"} color={"fill-primary"} />
                                </div>
                            </div>
                        }
                    />
                </div>
            )}
            {hasPermission && (
                <>
                    <CancelModal
                        id="coursePageCancelModal"
                        isEdit={isEditCancel}
                        isOpen={isCancel || isEditCancel}
                        setIsOpen={isEditCancel ? setIsEditCancel : setIsCancel}
                        onSubmit={onCancelModalSubmit}
                    />

                    <ContentLayout>
                        <Breadcrumbs className="pb-2.5" id="adminNewCourseBreadcrumbs">
                            <Breadcrumbs.Link title="Администратор" />
                            <Breadcrumbs.Link title="Курсы" url="/admin/courses" />
                            {initCategoryId && (
                                <Breadcrumbs.Link
                                    title={categories.find((p) => p.value === initCategoryId)?.label ?? ""}
                                    url={"/admin/courses/" + initCategoryId}
                                />
                            )}
                            {!initialCourse.id && <Breadcrumbs.Link title="Новый курс" />}
                            {!!initialCourse.id && (
                                <Breadcrumbs.Link title={getCopyTitle(Boolean(isCopy), initialCourse.title)} />
                            )}
                        </Breadcrumbs>
                        <div className="flex justify-between items-center pb-7.5 gap-4">
                            <h1 id="adminNewCourseTitle">
                                <MultiClumpTooltip
                                    label={
                                        initialCourse.title
                                            ? getCopyTitle(Boolean(isCopy), initialCourse.title)
                                            : "Новый курс"
                                    }
                                    clamp={1}
                                    textClassName="!leading-8"
                                ></MultiClumpTooltip>
                            </h1>
                            <div className="flex items-center">
                                <Toggle
                                    className="mr-7.5 font-semibold"
                                    label={
                                        course.state
                                            ? course.state === ResourceState.ACTIVE
                                                ? "Активен"
                                                : "Скрыт"
                                            : "Активен"
                                    }
                                    enabled={course.state ? course.state === ResourceState.ACTIVE : true}
                                    onChange={(p) => {
                                        const updatedCourse = {
                                            ...course,
                                            state: p ? ResourceState.ACTIVE : ResourceState.HIDDEN,
                                        };
                                        if (p) {
                                            updatedCourse.deadlineTimestamp = null;
                                        }

                                        updatedCourse.publicationDelayTimestamp = null;
                                        updatedCourse.expirationTimestamp = null;
                                        setCourse(updatedCourse);
                                        setIsChanged(true);
                                        setIsToggleChanged((isToggleChanged) => !isToggleChanged);
                                    }}
                                    id="adminNewCourseToggleIsActive"
                                />
                                {(isAccessFormDirty || isChanged || !course.id) && (
                                    <Button
                                        className="mr-4"
                                        variant="outline"
                                        color="secondary"
                                        onClick={onCancelChange}
                                        id="adminNewCourseBtnCancel"
                                    >
                                        Отменить
                                    </Button>
                                )}
                                {(isAccessFormDirty || isChanged || !course.id) && (
                                    <Button onClick={onSubmit} id="adminNewCourseBtnOk">
                                        Сохранить
                                    </Button>
                                )}
                                {!isAccessFormDirty && !isChanged && course.id && (
                                    <Button
                                        className="w-10 h-10 !p-0"
                                        variant="outline"
                                        color="secondary"
                                        onClick={() => navigate("/admin/courses")}
                                        id="adminNewCourseBtnCrest"
                                        shape="square"
                                    >
                                        <Icon icon={Icons.Close} width={24} height={24} color="fill-[#939393]" />
                                    </Button>
                                )}
                            </div>
                        </div>
                        <TabsWrapper selectedIndex={currentTab} onChange={setCurrentTab}>
                            <TabsWrapper.Tabs
                                classname="flex flex-grow justify-between items-center h-max"
                                label={tabsComponent()}
                                id="adminNewCourseTabs"
                            >
                                <Tabs.Tab
                                    title="Настройка"
                                    disabled={isAccessFormDirty}
                                    tooltip={isAccessFormDirty ? "Будет доступно после сохранения" : ""}
                                    error={
                                        errors["title"] ||
                                        errors["description"] ||
                                        errors["category"] ||
                                        errors["manager"] ||
                                        errors["time"]
                                    }
                                />
                                <Tabs.Tab
                                    title="Содержимое"
                                    disabled={isAccessFormDirty}
                                    tooltip={isAccessFormDirty ? "Будет доступно после сохранения" : ""}
                                    error={errors["sections"]}
                                />
                                {course.id && (
                                    <Tabs.Tab
                                        title={`Отзывы${
                                            course.averageReviewRating ? ` (${course.averageReviewRating})` : ""
                                        }`}
                                        disabled={isChanged || !course.id}
                                        tooltip={isChanged || !course.id ? "Будет доступно после сохранения" : ""}
                                        onClick={() => setCurrentTab(2)}
                                    />
                                )}
                                <Tabs.Tab
                                    title="Доступ"
                                    disabled={isChanged || !course.id}
                                    tooltip={isChanged || !course.id ? "Будет доступно после сохранения" : ""}
                                    error={errors["access"]}
                                />
                            </TabsWrapper.Tabs>
                            <TabsWrapper.Content>
                                <Content.Body>
                                    <CourseSettings
                                        ref={ref}
                                        errors={errors}
                                        users={responsibleList}
                                        categories={categories}
                                        setCategories={setCategories}
                                        course={course}
                                        defaultLogos={defaultLogos}
                                        cover={cover}
                                        onChangeCover={onChangeCover}
                                        onChange={(p) => {
                                            setCourse(p);
                                            setIsChanged(true);
                                        }}
                                    />
                                </Content.Body>
                                <Content.Body>
                                    <CourseContent
                                        course={course}
                                        onChange={(p) => {
                                            setCourse(p);
                                            setIsChanged(true);
                                        }}
                                        errors={errors}
                                    />
                                </Content.Body>
                                {course.id && (
                                    <Content.Body className="h-full">
                                        <FeedbackTable resourceId={course.id} resourceType={ResourceType.COURSE} />
                                    </Content.Body>
                                )}
                                <Content.Body>
                                    <Access
                                        resourceId={id}
                                        resourceType={ResourceType.COURSE}
                                        setIsAccessFormDirty={setIsAccessFormDirty}
                                        setTeamIds={setTeamIds}
                                        allTeamsAccess={allTeamsAccess}
                                        setAllTeamsAccess={setAllTeamsAccess}
                                        nodeAddComponentLink={`/admin/members?teamId.in=`}
                                        ref={accessRef}
                                    />
                                </Content.Body>
                            </TabsWrapper.Content>
                        </TabsWrapper>
                        <Confirmation {...dialogState} />
                    </ContentLayout>
                </>
            )}
        </>
    );
};
