import React from "react";
import Tippy, { TippyProps } from "@tippyjs/react";
import { followCursor, Placement } from "tippy.js";
import "tippy.js/dist/tippy.css";

export const Tooltip = ({ children, className, content, ...rest }: TippyProps & { placement?: Placement }) => {
    return (
        <Tippy
            content={content}
            {...rest}
            className="ui-tooltip bg-black-90 break-anywhere"
            plugins={[followCursor]}
            appendTo={() => document.body}
            interactive
        >
            <span className={className}>{children}</span>
        </Tippy>
    );
};
