import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, Row, SortingState } from "@tanstack/react-table";
import { Button, Checkbox, Icon, Icons, PopoverList, Table, Tooltip } from "Uikit";
import { BlockMemberModal } from "./Modal/BlockMemberModal";
import { PopoverRowAction } from "./MemberRowActionPopover";
import { useLocation, useNavigate } from "react-router-dom";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { RoleItemResponse, RoleResponse } from "Api/Responses/RoleResponse";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { DateFormat, formatDate, formatLastActivityTime } from "helpers/dateHelper";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { BasePaginationResponse } from "Api/BaseResponse";
import { useQuery } from "react-query";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { MoveMembersModal } from "./Modal/MoveMembersModal";
import { ExportModal } from "./Modal/ExportModal";
import { OfficeResponse } from "Api/Responses/OfficeResponse";
import { JobResponse } from "Api/Responses/JobResponse";
import { ClearIndicatorProps, components } from "react-select";
import { UserState } from "types/User";
import { useInvalidate } from "hooks/useInvalidate";
import clsx from "clsx";
import Api from "Api/index";
import { flattenTeams } from "models/Team";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Empty } from "Uikit/Page/Empty";
import { RoleName } from "Enums";

interface IMembersTable {
    id: string;
    type: UserState;
    roles: RoleResponse[];
    teams: TeamTreeAllBasicInfoResponse[];
    fetch: (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => Promise<BasePaginationResponse<MembersListResponse>>;
    offices: OfficeResponse[];
    jobs: JobResponse[];
}

const getFilterInitialParameter = (queryParams: string | null, teams: TeamTreeAllBasicInfoResponse[]) => {
    if (!queryParams) {
        return {};
    }

    const queryArr = queryParams.split(",");

    return {
        "teamId.in": teams.filter(({ id }) => queryArr.includes(id)).map((p) => p.id) ?? [],
    };
};

const getFilterInitialJobs = (queryParams: string | null, jobs: JobResponse[]) => {
    if (!queryParams) {
        return {};
    }

    return {
        "jobTitle.in": jobs.filter(({ id }) => id === queryParams).map((p) => ({ label: p.name, value: p.id })) ?? [],
    };
};

const getFilterInitialOffices = (queryParams: string | null, offices: OfficeResponse[]) => {
    if (!queryParams) {
        return {};
    }

    return {
        "office.in": offices.filter(({ id }) => id === queryParams).map((p) => ({ label: p.name, value: p.id })) ?? [],
    };
};

const getFilterInitialIsMentor = (queryParams: string | null) => {
    if (!queryParams) {
        return {};
    }

    return {
        "isMentor.equal": queryParams,
    };
};

export const MembersTable = ({ id, type, roles, teams, fetch, offices, jobs }: IMembersTable) => {
    const navigate = useNavigate();
    const location = useLocation();
    const invalidate = useInvalidate();
    const [isMounted, setIsMounted] = useState(false);

    const queryParams = new URLSearchParams(location.search);
    const [moveMembersModalOpen, setMoveMembersModalOpen] = useState(false);

    const [isBlockModalOpen, setIsBlockModalOpen] = useState(false);
    const [blockType, setBlockType] = useState<"block" | "unblock">("block");

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [sorting, setSorting] = useState<SortingState>([]);

    const [selectedRows, setSelectedRows] = useState<any>();
    const [rowSelection, setRowSelection] = useState({});

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    const [isFilters, setIsFilters] = useState(false);
    const [filters, setFilters] = useState<{ [id: string]: any }>({
        ...getFilterInitialParameter(queryParams.get("teamId.in"), flattenTeams(teams)),
        ...getFilterInitialJobs(queryParams.get("jobTitle.in"), jobs),
        ...getFilterInitialOffices(queryParams.get("office.in"), offices),
        ...getFilterInitialIsMentor(queryParams.get("isMentor.equal")),
        "role.in": { label: "Все", value: "ALL" },
    });

    const [isExportModal, setIsExportModal] = useState(false);

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters)
            .filter((p) => p !== "role.in" || (p === "role.in" && filters[p].value !== "ALL"))
            .every((f) => !filters[f]);
    }, [filters]);

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    const { data: currentUser } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());
    const isAdmin = currentUser && currentUser.role === RoleName.ADMIN;

    const [roleData, setRoleData] = useState<RoleItemResponse>();
    useEffect(() => {
        if (isAdmin) {
            const fetchRoleData = async () => {
                const data = await Api.Role.Id(currentUser.id);
                setRoleData(data);
            };

            fetchRoleData().then();
        }
    }, [currentUser, isAdmin]);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["members", "collection", pagination, sorting, filters],
        async () => {
            const filtersKeys = Object.keys({
                ...filters,
            });
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "jobTitle.in" || element === "office.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value).join(",");
                } else if (element === "teamId.in") {
                    filtersData[element] = filters[element].join(",");
                } else if (element === "role.in") {
                    if (filters[element].value !== "ALL") {
                        filtersData[element] = filters[element].value;
                    }
                } else {
                    filtersData[element] = filters[element];
                }
            }

            filtersData["state.equal"] = type;
            return await fetch(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: isMounted,
        },
    );

    const transformRolesList = () => {
        const rolesOptions = [];

        rolesOptions.push({
            label: "Все",
            value: "ALL",
        });

        for (const element of roles) {
            if (element.name !== RoleName.ROOT) {
                rolesOptions.push({
                    label: element.readableName,
                    value: element.name,
                });
            }
        }

        return rolesOptions;
    };

    const canEditRow = useCallback(
        (row: Row<any>) => {
            /*
            const isCurrentUserWithSameOrLowerRole =
                ((row.original.role === "SUPER_ADMIN" ||
                    row.original.role === "ROOT" ||
                    row.original.role === "ADMIN") &&
                    currentUser?.role === "ADMIN") ||
                ((row.original.role === "ROOT" || row.original.role === "SUPER_ADMIN") &&
                    currentUser?.role === "SUPER_ADMIN");
            */
            return row.original.id !== currentUser?.id && row.original.hasEditPermission;
        },
        [currentUser],
    );

    const columns = React.useMemo<ColumnDef<MembersListResponse>[]>(() => {
        const result: ColumnDef<MembersListResponse>[] = [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                        disabled={!canEditRow(row)}
                    />
                ),
            },
            {
                header: "участник",
                accessorKey: "lastName",
                cell: ({ row: { original: user } }) => {
                    return (
                        <div className="group flex items-center space-x-3 w-full overflow-hidden">
                            <UserAvatar
                                avatarId={user.avatarId}
                                color={user.defaultAvatarColor}
                                userInitials={`${user.firstName?.slice(0, 1)}${user.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <div
                                className={clsx(
                                    "w-full",
                                    type === UserState.ACTIVE && user.id !== currentUser?.id && "cursor-pointer",
                                )}
                                onClick={() => {
                                    if (type === UserState.ACTIVE && user.id !== currentUser?.id) {
                                        navigate(`/admin/member/${user.id}`);
                                    }
                                }}
                            >
                                <MultiClumpTooltip
                                    className="group-hover:text-blue"
                                    clamp={1}
                                    label={String(user.lastName + " " + user.firstName)}
                                />
                            </div>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "должность",
                accessorKey: "jobTitle",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({ row: { original: user } }) => (
                    <div className="overflow-hidden max-w-50">
                        <MultiClumpTooltip clamp={1} label={String(user.jobTitle ? user.jobTitle.name : "-")} />
                    </div>
                ),
            },
            {
                header: "роль",
                accessorKey: "role",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({ row: { original: user } }) => {
                    const role = roles.find((role) => role.name === user.role);
                    return (
                        <div className="overflow-hidden whitespace-nowrap max-w-50">
                            {role ? <MultiClumpTooltip clamp={1} label={String(role ? role.readableName : "-")} /> : ""}
                        </div>
                    );
                },
            },
            {
                id: "teams",
                header: "команды",
                accessorKey: "teams.name",
                enableResizing: true,
                enableSorting: false,
                size: 270,
                footer: (props) => props.column.id,
                cell: ({ row: { original: user } }) => {
                    let length = 31;
                    let counter = user.teams.length;

                    for (const element of user.teams) {
                        if (element.name.length + 2 > length) {
                            break;
                        }

                        length -= element.name.length + 2;
                        counter -= 1;
                    }

                    return (
                        <div className="flex items-center truncate">
                            <p className="truncate w-56 font-normal">
                                {user.teams.length === 0 && "-"}
                                {user.teams.map((team) => team.name).join(", ")}
                            </p>
                            {!!counter && (
                                <Tooltip
                                    placement="top"
                                    arrow={true}
                                    content={user.teams.map((team) => team.name).join(", ")}
                                >
                                    <span className="ml-auto py-0.5 px-1 bg-gray-light rounded-full p4-accent text-white cursor-pointer">
                                        {counter}+
                                    </span>
                                </Tooltip>
                            )}
                        </div>
                    );
                },
            },
            type === "ACTIVE"
                ? {
                      header: "был в сети",
                      accessorKey: "lastActivityDate",
                      cell: ({ row: { original: user } }) => {
                          return (
                              <div className="flex items-center space-x-3 truncate">
                                  <p className="font-normal">
                                      {user.lastActivityDate
                                          ? formatLastActivityTime(user.lastActivityDate * 1000)
                                          : "-"}
                                  </p>
                              </div>
                          );
                      },
                      footer: (props) => props.column.id,
                  }
                : {
                      header: "заблокирован",
                      accessorKey: "lastBlockDate",
                      cell: ({ row: { original: user } }) => {
                          return (
                              <div className="flex items-center space-x-3 truncate">
                                  <p className="font-normal">
                                      {user.lastBlockDate
                                          ? formatDate(user.lastBlockDate * 1000, DateFormat.DATE_TIME_LONG)
                                          : ""}
                                  </p>
                              </div>
                          );
                      },
                      footer: (props) => props.column.id,
                  },
            {
                header: "",
                id: "buttons",
                accessorKey: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 30,
                enableSorting: false,
                cell: ({ row }) => {
                    const user = row.original;
                    return (
                        <div className="flex" id={"adminMembersTableGroupButton" + user.id}>
                            {type === UserState.ACTIVE && canEditRow(row) && (
                                <Button
                                    shape={"round"}
                                    color={"common"}
                                    icon={
                                        <Icon
                                            icon={Icons.Pencil}
                                            width="18px"
                                            height="18px"
                                            color="fill-blue-drk hover:fill-blue-hover"
                                        />
                                    }
                                    iconPlacement={"center"}
                                    onClick={() => {
                                        navigate(`/admin/member/${user.id}/edit`);
                                    }}
                                />
                            )}
                            {canEditRow(row) && (
                                <PopoverRowAction
                                    type={type}
                                    setRowFunc={() => setSelectedRows([user])}
                                    setBlockType={setBlockType}
                                    setBlockModalIsOpen={setIsBlockModalOpen}
                                    onMoveClick={handleMoveClickOnRow}
                                />
                            )}
                        </div>
                    );
                },
            },
        ];

        if (type === UserState.BLOCKED) {
            return result.filter((column) => column.id !== "teams");
        }

        return result;
    }, [type, navigate, roles, canEditRow, currentUser]);

    const controlButtons = (
        <div className="flex justify-end items-center space-x-4">
            {type === UserState.ACTIVE && (
                <PopoverList offset={[0, 18]} visible={isPopoverOpen} onClickOutside={() => setIsPopoverOpen(false)}>
                    <Button
                        id="adminMembersBtnOther"
                        variant="outline"
                        color="secondary"
                        size="medium"
                        icon={<Icon icon={Icons.ChevronDown} color="fill-gray-dark" width={12} height={12} />}
                        iconPlacement="right"
                        onClick={() => setIsPopoverOpen(true)}
                    >
                        Опции
                    </Button>
                    <PopoverList.Item name="export" onClickItem={() => setIsExportModal(true)}>
                        Экспорт
                    </PopoverList.Item>
                    <PopoverList.Item name="import" onClickItem={() => navigate("/admin/members/import")}>
                        Импорт
                    </PopoverList.Item>
                </PopoverList>
            )}
            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement="left"
                onClick={() => setIsFilters(!isFilters)}
                id="adminMembersBtnFilter"
            >
                Фильтры
            </Button>
            {type === UserState.ACTIVE && (
                <Tooltip
                    content="Для создания участника нужен доступ к командам"
                    disabled={!isAdmin || (roleData?.visibleTeams && roleData.visibleTeams.length > 0)}
                >
                    <Button
                        className="rounded-lg whitespace-nowrap font-medium"
                        size="medium"
                        icon={
                            <Icon
                                icon={Icons.PlusFilled}
                                width={20}
                                height={20}
                                color={
                                    isAdmin && roleData?.visibleTeams && roleData.visibleTeams.length === 0
                                        ? "fill-[#C1C6CA]"
                                        : "fill-[#FFFFFF]"
                                }
                            />
                        }
                        disabled={isAdmin && roleData?.visibleTeams && roleData.visibleTeams.length === 0}
                        iconPlacement="left"
                        onClick={() => navigate("/admin/member")}
                        id="adminMembersBtnCreateMember"
                    >
                        Создать участника
                    </Button>
                </Tooltip>
            )}
        </div>
    );

    const selectedRowButtons = (
        <div className="flex justify-end items-center space-x-5" id="membersTableSelectedRowButtons">
            {type === "ACTIVE" && (
                <>
                    <Button
                        onClick={() => setMoveMembersModalOpen(true)}
                        variant="outline"
                        color="common"
                        size="medium"
                        className="border-[#E6E9ED] rounded-lg font-medium"
                        id="adminMembersBtnChangeTeam"
                    >
                        Изменить команду
                    </Button>
                    <Button
                        size="medium"
                        color="danger"
                        className="rounded-lg whitespace-nowrap font-medium"
                        onClick={() => {
                            setBlockType("block");
                            setIsBlockModalOpen(true);
                        }}
                        id="adminMembersBtnFreeze"
                    >
                        Заблокировать
                    </Button>
                </>
            )}
            {type !== "ACTIVE" && (
                <Button
                    size="medium"
                    color="primary"
                    className="rounded-lg whitespace-nowrap font-medium"
                    onClick={() => {
                        setBlockType("unblock");
                        setIsBlockModalOpen(true);
                    }}
                    id="adminMembersBtnUnFreeze"
                >
                    Разблокировать
                </Button>
            )}
        </div>
    );

    const SelectValueContainer = ({ children, ...props }: ClearIndicatorProps<any>) => {
        const itemsLength = props.getValue().length;

        const onClear = () => {
            props.clearValue();
        };
        return (
            <components.ClearIndicator {...props} className={"gap-1 !pr-7 relative"}>
                {children}
                {itemsLength > 0 && (
                    <div className="absolute group right-0 flex-center rounded-full top-1/2 -translate-y-1/2 h-5.5 min-w-[22px] text-sm bg-primary text-white cursor-pointer">
                        <span className="group-hover:hidden">{itemsLength}</span>
                        <div onClick={onClear} className="hidden group-hover:block">
                            <Icon icon={Icons.Close} width={18} height={18} color="fill-white" />
                        </div>
                    </div>
                )}
            </components.ClearIndicator>
        );
    };

    let filtersConfig = [
        {
            label: "Должность",
            fields: [
                {
                    accessor: "jobTitle.in",
                    type: "multi-select",
                    placeholder: "Выберите должности",
                    options: jobs.map((p) => {
                        return { label: p.name, value: p.id };
                    }),
                    components: {
                        ClearIndicator: SelectValueContainer,
                    },
                },
            ],
        },
    ];
    if (type === UserState.ACTIVE) {
        filtersConfig.push({
            label: "Команды",
            fields: [
                {
                    accessor: "teamId.in",
                    type: "team-multi-select",
                    placeholder: "Выберите команды",
                    options: teams.map((p) => {
                        return { label: p.name, value: p.id };
                    }),
                    components: {
                        ClearIndicator: SelectValueContainer,
                    },
                },
            ],
        });
    }
    filtersConfig = filtersConfig.concat([
        {
            label: "Офис",
            fields: [
                {
                    accessor: "office.in",
                    type: "multi-select",
                    placeholder: "Выберите офисы",
                    options: offices.map((p) => {
                        return { label: p.name, value: p.id };
                    }),
                    components: {
                        ClearIndicator: SelectValueContainer,
                    },
                },
            ],
        },
        {
            label: "Роль в системе",
            fields: [
                {
                    accessor: "role.in",
                    type: "select",
                    placeholder: "Выберите роли",
                    options: transformRolesList(),
                    components: {
                        ClearIndicator: SelectValueContainer,
                    },
                },
            ],
        },
        {
            label: "Дополнительно",
            fields: [
                {
                    accessor: "isMentor.equal",
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    label: "Только наставники",
                    type: "checkbox",
                    default: false,
                },
            ],
        },
    ]);

    const handleAfterTeamActionSuccess = () => {
        setSelectedRows([]);
        setRowSelection({});
    };
    const handleMoveClickOnRow = () => {
        setMoveMembersModalOpen(true);
    };

    useEffect(() => {
        setIsMounted(true);
        invalidate("members");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div id="membersTable" className="h-full">
            <ExportModal isOpen={isExportModal} setIsOpen={setIsExportModal} filters={filters} />
            <BlockMemberModal
                id="membersBlockModal"
                type={blockType}
                isOpen={isBlockModalOpen}
                teams={teams}
                selectedUsers={selectedRows}
                onSubmit={() => {
                    invalidate("members");
                    setIsBlockModalOpen(false);
                    setSelectedRows([]);
                    setRowSelection({});
                }}
                onClose={() => setIsBlockModalOpen(false)}
            />
            <MoveMembersModal
                isOpen={moveMembersModalOpen}
                onClose={() => {
                    handleAfterTeamActionSuccess();
                    setMoveMembersModalOpen(false);
                }}
                users={selectedRows}
                hideTeamMembers={currentUser?.role === RoleName.ADMIN}
            />

            {isFiltersEmpty && dataQuery.data?.Content && dataQuery.data?.Content.length === 0 && (
                <div className="flex justify-center items-center h-full">
                    <Empty
                        className="max-w-142"
                        topElement={
                            <div className="flex-center mb-5">
                                <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                    <Icon icon={Icons.FileEdit} width={"36px"} height={"36px"} color={"fill-primary"} />
                                </div>
                            </div>
                        }
                        title="Заблокированных участников нет"
                        description="На эту вкладку попадут все участники, которые были заблокированы. На данный момент в списке нет ни одного заблокированного участника"
                    ></Empty>
                </div>
            )}

            {!isFiltersEmpty || (dataQuery.data?.Content && dataQuery.data?.Content.length > 0) ? (
                <>
                    <Filter
                        isActive={isFilters}
                        setIsActive={setIsFilters}
                        configuration={filtersConfig as unknown as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                        hideTeamMembers={currentUser?.role === RoleName.ADMIN}
                    />
                    <Table
                        id={id}
                        columns={columns}
                        controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={dataQuery.data?.TotalPages}
                        pagination={pagination}
                        emptyMessage="По заданным параметрам результатов нет"
                        searchTitle="Поиск..."
                        isFetching={dataQuery.isFetching}
                        data={dataQuery.data?.Content}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        enableRowSelection={canEditRow}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        selectButtons={selectedRowButtons}
                        setSelectedRows={setSelectedRows}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={onSearch}
                    />
                </>
            ) : null}
        </div>
    );
};
