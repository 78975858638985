import React, { useEffect, useMemo, useState } from "react";
import { Button } from "../Button/Button";
import { Input } from "../Forms/Input";
import { Modal } from "../Modal/Modal";
import { Tree } from "./Tree";
import { TreeSearchProps } from "./TreeSearch";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { Link } from "react-router-dom";
import { ResourceType } from "Enums";
import { TVoidFunction } from "types";

interface ModalTreeProps {
    isOpen: boolean;
    title: string;
    setIsOpen: (open: boolean) => void;
    treeData: any[];
    onLoadTree?: (tree: any, treeRef: any) => void;
    onSubmit?: TVoidFunction;
    checkedChange?: (node: any, targetNode?: any, tree?: any) => void;
    submitButtonTitle?: string;
    selectedCount?: number;
    watchSelected?: boolean;
    onTogglerClick?: (node: any) => void;
}

const TreeSearch = ({ query, setQuery }: TreeSearchProps) => (
    <div className="relative pt-5 pb-5 w-full">
        <div className="absolute left-1 flex items-center inset-y-0 p-1 pl-2 z-20">
            <Icon icon={Icons.Search} width={18} height={18} color="fill-[#939393]" />
        </div>
        <Input
            className="w-full border border-[#E6E9ED] placeholder:text-[#939393] pl-10 2xl:pl-10"
            placeholder="Поиск по названию"
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            id="modalTreeSearchInput"
        />
    </div>
);

export const ModalTree = ({
    isOpen,
    title,
    setIsOpen,
    treeData = [],
    onLoadTree,
    onSubmit,
    checkedChange,
    submitButtonTitle = "Добавить",
    selectedCount = 0,
    watchSelected = true,
    onTogglerClick,
}: ModalTreeProps) => {
    const [selected, setSelected] = useState<number>(selectedCount);

    useEffect(() => {
        setSelected((prevSelected) => {
            return prevSelected !== selectedCount ? selectedCount : prevSelected;
        });
    }, [selectedCount]);

    const _treeData = useMemo(() => {
        return treeData.map((dataItem) => {
            if (
                dataItem.children.some(({ state }: any) => {
                    return state?.checked;
                })
            ) {
                return {
                    ...dataItem,
                    state: {
                        ...dataItem.state,
                        checked: true,
                        open: true,
                        indeterminate: !dataItem.children.every(({ state: { checked } }: any) => checked),
                    },
                };
            } else {
                return { ...dataItem };
            }
        });
    }, [treeData]);

    return (
        <Modal isOpen={isOpen} title={title} setIsOpen={setIsOpen} id="modalTree">
            <Modal.Body className="flex flex-col overflow-hidden h-[543px] mb-2">
                <Tree
                    onLoadTree={onLoadTree}
                    data={_treeData}
                    searchable
                    isCheckboxInside
                    checkedChange={(nodes, targetNode, tree) => {
                        let selectedNodes = [...nodes["SECTION"], ...nodes["ARTICLE"]];

                        nodes["PROJECT"].map((p: any) => {
                            selectedNodes = [...selectedNodes, ...p.children];
                        });

                        if (checkedChange) {
                            checkedChange(selectedNodes, targetNode, tree);
                        }

                        setSelected(selectedNodes.length);
                    }}
                    renderSearch={TreeSearch}
                    nodeAddComponent={({ node: { id, nodeType, type, file } }) => {
                        if (nodeType === "PROJECT") {
                            return <></>;
                        }

                        return (
                            <div className="flex items-center ml-auto">
                                {nodeType === "SECTION" && (
                                    <div className="ml-1 p2 text-gray-light group-hover:hidden">
                                        {type === ResourceType.ARTICLE && "Статья"}
                                        {type === ResourceType.VIDEO && "Видео"}
                                        {type === ResourceType.SCORM && "SCORM"}
                                        {type === ResourceType.DOCUMENT && String(file?.extension).toUpperCase()}
                                    </div>
                                )}
                                <Link
                                    className="hidden items-center group-hover:flex cursor-pointer"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={(() => {
                                        if (nodeType === "SECTION") {
                                            if (type === ResourceType.EXERCISE) {
                                                return `/admin/task/${id}`;
                                            }
                                            if (type === ResourceType.QUIZ) {
                                                return `/admin/test/${id}`;
                                            }
                                            if (type === ResourceType.COURSE) {
                                                return `/admin/course/${id}`;
                                            }
                                            return `/admin/material/${type}/${id}`;
                                        }
                                        return `/admin/test/${id}`;
                                    })()}
                                >
                                    <Icon className="ml-1 mr-1" icon={Icons.ShareBox} width={17} height={17} />
                                    Посмотреть
                                </Link>
                            </div>
                        );
                    }}
                    onTogglerClick={onTogglerClick}
                />
            </Modal.Body>
            <Modal.Footer className="flex justify-between items-center">
                <p className="p2 text-gray-text" id="modalTreeSelectedCount">
                    Выбрано {selected}
                </p>
                <div className="space-x-4">
                    <Button
                        className="border-[#EAEDF3]"
                        variant="outline"
                        color="secondary"
                        onClick={() => setIsOpen(false)}
                        id="modalTreeCancelBtn"
                    >
                        Отмена
                    </Button>
                    <Button
                        disabled={watchSelected && selected === 0}
                        onClick={onSubmit}
                        color="primary"
                        id="modalTreeOkBtn"
                    >
                        {submitButtonTitle}
                    </Button>
                </div>
            </Modal.Footer>
        </Modal>
    );
};
