import React, { useEffect, useMemo, useState } from "react";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { ClearIndicatorProps, components } from "react-select";
import { AccessOpenModal } from "./AccessOpenModal";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { Button, Checkbox, flash, Icon, Icons, SelectValueContainer } from "Uikit";
import { Table } from "Uikit/Table/Table";
import { UserElement } from "Api/Responses/lmsRoles/UserAccess";
import { UserAccessRequest } from "Api/Requests/AccessRequest";
import { BasePaginationRequest } from "Api/BaseRequest";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import Api from "Api/index";
import { ID } from "types/ID";
import { ResourceType, RoleName, ProgressStatus, ProgressStatusTranslate } from "Enums";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { useCurrentUser } from "hooks/useCurrentUser";

interface AccessTableProps {
    resourceId: ID;
    resourceType: ResourceType;
    showProgressFilter?: boolean;
}

const getGenderLabel = (resourceType: ResourceType, labels: Record<"male" | "female" | "neutral", string>) => {
    const isMaleGender = [ResourceType.POLL, ResourceType.QUIZ, ResourceType.COURSE].includes(resourceType);
    const isFemaleGender = resourceType === ResourceType.PROGRAM;

    return isMaleGender ? labels.male : isFemaleGender ? labels.female : labels.neutral;
};

export const AccessTable = ({ resourceId, resourceType, showProgressFilter = true }: AccessTableProps) => {
    const navigate = useNavigate();
    const currentUser = useCurrentUser();

    const resourceTypeName: { [key: string]: any } = useMemo(
        () => ({
            COURSE: "этот курс",
            EXERCISE: "это задание",
            QUIZ: "этот тест",
        }),
        [],
    );

    const progressOptions = useMemo(() => {
        const options = [
            {
                label: getGenderLabel(resourceType, {
                    male: "Не начат",
                    female: "Не начата",
                    neutral: "Не начато",
                }),
                value: ProgressStatus.NOT_STARTED,
            },
            {
                label: getGenderLabel(resourceType, {
                    male: [ResourceType.QUIZ, ResourceType.COURSE].includes(resourceType) ? "Пройден" : "Принят",
                    female: [ResourceType.PROGRAM].includes(resourceType) ? "Пройдена" : "Принята",
                    neutral: "Принято",
                }),
                value: ProgressStatus.PASSED,
            },
        ];

        if (![ResourceType.PROGRAM, ResourceType.COURSE].includes(resourceType)) {
            options.splice(1, 0, {
                label: ProgressStatusTranslate.ON_REVIEW,
                value: ProgressStatus.ON_REVIEW,
            });
            options.splice(1, 0, {
                label: ProgressStatusTranslate.IN_PROGRESS,
                value: ProgressStatus.IN_PROGRESS,
            });
            options.push({
                label: getGenderLabel(resourceType, {
                    male: [ResourceType.QUIZ].includes(resourceType) ? "Провален" : "Отклонён",
                    female: "Отклонена",
                    neutral: "Отклонено",
                }),
                value: resourceType === ResourceType.EXERCISE ? ProgressStatus.RE_WORK : ProgressStatus.FAILED,
            });
        } else {
            options.splice(1, 0, {
                label: ProgressStatusTranslate.IN_PROGRESS,
                value: ProgressStatus.IN_PROGRESS,
            });
        }

        return options;
    }, [resourceType]);

    const [showAccessModal, setShowAccessModal] = useState(false);
    const [isFilterShow, setIsFilterShow] = useState(false);
    const [selectedRows, setSelectedRows] = useState<UserElement[]>([]);
    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [users, setUsers] = useState<UserElement[]>([]);
    const [search, setSearch] = useState("");
    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const [jobs, setJobs] = useState<{ label: string; value: string | undefined }[]>([]);
    const [offices, setOffices] = useState<{ label: string; value: string | undefined }[]>([]);

    const { dialogState, openDialog, closeDialog } = useDialog();
    const invalidate = useInvalidate();

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });

    const pagination = useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const {
        data: userAccess,
        isFetching,
        refetch,
    } = useQuery(
        ["userAccess", "collection", pageIndex, pageSize, sorting, filters, search],
        async () => {
            const fetchDataOptions = new BasePaginationRequest();
            fetchDataOptions.size = pageSize;
            fetchDataOptions.page = pageIndex;
            fetchDataOptions.sort = sorting.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "desc";

            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            console.log("filtersData", filtersData);
            console.log("filters", filters);

            for (const element of filtersKeys) {
                if (element === "date") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                } else if (["progressStatus", "jobId", "officeId"].includes(element)) {
                    filtersData[element + ".in"] = filters[element].map((p: any) => p.value).join(",");
                } else if (element === "teamId") {
                    filtersData[element + ".in"] = filters[element].join(",");
                } else {
                    filtersData[element] = filters[element];
                }
            }

            // progressStatus
            fetchDataOptions.filters = filtersData;

            const userRes = await Api.LMSRoles.getUserAccess(resourceId, fetchDataOptions);

            return {
                ...userRes,
                users: userRes.users.filter(
                    (u) =>
                        u.user.firstName.toLowerCase().includes(search.toLowerCase()) ||
                        u.user.lastName.toLowerCase().includes(search.toLowerCase()),
                ),
            };
        },
        {
            keepPreviousData: true,
            enabled: !!resourceId,
        },
    );

    const { mutateAsync: setUserAccess } = useMutation((payload: UserAccessRequest) => {
        return Api.LMSRoles.setUserAccess(payload);
    });

    useEffect(() => {
        if (userAccess?.users) {
            setUsers(userAccess?.users);
        }
    }, [userAccess?.users]);

    useEffect(() => {
        const fetch = async () => {
            // Должности
            const jobsFetched = await Api.Job.GetJobs();
            const jobsOptions: { label: string; value: string | undefined }[] = jobsFetched.Content.map((p) => {
                return { label: p.name, value: p.id };
            });
            setJobs(jobsOptions);
            // Офисы
            const officesFetched = await Api.Office.List();
            const officesOptions: { label: string; value: string | undefined }[] = officesFetched.Content.map((p) => {
                return { label: p.name, value: p.id };
            });
            setOffices(officesOptions);
        };
        fetch();
    }, []);

    const onSearch = (query: string) => {
        setSearch(query);
    };

    const columns = React.useMemo<ColumnDef<UserElement>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Участник",
                footer: (props) => props.column.id,
                cell: ({ row, row: { original: userElement } }) => {
                    return (
                        <div
                            className="group flex items-center space-x-3 max-w-75"
                            onClick={row.getToggleSelectedHandler()}
                        >
                            <UserAvatar
                                avatarId={userElement.user.avatarId}
                                color={userElement.user.defaultAvatarColor}
                                userInitials={`${userElement.user.firstName?.slice(
                                    0,
                                    1,
                                )}${userElement.user.lastName?.slice(0, 1)}`}
                                size={36}
                            />
                            <div
                                className="line-clamp-1 cursor-pointer group-hover:text-blue"
                                // onClick={() => navigate(`/admin/members/${userElement.user.id}/edit`)}
                            >
                                {userElement.user.lastName} {userElement.user.firstName}
                            </div>
                        </div>
                    );
                },
                accessorFn: (row: UserElement) => row.user.login,
            },
            {
                header: "Должность",
                footer: (props) => props.column.id,
                cell: ({ row: { original: userElement }, ..._info }) => {
                    return <div>{userElement.user.job.name}</div>;
                },
            },
            {
                header: "Дата открытия",
                accessorKey: "date",
                cell: (info) => {
                    return formatDate(info.row.original.date * 1000, DateFormat.DATE_TIME_LONG);
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Прогресс",
                accessorKey: "progress",
                cell: ({ row: { original: userElement }, ..._info }) => {
                    return (
                        <div>
                            {userElement.progress === ProgressStatus.NOT_STARTED &&
                                getGenderLabel(resourceType, {
                                    male: "Не начат",
                                    female: "Не начата",
                                    neutral: "Не начато",
                                })}
                            {userElement.progress === ProgressStatus.IN_PROGRESS && ProgressStatusTranslate.IN_PROGRESS}
                            {userElement.progress === ProgressStatus.ON_REVIEW && ProgressStatusTranslate.ON_REVIEW}
                            {userElement.progress === ProgressStatus.PASSED &&
                                getGenderLabel(resourceType, {
                                    male: [ResourceType.QUIZ, ResourceType.COURSE].includes(resourceType)
                                        ? "Пройден"
                                        : "Принят",
                                    female: [ResourceType.PROGRAM].includes(resourceType) ? "Пройдена" : "Принята",
                                    neutral: "Принято",
                                })}
                            {userElement.progress !== ProgressStatus.NOT_STARTED &&
                                userElement.progress !== ProgressStatus.IN_PROGRESS &&
                                userElement.progress !== ProgressStatus.ON_REVIEW &&
                                userElement.progress !== ProgressStatus.PASSED &&
                                getGenderLabel(resourceType, {
                                    male: [ResourceType.QUIZ].includes(resourceType) ? "Провален" : "Отклонён",
                                    female: "Отклонена",
                                    neutral: "Отклонено",
                                })}
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "",
                id: "buttons",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 30,
                cell: (info) => {
                    return (
                        <div className="flex">
                            <Button
                                shape="round"
                                color="common"
                                icon={
                                    <Icon
                                        icon={Icons.Close}
                                        width={20}
                                        height={20}
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement={"center"}
                                onClick={() => {
                                    const { user } = info.row.original;
                                    openDialog({
                                        title: "Забрать доступ",
                                        description: `${user.lastName} ${user.firstName}`,
                                        content:
                                            "Вы действительно хотите забрать индивидуальный доступ у выбранного пользователя?",
                                        closeBtnText: "Отмена",
                                        submitBtnText: "Забрать доступ",
                                        submitBtnColor: "danger",
                                        onRequestClose: () => closeDialog(),
                                        onRequestSubmit: () => {
                                            const accessedUsers = users
                                                .filter(({ user: { id } }) => {
                                                    return id !== user.id;
                                                })
                                                .map(({ user: { id } }) => id);

                                            setUserAccess(
                                                Object.assign(new UserAccessRequest(), {
                                                    resourceId,
                                                    resourceType,
                                                    users: accessedUsers,
                                                }),
                                            ).then(() => {
                                                invalidate("userAccess");

                                                closeDialog();
                                                flash.success("Доступ отозван!");
                                            });
                                        },
                                    });
                                }}
                            />
                        </div>
                    );
                },
            },
        ],
        //eslint-disable-next-line react-hooks/exhaustive-deps
        [
            closeDialog,
            invalidate,
            navigate,
            openDialog,
            resourceId,
            resourceType,
            setUserAccess,
            users,
            resourceTypeName,
        ],
    );

    const handleSuccessGranted = () => {
        invalidate("userAccess");
        setSelectedRows([]);
        refetch().then();
    };

    const controlButtons = (
        <div className="flex justify-end items-center space-x-4">
            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement={"left"}
                onClick={() => setIsFilterShow(true)}
            >
                Фильтры
            </Button>

            <Button
                size={"medium"}
                className="rounded-lg whitespace-nowrap font-medium"
                icon={<Icon color="fill-white" icon={Icons.LockClose} width={20} height={20} />}
                iconPlacement={"left"}
                onClick={() => {
                    setShowAccessModal(true);
                }}
            >
                Открыть доступ
            </Button>
        </div>
    );

    const selectedRowButtons = (
        <div className="flex justify-end items-center space-x-5">
            <Button
                size="medium"
                color="danger"
                className="rounded-lg whitespace-nowrap font-medium"
                onClick={() => {
                    openDialog({
                        title: "Забрать доступ",
                        description:
                            selectedRows.length > 1
                                ? `Выбрано участников: ${selectedRows.length}`
                                : `${selectedRows[0]?.user?.lastName} ${selectedRows[0]?.user?.firstName}`,
                        content:
                            selectedRows.length > 1
                                ? "Вы действительно хотите забрать индивидуальный доступ у выбранных пользователей?"
                                : "Вы действительно хотите забрать индивидуальный доступ у выбранного пользователя?",
                        closeBtnText: "Отмена",
                        submitBtnText: "Забрать доступ",
                        submitBtnColor: "danger",
                        onRequestClose: () => closeDialog(),
                        onRequestSubmit: () => {
                            const selectedUsers = selectedRows.map(({ user: { id } }) => id);
                            const accessedUsers = users
                                .filter(({ user: { id } }) => {
                                    return !selectedUsers.includes(id);
                                })
                                .map(({ user: { id } }) => id);

                            setUserAccess(
                                Object.assign(new UserAccessRequest(), {
                                    resourceId,
                                    resourceType,
                                    users: accessedUsers,
                                }),
                            ).then(() => {
                                invalidate("userAccess");
                                closeDialog();

                                flash.success(selectedRows.length > 1 ? "Доступы отозваны!" : "Доступ отозван!");
                                setRowSelection({});
                            });
                        },
                    });
                }}
            >
                Забрать доступ
            </Button>
        </div>
    );

    const ClearIndicator = ({ children, ...props }: ClearIndicatorProps<any>) => {
        const itemsLength = props.getValue().length;

        const onClear = () => {
            props.clearValue();
        };
        return (
            <components.ClearIndicator {...props} className={"gap-1 !pr-7 relative"}>
                {children}
                {itemsLength > 0 && (
                    <div className="absolute group right-0 flex-center rounded-full top-1/2 -translate-y-1/2 h-5.5 min-w-[22px] text-sm bg-primary text-white cursor-pointer">
                        <span className="group-hover:hidden">{itemsLength}</span>
                        <div onClick={onClear} className="hidden group-hover:block">
                            <Icon icon={Icons.Close} width={18} height={18} color="fill-white" />
                        </div>
                    </div>
                )}
            </components.ClearIndicator>
        );
    };

    const filtersConfig = useMemo(() => {
        const config: IFilterRow[] = [
            {
                label: "Дата открытия",
                fields: [
                    {
                        accessor: "date",
                        type: "date-range",
                    },
                ],
            },
        ];
        // Прогресс не отображается для ачивок
        if (showProgressFilter) {
            config.push({
                label: "Прогресс",
                fields: [
                    {
                        accessor: "progressStatus",
                        type: "multi-select",
                        placeholder: "Выберите прогресс",
                        options: progressOptions,
                        components: {
                            ClearIndicator,
                        },
                        allPlaceholder: "Все",
                    },
                ],
            } as unknown as IFilterRow);
        }

        return config.concat([
            {
                label: "Команда",
                fields: [
                    {
                        accessor: "teamId",
                        type: "team-multi-select",
                        stacked: true,
                    },
                ],
            },
            {
                label: "Должность",
                fields: [
                    {
                        accessor: "jobId",
                        type: "multi-select",
                        placeholder: "Выберите должность",
                        options: jobs,
                        components: {
                            ClearIndicator: SelectValueContainer,
                        },
                    },
                ],
            },
            {
                label: "Офис",
                fields: [
                    {
                        accessor: "officeId",
                        type: "multi-select",
                        placeholder: "Выберите офис",
                        options: offices,
                        components: {
                            ClearIndicator: SelectValueContainer,
                        },
                    },
                ],
            },
        ] as unknown as IFilterRow[]);
    }, [showProgressFilter, /* teams,  */ jobs, offices, progressOptions]);

    return (
        <>
            <div className="h-fit">
                <Table
                    columns={columns}
                    searchTitle="Поиск по имени"
                    data={userAccess?.users}
                    pagination={pagination}
                    onPaginationChange={setPagination}
                    isFetching={isFetching}
                    rowSelection={rowSelection}
                    onRowSelectionChange={setRowSelection}
                    onColumnFiltersChange={setColumnFilters}
                    columnFilters={columnFilters}
                    controlButtons={controlButtons}
                    selectButtons={selectedRowButtons}
                    setSelectedRows={setSelectedRows}
                    emptyBlock={
                        <div className="w-full text-center text-gray pt-5">
                            {!search
                                ? "Индивидуальный доступ пока никому не открыт"
                                : "По заданным параметрам результатов нет"}
                        </div>
                    }
                    emptyMessage="Индивидуальный доступ пока никому не открыт"
                    searchAlwaysRight={true}
                    title="Индивидуальный доступ"
                    sorting={sorting}
                    onSortingChange={setSorting}
                    onSearch={onSearch}
                />
                <Confirmation {...dialogState} />
                {showAccessModal && (
                    <AccessOpenModal
                        isOpen={showAccessModal}
                        resourceId={resourceId}
                        resourceType={resourceType as ResourceType}
                        onClose={() => setShowAccessModal(false)}
                        onSuccess={handleSuccessGranted}
                        hideTeamMembers={currentUser?.data?.role === RoleName.ADMIN}
                    />
                )}
            </div>
            <Filter
                isActive={isFilterShow}
                setIsActive={setIsFilterShow}
                configuration={filtersConfig}
                filters={filters}
                onChange={setFilters}
                hideTeamMembers={currentUser?.data?.role === RoleName.ADMIN}
            />
        </>
    );
};
