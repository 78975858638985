import React, { useCallback, useContext, useEffect, useState, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TaskCategory } from "types/TaskCategory";
import { ContentLayout, TreeWrapperContext } from "Containers";
import { Breadcrumbs } from "Uikit";
import { PromptModal } from "Uikit/Modal/PromptModal";
import { TasksEmpty } from "./TasksEmpty";
import { TasksTable } from "./TasksTable";
import { TasksCategoryModal } from "./TasksCategoryModal";
import Api from "Api";
import { TaskListRequest } from "Api/Requests/TaskRequest";
import { TaskCategoryTreeResponse } from "Api/Responses/TaskCategoryResponse";
import { TaskCategoryEditRequest } from "Api/Requests/TaskCategoryRequest";
import { BaseResponse } from "Api/BaseResponse";
import { ResourceState, ResourceType } from "Enums";
import { useDialog } from "hooks/useDialog";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useInvalidate } from "hooks/useInvalidate";

const NEW_CATEGORY = {};
const urlTasks = "/admin/tasks";
const urlTask = "/admin/task";

export const Tasks = () => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();
    const { setTreeProps, sidebarApi } = useContext(TreeWrapperContext);
    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [treeData, setTreeData] = useState<{ id: string; name: string; nodeType: string; children: any[] }[]>([]);

    const { categoryId } = useParams();
    const [selectedCategory, setSelectedCategory] = useState<TaskCategoryTreeResponse | undefined>(undefined);
    const [categories, setCategories] = useState<TaskCategoryTreeResponse[]>([]);

    const [editingCategory, setEditingCategory] = useState<Partial<TaskCategory> | null>(null);
    const [deletingCategory, setDeletingCategory] = useState<TaskCategory | null>(null);

    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const handleAddCategory = useCallback(() => {
        setEditingCategory(NEW_CATEGORY);
    }, []);

    const handleEditCategory = useCallback((name: string, id: string) => {
        setEditingCategory({ id, name });
    }, []);

    const handleDeleteCategory = useCallback(
        async (name: string, id: string) => {
            const forbiddenCategoryTasksIds = await Api.TaskCategoryApi.CheckDeletionPossibility({ uuid: id });
            const isMultiple = forbiddenCategoryTasksIds.length > 1;

            if (forbiddenCategoryTasksIds.length !== 0) {
                openDialog({
                    title: "Удаление невозможно",
                    description: isMultiple
                        ? `Количество заданий: ${forbiddenCategoryTasksIds.length}`
                        : `«${selectedCategory?.title}»`,
                    content: isMultiple
                        ? 'Удаление невозможно. Задания находятся в разделе "Проверка". Вы можете скрыть задания для пользователей или архивировать после проверки результатов заданий.'
                        : 'Удаление невозможно. Задание находится в разделе "Проверка". Вы можете скрыть задание для пользователей или архивировать после проверки результатов задания.',
                    closeBtnText: "Отмена",
                    submitBtnText: isMultiple ? "Скрыть все" : "Скрыть",
                    submitBtnColor: "danger",
                    onRequestClose: () => closeDialog(),
                    onRequestSubmit: async () => {
                        await onState(forbiddenCategoryTasksIds, ResourceState.HIDDEN, ResourceType.EXERCISE);
                        invalidate("tasks");
                        closeDialog();
                    },
                });
            } else {
                setDeletingCategory({ id, name });
            }
        },
        [setDeletingCategory, selectedCategory, openDialog, closeDialog, invalidate],
    );

    const handleNavigate = useCallback(
        (node: any) => {
            if (node?.id) {
                navigate(`${urlTasks}/${node.id}`);
            } else {
                navigate(urlTasks);
            }
        },
        [navigate],
    );

    const onOrderChange = async (nodes: any) => {
        try {
            await Api.TaskCategoryApi.Reorder({ categoryIds: nodes.map((item: any) => item.id) });

            setTreeData(nodes);
        } catch (error) {
            console.log(error);
        }
    };

    const handleCloseDeleteCategoryModal = () => {
        setDeletingCategory(null);
    };

    const handleCloseCategoryModal = () => {
        setEditingCategory(null);
    };

    const handleConfirmDeleteCategory = async () => {
        if (deletingCategory?.id) {
            await Api.TaskCategoryApi.Remove({ uuid: deletingCategory.id });
            setTreeData((prevState) => prevState.filter((item) => item.id !== deletingCategory.id));
        }
        handleCloseDeleteCategoryModal();
        invalidate("tasks");
        navigate(urlTasks, { replace: true });
    };

    const onCategorySubmit = async (title: string) => {
        let categoryResponse: BaseResponse | TaskCategoryTreeResponse;
        const result = { error: false };

        if (editingCategory?.id) {
            try {
                const request = new TaskCategoryEditRequest();
                request.id = editingCategory.id;
                request.title = title;
                categoryResponse = await Api.TaskCategoryApi.Edit(request);

                setTreeData((prevState) =>
                    prevState.map((item) => {
                        if (item.id === editingCategory.id) {
                            return { ...item, name: title };
                        }

                        return item;
                    }),
                );
                navigate(`${urlTasks}${categoryId ? `/${categoryId}` : ""}`);
            } catch (e) {
                result.error = true;
            }
        } else {
            try {
                categoryResponse = await Api.TaskCategoryApi.Create({ title });

                setTreeData((prevState) => [
                    ...prevState,
                    {
                        id: (categoryResponse as TaskCategoryTreeResponse).id,
                        name: (categoryResponse as TaskCategoryTreeResponse).title,
                        nodeType: "PROJECT",
                        children: [],
                    },
                ]);

                navigate(`${urlTasks}/${(categoryResponse as TaskCategoryTreeResponse).id}`);
            } catch (e) {
                result.error = true;
            }
        }

        const categories = await Api.TaskCategoryApi.List();
        setCategories(categories.Content);

        return result;
    };

    const treeProps = useMemo(() => {
        return {
            searchable: true,
            editable: true,
            selectable: true,
            browseAll: {
                link: urlTasks,
                title: "Все задания",
                onAddClick: () => {
                    navigate(urlTask);
                },
            },
            onNodeAddClick: (event: any, nodeId: string) => {
                event.stopPropagation();
                navigate(`${urlTask}?categoryId=${nodeId}`);
            },
            onEditClick: handleEditCategory,
            onDeleteClick: handleDeleteCategory,
            onSelectNode: handleNavigate,
            getNodeLink: (id: any) => `${urlTasks}/${id}`,
            onOrderChange: onOrderChange,
            onCheckedChange: (nodesIds: any) => setSelectedCategories(nodesIds),
            footer: {
                title: "Создать категорию",
                action: handleAddCategory,
            },
            id: "adminTasksSideBar",
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, handleAddCategory, handleEditCategory, handleDeleteCategory, handleNavigate]);

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps({
            ...treeProps,
            ...{
                data: treeData,
                selectedNode: selectedCategory && categoryId ? selectedCategory : undefined,
            },
        });

        setIsEmpty(treeData.length === 0);
        setIsFetched(true);

        return () => {
            setTreeProps?.({ data: null });
        };
    }, [navigate, setTreeProps, treeData, selectedCategory, treeProps, categoryId]);

    useEffect(() => {
        if (categoryId === undefined) {
            setSelectedCategory(undefined);
        } else {
            const category = categories.find((p) => p.id === categoryId);

            if (category === undefined) {
                return;
            }

            setSelectedCategory(category);
        }
    }, [navigate, categoryId, categories]);

    useEffect(() => {
        const fetch = async () => {
            const categories = await Api.TaskCategoryApi.List();
            setCategories(categories.Content);

            const categoriesTree = (categories.Content as unknown as TaskCategoryTreeResponse[]).map((category) => ({
                id: category.id,
                name: category.title,
                nodeType: "PROJECT",
                children: [],
            }));
            setTreeData(categoriesTree);
        };
        fetch().then();

        const sbApi = sidebarApi?.current;
        return () => {
            sbApi?.setCheckedBucket?.({});
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const fetchTasks = async (props: TaskListRequest) => {
        let filters = props.filters as { [id: string]: string };
        filters = Object.keys(filters).reduce((acc: any, key) => {
            if (filters[key]) {
                acc[key] = filters[key];
            }

            return acc;
        }, {});

        props.filters = filters;

        return await Api.Task.List(props);
    };
    const onCopy = async (id: string) => navigate(`${urlTask}/${id}/copy`);
    const onState = async (ids: string[], state: string, resourceType: ResourceType) => {
        await Api.Test.State(ids, state, resourceType);
    };

    return (
        <ContentLayout className="h-full">
            <TasksCategoryModal
                isOpen={!!editingCategory}
                onClose={handleCloseCategoryModal}
                categoryId={editingCategory?.id}
                categoryName={editingCategory?.name}
                onSubmit={onCategorySubmit}
            />
            <PromptModal
                onConfirm={handleConfirmDeleteCategory}
                onClose={handleCloseDeleteCategoryModal}
                isOpen={!!deletingCategory}
                title="Удаление категории"
                dialogText={["Отмена", "Удалить"]}
            >
                <div className="space-y-3.5">
                    <div className="text-gray-text">&quot;{deletingCategory?.name}&quot;</div>
                    <div>Вы действительно хотите удалить категорию? Все вложенные в нее элементы попадут в Архив</div>
                </div>
            </PromptModal>
            <Confirmation {...dialogState} />
            <Breadcrumbs className="mb-2.5" id="adminTasksBreadcrumbs">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Задания" url={selectedCategory ? urlTasks : ""} />
                {selectedCategory && <Breadcrumbs.Link title={selectedCategory["title"]} />}
            </Breadcrumbs>
            {isFetched && isEmpty && (
                <div className="h-full flex justify-center">
                    <TasksEmpty
                        categoryId={selectedCategory?.id}
                        onCreateCategory={handleAddCategory}
                        onCreateTask={() => navigate(urlTask)}
                    />
                </div>
            )}
            {isFetched && !isEmpty && (
                <TasksTable
                    categoryId={selectedCategory?.id}
                    // teams={teams}
                    selectedCategories={selectedCategories}
                    onState={onState}
                    onCopy={onCopy}
                    fetchTasks={fetchTasks}
                    onCreateCategory={handleAddCategory}
                />
            )}
        </ContentLayout>
    );
};
