import React, { useEffect, useState } from "react";
import { MaterialType, ProgressStatus, ResourceState, StatisticsUsersStatus } from "Enums";
import { useQuery } from "react-query";
import {
    Breadcrumbs,
    Button,
    ComboBox,
    FormGroup,
    Label,
    OptionsDropdownIndicator,
    SelectAvatarMultiValueLabel,
} from "Uikit";
import Api from "Api";
import { TasksModalTree } from "../Tasks/TasksModalTree";
import { MaterialListResponse } from "Api/Responses/MaterialResponse";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { UsersModal } from "../../Modal/UsersModal";
import { numWord } from "helpers/numWord";
import { IRequestSettingsItem, ReportSettings } from "../../ReportSettings/ReportSettings";
import { StatisticMaterialRequest } from "Api/Requests/StatisticRequest";
import { ReportUsersTable } from "./ReportUsersTable";
import { IStatisticValue, structurizeMaterialTreeData } from "../utils";
import { SingleValueContainer } from "../SingleValueContainer";

export type TTaskFilterValue = ResourceState | "ALL";

export const reportSettings = [
    {
        title: "Участник",
        key: "user",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Фамилия, Имя участника",
                value: "name",
                checked: true,
            },
            {
                title: "Логин",
                value: "login",
                checked: true,
            },
            {
                title: "Электронная почта",
                value: "email",
                checked: true,
            },
            {
                title: "Должность",
                value: "job",
                checked: true,
            },
            {
                title: "Офис",
                value: "office",
                checked: true,
            },
            {
                title: "Статус участника",
                value: "status",
                checked: true,
            },
            {
                title: "Команда",
                value: "command",
                checked: true,
            },
            {
                title: "Путь к команде",
                value: "commandPath",
                checked: true,
            },
            {
                title: "Наставник",
                value: "mentor",
                checked: true,
            },
        ],
    },
    {
        title: "Материал",
        key: "material",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Дата завершения",
                value: "endDate",
                checked: true,
            },
            {
                title: "Статус материала",
                value: "status",
                checked: true,
            },
            {
                title: "Затрачено времени",
                value: "spendTime",
                checked: true,
            },
        ],
    },
];

// Тип материала
export const materialTypeOptions = [
    {
        value: MaterialType.Article,
        label: "Статья",
    },
    {
        value: MaterialType.Video,
        label: "Видео",
    },
    {
        value: MaterialType.Document,
        label: "Документ",
    },
    {
        value: MaterialType.SCORM,
        label: "SCORM",
    },
];

// Статус прохождения материала
export const progressOptions = [
    {
        value: ProgressStatus.ALL,
        label: "Любой",
    },
    {
        value: ProgressStatus.PASSED,
        label: "Пройден",
    },
    {
        value: ProgressStatus.IN_PROGRESS,
        label: "В процессе",
    },
    {
        value: ProgressStatus.NOT_STARTED,
        label: "Не начат",
    },
];

// Статус участников
export const usersStatusOptions = [
    {
        value: StatisticsUsersStatus.ALL,
        label: "Любой",
    },
    {
        value: StatisticsUsersStatus.ACTIVE,
        label: "Активные",
    },
    {
        value: StatisticsUsersStatus.BLOCKED,
        label: "Заблокированные",
    },
];

export const Materials = () => {
    const [selectedMaterialItems, setSelectedMaterialItems] = useState<any[]>([]);
    const [selectedAcceptedMaterialItems, setSelectedAcceptedMaterialItems] = useState<any[]>([]);
    const [materialsValues, setMaterialsValues] = useState<IStatisticValue[]>([]);
    const [materialsTree, setMaterialsTree] = useState<any[]>([]);
    const [selectedMaterialsCount, setSelectedMaterialsCount] = useState(0);
    const [showReportUsersTable, setShowReportUsersTable] = useState(false);
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [isModalMaterialTreeOpen, setIsModalMaterialTreeOpen] = useState(false);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [materialType, setMaterialType] = useState(materialTypeOptions[0]);
    const [progress, setProgress] = useState<{ value: ProgressStatus; label: string }>(progressOptions[0]);
    const [usersStatus, setUsersStatus] = useState(usersStatusOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [isFilterEmpty, setIsFilterEmpty] = useState(true);

    // all materials request
    const materialsDataQuery = useQuery(
        ["materialsTree", materialType.value],
        async () => {
            return await Api.Material.List(0, 999, [{ id: "modifyTimestamp", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
                "type.equal": materialType.value,
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: materialTreeData } = materialsDataQuery;

    // all users request
    useQuery(
        ["users", "collection"],
        () =>
            Api.User.GetMembersList(0, 2000, [{ id: "firstName,lastName", desc: false }], {
                "state.in": "ACTIVE,BLOCKED",
            }),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setAllUsers(data.Content);
            },
        },
    );

    const onSubmitCourseSection = () => {
        setSelectedAcceptedMaterialItems(selectedMaterialItems);
        setIsModalMaterialTreeOpen(false);
        setShowReportUsersTable(false);
    };

    const createReport = () => {
        setShowReportUsersTable(true);
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateMaterialStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    const createPayloadParams = () => {
        const payload: StatisticMaterialRequest = {
            materialIds: materialsValues.map(({ id }) => id),
            userIds: users.map(({ id }) => id),
            teamsIds: Array.from(
                new Set(
                    users.reduce((prev, { teams }) => {
                        return [...prev, ...teams.map(({ id }) => id)];
                    }, [] as string[]),
                ),
            ),
            officeIds: users.map(({ officeId }) => officeId).filter((item) => !!item),
            jobIds: users.map(({ jobTitle }) => jobTitle?.id).filter((item) => !!item) as string[],
            status: progress.value,
            userStatus: usersStatus.value,
            materialType: materialType.value,
        };

        return payload;
    };

    // Setting courseTree data
    useEffect(() => {
        if (materialTreeData?.Content && selectedAcceptedMaterialItems.length === 0) {
            setMaterialsTree(
                structurizeMaterialTreeData(
                    materialTreeData?.Content,
                    selectedAcceptedMaterialItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                            selected: true,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [materialTreeData]);

    useEffect(() => {
        setSelectedMaterialsCount(selectedMaterialItems.length);
    }, [selectedMaterialItems]);

    useEffect(() => {
        setMaterialsTree(
            structurizeMaterialTreeData(
                materialTreeData?.Content as unknown as MaterialListResponse[],
                selectedAcceptedMaterialItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
            ),
        );

        setMaterialsValues(
            selectedAcceptedMaterialItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        if (selectedAcceptedMaterialItems.length === 0) {
            setUsers(allUsers);
            setProgress(progressOptions[0]);
            setUsersStatus(usersStatusOptions[0]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedMaterialItems]);

    useEffect(() => {
        setUsers(allUsers);
        setAllUsersCount(allUsers.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers]);

    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Материалы" />
                <Breadcrumbs.Link title="Отчет по материалам" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по материалам</h1>
                <div className="w-full h-full mt-5">
                    <div className="grid items-center gap-6.5 grid-cols-2 relative z-[75]">
                        <FormGroup className="!mb-0">
                            <Label>Тип материала</Label>
                            <ComboBox
                                onChange={(value) => {
                                    setMaterialType(value as { value: MaterialType; label: string });
                                    setSelectedAcceptedMaterialItems([]);
                                }}
                                value={materialType}
                                options={materialTypeOptions}
                                isSearchable={false}
                                isCreatable={false}
                                isClearable={false}
                            />
                        </FormGroup>
                        <FormGroup className="!mb-0">
                            <Label>Материал</Label>
                            {/* Выбор материала */}
                            <ComboBox
                                placeholder="Выберите материал"
                                onChange={(value) => {
                                    setSelectedAcceptedMaterialItems([value]);
                                    setShowReportUsersTable(false);
                                }}
                                value={materialsValues[0] ?? null}
                                components={{
                                    DropdownIndicator: OptionsDropdownIndicator({
                                        onClick: (e) => {
                                            if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                                setIsModalMaterialTreeOpen((prevIsOpen) => !prevIsOpen);
                                            }
                                        },
                                    }),

                                    ValueContainer: SingleValueContainer,
                                    // MultiValueLabel: SelectImgMultiValueLabel({}),
                                }}
                                onMenuOpen={() => setIsModalMaterialTreeOpen((prevIsOpen) => !prevIsOpen)}
                                /*
                                allPlaceholder={
                                    ((courseTreeData?.Content as unknown as CourseListResponse[]) ?? []).length > 0 &&
                                    coursesValues.length === courseTreeData?.Content.length
                                }
                                */
                                isSearchable={false}
                                isCreatable={false}
                                isClearable={false}
                                menuIsOpen={false}
                            />
                        </FormGroup>
                    </div>
                    {/* Выбор материала - модалка */}
                    <TasksModalTree
                        isOpen={isModalMaterialTreeOpen}
                        title="Выбор материала"
                        setIsOpen={(open: boolean) => {
                            setSelectedMaterialItems(selectedAcceptedMaterialItems);
                            setMaterialsTree(
                                structurizeMaterialTreeData(
                                    materialTreeData?.Content as unknown as MaterialListResponse[],
                                    selectedAcceptedMaterialItems.map(({ id, state }) => {
                                        return {
                                            id,
                                            checked: state.checked,
                                        };
                                    }),
                                ),
                            );

                            setIsModalMaterialTreeOpen(open);
                            setIsFilterEmpty(true);
                        }}
                        treeData={materialsTree}
                        /*
                        checkedChange={(selectedNodes) => {
                            setSelectedCourseItems(selectedNodes);
                        }}
                        */
                        onSelectNode={(node) => setSelectedMaterialItems([node])}
                        checkable={false}
                        selectable={true}
                        checkOnNameClick={true}
                        showSelectedCounter={false}
                        onSubmit={onSubmitCourseSection}
                        submitButtonTitle="Выбрать"
                        selectedCount={selectedMaterialsCount}
                        disableRootSelection
                        onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                            setMaterialsTree(
                                structurizeMaterialTreeData(
                                    (materialTreeData?.Content as unknown as MaterialListResponse[]).filter(
                                        ({ state, title }) => {
                                            return (
                                                (!query ||
                                                    title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                                    query.trim() === "") &&
                                                (state === value || value === "ALL")
                                            );
                                        },
                                    ),
                                    selectedMaterialItems.map(({ id, state }) => {
                                        return {
                                            id,
                                            checked: state.checked,
                                        };
                                    }),
                                ),
                            );
                            setIsFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                        }}
                        emptyMessage={
                            isFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"
                        }
                    />
                    {selectedAcceptedMaterialItems.length > 0 && (
                        <>
                            <div className="mt-7 flex-wrap relative z-[70]">
                                <div className="grid items-center gap-6.5 grid-cols-2">
                                    {/* Выбор участников */}
                                    <FormGroup className="!mb-0">
                                        <Label>Участники</Label>
                                        <ComboBox
                                            placeholder={"Выберите одного или нескольких участников"}
                                            onChange={(options, action) => {
                                                if (action && action.action === "clear") {
                                                    setUsers([]);
                                                }
                                                if (action && action.action === "remove-value") {
                                                    setUsers(options as MembersListResponse[]);
                                                }
                                                setShowReportUsersTable(false);
                                            }}
                                            value={users}
                                            components={{
                                                DropdownIndicator: OptionsDropdownIndicator({
                                                    onClick: (e) => {
                                                        if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                                            setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                                        }
                                                    },
                                                }),
                                                // ValueContainer: SelectValueContainer({
                                                //     onClick: (e) => {
                                                //         if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                                //             setShowUsersModal((prevIsOpen) => {
                                                //                 return !prevIsOpen;
                                                //             });
                                                //         }
                                                //     },
                                                //     // oneRow: true
                                                //     allPlaceholder: users.length > 0 && allUsersCount === users.length,
                                                // }),
                                                ValueContainer: StackedValueContainer,
                                                MultiValueLabel: SelectAvatarMultiValueLabel({}),
                                            }}
                                            onClick={(e) => {
                                                if (
                                                    !(e.target as HTMLElement).closest(
                                                        ".ui-combo-box__multi-value__remove",
                                                    )
                                                ) {
                                                    setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                                }
                                            }}
                                            allPlaceholder={users.length > 0 && allUsersCount === users.length}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                            menuIsOpen={false}
                                            isMulti
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Статус прохождения материала</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setProgress(value as { value: ProgressStatus; label: string });
                                                setShowReportUsersTable(false);
                                            }}
                                            value={progress}
                                            options={progressOptions}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                    <FormGroup className="!mb-0">
                                        <Label>Статус участников</Label>
                                        <ComboBox
                                            onChange={(value) => {
                                                setUsersStatus(
                                                    value as { value: StatisticsUsersStatus; label: string },
                                                );
                                                setShowReportUsersTable(false);
                                            }}
                                            value={usersStatus}
                                            options={usersStatusOptions}
                                            isSearchable={false}
                                            isCreatable={false}
                                            isClearable={false}
                                        />
                                    </FormGroup>
                                </div>
                            </div>
                            {/* Выбор участников - модалка */}
                            <UsersModal
                                isOpen={showUsersModal}
                                onClose={() => {
                                    setShowUsersModal(false);
                                }}
                                onSubmit={(users) => {
                                    setUsers(
                                        users.map((user) => {
                                            const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                            return {
                                                ...user,
                                                value: {
                                                    avatarId,
                                                    firstName,
                                                    lastName,
                                                    defaultAvatarColor,
                                                },
                                                label: `${lastName} ${firstName}`,
                                            };
                                        }),
                                    );
                                    setShowReportUsersTable(false);
                                }}
                                selectedUsersDefault={users}
                                allUsers={allUsers}
                            />
                            <div className="mt-9">
                                {showReportUsersTable ? (
                                    <>
                                        <ReportUsersTable
                                            onClickLoadReportFile={() => setShowReportSettings(true)}
                                            queryPayload={createPayloadParams()}
                                        />
                                        <ReportSettings
                                            isActive={showReportSettings}
                                            setIsActive={setShowReportSettings}
                                            settings={reportSettings}
                                            onSubmit={onSubmit}
                                            isReportFileFetching={false}
                                        />
                                    </>
                                ) : (
                                    <Button
                                        key={1}
                                        onClick={() => createReport()}
                                        variant="standard"
                                        size={"medium"}
                                        color={"primary"}
                                        className="w-full"
                                        disabled={!users.length}
                                    >
                                        {`Сформировать отчет по ${users.length} ${numWord(users.length, [
                                            "участнику",
                                            "участникам",
                                            "участникам",
                                        ])}`}
                                    </Button>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
