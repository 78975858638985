import React, { forwardRef } from "react";
import { SingleValue } from "react-select";
import { ComboBox, FormGroup, Input, Label, Toggle, CoverInput } from "Uikit";
import { Section } from "Uikit/Forms/Section";
import { CourseRequest } from "Api/Requests/CourseRequest";
import { CategoryMenuList, UsersOption, UsersSingleValue } from "Uikit/Forms/SelectComponents";
import { CategoryModal } from "Uikit/CategoryModalNew/CategoryModal";
import Api from "Api/index";
import { ResourceState } from "Enums";
import { AccountableUser } from "Api/Responses/UserResponse";
import { DateTimeSelector } from "Uikit/DateTimeSelector/DateTimeSelector";
import { IOption } from "types";

interface ICourseSettings {
    errors: any;
    users: { label: string; value: string; payload: AccountableUser }[];
    categories: IOption[];
    setCategories: (value: IOption[]) => void;
    course: CourseRequest;
    defaultLogos: string[];
    cover: File | null;
    onChangeCover: (data: File | null) => void;
    onChange: (course: CourseRequest) => void;
}
export const CourseSettings = forwardRef(
    ({
        errors,
        users,
        categories,
        setCategories,
        course,
        defaultLogos,
        onChangeCover,
        onChange,
        cover,
    }: ICourseSettings) => {
        const [isCategoryModalOpen, setIsCategoryModalOpen] = React.useState(false);

        const onAddCategoryClick = () => {
            setIsCategoryModalOpen(true);
        };

        const onCategorySubmit = async (title: string) => {
            try {
                const response = await Api.Course.CategoryCreate({ title: title });

                setCategories([...categories, { label: title, value: response.id }]);
                onChange({ ...course, categoryId: response.id });
            } catch (error) {
                return error;
            }
        };

        const maxTime = new Date();
        maxTime.setHours(23);
        maxTime.setMinutes(45);

        return (
            <>
                <Section label="Внешний вид">
                    <FormGroup>
                        <Label>Обложка курса</Label>
                        <CoverInput
                            btn={false}
                            // logo={course?.logoId ?? null}
                            logo={cover ?? course.logoId ?? null}
                            onChange={onChangeCover}
                            onChangeDefaultLogo={(id) => onChange({ ...course, logoId: id })}
                            defaultLogoIds={defaultLogos}
                            id="adminNewCourse"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label isRequired={true}>Заголовок курса</Label>
                        <Input
                            isRequired={true}
                            placeholder="Введите заголовок"
                            value={course.title}
                            onChange={(e) => onChange({ ...course, title: e.target.value })}
                            error={errors["title"]}
                            maxLength={128}
                            id="adminNewCourseInputName"
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label>Краткое описание</Label>
                        <Input
                            as="textarea"
                            placeholder="Введите краткое описание"
                            value={course.description}
                            onChange={(e) => onChange({ ...course, description: e.target.value })}
                            error={errors["description"]}
                            maxLength={1024}
                            id="adminNewCourseInputDescription"
                        />
                    </FormGroup>
                </Section>
                <Section label="Месторасположение">
                    <FormGroup>
                        <Label isRequired={true}>Категория</Label>
                        <ComboBox
                            isSearchable
                            placeholder="Выберите категорию"
                            value={categories[categories.findIndex((p) => course.categoryId === p.value)]}
                            options={categories}
                            addOptionClick={onAddCategoryClick}
                            components={{ MenuList: CategoryMenuList, NoOptionsMessage: () => <div></div> }}
                            onChange={(option: SingleValue<any>) => onChange({ ...course, categoryId: option.value })}
                            error={errors["category"]}
                            id="adminNewCourseComboBoxCategory"
                        />
                    </FormGroup>
                </Section>
                <Section label="Контроль">
                    <FormGroup>
                        <Label isRequired={true}>Ответственный</Label>
                        <ComboBox
                            isSearchable
                            placeholder="Выберите ответственного"
                            value={users[users.findIndex((p) => p.value === course.managerUserId)]}
                            options={users}
                            onChange={(option: SingleValue<any>) =>
                                onChange({ ...course, managerUserId: option.value })
                            }
                            error={errors["manager"]}
                            components={{
                                SingleValue: UsersSingleValue,
                                Option: UsersOption,
                            }}
                            id="adminNewCourseComboBoxManager"
                        />
                    </FormGroup>
                </Section>
                <Section label="Публикация">
                    <FormGroup className="flex items-center !mb-3.5 h-10">
                        <Toggle
                            className="mr-auto"
                            label="Назначить отложенную дату публикации"
                            enabled={!!course.publicationDelayTimestamp}
                            onChange={(p) => {
                                const defaultDate = new Date();

                                defaultDate.setHours(10);
                                defaultDate.setMinutes(0);

                                defaultDate.setSeconds(0);
                                defaultDate.setMilliseconds(0);

                                const updatedCourse: CourseRequest = {
                                    ...course,
                                    publicationDelayTimestamp: p ? defaultDate.getTime() / 1000 + 86400 : null,
                                };
                                if (p && course.state === ResourceState.ACTIVE) {
                                    updatedCourse.state = ResourceState.HIDDEN;
                                }
                                onChange(updatedCourse);
                            }}
                            id="adminNewCourseTogglePublicLater"
                        />
                        {!!course.publicationDelayTimestamp && (
                            <DateTimeSelector
                                isPastTime={false}
                                error={
                                    course.publicationDelayTimestamp &&
                                    course.expirationTimestamp &&
                                    course.expirationTimestamp <= course.publicationDelayTimestamp
                                        ? "Дата снятия с публикации не может быть раньше даты публикации"
                                        : ""
                                }
                                dateTime={course.publicationDelayTimestamp}
                                onChange={(dateTime) => onChange({ ...course, publicationDelayTimestamp: dateTime })}
                                showTimeInput
                            />
                        )}
                    </FormGroup>
                    <FormGroup className="flex items-center !mb-3.5 h-10">
                        <Toggle
                            className="mr-auto"
                            enabled={!!course.expirationTimestamp}
                            onChange={(p) => {
                                const defaultDate = new Date();

                                defaultDate.setHours(10);
                                defaultDate.setMinutes(0);

                                defaultDate.setSeconds(0);
                                defaultDate.setMilliseconds(0);

                                const updatedCourse: CourseRequest = {
                                    ...course,
                                    expirationTimestamp: p ? defaultDate.getTime() / 1000 + 86400 : null,
                                };
                                if (p && course.state === ResourceState.HIDDEN) {
                                    updatedCourse.state = !course.publicationDelayTimestamp
                                        ? ResourceState.ACTIVE
                                        : ResourceState.HIDDEN;
                                }
                                onChange(updatedCourse);
                            }}
                            label="Назначить дату снятия с публикации"
                            id="adminNewCourseTogglePublicEnd"
                        />
                        {!!course.expirationTimestamp && (
                            <DateTimeSelector
                                isPastTime={false}
                                error={
                                    course.publicationDelayTimestamp &&
                                    course.expirationTimestamp &&
                                    course.expirationTimestamp <= course.publicationDelayTimestamp
                                        ? "Дата снятия с публикации не может быть раньше даты публикации"
                                        : ""
                                }
                                dateTime={course.expirationTimestamp}
                                onChange={(dateTime) => onChange({ ...course, expirationTimestamp: dateTime })}
                                showTimeInput
                            />
                        )}
                    </FormGroup>
                    <FormGroup className="flex items-center !mb-3.5 h-10">
                        <Toggle
                            className="mr-auto"
                            enabled={!!course.deadlineTimestamp}
                            onChange={(p) => {
                                const defaultDate = new Date();

                                defaultDate.setHours(10);
                                defaultDate.setMinutes(0);

                                onChange({
                                    ...course,
                                    deadlineTimestamp: p ? defaultDate.getTime() / 1000 + 86400 : null,
                                });
                            }}
                            label="Назначить дедлайн"
                            tip="Курс получит статус «Просрочен», но будет доступен для прохождения"
                            id="adminNewCourseToggleDeadLine"
                        />
                        {!!course.deadlineTimestamp && (
                            <DateTimeSelector
                                isPastTime={true}
                                dateTime={course.deadlineTimestamp}
                                onChange={(dateTime) => onChange({ ...course, deadlineTimestamp: dateTime })}
                                showTimeInput
                            />
                        )}
                    </FormGroup>
                    <FormGroup className="flex items-center !mb-3.5 h-10">
                        <Toggle
                            className="mr-auto"
                            enabled={course.isRequired}
                            onChange={(p) => onChange({ ...course, isRequired: p })}
                            label="Курс обязательный к прохождению"
                            tip={
                                'Курс будет закреплен в блоке "Моё обучение" на главной странице и в разделе "Обучение" в блоке "Актуальное"'
                            }
                            id="adminNewCourseToggleRequired"
                        />
                    </FormGroup>
                </Section>
                <Section label="Прочее" className="!border-0">
                    <FormGroup className="flex items-center">
                        <Toggle
                            className="mr-auto"
                            enabled={course.issueCertificate}
                            onChange={(p) => onChange({ ...course, issueCertificate: p })}
                            label="Выдать сертификат после прохождения"
                        />
                    </FormGroup>
                    <FormGroup className="flex items-center">
                        <Toggle
                            className="mr-auto"
                            enabled={course.hideAvgReviewScore}
                            onChange={(p) => onChange({ ...course, hideAvgReviewScore: p })}
                            label="Скрыть оценку курса"
                        />
                    </FormGroup>
                    <FormGroup className="flex items-center">
                        <Toggle
                            className="mr-auto"
                            enabled={course.hideUserReviews}
                            onChange={(p) => onChange({ ...course, hideUserReviews: p })}
                            label="Скрыть отзывы для участников"
                        />
                    </FormGroup>
                </Section>

                <CategoryModal
                    isOpen={isCategoryModalOpen}
                    onClose={() => setIsCategoryModalOpen(false)}
                    onSubmit={onCategorySubmit}
                />
            </>
        );
    },
);
