import React, { useEffect, useMemo, useState } from "react";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";
import { useInfiniteQuery, useQuery, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { IReducer } from "store";
import { useCurrentUser } from "hooks/useCurrentUser";
import { DateFormat, formatDate, formatLastActivityTime } from "helpers/dateHelper";
import { setIsNotifications, setNotificationsCount } from "slices/headerSlice";
import { InfiniteList } from "Components/InfiniteList/InfiniteList";
import { AchievementsOwnModal } from "Components/AchievementsOwnModal";
import { Loader } from "Uikit/Loader/Loader";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { NotificationListResponse } from "Api/Responses/NotificationResponse";
import NotificationConnect, { NotificationSubscribe, WssMessage, WssMessageType } from "Api/Wss/Notifications";
import Api from "Api/index";
import { Button } from "Uikit/Button/Button";
import { GetSystemProperty } from "helpers/systemPropertyHelper";
import { NotificationActionType, ResourceType, RoleName, UserImportTypes } from "Enums";
import { Empty } from "Uikit/Page/Empty";
import { LogoSize } from "Api/Services/UploadApi";

let readTimeout: string | number | NodeJS.Timeout | undefined = undefined;

export const Notifications = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const queryClient = useQueryClient();
    const user = useCurrentUser();

    const isOpen = useSelector((state: IReducer) => state.header.isNotifications);
    const notificationsCount = useSelector((state: IReducer) => state.header.notificationsCount);

    const [isInitialized, setIsInitialized] = useState(false);
    const [isAchievementModal, setIsAchievementModal] = useState(false);

    const dataQuery = useInfiniteQuery(
        ["notifications"],
        async ({ pageParam }) => {
            return await Api.NotificationApi.List(pageParam || 0, 15);
        },
        {
            enabled: isOpen,
            getNextPageParam: (lastPage) => {
                return lastPage.Page + 1;
            },
        },
    );
    const systemProperties = useQuery(["system-properties"], async () => await Api.Commons.getSystemProperties(), {
        enabled: !!user.data && user.data?.role !== RoleName.USER,
        staleTime: 60 * 60 * 1000,
    });

    const onReadAll = async () => {
        await Api.NotificationApi.ReadAll();

        await dataQuery.refetch();
        dispatch(setNotificationsCount(+(await Api.NotificationApi.Count())));
    };

    const getItemTitle = (item: NotificationListResponse) => {
        const fileExpirationTime = GetSystemProperty("FILE_EXPIRATION_TIME", "15", systemProperties.data);

        if (item.objectType === ResourceType.QUIZ && item.actionType === NotificationActionType.CHECK) {
            return (
                <>
                    Тест <span className="p2 text-blue 2xl:text-md">“{item.message}“</span> был проверен
                </>
            );
        } else if (item.objectType === ResourceType.EXERCISE && item.actionType === NotificationActionType.CHECK) {
            return (
                <>
                    Задание <span className="p2 text-blue 2xl:text-md">“{item.message}“</span> было проверено
                </>
            );
        } else if (item.objectType === ResourceType.PROGRAM && item.actionType === NotificationActionType.ASSIGN) {
            return (
                <>
                    Вам назначена программа обучения <span className="p2 text-blue 2xl:text-md">“{item.message}“</span>
                </>
            );
        } else if (item.objectType === ResourceType.COURSE && item.actionType === NotificationActionType.ASSIGN) {
            return (
                <>
                    Вам назначен курс <span className="p2 text-blue 2xl:text-md">“{item.message}“</span>
                </>
            );
        } else if (item.objectType === ResourceType.QUIZ && item.actionType === NotificationActionType.ASSIGN) {
            return (
                <>
                    Вам назначен тест <span className="p2 text-blue 2xl:text-md">“{item.message}“</span>
                </>
            );
        } else if (item.objectType === ResourceType.EXERCISE && item.actionType === NotificationActionType.ASSIGN) {
            return (
                <>
                    Вам назначено задание <span className="p2 text-blue 2xl:text-md">“{item.message}“</span>
                </>
            );
        } else if (item.objectType === ResourceType.ACHIEVEMENT && item.actionType === NotificationActionType.ASSIGN) {
            return (
                <>
                    Вам выдали ачивку <span className="p2 text-blue 2xl:text-md">“{item.message}“</span>
                </>
            );
        } else if (
            item.objectType === ResourceType.REPORT &&
            item.actionType === NotificationActionType.GENERATE_START
        ) {
            return (
                <>
                    Идет формирование файла отчета <span className="p2 text-blue 2xl:text-md">“{item.message}“</span>
                </>
            );
        } else if (
            item.objectType === ResourceType.REPORT &&
            item.actionType === NotificationActionType.GENERATE_FINISH
        ) {
            return (
                <>
                    Отчет <span className="p2 text-blue 2xl:text-md">“{item.message}“</span> готов. Скачать отчет можно
                    до&nbsp;
                    {formatDate(
                        item.timestamp * 1000 + 60 * 60 * 24 * +fileExpirationTime * 1000,
                        DateFormat.NOTIFICATION_DATE,
                    )}
                </>
            );
        } else if (item.objectType === ResourceType.REPORT && item.actionType === NotificationActionType.EXPIRED) {
            return (
                <>
                    Отчет <span className="p2 text-blue 2xl:text-md">“{item.message}“</span> больше недоступен для
                    скачивания. Его можно сформировать заново
                </>
            );
        } else if (item.actionType === NotificationActionType.USER_IMPORT_STARTED) {
            return (
                <>
                    {item.data.importType === UserImportTypes.CREATE
                        ? "Идет импорт пользователей"
                        : "Идет обновление пользователей"}
                </>
            );
        } else if (item.actionType === NotificationActionType.USER_IMPORT_FINISHED && !item.data.errorFileId) {
            return (
                <>
                    {item.data.importType === UserImportTypes.CREATE
                        ? "Импорт завершен. Ошибок не выявлено"
                        : "Обновление пользователей завершено. Ошибок не выявлено"}
                </>
            );
        } else if (item.actionType === NotificationActionType.USER_IMPORT_FINISHED && item.data.errorFileId) {
            if (!isFileExpired(item.timestamp)) {
                return (
                    <>
                        {item.data.importType === UserImportTypes.CREATE
                            ? "Импорт завершен"
                            : "Обновление пользователей завершено"}
                        . Были выявлены ошибки. <span className="p2 text-blue 2xl:text-md">Скачать файл</span> с
                        ошибками можно до&nbsp;
                        {formatDate(
                            item.timestamp * 1000 + 60 * 60 * 24 * +fileExpirationTime * 1000,
                            DateFormat.NOTIFICATION_DATE,
                        )}
                    </>
                );
            }

            return (
                <>
                    {item.data.importType === UserImportTypes.CREATE
                        ? "Файл с ошибками импорта пользователей больше не доступен"
                        : "Файл с ошибками обновления пользователей больше не доступен"}
                </>
            );
        }
    };

    const navigateToItem = async (item: NotificationListResponse) => {
        if (item.objectType === ResourceType.PROGRAM && item.actionType === NotificationActionType.ASSIGN) {
            dispatch(setIsNotifications(false));
            navigate(`/training/program/${item.objectId}`);
        } else if (item.objectType === ResourceType.COURSE && item.actionType === NotificationActionType.ASSIGN) {
            dispatch(setIsNotifications(false));
            navigate(`/training/course/${item.objectId}`);
        } else if (item.objectType === ResourceType.QUIZ && item.actionType === NotificationActionType.ASSIGN) {
            dispatch(setIsNotifications(false));
            navigate(`/test/${item.objectId}`);
        } else if (item.objectType === ResourceType.QUIZ && item.actionType === NotificationActionType.CHECK) {
            dispatch(setIsNotifications(false));
            navigate(`/test/${item.objectId}/statistics`);
        } else if (item.objectType === ResourceType.EXERCISE && item.actionType === NotificationActionType.ASSIGN) {
            dispatch(setIsNotifications(false));
            navigate(`/task/${item.objectId}`);
        } else if (item.objectType === ResourceType.EXERCISE && item.actionType === NotificationActionType.CHECK) {
            dispatch(setIsNotifications(false));
            navigate(`/task/${item.objectId}/statistics`);
        } else if (item.objectType === ResourceType.ACHIEVEMENT && item.actionType === NotificationActionType.ASSIGN) {
            if (window.screen.width <= 568) {
                navigate("/personal/achievements");
            } else {
                setIsAchievementModal(true);
            }
        } else if (
            item.objectType === ResourceType.REPORT &&
            item.actionType === NotificationActionType.GENERATE_FINISH
        ) {
            const data = await Api.Statistic.DownloadReportFile(item.objectId);

            const blob = new Blob([data], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });

            saveAs(blob, item.message);
        } else if (item.actionType === NotificationActionType.USER_IMPORT_FINISHED && item.data.errorFileId) {
            if (!isFileExpired(item.timestamp)) {
                const data = await Api.Upload.Download(item.data.errorFileId);

                const blob = new Blob([data], {
                    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                });

                const fileDate = formatDate(item.timestamp * 1000, DateFormat.NOTIFICATION_DATE).replace(":", ".");
                const fileName = `Ошибки ${
                    item.data.importType === UserImportTypes.CREATE ? "импорта" : "обновления пользователей"
                } ${fileDate}.xlsx`;

                saveAs(blob, fileName);
            }
        }
    };

    const isFileExpired = (notificationTimestamp: number) => {
        const fileExpirationTime = GetSystemProperty("FILE_EXPIRATION_TIME", "15", systemProperties.data);

        return Date.now() > notificationTimestamp * 1000 + 60 * 60 * 24 * +fileExpirationTime * 1000;
    };

    useEffect(() => {
        if (!isOpen) {
            clearTimeout(readTimeout);
            return;
        }

        const read = () => {
            if (!dataQuery.data) {
                readTimeout = setTimeout(read, 3000);
                return;
            }

            const view = document.getElementById("notificationsViewSection");

            if (!view) {
                readTimeout = setTimeout(read, 3000);
                return;
            }

            const viewItems: NodeListOf<HTMLDivElement> = view.querySelectorAll(".notification");
            const viewItemsIds: string[] = [];

            for (let i = 0; i < viewItems.length; i++) {
                if (viewItems[i].offsetTop > view.offsetHeight + view.scrollTop) {
                    continue;
                }

                viewItemsIds.push(viewItems[i].id);
            }

            const data = dataQuery.data.pages
                .map((p) => p.Content)
                .flat()
                .filter((p) => viewItemsIds.indexOf(p.id) !== -1 && !p.readByUser);

            if (data.length === 0) {
                readTimeout = setTimeout(read, 3000);
                return;
            }

            for (let i = 0; i < data.length; i++) {
                Api.NotificationApi.Read(data[i].id).then();
                data[i].readByUser = true;
                dispatch(setNotificationsCount("-1"));
            }

            readTimeout = setTimeout(read, 3000);
        };

        clearTimeout(readTimeout);
        readTimeout = setTimeout(read, 3000);
    }, [dispatch, isOpen, dataQuery]);

    useEffect(() => {
        if (isOpen) {
            return;
        }

        dataQuery.remove();
    }, [isOpen, dataQuery]);

    if (!isInitialized) {
        (async () => dispatch(setNotificationsCount(+(await Api.NotificationApi.Count()))))();

        NotificationConnect();
        NotificationSubscribe(async (message: WssMessage) => {
            if (message.messageType !== WssMessageType.NOTIFICATION_COUNT) {
                return;
            }

            await queryClient.invalidateQueries("notifications");
            dispatch(setNotificationsCount(message.body.count));
        });

        setIsInitialized(true);
    }

    const itemsList = useMemo(() => {
        const list = dataQuery?.data?.pages.map((p) => p.Content).flat();

        return list || [];
    }, [dataQuery.data]);

    if (!user.data) {
        return null;
    }
    console.log(itemsList);
    return (
        <div
            className={`sm:fixed sm:top-12 2xl:top-15 mt-3 sm:mt-0 w-full sm:w-125 2xl:w-[570px] sm:max-h-[60vh] h-full bg-white rounded-2lg sm:shadow-calendar ${
                isOpen ? "right-6.5" : "-right-140 2xl:-right-160"
            }`}
            style={{ transition: "all 0.3s ease-in-out" }}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <AchievementsOwnModal
                isOpen={isAchievementModal}
                onClose={() => setIsAchievementModal(false)}
                user={user.data}
            />
            <div className="hidden sm:flex justify-between items-center mb-2.5 pt-6 px-6">
                <h2 className="text-black 2xl:!text-2xl 2xl:!leading-[33px]">Уведомления</h2>
                {notificationsCount > 0 && (
                    <div className="flex items-center cursor-pointer" onClick={onReadAll}>
                        <Icon icon={Icons.TickAll} width={20} height={20} color="fill-blue" />
                        <span className="ml-1.5 p2-accent text-blue 2xl:text-md">Прочитать все</span>
                    </div>
                )}
            </div>
            {notificationsCount > 0 && (
                <div className="sm:hidden fixed bottom-14 left-0 p-2.5 w-full bg-white z-10">
                    <Button className="w-full h-12" variant="outline" color="secondary" onClick={onReadAll}>
                        <Icon icon={Icons.TickAll} width={20} height={20} color="fill-blue" />
                        <span className="ml-1.5 p2-accent text-blue">Прочитать все</span>
                    </Button>
                </div>
            )}
            {!dataQuery.data?.pages && (
                <div className="flex justify-center items-center pb-31 sm:pb-2 sm:h-[calc(100%-64px)]">
                    <Loader />
                </div>
            )}
            {itemsList.length === 0 && !dataQuery.isLoading && (
                <div className="flex justify-center items-center pb-31 sm:pb-2 sm:h-[calc(100%-64px)]">
                    <Empty
                        topElement={
                            <div className="flex items-center justify-center mb-4 2xl:mb-5 w-16.5 2xl:w-20.5 h-16.5 2xl:h-20.5 bg-blue-10 rounded-full">
                                <Icon
                                    icon={Icons.EmojiSad}
                                    width={36}
                                    height={36}
                                    color="fill-primary"
                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                />
                            </div>
                        }
                        title="Ничего нет :("
                        description="Уведомлений еще нет, но обязательно скоро появятся"
                        titleId="emptyPageTitle_mobile"
                        descriptionId="emptyPageDecription_mobile"
                    />
                </div>
            )}
            <InfiniteList
                id="notificationsView"
                className="scrollbar-hidden flex flex-col pb-31 sm:pb-2 sm:h-[calc(100%-64px)] overflow-auto"
                isLoading={false}
                hasMore={!dataQuery?.data?.pages[dataQuery.data.pages.length - 1].Last}
                onLoadMore={() => dataQuery.fetchNextPage()}
            >
                {itemsList.map((item) => (
                    <div
                        key={item.id}
                        id={item.id}
                        className={`notification group relative cursor-pointer hover:bg-background ${
                            !item.readByUser ? "bg-background" : ""
                        }`}
                        style={{ transition: "all 0.3s ease-in-out" }}
                        onClick={() => navigateToItem(item)}
                    >
                        <div className="flex py-4 mx-4.5 sm:mx-6 border-b border-input-stroke group-[:nth-last-child(2)]:border-b-0">
                            <div
                                className={`relative flex justify-center mr-3 2xl:mr-4 ${
                                    item.objectType === ResourceType.ACHIEVEMENT
                                        ? "min-w-16 2xl:min-w-[86px] min-h-16 2xl:min-h-[86px] w-16 2xl:w-[86px] h-16 2xl:h-[86px]"
                                        : "min-w-16 2xl:min-w-[86px] min-h-10 2xl:min-h-[56px] w-16 2xl:w-[86px] h-10 2xl:h-[56px]"
                                }`}
                            >
                                {!item.readByUser && (
                                    <span className="absolute top-[calc(50%-3px)] -left-3 sm:-left-3.75 w-1.5 h-1.5 bg-red rounded-full"></span>
                                )}
                                {item?.data["logoId"] && (
                                    <img
                                        className={
                                            item.objectType === ResourceType.ACHIEVEMENT
                                                ? "w-16 2xl:w-[86px] h-16 2xl:h-[86px] rounded-full"
                                                : "w-16 2xl:w-[86px] h-10 2xl:h-[56px] rounded-2sm 2xl:rounded-lg"
                                        }
                                        src={Api.Upload.GetLogo(item.data["logoId"], LogoSize.THUMB_MICRO)}
                                        alt={item.id}
                                    />
                                )}
                                {((item.objectType === ResourceType.REPORT &&
                                    item.actionType === NotificationActionType.GENERATE_START) ||
                                    item.actionType === NotificationActionType.USER_IMPORT_STARTED) && (
                                    <Loader size={window.screen.width >= 1600 ? 56 : 40} />
                                )}
                                {((item.objectType === ResourceType.REPORT &&
                                    item.actionType === NotificationActionType.GENERATE_FINISH) ||
                                    (item.actionType === NotificationActionType.USER_IMPORT_FINISHED &&
                                        !item.data.errorFileId)) && (
                                    <div className="flex justify-center items-center w-10 2xl:w-[56px] h-10 2xl:h-[56px] border-4 border-blue rounded-full">
                                        <Icon
                                            icon={Icons.Check}
                                            width={24}
                                            height={24}
                                            color="fill-blue"
                                            className="2xl:!w-7.5 2xl:!h-7.5"
                                        />
                                    </div>
                                )}
                                {item.actionType === NotificationActionType.USER_IMPORT_FINISHED &&
                                    item.data.errorFileId &&
                                    !isFileExpired(item.timestamp) && (
                                        <div className="flex justify-center items-center w-10.5 2xl:w-[56px] h-10.5 2xl:h-[56px]">
                                            <Icon
                                                icon={Icons.Warning}
                                                width={42}
                                                height={42}
                                                color="fill-red"
                                                className="2xl:!w-14 2xl:!h-14"
                                            />
                                        </div>
                                    )}
                                {item.actionType === NotificationActionType.USER_IMPORT_FINISHED &&
                                    item.data.errorFileId &&
                                    isFileExpired(item.timestamp) && (
                                        <div className="flex justify-center items-center w-10.5 2xl:w-[56px] h-10.5 2xl:h-[56px]">
                                            <Icon
                                                icon={Icons.Close}
                                                width={24}
                                                height={24}
                                                color="fill-red"
                                                className="2xl:!w-7.5 2xl:!h-7.5"
                                            />
                                        </div>
                                    )}
                                {item.objectType === ResourceType.REPORT &&
                                    item.actionType === NotificationActionType.EXPIRED && (
                                        <div className="flex justify-center items-center w-10 2xl:w-[56px] h-10 2xl:h-[56px] border-4 border-red rounded-full">
                                            <Icon
                                                icon={Icons.Ban}
                                                width={42}
                                                height={42}
                                                color="fill-blue-dark"
                                                className="2xl:!w-14 2xl:!h-14"
                                            />
                                        </div>
                                    )}
                            </div>
                            <div className="flex flex-col sm:flex-row w-full">
                                <p className="p2 text-black sm:grow 2xl:text-md break-anywhere">{getItemTitle(item)}</p>
                                <span className="min-w-30 2xl:min-w-35 mt-0.5 sm:mt-0 sm:ml-3.5 2xl:ml-4 p2 text-gray 2xl:text-md sm:text-right">
                                    {formatLastActivityTime(item.timestamp * 1000)}
                                </span>
                            </div>
                        </div>
                    </div>
                ))}
            </InfiniteList>
        </div>
    );
};
