import React, { RefObject } from "react";
import { BaseInput, BaseInputProps } from "./BaseInput";
import { Except } from "type-fest";

// eslint-disable-next-line no-useless-escape
const regexp = new RegExp(/[^а-яА-ЯёЁa-zA-Z0-9"'\(\)«»!@#№$%&*_ +=?,.\-;:\/]/gi);
interface TextareaProps extends BaseInputProps {
    value?: string | number;
    maxLength?: number;
    cutRegExp?: RegExp;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    inputRef?: RefObject<HTMLTextAreaElement>;
}

export const Textarea = ({
    rows = 2,
    error,
    value = "",
    maxLength = 64,
    onChange,
    cutRegExp = regexp,
    inputRef,
    ...rest
}: Except<TextareaProps, "as">) => {
    const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const re = new RegExp(`[${String.fromCharCode(160)}${String.fromCharCode(10240)}]`, "g");

        const str = String(e.target.value)
            .replace(/(?=\s)[^\r\n\t]/g, " ")
            .replace(re, " ")
            .replaceAll(cutRegExp, "");

        if (maxLength && str.length > maxLength) {
            return value;
        }

        const caretPosition = e.target.selectionStart;
        const removedSymbols = e.target.value.length - str.length;

        e.target.value = str;
        onChange?.(e);

        if (caretPosition) {
            e.target.setSelectionRange(caretPosition - removedSymbols, caretPosition - removedSymbols);
        }
    };

    const onBlur = (event: any) => {
        const newVal = event.target.value
            .toString()
            .trim()
            .replace(/^\s/g, "")
            .replace(/\s{2,}/g, " ");

        if (event.target.value.toString() !== newVal) {
            event.target.value = newVal;
            onInputChange(event);
        }
    };

    return (
        <>
            <BaseInput
                isError={!!error}
                as="textarea"
                rows={rows}
                value={value}
                onChange={onInputChange}
                onBlur={onBlur}
                maxLength={maxLength}
                ref={inputRef}
                {...rest}
            />

            {error && <div className="text-danger">{error}</div>}
        </>
    );
};
