import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { generatePassword } from "helpers/password";
import { ValidateEmail, ValidateLogin, ValidateName, ValidatePassword } from "helpers/Validator";
import { ContentLayout } from "Containers/ContentLayout";
import { TreeWrapperContext } from "Containers/TreeWrapper/TreeWrapperContext";
import { Breadcrumbs, Button, Content, flash, Icon, Icons, Tabs, TabsWrapper, Toggle } from "Uikit";
import { MemberForm } from "./MemberForm";
import { MemberAccesses } from "./MemberAccesses";
import { MemberAchievements } from "./MemberAchievements";
import { UserLoginValidationRequest, UserPhoneValidationRequest, UserRequest } from "Api/Requests/UserRequest";
import { UserListResponse } from "Api/Responses/UserResponse";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import Api from "Api";
import { useQuery } from "react-query";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { MemberCommands } from "./MemberCommands";
import { FileUploadType, RoleName, UIErrorMessages } from "Enums";
import { CancelModal } from "Components/CancelModal/CancelModal";
import { UserState } from "types/User";
import { useDialog } from "hooks/useDialog";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { RoleItemResponse } from "Api/Responses/RoleResponse";
import { Empty } from "Uikit/Page/Empty";
import { useInvalidate } from "hooks/useInvalidate";

const userAvatarBgColors = [
    "#FF0361",
    "#FF4300",
    "#FF6200",
    "#FF8F00",
    "#FFA800",
    "#FFB800",
    "#FFCA00",
    "#C6DB10",
    "#8ACE1D",
    "#1AC65D",
    "#09CF79",
    "#04CEBA",
    "#31C7F6",
    "#16B9FF",
    "#2B91FF",
    "#2273FF",
    "#2B55EF",
    "#5F00FF",
    "#8338EC",
    "#C55DF6",
];

interface IMemberPage {
    isEdit: boolean;
}
export const MemberPage = ({ isEdit }: IMemberPage) => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const { id } = useParams();
    const { setTreeProps } = useContext(TreeWrapperContext);

    const [isChanged, setIsChanged] = useState<boolean>(false);

    const [access, setAccess] = useState<boolean>(true);

    const blocker = useBlocker((params) => {
        if (isChanged && params?.historyAction !== "REPLACE") {
            setIsCancel(true);
        } else if (params?.historyAction === "REPLACE") {
            return false;
        }

        return isChanged;
    });

    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [isEditCancel, setIsEditCancel] = useState<boolean>(false);

    const [state, setState] = useState<boolean>(true);
    const [userName, setUserName] = useState<{ firstName: string; lastName: string } | null>(null);
    const userInitials = useMemo(() => {
        if (!userName) {
            return "";
        }

        return `${userName.firstName.slice(0, 1) + userName.lastName.slice(0, 1)}`;
    }, [userName]);

    const [user, setUser] = useState<UserRequest>(
        Object.assign(new UserRequest(), {
            id: undefined,
            avatarId: "",
            jobTitleId: undefined,
            officeId: undefined,
            role: undefined,
            roleTeams: [],
            roleSections: [],
            teamIds: [],
            mentorTeamIds: [],
            contentManagerTeamIds: [],
            login: "",
            password: generatePassword(8),
            firstName: "",
            lastName: "",
            middleName: "",
            email: "",
            phone: "",
            enabledNotifications: ["Email"],
            enabledCommenting: true,
            defaultAvatarColor: userAvatarBgColors[Math.floor(Math.random() * userAvatarBgColors.length)],
            lastModificationDate: undefined,
            companyId: localStorage.getItem("companyId"),
        }),
    );

    const [avatar, setAvatar] = useState<File | null>(null);
    const [errors, setErrors] = useState<any>({});

    const teams = useQuery(
        ["teams", "tree", "basic-info", "collection"],
        async () => await Api.Teams.TreeAllBasicInfo([]),
        {
            keepPreviousData: false,
            refetchOnWindowFocus: false,
            staleTime: 5 * 60 * 1000,
        },
    );

    const onChangeAvatar = (data: File | null, imgBase64?: string) => {
        setAvatar(data);
        setUser({ ...user, avatarId: imgBase64 });

        setIsChanged(true);
    };

    const onLoginValidate = async () => {
        if (!user.id && user.login.length > 0) {
            const existLogin = await Api.User.UserLoginValidation(new UserLoginValidationRequest(user.login));
            setUser((prev) => {
                const newUser = Object.assign({}, prev);

                if (prev.login !== user.login) {
                    return prev;
                }

                newUser.login = existLogin;
                return newUser;
            });
        }
    };

    const onValidate = async (): Promise<boolean> => {
        const errors: any = {};

        errors["firstName"] = ValidateName(user.firstName, true);
        errors["lastName"] = ValidateName(user.lastName, true);
        errors["middleName"] = ValidateName(user.middleName, false);

        errors["login"] = ValidateLogin(user.login);
        errors["password"] = ValidatePassword(user.password, !user.id);
        errors["phone"] = user.phone?.includes("_") ? "Поле заполнено некорректно" : undefined;

        if (!id && user.login) {
            const existLogin = await Api.User.UserLoginValidation(new UserLoginValidationRequest(user.login));
            errors["login"] = existLogin !== user.login ? "Такой логин уже существует" : undefined;
        }

        if (!errors["phone"] && !!user.phone) {
            const userPhoneValidationRequest: UserPhoneValidationRequest = {
                userID: user.id,
                phone: user.phone.replaceAll(/[\s-]/g, ""),
            };

            const existUser = await Api.User.UserPhoneValidation(userPhoneValidationRequest);

            if (existUser.state === UserState.ACTIVE) {
                errors["phone"] = "Данный номер телефона уже используется";
            }
            if (existUser.state === UserState.BLOCKED) {
                errors["phone"] = (
                    <span>
                        Номер телефона прикреплен к аккаунту
                        <a
                            href="/admin/members?showBlockedUsers=true"
                            target="_blank"
                            className="text-current underline ml-1"
                            rel="noreferrer"
                        >
                            заблокированного пользователя
                        </a>
                    </span>
                );
            }
        }

        if (user.email) {
            errors["email"] = ValidateEmail(user.email);
        }

        errors["teamIds"] = user.teamIds.length !== teams.data?.length ? "Поле обязательно для заполнения" : undefined;
        errors["jobTitleId"] = !user.jobTitleId ? "Поле обязательно для заполнения" : undefined;
        errors["roleId"] = !user.role ? "Поле обязательно для заполнения" : undefined;

        if (user.role === RoleName.ADMIN) {
            errors["roleSections"] = user.roleSections?.length === 0 ? "Поле обязательно для заполнения" : undefined;
        }

        if (Object.keys(errors).filter((p) => errors[p]).length !== 0) {
            setErrors(errors);

            let errMessage = "Ошибка, не все поля формы заполнены правильно";

            if (errors.password) {
                errMessage = errors.password;
            }

            flash.error(errMessage);
            return false;
        }

        setErrors({});
        return true;
    };

    const onSave = async (loginIndex: number) => {
        try {
            const isValide = await onValidate();
            if (!isValide) {
                return;
            }

            // Upload user cover;
            let response: UserListResponse;

            try {
                if (avatar) {
                    const uploadFileResponse = await Api.File.UploadFile(
                        avatar,
                        undefined,
                        undefined,
                        false,
                        FileUploadType.USER_AVATAR,
                    );
                    user.avatarId = uploadFileResponse.id;
                }
            } catch (e: unknown) {
                const knownError = e as BadRequestResponse;
                let message = "Размер обложки слишком большой!";
                if (
                    [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                        String(knownError.errorCode) as ErrorCode,
                    )
                ) {
                    message = UIErrorMessages.FILE_LOADING_ERROR;
                }
                flash.error(message);
                return;
            }

            // Create or update user;
            if (user.id) {
                response = await Api.User.Edit(user);
                flash.success("Все изменения сохранены!");
            } else {
                response = await Api.User.Create({
                    ...user,
                    login: loginIndex ? user.login + "_" + loginIndex : user.login,
                });
                flash.success("Все изменения сохранены!");
            }

            // Update user role;
            await Api.Role.Set({
                userId: response.id,
                roleName: user.role as RoleName,
                programs: user.roleSections.includes("programs"),
                courses: user.roleSections.includes("courses"),
                tests: user.roleSections.includes("tests"),
                exercise: user.roleSections.includes("exercise"),
                materials: user.roleSections.includes("materials"),
                statistic: user.roleSections.includes("statistic"),
                userControl: user.roleSections.includes("userControl"),
                visibleTeams: !user.allTeams ? user.roleTeams : [],
                allTeams: user.allTeams ?? undefined,
            });

            setUser({
                ...Object.assign(new UserRequest(), { ...user, lastModificationDate: response?.lastModificationDate }),
                password: "",
            });
            setUserName({ firstName: user.firstName, lastName: user.lastName });
            setIsChanged(false);
            invalidate(["teams", "tree", "basic-info"]);

            navigate(`/admin/member/${response.id}`, { replace: true });
        } catch (e) {
            let errorMessage = "Неизвестная ошибка";

            if (e instanceof BadRequestResponse) {
                const { errorCode /* , message, errorParams */ } = e;

                if (
                    [
                        ErrorCode.ACTIVE_USER_ALREADY_EXISTS,
                        ErrorCode.ACTIVE_USER_OTHER_COMPANY_ALREADY_EXISTS,
                        ErrorCode.BLOCKED_USER_OTHER_COMPANY_ALREADY_EXISTS,
                    ].includes(errorCode)
                ) {
                    setErrors((errors: any) => ({
                        ...errors,
                        email: "Данный адрес электронной почты уже используется",
                    }));
                }
                if (errorCode === ErrorCode.BLOCKED_USER_ALREADY_EXISTS) {
                    setErrors((errors: any) => ({
                        ...errors,
                        email: (
                            <>
                                Почта прикреплена к аккаунту{" "}
                                <a className="text-white font-semibold" href="/admin/members">
                                    заблокированного пользователя
                                </a>
                            </>
                        ),
                    }));
                }

                errorMessage = "Не все поля формы заполнены правильно";
            }

            flash.error(errorMessage);
        }
    };

    const fetchUser = useCallback(async () => {
        if (!id) {
            return;
        }

        try {
            const user = await Api.User.Read(id);
            const role: RoleItemResponse = await Api.Role.Id(user.id);

            setUser(
                Object.assign(new UserRequest(), {
                    id: user.id,
                    avatarId: user.avatarId,
                    jobTitleId: user.jobTitle!.id!,
                    officeId: user.officeId,
                    role: user.role,
                    roleSections: Object.keys(role).filter(
                        (p) => p !== "roleName" && p !== "visibleTeams" && role[p as keyof RoleItemResponse] === true,
                    ),
                    roleTeams: role.visibleTeams.map((innerRole) => innerRole.id),
                    allTeams: role.allTeams,
                    teamIds: user.teams.map((p) => p.id),
                    mentorTeamIds: user.mentorTeamIds!,
                    contentManagerTeamIds: user.contentManagerTeamIds!,
                    login: user.login,
                    password: "",
                    firstName: user.firstName,
                    lastName: user.lastName,
                    middleName: user.middleName,
                    email: user.email,
                    phone: user.phone,
                    enabledNotifications: user.enabledNotifications!,
                    enabledCommenting: true,
                    defaultAvatarColor: user.defaultAvatarColor,
                    lastModificationDate: user.lastModificationDate,
                    companyId: user.companyId,
                }),
            );

            setUserName({ firstName: user.firstName, lastName: user.lastName });
            setState(user.state === "ACTIVE");
            setAccess(user.hasEditPermission);
        } catch (e) {
            if (e instanceof BadRequestResponse) {
                if (e.errorCode === ErrorCode.NO_USER_ACCESS) {
                    setAccess(false);
                } else {
                    flash.error("Неизвестная ошибка");
                }
            }
        }
    }, [id]);

    const onCancelChange = () => {
        user.id ? setIsEditCancel(true) : navigate("/admin/members");
    };
    const onCancelModalSubmit = useCallback(() => {
        setIsChanged(false);

        if (isCancel && blocker.state === "blocked") {
            blocker.proceed();
        } else {
            setIsEditCancel(false);
            fetchUser().then();
            navigate(`/admin/member/${id}`, { replace: true });
        }

        setIsCancel(false);
    }, [blocker, isCancel, fetchUser, navigate, id]);

    const onMemberStatusChange = (state: any) => {
        setIsChanged(false);

        openDialog({
            title: `Изменить статус на «${state ? "Активен" : "Заблокирован"}»`,
            content: `"Вы действительно хотите изменить статус пользователя ${
                userName?.firstName + " " + userName?.lastName
            } на «Заблокирован»? Система станет недоступна для пользователя`,
            closeBtnText: "Отмена",
            submitBtnText: "Изменить",
            onRequestClose: () => {
                setIsChanged(true);
                closeDialog();
            },
            onRequestSubmit: () => {
                Api.User.Block(user.id!).then(() => {
                    setState(false);

                    closeDialog();
                    navigate("/admin/members");
                });
            },
        });
    };

    useEffect(() => {
        fetchUser().then(() => setErrors({}));
    }, [fetchUser]);
    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps(undefined);
    }, [setTreeProps]);
    useEffect(() => {
        if (isEdit) {
            setIsChanged(true);
        }
    }, [isEdit]);

    if (teams.isFetching) {
        return null;
    }

    return (
        <ContentLayout className="h-full">
            <Breadcrumbs className="mb-2.5" id="adminNewMemberBreadcrumbs">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Участники" url="/admin/members" />
                {!user.id && <Breadcrumbs.Link title="Новый участник" />}
                {!!user.id && <Breadcrumbs.Link title={userName?.firstName + " " + userName?.lastName} />}
            </Breadcrumbs>
            {!access && (
                <div className="h-full flex justify-center">
                    <Empty
                        className="!mt-0"
                        title="Нет доступа"
                        description="Вы не можете просматривать и редактировать профиль этого пользователя"
                        topElement={
                            <div className="flex-center mb-4">
                                <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                    <Icon icon={Icons.EmojiSad} width={"36px"} height={"36px"} color={"fill-primary"} />
                                </div>
                            </div>
                        }
                    >
                        <Button onClick={() => navigate("/admin/members")} id="noUserAccessBtnGoToUserTable">
                            Вернуться в раздел
                        </Button>
                    </Empty>
                </div>
            )}
            {access && (
                <>
                    <Confirmation {...dialogState} />
                    <CancelModal
                        id="memberPageCancelCreationModal"
                        isEdit={isEditCancel}
                        isOpen={isCancel || isEditCancel}
                        setIsOpen={isEditCancel ? setIsEditCancel : setIsCancel}
                        onSubmit={onCancelModalSubmit}
                    />
                    <div className="flex justify-between items-center mb-7.5">
                        <h1 className="text-[#262626]-600" id="adminNewMemberTitle">
                            {userName ? userName.firstName + " " + userName.lastName : "Новый участник"}
                        </h1>
                        <div className="flex items-center">
                            {user.id && (
                                <Toggle
                                    className="mr-7.5 font-semibold"
                                    label={state ? "Активен" : "Заблокирован"}
                                    enabled={state}
                                    onChange={onMemberStatusChange}
                                    id="adminNewMemberToggleIsActive"
                                />
                            )}
                            {(isChanged || isEdit || !user.id) && (
                                <Button
                                    className="mr-4"
                                    variant="outline"
                                    color="secondary"
                                    onClick={onCancelChange}
                                    id="adminNewMemberBtnCancel"
                                >
                                    Отменить
                                </Button>
                            )}
                            {(isChanged || isEdit || !user.id) && (
                                <Button className="mr-4" onClick={() => onSave(0)} id="adminNewMemberBtnOk">
                                    Сохранить
                                </Button>
                            )}
                            {!isChanged && !isEdit && user.id && (
                                <Button
                                    className="w-10 h-10 !p-0"
                                    variant="outline"
                                    color="secondary"
                                    onClick={() => navigate("/admin/members")}
                                    id="adminNewMemberBtnCrest"
                                >
                                    <Icon icon={Icons.Close} width={24} height={24} color="fill-[#939393]" />
                                </Button>
                            )}
                        </div>
                    </div>
                    <TabsWrapper>
                        <TabsWrapper.Tabs
                            classname="flex flex-grow justify-between items-center h-max"
                            description={
                                user.getLastModificationDate()
                                    ? `Обновлён ${formatDate(
                                          user.lastModificationDate! * 1000,
                                          DateFormat.DATE_TIME_LONG,
                                      )}`
                                    : ""
                            }
                            id="adminNewMemberTabs"
                        >
                            <Tabs.Tab
                                title="Основное"
                                error={Object.keys(errors).filter((p) => p !== "teamIds" && errors[p]).length !== 0}
                            />
                            <Tabs.Tab title="Команды" error={errors["teamIds"]} />
                            {/*<Tabs.Tab
                                title="Доступы"
                                disabled={isChanged || isEdit || !user.id}
                                tooltip={isChanged || isEdit || !user.id ? "Будет доступно после сохранения" : ""}
                            />*/}
                            {/*<Tabs.Tab
                                title="Ачивки"
                                disabled={isChanged || isEdit || !user.id}
                                tooltip={isChanged || isEdit || !user.id ? "Будет доступно после сохранения" : ""}
                            />*/}
                        </TabsWrapper.Tabs>
                        <TabsWrapper.Content>
                            <Content.Body className="h-fit">
                                <MemberForm
                                    errors={errors}
                                    user={user}
                                    userInitials={userInitials}
                                    onChangeAvatar={onChangeAvatar}
                                    onLoginValidate={onLoginValidate}
                                    onChange={(p) => {
                                        if (user.login !== p.login) {
                                            setErrors((prevErrors: any) => ({ ...prevErrors, login: undefined }));
                                        }
                                        setUser(p);
                                        setIsChanged(true);
                                    }}
                                />
                            </Content.Body>
                            <Content.Body className="h-fit">
                                <MemberCommands
                                    errors={errors}
                                    user={user}
                                    teams={teams.data}
                                    onChange={(p) => {
                                        setUser(p);
                                        setIsChanged(true);
                                    }}
                                />
                            </Content.Body>
                            <Content.Body className="h-fit">
                                {user.id && <MemberAccesses userId={user.id} />}
                            </Content.Body>
                            <Content.Body className="h-fit">
                                <MemberAchievements />
                            </Content.Body>
                        </TabsWrapper.Content>
                    </TabsWrapper>
                </>
            )}
        </ContentLayout>
    );
};
