import React, { useState, useContext } from "react";
import { FormGroup, RadioGroup, Checkbox, Icon, Icons } from "Uikit";
import { Section } from "Uikit/Forms/Section";
import {
    TaskPublicVerificatedReadResponse,
    TTaskPublicAttemptsTextContent,
    TTaskPublicAttemptsNumberContent,
    TTaskPublicAttemptsChoiceContent,
    TTaskPublicAttemptsFileContent,
    TTaskPublicAttemptsImageContent,
} from "Api/Responses/TaskPublicResponse";
import { SectionsWrapper } from "Uikit/Forms/SectionsWrapper";
import { FileViewDialog } from "Uikit/FileViewDialog/FileViewDialog";
import { TaskItemType } from "Enums";
import { getObjectUniqueId } from "helpers/objectUniqueId";
import { humanFileSize } from "helpers/humanFileSize";
import { FileReadResponse } from "Api/Responses/FileResponse";
import { FILE_INPUT_ACCEPTED_TYPES, FILES_TYPE_ICONS, FILES_TYPE_SHORT_NAME } from "constants/attachments";
import { GlobalContext } from "App";
import clsx from "clsx";

interface TaskPageFormProps {
    task: TaskPublicVerificatedReadResponse;
}

interface ITaskPageFormCommentProps {
    title?: string;
    comment?: string;
}

// break-words
const TaskPageFormComment = ({ title = "Комментарий автора", comment }: ITaskPageFormCommentProps) => {
    if (!comment) return null;

    return (
        <div className="bg-gray-blue rounded-lg px-4 py-2">
            <span className="text-sm text-gray-text">{title}</span>
            <p className="break-words">{comment}</p>
        </div>
    );
};

export const TaskPageForm = ({ task }: TaskPageFormProps) => {
    const [isViewFileModal, setIsViewFileModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<any>(null);
    const { setIsFullScreenViewEnabled } = useContext(GlobalContext);
    const hostname = window.location.hostname.includes("localhost")
        ? "http://localhost:3000"
        : window.location.protocol + "//" + window.location.hostname;
    const taskitems = [
        ...(task.textContent ?? []),
        ...(task.numericContent ?? []),
        ...(task.imageContent ?? []),
        ...(task.fileContent ?? []),
        ...(task.singleChoiceContent ?? []),
        ...(task.multipleChoiceContent ?? []),
    ];

    return (
        <>
            <SectionsWrapper
                firstColumnTitle="Вопросы"
                secondColumnTitle="Ответы пользователя"
                columnProportion="30/50"
                className="mt-8 mb-24 !h-auto grow"
            >
                {taskitems
                    .sort((a, b) => a?.orderNumber - b?.orderNumber)
                    .map((taskItem, index) => {
                        return (
                            <Section
                                key={index}
                                label={taskItem.question}
                                description={taskItem.description}
                                className={clsx(index === taskitems.length - 1 ? "border-b-0 pb-0" : "", "last:!mb-0")}
                                columnProportion="40/50"
                                leftColumnClassName="break-words"
                                rightColumnClassName="w-full"
                                id={"AdminValiadtionTaskQuestion" + index}
                            >
                                {/* Текстовый вопрос */}
                                {taskItem.contentType === TaskItemType.Text && (
                                    <div>
                                        <div className="bg-[#F5F7F9] rounded-lg mb-4 px-4 py-2">
                                            <span className="text-sm text-[#939393]">Ответ пользователя</span>
                                            <p>{(taskItem as TTaskPublicAttemptsTextContent).userText}</p>
                                        </div>
                                        <TaskPageFormComment comment={taskItem.reviewerNote} />
                                    </div>
                                )}
                                {/* Числовой вопрос */}
                                {taskItem.contentType === TaskItemType.Number && (
                                    <div>
                                        <div className="bg-[#F5F7F9] rounded-lg mb-4 px-4 py-2">
                                            <span className="text-sm text-[#939393]">Ответ пользователя</span>
                                            <p>{(taskItem as TTaskPublicAttemptsNumberContent).userNumber}</p>
                                        </div>
                                        <TaskPageFormComment comment={taskItem.reviewerNote} />
                                    </div>
                                )}
                                {/* Вопрос типа "Один из списка" */}
                                {taskItem.contentType === TaskItemType.SingleChoice && (
                                    <FormGroup className="!mb-0">
                                        <RadioGroup
                                            value={
                                                (taskItem as TTaskPublicAttemptsChoiceContent).answers.find(
                                                    ({ isUserChecked }) => isUserChecked,
                                                )?.id
                                            }
                                            className="mb-4"
                                        >
                                            {(taskItem as TTaskPublicAttemptsChoiceContent).answers
                                                .sort((a, b) => a.orderNumber - b.orderNumber)
                                                .map((variant) => {
                                                    return (
                                                        <RadioGroup.Button
                                                            key={`TaskItemChoiceItem_${getObjectUniqueId(variant)}`}
                                                            label={variant.answer}
                                                            value={variant.id}
                                                            className="pointer-events-none"
                                                        />
                                                    );
                                                })}
                                        </RadioGroup>
                                        <TaskPageFormComment comment={taskItem.reviewerNote} />
                                    </FormGroup>
                                )}
                                {/* Вопрос типа "Несколько из списка" */}
                                {taskItem.contentType === TaskItemType.MultipleChoice && (
                                    <FormGroup
                                        value={(taskItem as TTaskPublicAttemptsChoiceContent).answers
                                            .filter(({ isUserChecked }) => isUserChecked)
                                            .map(({ id }) => id)}
                                        className="flex flex-col gap-4 !bg-transparent !mb-0"
                                    >
                                        {(taskItem as TTaskPublicAttemptsChoiceContent).answers
                                            .sort((a, b) => a.orderNumber - b.orderNumber)
                                            .map((variant) => {
                                                return (
                                                    <Checkbox
                                                        key={`TaskItemChoiceItem_${getObjectUniqueId(variant)}`}
                                                        checked={(taskItem as TTaskPublicAttemptsChoiceContent).answers
                                                            .filter(({ isUserChecked }) => isUserChecked)
                                                            .map(({ id }) => id)
                                                            ?.includes(variant.id)}
                                                        variant="large"
                                                        label={variant.answer}
                                                        value={variant.id}
                                                    />
                                                );
                                            })}
                                        <TaskPageFormComment comment={taskItem.reviewerNote} />
                                    </FormGroup>
                                )}
                                {/* Вопрос типа "Загрузка изображения" */}
                                {taskItem.contentType === TaskItemType.Image && (
                                    <>
                                        {(taskItem as TTaskPublicAttemptsImageContent).images?.length > 0 && (
                                            <div className="grid grid-cols-[repeat(3,180px)] gap-4 mb-4">
                                                {(taskItem as TTaskPublicAttemptsImageContent).images?.map(
                                                    ({ id, name: fileName, type, size }) => {
                                                        return (
                                                            <div
                                                                key={id}
                                                                className="w-45 h-45 relative group cursor-pointer"
                                                                onClick={() => {
                                                                    setSelectedFile({
                                                                        type,
                                                                        id,
                                                                        size,
                                                                        name: fileName,
                                                                        extension: FILE_INPUT_ACCEPTED_TYPES[type],
                                                                        disposableLink: `/service/lms-upload/api/file/download/${id}`,
                                                                    });
                                                                    setIsViewFileModal(true);
                                                                    setIsFullScreenViewEnabled(true);
                                                                }}
                                                            >
                                                                <div className="absolute bottom-3 right-3 px-2 py-0.5 bg-black-50 rounded-md text-white">
                                                                    <p className="p3">
                                                                        {type?.replace("image/", "")} ·{" "}
                                                                        {humanFileSize(size, true)}
                                                                    </p>
                                                                </div>
                                                                <img
                                                                    className={"w-full h-full rounded-2lg object-cover"}
                                                                    src={`/service/lms-upload/api/file/download/${id}`}
                                                                    alt={fileName}
                                                                />
                                                            </div>
                                                        );
                                                    },
                                                )}
                                            </div>
                                        )}
                                        <TaskPageFormComment comment={taskItem.reviewerNote} />
                                    </>
                                )}
                                {/* Вопрос типа "Загрузка файлов" */}
                                {taskItem.contentType === TaskItemType.File && (
                                    <>
                                        {(taskItem as TTaskPublicAttemptsFileContent).files?.length > 0 && (
                                            <div className="flex flex-col gap-4 mb-4">
                                                {(taskItem as TTaskPublicAttemptsFileContent).files?.map(
                                                    ({ id, name: fileName, size, type: fileType }) => {
                                                        const type = fileType.replace(/(;(.)*)/g, ""); // удаление ';charset=' в случае 'text/plain;charset=ISO-8859-1', например
                                                        return (
                                                            <div
                                                                key={id}
                                                                className="flex p-4 border border-gray-blue bg-white rounded-md group cursor-pointer"
                                                                onClick={() => {
                                                                    setSelectedFile({
                                                                        type,
                                                                        id,
                                                                        size,
                                                                        name: fileName,
                                                                        extension: FILE_INPUT_ACCEPTED_TYPES[type],
                                                                        disposableLink: `/service/lms-upload/api/file/download/${id}`,
                                                                    });
                                                                    setIsViewFileModal(true);
                                                                    setIsFullScreenViewEnabled(true);
                                                                }}
                                                            >
                                                                <div className="relative flex items-center w-full">
                                                                    <Icon
                                                                        className="mr-5"
                                                                        icon={
                                                                            FILES_TYPE_ICONS[
                                                                                FILE_INPUT_ACCEPTED_TYPES[type]
                                                                            ] || Icons.FileOther
                                                                        }
                                                                        width="32px"
                                                                        height="32px"
                                                                    />
                                                                    <div>
                                                                        <div className="flex items-center pb-2 group-hover:text-blue-light">
                                                                            {fileName}
                                                                        </div>
                                                                        <div className="text-gray-text">
                                                                            {`${
                                                                                FILES_TYPE_SHORT_NAME[
                                                                                    FILE_INPUT_ACCEPTED_TYPES[type]
                                                                                ]
                                                                            } — ${humanFileSize(size, true)}`}
                                                                        </div>
                                                                    </div>
                                                                    <div className="absolute top-0 right-0 flex items-center gap-5">
                                                                        <a
                                                                            href={`${hostname}/service/lms-upload/api/file/download/${id}`}
                                                                            download={fileName}
                                                                            onClick={(e) => {
                                                                                e.stopPropagation();
                                                                            }}
                                                                        >
                                                                            <Icon
                                                                                icon={Icons.Download}
                                                                                width="18px"
                                                                                height="17px"
                                                                                color="fill-blue-drk"
                                                                            />
                                                                        </a>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    },
                                                )}
                                            </div>
                                        )}
                                        <TaskPageFormComment comment={taskItem.reviewerNote} />
                                    </>
                                )}
                            </Section>
                        );
                    })}
            </SectionsWrapper>
            {isViewFileModal && (
                <FileViewDialog
                    file={selectedFile as FileReadResponse & { type: string; data: string }}
                    handleClose={() => {
                        setIsViewFileModal(false);
                        setIsFullScreenViewEnabled(false);
                    }}
                />
            )}
        </>
    );
};
