import React, { useEffect } from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "../../../slices/headerSlice";
import { Loader } from "Uikit/Loader/Loader";
import { Icon, Icons } from "Uikit/Icon/Icon";
import Api from "Api/index";
import { useParams } from "react-router-dom";

export const PersonalStatistics = () => {
    const dispatch = useDispatch();
    const { id } = useParams();

    const { data: user } = useQuery(["users", "personal", id], () =>
        id ? Api.User.Read(id) : Api.User.GetCurrentUser(),
    );
    const { data: statistics } = useQuery(
        ["profile", "stats", user?.id],
        () => Api.Statistic.GetUserProgressActivity(user?.id ?? ""),
        {
            enabled: !!user,
        },
    );

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Статистика"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);

    if (!user) {
        return (
            <div className="flex mt-10 justify-center">
                <Loader />
            </div>
        );
    }

    return (
        <div className="flex flex-col space-y-4 pt-3 px-3 w-full">
            {statistics?.lastActivityStatistics && (
                <div className="flex flex-col py-5 px-4 bg-blue rounded-2xl">
                    <h3 className="mb-6 text-white">Активность за 30 дней</h3>
                    <div className="flex">
                        <div className="flex flex-col w-[50%]">
                            <span className="mb-0.5 p4 text-white">Набрано баллов</span>
                            <div className="flex items-center">
                                <Icon icon={Icons.Trophy} width={18} height={18} color="fill-white" />
                                <span className="ml-1 p1-accent text-white">
                                    {statistics.lastActivityStatistics.rating}
                                </span>
                            </div>
                        </div>
                        <div className="flex flex-col w-[50%]">
                            <span className="mb-0.5 p4 text-white">Времени в обучении</span>
                            <div className="flex items-center">
                                <Icon icon={Icons.Time} width={20} height={20} color="fill-white" />
                                <span className="ml-1 p1-accent text-white">12ч</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {statistics?.trainingProgramStatistics && (
                <div className="flex items-center justify-between mb-4 py-5 px-4 bg-background rounded-2xl">
                    <div className="flex flex-col mr-2 w-full">
                        <span className="mb-3 p1-accent text-black">Программы обучения</span>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex flex-col">
                                <span className="mb-0.5 p4 text-gray">Пройдено</span>
                                <div className="flex items-center">
                                    <span className="mr-2 w-1.5 h-1.5 bg-primary rounded-full"></span>
                                    <h4 className="text-black">{statistics?.trainingProgramStatistics.passedCount}</h4>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="mb-0.5 p4 text-gray">В процессе</span>
                                <div className="flex items-center">
                                    <span className="mr-2 w-1.5 h-1.5 bg-gray-light rounded-full"></span>
                                    <h4 className="text-black">
                                        {statistics?.trainingProgramStatistics.inProgressCount}
                                    </h4>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="mb-0.5 p4 text-gray">Доступно</span>
                                <div className="flex items-center">
                                    <span className="mr-2 w-1.5 h-1.5 bg-input-stroke rounded-full"></span>
                                    <h4 className="text-black">
                                        {statistics?.trainingProgramStatistics.availableCount}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="min-w-16 min-h-16">
                        <svg viewBox="0 0 36 36">
                            <path
                                className="that-circle"
                                stroke="#E6E9ED"
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                                className="that-circle"
                                stroke="#C1C6CA"
                                strokeDasharray={`${
                                    ((statistics.trainingProgramStatistics.inProgressCount +
                                        statistics.trainingProgramStatistics.passedCount) *
                                        100) /
                                    statistics?.trainingProgramStatistics.totalCount
                                } 100`}
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                                className="that-circle"
                                stroke="#0084E2"
                                strokeDasharray={`${
                                    (statistics.trainingProgramStatistics.passedCount * 100) /
                                    statistics?.trainingProgramStatistics.totalCount
                                } 100`}
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text className="percentage" x="9" y="22">
                                {Math.round(
                                    (statistics?.trainingProgramStatistics.passedCount * 100) /
                                        statistics?.trainingProgramStatistics.totalCount,
                                )}
                                %
                            </text>
                        </svg>
                    </div>
                </div>
            )}
            {statistics?.courseStatistics && (
                <div className="flex items-center justify-between mb-4 py-5 px-4 bg-background rounded-2xl">
                    <div className="flex flex-col mr-2 w-full">
                        <span className="mb-3 p1-accent text-black">Курсы</span>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex flex-col">
                                <span className="mb-0.5 p4 text-gray">Пройдено</span>
                                <div className="flex items-center">
                                    <span className="mr-2 w-1.5 h-1.5 bg-primary rounded-full"></span>
                                    <h4 className="text-black">{statistics?.courseStatistics.passedCount}</h4>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="mb-0.5 p4 text-gray">В процессе</span>
                                <div className="flex items-center">
                                    <span className="mr-2 w-1.5 h-1.5 bg-gray-light rounded-full"></span>
                                    <h4 className="text-black">{statistics?.courseStatistics.inProgressCount}</h4>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="mb-0.5 p4 text-gray">Доступно</span>
                                <div className="flex items-center">
                                    <span className="mr-2 w-1.5 h-1.5 bg-input-stroke rounded-full"></span>
                                    <h4 className="text-black">{statistics?.courseStatistics.availableCount}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="min-w-16 min-h-16">
                        <svg viewBox="0 0 36 36">
                            <path
                                className="that-circle"
                                stroke="#E6E9ED"
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                                className="that-circle"
                                stroke="#C1C6CA"
                                strokeDasharray={`${
                                    ((statistics.courseStatistics.inProgressCount +
                                        statistics.courseStatistics.passedCount) *
                                        100) /
                                    statistics?.courseStatistics.totalCount
                                } 100`}
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                                className="that-circle"
                                stroke="#0084E2"
                                strokeDasharray={`${
                                    (statistics.courseStatistics.passedCount * 100) /
                                    statistics?.courseStatistics.totalCount
                                } 100`}
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text className="percentage" x="9" y="22">
                                {Math.round(
                                    (statistics?.courseStatistics.passedCount * 100) /
                                        statistics?.courseStatistics.totalCount,
                                )}
                                %
                            </text>
                        </svg>
                    </div>
                </div>
            )}
            {statistics?.quizStatistics && (
                <div className="flex items-center justify-between mb-4 py-5 px-4 bg-background rounded-2xl">
                    <div className="flex flex-col mr-2 w-full">
                        <span className="mb-3 p1-accent text-black">Тесты</span>
                        <div className="flex items-center justify-between w-full">
                            <div className="flex flex-col">
                                <span className="mb-0.5 p4 text-gray">Пройдено</span>
                                <div className="flex items-center">
                                    <span className="mr-2 w-1.5 h-1.5 bg-primary rounded-full"></span>
                                    <h4 className="text-black">{statistics?.quizStatistics.passedCount}</h4>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="mb-0.5 p4 text-gray">Провалено</span>
                                <div className="flex items-center">
                                    <span className="mr-2 w-1.5 h-1.5 bg-red rounded-full"></span>
                                    <h4 className="text-black">{statistics?.quizStatistics.failedCount}</h4>
                                </div>
                            </div>
                            <div className="flex flex-col">
                                <span className="mb-0.5 p4 text-gray">Доступно</span>
                                <div className="flex items-center">
                                    <span className="mr-2 w-1.5 h-1.5 bg-input-stroke rounded-full"></span>
                                    <h4 className="text-black">{statistics?.quizStatistics.availableCount}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="min-w-16 min-h-16">
                        <svg viewBox="0 0 36 36">
                            <path
                                className="that-circle"
                                stroke="#E6E9ED"
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                                className="that-circle"
                                stroke="#FF5963"
                                strokeDasharray={`${
                                    ((statistics.quizStatistics.failedCount + statistics.quizStatistics.passedCount) *
                                        100) /
                                    statistics?.quizStatistics.totalCount
                                } 100`}
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <path
                                className="that-circle"
                                stroke="#0084E2"
                                strokeDasharray={`${
                                    (statistics.quizStatistics.passedCount * 100) /
                                    statistics?.quizStatistics.totalCount
                                } 100`}
                                d="M18 2.0845 a 15.9155 15.9155 0 0 1 0 31.831 a 15.9155 15.9155 0 0 1 0 -31.831"
                            />
                            <text className="percentage" x="9" y="22">
                                {Math.round(
                                    (statistics?.quizStatistics.passedCount * 100) /
                                        statistics?.quizStatistics.totalCount,
                                )}
                                %
                            </text>
                        </svg>
                    </div>
                </div>
            )}
        </div>
    );
};
