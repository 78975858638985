import React, { useContext, useEffect, useMemo, useState, useCallback } from "react";
import { PaginationState, SortingState } from "@tanstack/react-table";
import { useNavigate, useParams } from "react-router-dom";
import { Breadcrumbs } from "Uikit";
import { PromptModal } from "Uikit/Modal/PromptModal";
import { ContentLayout, TreeWrapperContext } from "Containers";
import { CoursesEmpty } from "./CoursesEmpty";
import { CoursesTable } from "./CoursesTable";
import { ICoursesCategoryModalForm, CoursesCategoryModal } from "./CoursesCategoryModal";
import { CourseCategoryGetResponse, CourseCategoryResponse } from "Api/Responses/CourseResponse";
import Api from "Api";
import { useInvalidate } from "hooks/useInvalidate";
import { useQuery } from "react-query";
import { FormikHelpers } from "formik";
import { ResourceState, ResourceType } from "Enums";
import { ErrorCode } from "Api/BaseResponse";

export const Courses = () => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();

    const { id } = useParams();
    const { setTreeProps, sidebarApi } = useContext(TreeWrapperContext);

    const [isFetched, setIsFetched] = useState<boolean>(false);

    const [category, setCategory] = useState<CourseCategoryResponse | undefined>(undefined);
    const [categories, setCategories] = useState<CourseCategoryGetResponse[]>([]);

    const [editingCategory, setEditingCategory] = useState<Partial<CourseCategoryResponse> | undefined>(undefined);
    const [deletingCategory, setDeletingCategory] = useState<Partial<CourseCategoryResponse> | undefined>(undefined);

    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [sorting, setSorting] = useState<SortingState>([{ id: "modifyTime", desc: true }]);

    const [filters, setFilters] = useState<{ [id: string]: any }>({});

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["courses", "collection", pagination, sorting, filters, id, selectedCategories],
        async () => {
            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "modifyTimestamp") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                } else if (element === "ratingPoints" || element === "averageReviewRating") {
                    if (filters[element]["minValue"]) {
                        filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                    }
                    if (filters[element]["maxValue"]) {
                        filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                    }
                } else if (element === "assignedToTeam.in") {
                    filtersData[element] = filters[element].join(",");
                } else if (element === "managerUserId.in" || element === "assignedToUser.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (element === "deadlineTimestamp.isNull") {
                    filtersData[element] = "false";
                } else if (element === "deadlineTimestamp.lessThanOrEqual") {
                    filtersData[element] = Math.round(new Date().getTime() / 1000);
                } else if (element === "state.equal") {
                    filtersData[element] = "HIDDEN";
                } else if (element === "assigned") {
                    filtersData["assignedToTeam.isNull"] = true;
                    filtersData["assignedToUser.isNull"] = true;
                } else {
                    filtersData[element] = filters[element];
                }
            }

            filtersData["state.notEqual"] = ResourceState.ARCHIVED;
            if (!filtersData["assignedToTeam.in"]?.length) {
                delete filtersData["assignedToTeam.in"];
            }

            if (id) {
                filtersData["categoryId.equal"] = id;
            }

            if (selectedCategories.length !== 0) {
                filtersData["categoryId.in"] = selectedCategories.join(",");
            }

            return await fetchCourses(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const onChangeCategory = async (
        data: ICoursesCategoryModalForm,
        formikHelpers: FormikHelpers<ICoursesCategoryModalForm>,
    ) => {
        try {
            let categoryResponse;

            if (!editingCategory?.id) {
                categoryResponse = await Api.Course.CategoryCreate({ title: data.title });
            } else {
                categoryResponse = await Api.Course.CategoryEdit({ id: editingCategory.id, title: data.title });
            }

            const categories = await Api.Course.CategoryGet();
            setCategories(categories.Content);

            navigate("/admin/courses/" + categoryResponse.id);
            setEditingCategory(undefined);
        } catch (error: any) {
            if (error?.errorCode === ErrorCode.TITLE_ALREADY_EXISTS) {
                formikHelpers.setFieldError("title", "Данное название уже используется");
            }
            console.log(error);
        }
    };
    const onDeleteCategory = async () => {
        if (!deletingCategory) {
            return;
        }

        await Api.Course.CategoryDelete(deletingCategory.id!);
        setDeletingCategory(undefined);

        const categories = await Api.Course.CategoryGet();
        setCategories(categories.Content);
        invalidate("courses");

        navigate("/admin/courses");
    };
    const onCreateCategoryHandler = () => {
        setEditingCategory({});
    };

    const fetchCourses = async (page: number, size: number, sort: SortingState, filter: { [id: string]: string }) => {
        const filters = Object.keys(filter).reduce((acc: any, key) => {
            if (filter[key]) {
                acc[key] = filter[key];
            }

            return acc;
        }, {});

        return await Api.Course.List(page, size, sort, filters);
    };

    const onCopy = useCallback(
        async (id: string) => {
            navigate(`/admin/course/${id}/copy`);
        },
        [navigate],
    );

    const onState = useCallback(async (ids: string[], state: string) => {
        await Api.Course.State(ids, state, ResourceType.COURSE);
        invalidate("courses");
        dataQuery.refetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onOrderChange = async (nodes: any) => {
        try {
            await Api.Course.CategoryReorder({ categoryIds: nodes.map((item: any) => item.id) });

            const categories = await Api.Course.CategoryGet();
            setCategories(categories.Content);

            navigate("/admin/courses");
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        const treeProps = {
            nodeId: id,
            data: categories.map(({ id, title }) => {
                return {
                    id: id,
                    name: title,
                    nodeType: "PROJECT",
                    children: [],
                };
            }),
            searchable: true,
            editable: true,
            selectable: true,
            browseAll: {
                link: "/admin/courses",
                title: "Все курсы",
                onAddClick: () => navigate("/admin/course"),
            },
            onNodeAddClick: (event: any, nodeId: string) => {
                event.stopPropagation();
                navigate(`/admin/course?categoryId=${nodeId}`);
            },
            onEditClick: (title: string, id: string) => setEditingCategory({ id, title }),
            onDeleteClick: (title: string, id: string) => setDeletingCategory({ id, title }),
            onSelectNode: (node: any) => navigate(`/admin/courses/${node.id}`),
            getNodeLink: (id: any) => `/admin/courses/${id}`,
            onOrderChange: onOrderChange,
            onCheckedChange: (nodesIds: any) => setSelectedCategories(nodesIds),
            footer: {
                title: "Создать категорию",
                action: () => setEditingCategory({}),
            },
            id: "adminCoursesSideBar",
        };

        setTreeProps({
            ...treeProps,
            ...{
                selectedNode: category && id ? category : undefined,
            },
        });
        setIsFetched(true);

        return () => {
            setTreeProps?.({ data: null });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, invalidate, id, categories, setTreeProps, category]);

    useEffect(() => {
        if (id === undefined) {
            setCategory(undefined);
        } else {
            const category = categories.find((p) => p.id === id);

            if (category === undefined) {
                return;
            }

            setCategory(category);
        }
    }, [navigate, id, categories]);

    useEffect(() => {
        const fetch = async () => {
            const categories = await Api.Course.CategoryGet();
            setCategories(categories.Content);
        };
        fetch().then();

        const sbApi = sidebarApi?.current;
        return () => {
            sbApi?.setCheckedBucket?.({});
        };

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setPagination((prev) => ({ ...prev, pageIndex: 0 }));
    }, [filters]);

    return (
        <ContentLayout className="h-full">
            <CoursesCategoryModal
                isOpen={!!editingCategory}
                id={editingCategory?.id}
                title={editingCategory?.title}
                onChange={onChangeCategory}
                onClose={() => setEditingCategory(undefined)}
            />
            <PromptModal
                onConfirm={onDeleteCategory}
                onClose={() => setDeletingCategory(undefined)}
                isOpen={!!deletingCategory}
                title="Удаление категории"
                dialogText={["Отмена", "Удалить"]}
            >
                <div className="space-y-3.5">
                    <div className="text-gray-text">&quot;{deletingCategory?.title}&quot;</div>
                    <div>Вы действительно хотите удалить категорию? Все вложенные в нее элементы попадут в Архив</div>
                </div>
            </PromptModal>
            <Breadcrumbs className="mb-2.5" id="adminCoursesBreadcrumbs">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Курсы" url="/admin/courses" />
                {category && <Breadcrumbs.Link title={category.title} url={"/admin/courses/" + category.id} />}
            </Breadcrumbs>
            {isFetched && !categories.length && (
                <div className="h-full flex justify-center">
                    <CoursesEmpty
                        isEmptyCategories={id === undefined}
                        onCreateCategoryClick={onCreateCategoryHandler}
                        onCreateProgramClick={() => navigate("/admin/course")}
                    />
                </div>
            )}
            {isFetched && categories.length > 0 && (
                <CoursesTable
                    categoryId={id}
                    fetchCourses={fetchCourses}
                    onCopy={onCopy}
                    onState={onState}
                    selectedCategories={selectedCategories}
                    courses={dataQuery.data?.Content ?? []}
                    pageCount={dataQuery.data?.TotalPages ?? 0}
                    sorting={sorting}
                    filters={filters}
                    isFetching={dataQuery.isFetching}
                    pagination={pagination}
                    setFilters={setFilters}
                    setSorting={setSorting}
                    setPagination={setPagination}
                    onCreateCategory={onCreateCategoryHandler}
                />
            )}
        </ContentLayout>
    );
};
