import React, { SyntheticEvent, useEffect, useMemo, useRef, useState } from "react";
import { useFormik } from "formik";
import { useQuery } from "react-query";
import Api from "Api/index";
import { FormGroup } from "Uikit/Forms/FormGroup";
import { Section } from "Uikit/Forms/Section";
import { FormField } from "Uikit/Forms/FormField";
import { SubmitButton } from "Uikit/Button/SubmitButton";
import { SecondaryButton } from "Uikit/Button/SecondaryButton";
import { Breadcrumbs } from "Uikit/Breadcrumbs/Breadcrumbs";
import { EditUserRequest, IEditUserRequest, UserPhoneValidationRequest } from "Api/Requests/UserRequest";
import { useNavigate } from "react-router-dom";
import { Label } from "Uikit/Forms/Label";
import InputMask from "react-input-mask";
import { Input } from "Uikit/Forms/Input";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { flash } from "Uikit/Notification/flash";
import { object } from "yup";
import { Icon, Icons } from "Uikit/Icon/Icon";
import { Button } from "Uikit/Button/Button";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { AvatarEditor } from "Uikit/AvatarEditor/AvatarEditor";
import { setIsBack, setIsFavorite, setIsHidden, setTitle } from "slices/headerSlice";
import { useDispatch } from "react-redux";
// import { UserState } from "types/User";
import { ValidatePassword } from "helpers/Validator";
import { FileUploadType, UIErrorMessages } from "Enums";
import { useScreenSize } from "hooks/useMediaQuery";
import { getFileSizeErrorMessage } from "helpers/file";

const validationSchema = object().shape({
    //email: string().email("Допускается ввод email").required("Обязательно для ввода"),
});

export const PersonalEdit = () => {
    const { data: user } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());

    const { values, setFieldValue, handleSubmit } = useFormik<IEditUserRequest>({
        initialValues: {
            email: user?.email,
            phone: user?.phone,
            allowPush: false,
            allowEmail: false,
            oldPassword: undefined,
            newPassword: undefined,
            avatarId: user?.avatarId,
        },
        enableReinitialize: true,
        validationSchema,
        onSubmit: onSubmit,
    });
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [errors, setErrors] = useState<any>({});

    const [editMode, setEditMode] = useState(true);
    const [updating, setUpdating] = useState(false);
    const [isChanged, setIsChanged] = useState(false);

    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);

    const inputRef = useRef(null);

    const [isAvatarMobileMenu, setIsAvatarMobileMenu] = useState(false);
    const [isAvatarDeleteMobileMenu, setIsAvatarDeleteMobileMenu] = useState(false);

    const [avatarEditor, setAvatarEditor] = useState<string | undefined>(undefined);
    const [avatar, setAvatar] = useState<File | null>(null);

    const userInitials = useMemo(() => {
        if (!user) {
            return "";
        }

        return `${user.firstName.slice(0, 1) + user.lastName.slice(0, 1)}`;
    }, [user]);

    const onValidate = async (values: IEditUserRequest): Promise<boolean> => {
        const errors: any = {};

        //валидация телефона
        errors["phone"] = values.phone?.includes("_") ? "Поле заполнено некорректно" : undefined;

        if (!errors["phone"] && !!values.phone) {
            const userPhoneValidationRequest: UserPhoneValidationRequest = {
                userID: user!.id,
                phone: values.phone.replaceAll(/[\s-]/g, ""),
            };

            try {
                await Api.User.UserPhoneValidation(userPhoneValidationRequest);
            } catch (e: unknown) {
                null;
            }
        }

        //валидация почты
        // errors["email"] = ValidateEmail(values.email!);

        //валидация паролей
        errors["oldPassword"] = ValidatePassword(values.oldPassword!, false);
        errors["newPassword"] = ValidatePassword(values.newPassword!, false);

        if (values.oldPassword && !values.newPassword) {
            errors["newPassword"] = "Новый пароль не введен";
        }

        if (values.newPassword && !values.oldPassword) {
            errors["oldPassword"] = "Текущий пароль неверный";
        }

        if (Object.keys(errors).filter((p) => errors[p]).length === 0) {
            setErrors({});
            return true;
        }

        setErrors(errors);
        return false;
    };

    async function onSubmit(values: IEditUserRequest, { setFieldValue }: any) {
        const isValid = await onValidate(values);
        if (!isValid) {
            return;
        }

        try {
            if (avatar) {
                const uploadFileResponse = await Api.File.UploadFile(
                    avatar,
                    undefined,
                    undefined,
                    false,
                    FileUploadType.USER_AVATAR,
                );
                values.avatarId = uploadFileResponse.id;
            }
        } catch (e: unknown) {
            const knownError = e as BadRequestResponse;
            let message = "Размер обложки слишком большой!";
            if (
                [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                    String(knownError.errorCode) as ErrorCode,
                )
            ) {
                message = UIErrorMessages.FILE_LOADING_ERROR;
            }
            flash.error(message);
            return;
        }

        try {
            setUpdating(true);
            await Api.User.editCurrentUser(Object.assign(new EditUserRequest(), values));
            setEditMode(false);
            setIsChanged(false);
            flash.info("Изменения сохранены");

            if (window.screen.width <= 568) {
                navigate("/personal");
            }
        } catch (error) {
            if (error instanceof BadRequestResponse) {
                const { errorCode } = error;
                if (errorCode === ErrorCode.USER_INVALID_PASSWORD) {
                    setFieldValue("oldPassword", "");
                    setFieldValue("newPassword", "");

                    setErrors({ oldPassword: "Текущий пароль неверный" });
                } else if (
                    [
                        ErrorCode.ACTIVE_USER_ALREADY_EXISTS,
                        ErrorCode.ACTIVE_USER_OTHER_COMPANY_ALREADY_EXISTS,
                        ErrorCode.BLOCKED_USER_OTHER_COMPANY_ALREADY_EXISTS,
                    ].includes(errorCode)
                ) {
                    setErrors({ email: "Данный адрес электронной почты уже используется" });
                } else if (errorCode === ErrorCode.BLOCKED_USER_ALREADY_EXISTS) {
                    setErrors({
                        email: (
                            <>
                                Почта прикреплена к аккаунту{" "}
                                <a className="text-white font-semibold" href="/admin/members">
                                    заблокированного пользователя
                                </a>
                            </>
                        ),
                    });
                } else {
                    console.log(error);
                }
                flash.error("Не все поля формы заполнены правильно");
            }
        } finally {
            setUpdating(false);
        }
    }

    const handleCancel = () => navigate("/personal");

    const onAvatarChange = (event: any) => {
        const files = (event?.target as HTMLInputElement)?.files;

        if (!files || files.length === 0) {
            return;
        }

        if (files.length > 1) {
            flash.error("Выбрано более 1 файла");
            return;
        }

        if (!RegExp(/^image\/(jpg|jpeg|png)/).exec(files[0].type)) {
            flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
            return;
        }

        if (files[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2 МБ"));
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            // onChangeAvatar(files[0], reader.result!.toString());
            setAvatarEditor(reader.result!.toString());
            event.target.value = "";
        };
    };

    const onChangeAvatar = (data: File | null, imgBase64?: string) => {
        setAvatar(data);
        setFieldValue("avatarId", imgBase64);
        setEditMode(true);
    };

    const onAvatarRemove = async () => {
        setAvatar(null);
        await setFieldValue("avatarId", null);
        setEditMode(true);
    };

    useEffect(() => {
        dispatch(setIsHidden(false));
        dispatch(setIsBack(true));
        dispatch(setTitle("Настройки"));
        dispatch(setIsFavorite(false));
    }, [dispatch]);
    const { size } = useScreenSize();
    const isLarge = size === "large";

    const onEmailBlur = (e: SyntheticEvent) => {
        if (!(e.target as HTMLInputElement).value) {
            setFieldValue("email", null);
        }
    };

    if (!user) {
        return null;
    }

    return (
        <div className="sm:max-w-[1216px] 2xl:max-w-[1506px] w-full sm:mx-auto px-4 sm:space-y-6">
            <div className="pt-4 sm:pt-0 sm:pr-6.5">
                {isAvatarDeleteMobileMenu && (
                    <div className="fixed top-0 left-0 flex sm:hidden flex-col justify-center items-center w-screen h-screen py-5 px-3 bg-black-50 z-[101]">
                        <div className="p-3 w-62.5 bg-white rounded-2xl">
                            <div className="mb-3 p2 text-gray-dark">
                                Вы действительно хотите удалить фотографию? Отменить это действие будет невозможно
                            </div>
                            <div className="flex w-full">
                                <div
                                    className="flex justify-center items-center pt-3 w-full text-black p1-accent"
                                    onClick={() => setIsAvatarDeleteMobileMenu(false)}
                                >
                                    Отмена
                                </div>
                                <div
                                    className="flex justify-center items-center pt-3 w-full text-red p1-accent"
                                    onClick={() => {
                                        setIsAvatarDeleteMobileMenu(false);
                                        onChangeAvatar(null);
                                    }}
                                >
                                    Удалить
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {isAvatarMobileMenu && (
                    <div className="fixed top-0 left-0 flex sm:hidden flex-col justify-end w-screen h-screen py-5 px-3 bg-black-50 z-[101]">
                        <div className="mb-3 p-1 w-full rounded-2xl bg-white">
                            <div
                                className="py-2 px-4 text-blue p1"
                                onClick={() => {
                                    setIsAvatarMobileMenu(false);
                                    (inputRef.current as unknown as HTMLInputElement).click();
                                }}
                            >
                                Загрузить фото
                            </div>
                            <div
                                className="py-2 px-4 text-red p1"
                                onClick={() => {
                                    setIsAvatarDeleteMobileMenu(true);
                                    setIsAvatarMobileMenu(false);
                                }}
                            >
                                Удалить
                            </div>
                        </div>
                        <div className="p-1 w-full rounded-2xl bg-white">
                            <div className="py-2 px-4 text-black p1" onClick={() => setIsAvatarMobileMenu(false)}>
                                Отмена
                            </div>
                        </div>
                    </div>
                )}
                <div
                    className="absolute top-2.75 right-10 block sm:hidden z-[100] p1-accent text-blue"
                    onClick={() => {
                        if (Object.entries(errors).length > 0 || updating) {
                            return;
                        }

                        handleSubmit();
                    }}
                >
                    Готово
                </div>
                <AvatarEditor
                    isOpen={avatarEditor !== undefined}
                    type="circle"
                    title="Загрузка аватара"
                    img={avatarEditor ?? ""}
                    onDismiss={() => setAvatarEditor(undefined)}
                    onSubmit={(img: string, blob: Blob) => onChangeAvatar(new File([blob], "avatar.jpg"), img)}
                />
                <Breadcrumbs className="hidden sm:block mb-3">
                    <Breadcrumbs.Link title="Личный кабинет" url="/personal" />
                    <Breadcrumbs.Link title="Редактирование" url="" />
                </Breadcrumbs>
                <div className="hidden sm:flex">
                    <h1 className="grow">
                        {user?.firstName} {user?.lastName}
                    </h1>
                    {isChanged || editMode ? (
                        <div className="flex space-x-4">
                            <SecondaryButton onClick={handleCancel}>Отменить</SecondaryButton>
                            <SubmitButton onClick={handleSubmit}>Сохранить</SubmitButton>
                        </div>
                    ) : (
                        <div>
                            <Button
                                className="w-10 h-10 !p-0"
                                variant="outline"
                                color="secondary"
                                onClick={() => navigate("/personal")}
                                id="profileCancelEdit"
                            >
                                <Icon icon={Icons.Close} width={24} height={24} color="fill-[#939393]" />
                            </Button>
                        </div>
                    )}
                </div>
                <Section label="Фотография" className="p2-accent">
                    <div className="group flex justify-center sm:justify-start sm:space-x-7.5 sm:mb-5.5 pb-6 sm:pb-0 2xl:space-x-10">
                        <div className="relative" onClick={() => setIsAvatarMobileMenu(true)}>
                            <UserAvatar
                                avatarId={values.avatarId}
                                color={user.defaultAvatarColor ?? undefined}
                                userInitials={userInitials}
                                size={isLarge ? 130 : 112}
                            />
                            <div className="absolute bottom-0 right-0 flex justify-center items-center sm:hidden w-7 h-7 rounded-full bg-blue-10 border-2 border-white">
                                <Icon icon={Icons.Camera} width={16} height={16} color="fill-blue" />
                            </div>
                        </div>
                        <input
                            ref={inputRef}
                            id="logoInput"
                            className="hidden"
                            type="file"
                            accept="image/png, image/jpeg, image/jpg"
                            onChange={onAvatarChange}
                        />
                        <div className="hidden sm:flex">
                            <Button
                                className="my-auto"
                                onClick={() => (inputRef.current as unknown as HTMLInputElement).click()}
                                id="adminNewMemberBtnLoadImg"
                            >
                                Загрузить
                            </Button>
                            <Button
                                variant="outline"
                                color="common"
                                className="border-[#EAEDF3] ml-4 my-auto font-normal 2xl:ml-5"
                                onClick={onAvatarRemove}
                                disabled={!values.avatarId}
                                id="adminNewMemberBtnDeleteImg"
                            >
                                Удалить
                            </Button>
                        </div>
                    </div>
                </Section>
                <Section label="Изменение пароля" className="py-6 sm:py-0 p2-accent">
                    <p className="block sm:hidden mb-6 p2-accent text-black">Изменение пароля</p>
                    <form autoComplete="off">
                        <FormField name="oldPassword" label="Старый пароль">
                            <Input
                                id="profile-old-password"
                                name="profile-old-password"
                                placeholder="Введите старый пароль"
                                autoComplete="off"
                                type={showOldPassword ? "text" : "password"}
                                value={values.oldPassword}
                                minLength={8}
                                maxLength={16}
                                // eslint-disable-next-line no-useless-escape
                                cutRegExp={new RegExp(/[^a-zA-Z0-9.,\-"'()«»!@#№$%^&*_+=?;:\\|\/~><\]\[{}]/g)}
                                error={errors["oldPassword"]}
                                onChange={(e) => {
                                    setFieldValue("oldPassword", e.target.value);
                                    setIsChanged(true);
                                }}
                                after={
                                    <div
                                        className={"cursor-pointer z-10 " + (errors["oldPassword"] ? "pr-7" : "pr-0")}
                                        onClick={() => {
                                            setShowOldPassword((oldPassword) => !oldPassword);
                                        }}
                                    >
                                        <Icon
                                            icon={showOldPassword ? Icons.Eye : Icons.EyeSlash}
                                            width={20}
                                            height={20}
                                            color="fill-blue-dark"
                                        />
                                    </div>
                                }
                            />
                        </FormField>
                        <FormField name="newPassword" label="Новый пароль" className="!mb-0">
                            <Input
                                id="profile-new-password"
                                name="profile-new-password"
                                placeholder="Введите новый пароль"
                                autoComplete="off"
                                type={showNewPassword ? "text" : "password"}
                                value={values.newPassword}
                                minLength={8}
                                maxLength={16}
                                // eslint-disable-next-line no-useless-escape
                                cutRegExp={new RegExp(/[^a-zA-Z0-9.,\-"'()«»!@#№$%^&*_+=?;:\\|\/~><\]\[{}]/g)}
                                error={errors["newPassword"]}
                                onChange={(e) => {
                                    setFieldValue("newPassword", e.target.value);
                                    setIsChanged(true);
                                }}
                                after={
                                    <div
                                        className={"cursor-pointer z-10 " + (errors["newPassword"] ? "pr-7" : "pr-0")}
                                        onClick={() => {
                                            setShowNewPassword((newPassword) => !newPassword);
                                        }}
                                    >
                                        <Icon
                                            icon={showNewPassword ? Icons.Eye : Icons.EyeSlash}
                                            width={20}
                                            height={20}
                                            color="fill-blue-dark"
                                        />
                                    </div>
                                }
                            />
                        </FormField>
                    </form>
                </Section>
                <Section label="Основные данные" className="py-6 sm:py-0 p2-accent">
                    <p className="block sm:hidden mb-6 p2-accent text-black">Основные данные</p>
                    <FormField name="email" label="Почта">
                        <Input
                            placeholder="Введите вашу почту"
                            value={values.email as string}
                            onChange={(e) => {
                                setFieldValue("email", e.target.value);
                                setIsChanged(true);
                            }}
                            onBlur={onEmailBlur}
                            // eslint-disable-next-line no-useless-escape
                            cutRegExp={new RegExp(/[^a-zA-Z0-9!#$%&'*+\-\/=?^_`{|}~.@]/g)}
                            minLength={5}
                            maxLength={256}
                            // error={errors["email"]}
                        />
                    </FormField>
                    <FormGroup>
                        <Label>Номер телефона</Label>
                        <InputMask
                            mask="+7 999 999-99-99"
                            type="text"
                            placeholder="+7 999 999-99-99"
                            value={values.phone}
                            onChange={(e) => {
                                setFieldValue("phone", e.target.value);
                                setIsChanged(true);
                            }}
                            beforeMaskedStateChange={({ nextState, previousState }) => {
                                return nextState.value.includes("_") && nextState.selection?.end === 16
                                    ? previousState ?? nextState
                                    : nextState;
                            }}
                        >
                            <Input className="!text-black" error={errors["phone"]} />
                        </InputMask>
                    </FormGroup>
                </Section>
            </div>
        </div>
    );
};
