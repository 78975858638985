import React, { ReactNode, useCallback, useContext, useEffect, useRef, useState } from "react";
import { ContentLayout, TreeWrapperContext } from "Containers";
import { Breadcrumbs, Button, Content, flash, Icon, Icons, Tabs, TabsWrapper, Toggle } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { TestSettings } from "./TestSettings";
import { TestTesting } from "./TestTesting";
import { TestContent } from "./TestContent";
import { AccessDummy as Access, AccessRef } from "Components/Access/AccessDummy";
import { useBlocker, useNavigate, useParams } from "react-router-dom";
import { FileUploadType, QuizType, ResourceState, ResourceType, TestQuestionType } from "Enums";
import { TestRequest } from "Api/Requests/TestRequest";
import Api from "Api/index";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { TestResponse } from "Api/Responses/TestResponse";
import { DateFormat, formatDate } from "helpers/dateHelper";
import { CurrentUserResponse } from "Api/Responses/UserResponse";
import { ApproxTime } from "Components/ApproxTime/ApproxTime";
import { useMutation, useQueryClient } from "react-query";
import { TeamAccessRequest } from "Api/Requests/AccessRequest";
import { useInvalidate } from "hooks/useInvalidate";
import { CancelModal } from "Components/CancelModal/CancelModal";
import { useDialog } from "hooks/useDialog";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { numCapEnd } from "helpers/numCapEnd";
import { IOption, TVoidFunction } from "types";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

interface ITest {
    isEdit?: boolean;
    isCopy?: boolean;
}

const getRatingCaption = (value: number) => {
    return numCapEnd({ one: "балл", two: "балла", few: "баллов" }, value);
};

const isRequiredFieldMessage = "Поле обязательно для заполнения";
const noCorrectAnswersMessage = "Должен быть выбран как минимум один правильный вариант";
const gotUncorrectFieldsMessage = "Ошибка, не все поля формы заполнены правильно";

export const Test = ({ isEdit, isCopy }: ITest) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();

    const invalidate = useInvalidate();
    const { dialogState, openDialog, closeDialog } = useDialog();

    const currentUser: CurrentUserResponse | undefined = queryClient.getQueryData(["users", "current"]);

    const [initTitle, setInitTitle] = useState("Новый тест");
    const [currentTab, setCurrentTab] = useState(0);

    const [isChanged, setIsChanged] = useState(false);
    const blocker = useBlocker((params) => {
        if ((isChanged || isAccessFormDirty) && params?.historyAction !== "REPLACE") {
            setIsCancel(true);
        } else if (params?.historyAction === "REPLACE") {
            return false;
        }

        return isChanged || isAccessFormDirty;
    });

    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [isEditCancel, setIsEditCancel] = useState<boolean>(false);

    const { id } = useParams();
    const { setTreeProps } = useContext(TreeWrapperContext);
    const [categories, setCategories] = useState<IOption[]>([]);
    const [complexity, setComplexity] = useState<IOption[]>([]);
    const [initCategoryId, setInitCategoryId] = useState("");
    const [solutionsOnReview, setSolutionsOnReview] = useState(false);

    const [cover, setCover] = useState<File | null>(null);
    const [defaultLogos, setDefaultLogos] = useState<any>([]);

    const testContentRef = useRef();
    const accessRef = useRef<AccessRef>();

    const initTestValues = {
        id: "",
        logoId: null,
        categoryId: initCategoryId,
        reviewerId: "",
        title: "",
        description: "",
        state: ResourceState.ACTIVE,
        publicAccess: true,
        quizType: QuizType.LINEAR,
        questionSkipping: false,
        quizTimeLimit: null,
        questionTimeLimit: null,
        showMistakesDuringQuiz: false,
        showMistakesAfterQuiz: false,
        maxAttempts: 1,
        notRated: false,
        passPercentage: "",
        shuffledQuestions: false,
        shuffledAnswers: false,
        successMessage: "Поздравляем! Тест успешно пройден",
        failureMessage: "К сожалению, тест был провален",
        approxCompletionMinutes: 1,
        isApproxCompletionMinutesManuallyChanged: false,
        questions: [],
        publicationTime: null,
        archiveTime: null,
        deadlineTime: null,
        modifyTime: null,
        notIssueCertificateIfFailed: false,
    };
    const [test, setTest] = useState<TestRequest>(initTestValues);
    const [errors, setErrors] = useState<any>({});
    const [initialTest, setInitialTest] = useState(test);
    const [isMaxAttemptsEnabled, setIsMaxAttemptsEnabled] = useState(!!initTestValues.maxAttempts);

    const [isAccessFormDirty, setIsAccessFormDirty] = useState(false);
    const [teamIds, setTeamIds] = useState<any[]>([]);
    const [allTeamsAccess, setAllTeamsAccess] = useState(false);

    const responsibleList = useResponsibleList(test.reviewerId);

    const fetchTest = useCallback(async () => {
        if (!id) {
            return;
        }

        const response = await Api.Test.Read(id);

        setTest((prevState) => ({
            ...prevState,
            id: response.id,
            logoId: response.logoId,
            categoryId: response.categoryId,
            reviewerId: response.reviewerId,
            title: isCopy ? "Копия 1 " + response.title : response.title,
            description: response.description,
            state: isCopy ? ResourceState.HIDDEN : response.state,
            publicAccess: response.publicAccess,
            quizType: response.quizType,
            questionSkipping: false,
            quizTimeLimit: response.quizTimeLimit,
            questionTimeLimit: response.questionTimeLimit,
            showMistakesDuringQuiz: response.showMistakesDuringQuiz,
            showMistakesAfterQuiz: response.showMistakesAfterQuiz,
            maxAttempts: response.maxAttempts,
            notRated: response.notRated,
            passPercentage: response.passPercentage,
            shuffledQuestions: response.shuffledQuestions,
            shuffledAnswers: response.shuffledAnswers,
            successMessage: response.successMessage,
            failureMessage: response.failureMessage,
            approxCompletionMinutes: response.approxCompletionMinutes,
            isApproxCompletionMinutesManuallyChanged: response.isApproxCompletionMinutesManuallyChanged,
            questions: response.questions,
            publicationTime: response.publicationTime,
            archiveTime: response.archiveTime,
            deadlineTime: response.deadlineTime,
            modifyTime: response.modifyTime,
            notIssueCertificateIfFailed: response.notIssueCertificateIfFailed,
        }));

        setSolutionsOnReview(response.hasUserSolutionsOnReview);
        setInitCategoryId(response.categoryId);
        setInitTitle(isCopy ? "Копия 1 " + response.title : response.title);
        setIsMaxAttemptsEnabled(!!response.maxAttempts);

        setInitialTest((prevState) => ({
            ...prevState,
            state: isCopy ? ResourceState.HIDDEN : response.state,
        }));
    }, [id, isCopy]);

    const onChangeCover = (data: File | null, imgBase64?: string) => {
        setCover(data);
        setTest({ ...test, logoId: imgBase64 });

        setIsChanged(true);
    };

    const onValidate = (): boolean | "noCorrectAnswers" => {
        const errors: any = {};

        errors["title"] = !test.title ? isRequiredFieldMessage : undefined;
        errors["categoryId"] = !test.categoryId ? isRequiredFieldMessage : undefined;
        errors["reviewerId"] = !test.reviewerId ? isRequiredFieldMessage : undefined;
        // errors["maxAttempts"] = !test.maxAttempts ? isRequiredFieldMessage : undefined;
        errors["passPercentage"] = test.passPercentage === "" ? isRequiredFieldMessage : undefined;
        errors["time"] =
            test.publicationTime && test.archiveTime && test.archiveTime <= test.publicationTime
                ? "Дата снятия с публикации не может быть раньше даты публикации"
                : undefined;
        errors["access"] = accessRef.current?.validateAccess();

        if (test.questions.length === 0) {
            errors["questions"] = "Для сохранения требуется хотя бы один вопрос в содержимом теста";
            flash.error("Для сохранения требуется хотя бы один вопрос в содержимом теста");
        } else {
            errors["questions"] = {};

            for (const element of test.questions) {
                const questionErrors: any = {};
                const testQuestion = element;

                if (!testQuestion.questionText) {
                    questionErrors["questionText"] = isRequiredFieldMessage;
                }

                if (testQuestion.questionType === TestQuestionType.NUMBER && !testQuestion.correctAnswer) {
                    questionErrors["correctAnswer"] = isRequiredFieldMessage;
                }

                if (testQuestion.questionType === TestQuestionType.TEXT && !testQuestion.answerNote) {
                    questionErrors["answerNote"] = isRequiredFieldMessage;
                }

                const isSelectable = [TestQuestionType.ONE_OPTION, TestQuestionType.MULTIPLE_OPTIONS].includes(
                    testQuestion.questionType,
                );

                // Валидация на предмет заполненных ответов
                if (
                    isSelectable &&
                    (testQuestion.answers.length < 2 ||
                        (testQuestion.answers.filter((p) => !p.answerText).length !== 0 &&
                            testQuestion.answers.filter((p) => !p.pictureId).length !== 0))
                ) {
                    questionErrors["answers"] = isRequiredFieldMessage;
                }

                // Валидация на предмет выбранных правильных ответов
                if (isSelectable && testQuestion.answers.every(({ isCorrect }) => !isCorrect)) {
                    questionErrors["noCorrectAnswers"] = noCorrectAnswersMessage;
                }

                if (Object.keys(questionErrors).length !== 0) {
                    errors["questions"][testQuestion.id!] = questionErrors;
                }
            }

            if (Object.keys(errors["questions"]).length === 0) {
                errors["questions"] = undefined;
            }
        }

        if (Object.keys(errors).filter((p) => errors[p]).length !== 0) {
            if (errors["access"]) {
                flash.error(errors["access"]);
            } else if (errors["questions"]) {
                // Вывод сообщения в случае наличия невыбранных правильных ответов вопросов
                if (
                    Object.values(errors["questions"]).some(
                        (question) => (question as { noCorrectAnswers: string }).noCorrectAnswers,
                    )
                ) {
                    flash.error(noCorrectAnswersMessage);
                }
                // Вывод сообщения в случае наличия неправильно заполненных полей в вопросах
                if (
                    Object.values(errors["questions"]).some((question) => {
                        return (
                            Object.keys(question as Record<string, string>).filter((key) => key !== "noCorrectAnswers")
                                .length > 0
                        );
                    })
                ) {
                    flash.error(gotUncorrectFieldsMessage);
                }
            } else {
                flash.error(gotUncorrectFieldsMessage);
            }

            setErrors(errors);
            return false;
        }

        setErrors({});
        return true;
    };

    const { mutateAsync: setTeamAccess } = useMutation((payload: TeamAccessRequest) => {
        return Api.LMSRoles.setTeamAccess(payload);
    });

    const onSave = async () => {
        try {
            const validationResult = onValidate();

            if (!validationResult) {
                return;
            }

            if (isAccessFormDirty) {
                await setTeamAccess(
                    Object.assign(new TeamAccessRequest(), {
                        resourceId: id,
                        resourceType: ResourceType.QUIZ,
                        teams: teamIds.filter((id) => !id.startsWith("root:")),
                        allTeams: allTeamsAccess,
                    }),
                );

                invalidate("teamAccess", id);
                setIsAccessFormDirty(false);
            }

            test.publicAccess = allTeamsAccess;

            if (testContentRef?.current) {
                (testContentRef.current as { collapseTestItem: TVoidFunction }).collapseTestItem();
            }

            // Upload test cover;
            let response: TestResponse;

            try {
                if (cover) {
                    const uploadFileResponse = await Api.File.UploadFile(
                        cover,
                        undefined,
                        undefined,
                        false,
                        FileUploadType.RESOURCE_LOGO,
                    );
                    test.logoId = uploadFileResponse.id;
                }
            } catch (e: unknown) {
                const knownError = e as BadRequestResponse;
                let message = "Размер обложки слишком большой!";
                if (
                    [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                        String(knownError.errorCode) as ErrorCode,
                    )
                ) {
                    message = "Ошибка при загрузке, загрузите другой файл";
                }
                flash.error(message);
                return;
            }

            // Upload test questions covers;
            for (const element of test.questions) {
                if (element.id && element.id.length < 24) {
                    element.id = "";
                }

                if (element?.logoId?.file) {
                    try {
                        const uploadFileResponse = await Api.File.UploadFile(
                            element.logoId.file,
                            undefined,
                            undefined,
                            false,
                            FileUploadType.RESOURCE_IMAGE,
                        );
                        element.logoId = uploadFileResponse.id;
                    } catch (e: unknown) {
                        const knownError = e as BadRequestResponse;
                        let message = "Размер обложки слишком большой!";
                        if (
                            [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                                String(knownError.errorCode) as ErrorCode,
                            )
                        ) {
                            message = "Ошибка при загрузке, загрузите другой файл";
                        }
                        flash.error(message);
                        return;
                    }
                }

                // Upload test answers covers;
                for (const answer of element.answers) {
                    if (answer.id && answer.id.length < 24) {
                        answer.id = "";
                    }

                    if (!answer.pictureId || typeof answer.pictureId !== "object") {
                        continue;
                    }

                    try {
                        const uploadFileResponse = await Api.File.UploadFile(
                            answer.pictureId.file,
                            undefined,
                            undefined,
                            false,
                            FileUploadType.RESOURCE_IMAGE,
                        );
                        answer.pictureId = uploadFileResponse.id;
                    } catch (e: unknown) {
                        const knownError = e as BadRequestResponse;
                        let message = "Размер обложки слишком большой!";
                        if (
                            [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                                String(knownError.errorCode) as ErrorCode,
                            )
                        ) {
                            message = "Ошибка при загрузке, загрузите другой файл";
                        }
                        flash.error(message);
                        return;
                    }
                }
            }

            // Create or update test;
            test.approxCompletionMinutes = getTime();

            test.successMessage = test.successMessage ?? "";
            test.failureMessage = test.failureMessage ?? "";
            if (!isMaxAttemptsEnabled) {
                test.maxAttempts = null;
            }

            if (test.id && !isCopy) {
                response = await Api.Test.Edit(test);
                if (solutionsOnReview) {
                    await fetchTest();
                    flash.error(
                        <>
                            Изменения во вкладке «Содержимое» не сохранены, так как тест находится на
                            <a
                                href={`/admin/validations?tab=tests&testTitle=${test.title}&testId=${test.id}`}
                                target="_blank"
                                className="text-current underline ml-1"
                                rel="noreferrer"
                            >
                                проверке
                            </a>
                        </>,
                    );
                } else {
                    flash.success("Тест успешно сохранен!");
                }
            } else {
                delete test.id;
                response = await Api.Test.Create(test);
                flash.success("Тест успешно создан!");
            }

            setInitTitle(test.title);

            setTest({ ...test, modifyTime: response.modifyTime });
            setInitCategoryId(test.categoryId);
            setIsChanged(false);

            navigate(`/admin/test/${response.id}`, { replace: true });
        } catch (ex: any) {
            if (ex.errorCode === "TITLE_ALREADY_EXISTS") {
                setErrors((prev: any) => {
                    const next = Object.assign({}, prev);
                    next["title"] = "Тест с таким именем уже существует";
                    return next;
                });
            }

            flash.error("Ошибка, не все поля формы заполнены правильно");
        }
    };

    const onSubmit = () => {
        const isHidden = test.state === ResourceState.HIDDEN;
        initialTest.state !== test.state
            ? openDialog({
                  title: `Изменить статус на «${isHidden ? "Скрыт" : "Активен"}»`,
                  content: isHidden
                      ? "У всех пользователей пропадёт данный тест"
                      : "Все пользователи, у кого есть доступ, увидят данный тест",
                  closeBtnText: "Отмена",
                  submitBtnText: "Изменить",
                  submitBtnColor: "primary",
                  onRequestClose: () => closeDialog(),
                  onRequestSubmit: () => {
                      onSave().then(() => {
                          closeDialog();
                      });
                  },
              })
            : onSave();
    };

    const getTime = () => {
        if (test.quizTimeLimit) {
            return Math.ceil(test.quizTimeLimit / 60);
        } else if (test.questionTimeLimit) {
            return Math.ceil((test.questions.length * test.questionTimeLimit) / 60) || 1;
        } else if (test.isApproxCompletionMinutesManuallyChanged) {
            return test.approxCompletionMinutes;
        } else {
            let time = 0;

            test.questions.forEach((p) => {
                time += p.complexity === "NONE" || p.complexity === "LOW" ? 1 : 2;
                return p;
            });

            return Math.ceil(time) || 1;
        }
    };

    const tabsComponent = (): ReactNode => {
        if (currentTab !== 2 && test.modifyTime) {
            return `Обновлен ${formatDate(test.modifyTime * 1000, DateFormat.DATE_TIME_LONG)}`;
        } else if (currentTab === 2) {
            return (
                <ApproxTime
                    canEdit={!test.quizTimeLimit && !test.questionTimeLimit}
                    time={getTime()}
                    onChange={(time: number) => {
                        setTest({
                            ...test,
                            approxCompletionMinutes: time,
                            isApproxCompletionMinutesManuallyChanged: true,
                        });
                        setIsChanged(true);
                    }}
                />
            );
        }
    };

    const onCancelChange = () => {
        test.id ? setIsEditCancel(true) : navigate(`/admin/tests/${initCategoryId}`);
    };

    const onCancelModalSubmit = useCallback(async () => {
        setIsChanged(false);

        if (isCancel && blocker.state === "blocked") {
            blocker.proceed();
        } else {
            setIsEditCancel(false);
            setIsAccessFormDirty(false);
            await fetchTest();
            const initialAccess = await Api.LMSRoles.getTeamAccess(id!);

            if (accessRef?.current) {
                (accessRef.current as { resetAccess: (data: any) => void }).resetAccess(initialAccess);
            }

            navigate(`/admin/test/${test.id}`, { replace: true });
        }
    }, [blocker, isCancel, fetchTest, id, navigate, test.id]);

    useEffect(() => {
        (async () => {
            const defaultLogos = (await Api.Upload.GetDefaultLogos()).Content;

            setDefaultLogos(defaultLogos);
            setTest((prevState) => {
                return { ...prevState, logoId: defaultLogos[Math.floor(Math.random() * defaultLogos.length)] };
            });

            const categories = (await Api.Test.CategoryGet()).Content.map((p) => {
                return { label: p.title, value: p.id };
            });
            setCategories(categories);

            const rating = await Api.Rating.ReadByType("QUIZ");
            setComplexity([
                { label: "Без оценки (0 баллов)", value: "NONE" },
                { label: `Низкая (${rating.EASY} ${getRatingCaption(rating.EASY)})`, value: "LOW" },
                { label: `Средняя (${rating.MEDIUM} ${getRatingCaption(rating.MEDIUM)})`, value: "MEDIUM" },
                { label: `Высокая (${rating.HARD} ${getRatingCaption(rating.HARD)})`, value: "HIGH" },
            ]);

            const params = new URLSearchParams(location.search);

            if (!params.get("categoryId")) {
                return;
            }

            setInitCategoryId(params.get("categoryId") as string);

            setTest((prevState) => {
                return { ...prevState, categoryId: params.get("categoryId") ?? "" };
            });
        })();
    }, []);

    useEffect(() => {
        if (currentUser) {
            setTest((prevState) => ({
                ...prevState,
                reviewerId: currentUser.id,
            }));
        }
    }, [currentUser]);

    useEffect(() => {
        fetchTest().then();
    }, [fetchTest]);

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        setTreeProps(undefined);
    }, [setTreeProps]);

    useEffect(() => {
        setIsChanged(!!isEdit || !!isCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Confirmation {...dialogState} />

            <CancelModal
                id="testPageCancelModal"
                isEdit={isEditCancel}
                isOpen={isCancel || isEditCancel}
                setIsOpen={isEditCancel ? setIsEditCancel : setIsCancel}
                onSubmit={onCancelModalSubmit}
            />

            <ContentLayout>
                <Breadcrumbs className="mb-2.5" id="adminNewTestBreadcrumbs">
                    <Breadcrumbs.Link title="Администратор" />
                    <Breadcrumbs.Link title="Тесты" url="/admin/tests" />
                    {initCategoryId && (
                        <Breadcrumbs.Link
                            title={categories.find((p) => p.value === initCategoryId)?.label ?? ""}
                            url={"/admin/tests/" + initCategoryId}
                        />
                    )}
                    <Breadcrumbs.Link title={initTitle} />
                </Breadcrumbs>
                <div className="flex justify-between items-center mb-7.5 gap-4">
                    <h1 id="adminNewTestTitle">
                        <MultiClumpTooltip label={initTitle} clamp={1} textClassName="!leading-8"></MultiClumpTooltip>
                    </h1>
                    <div className="flex items-center">
                        <Toggle
                            className="mr-7.5 font-semibold"
                            label={test.state === ResourceState.HIDDEN ? "Скрыт" : "Активен"}
                            enabled={test.state ? test.state === ResourceState.ACTIVE : true}
                            onChange={(p) => {
                                const updatedTest = {
                                    ...test,
                                    state: p ? ResourceState.ACTIVE : ResourceState.HIDDEN,
                                };
                                if (p) {
                                    updatedTest.publicationTime = null;
                                    updatedTest.deadlineTime = null;
                                }
                                updatedTest.archiveTime = null;
                                setTest(updatedTest);
                                setIsChanged(true);
                            }}
                            id="adminNewTestToggleIsActive"
                        />
                        {(isAccessFormDirty || isChanged || !test.id) && (
                            <Button
                                className="mr-4"
                                variant="outline"
                                color="secondary"
                                onClick={onCancelChange}
                                id="adminNewTestBtnCancel"
                            >
                                Отменить
                            </Button>
                        )}
                        {(isAccessFormDirty || isChanged || !test.id) && (
                            <Button onClick={onSubmit} id="adminNewTestBtnOk">
                                Сохранить
                            </Button>
                        )}
                        {!isAccessFormDirty && !isChanged && test.id && (
                            <Button
                                className="w-10 h-10 !p-0"
                                variant="outline"
                                color="secondary"
                                shape="square"
                                // onClick={() => {
                                //     navigate(`/admin/tests/${initCategoryId}`);
                                // }}
                                onClick={() => {
                                    navigate(`/admin/tests`);
                                }}
                                id="adminNewTestBtnCrest"
                            >
                                <Icon icon={Icons.Close} width={24} height={24} color="fill-[#939393]" />
                            </Button>
                        )}
                    </div>
                </div>
                <TabsWrapper>
                    <TabsWrapper.Tabs
                        classname="flex flex-grow justify-between items-center h-max"
                        label={tabsComponent()}
                        id="adminNewTestTabs"
                    >
                        <Tabs.Tab
                            title="Настройка"
                            disabled={isAccessFormDirty}
                            tooltip={isAccessFormDirty ? "Будет доступно после сохранения" : ""}
                            error={errors["title"] || errors["categoryId"] || errors["reviewerId"] || errors["time"]}
                            onClick={() => setCurrentTab(0)}
                        />
                        <Tabs.Tab
                            title="Тестирование"
                            disabled={isAccessFormDirty}
                            tooltip={isAccessFormDirty ? "Будет доступно после сохранения" : ""}
                            error={errors["passPercentage"]}
                            onClick={() => setCurrentTab(1)}
                        />
                        <Tabs.Tab
                            title="Содержимое"
                            disabled={isAccessFormDirty}
                            tooltip={isAccessFormDirty ? "Будет доступно после сохранения" : ""}
                            error={errors["questions"]}
                            onClick={() => setCurrentTab(2)}
                        />
                        <Tabs.Tab
                            title="Доступ"
                            disabled={isChanged || !test.id}
                            tooltip={isChanged || !test.id ? "Будет доступно после сохранения" : ""}
                            error={errors["access"]}
                            onClick={() => setCurrentTab(3)}
                        />
                    </TabsWrapper.Tabs>
                    <TabsWrapper.Content>
                        <Content.Body>
                            <TestSettings
                                errors={errors}
                                users={responsibleList}
                                categories={categories}
                                onCategoriesChange={(category: any) => setCategories([...categories, category])}
                                test={test}
                                cover={cover}
                                defaultLogos={defaultLogos}
                                onChangeCover={onChangeCover}
                                onChange={(p) => {
                                    setTest(p);
                                    setIsChanged(true);
                                }}
                            />
                        </Content.Body>
                        <Content.Body>
                            <TestTesting
                                errors={errors}
                                test={test}
                                onChange={(p) => {
                                    setTest(p);
                                    setIsChanged(true);
                                }}
                                isMaxAttemptsEnabled={isMaxAttemptsEnabled}
                                setIsMaxAttemptsEnabled={(arg) => setIsMaxAttemptsEnabled(arg)}
                            />
                        </Content.Body>
                        <Content.Body>
                            <TestContent
                                errors={errors}
                                complexity={complexity}
                                test={test}
                                onChange={(p) => {
                                    setTest(p);
                                    setIsChanged(true);
                                }}
                                solutionsOnReview={solutionsOnReview}
                                ref={testContentRef}
                            />
                        </Content.Body>
                        <Content.Body>
                            <Access
                                resourceId={id}
                                resourceType={ResourceType.QUIZ}
                                setIsAccessFormDirty={setIsAccessFormDirty}
                                setTeamIds={setTeamIds}
                                allTeamsAccess={allTeamsAccess}
                                setAllTeamsAccess={setAllTeamsAccess}
                                nodeAddComponentLink={`/admin/members?teamId.in=`}
                                ref={accessRef}
                            />
                        </Content.Body>
                    </TabsWrapper.Content>
                </TabsWrapper>
            </ContentLayout>
        </>
    );
};
