import React, { useCallback, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import { Empty } from "Uikit/Page/Empty";
import { Button, Checkbox, Icon, Icons, Table } from "Uikit";
import { Filter } from "Components/Filter/Filter";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { AchievementsRowActionPopover } from "./AchievementsRowActionPopover";
import { AchievementResponse } from "Api/Responses/AchievementResponse";
import { BasePaginationResponse } from "Api/BaseResponse";
import { LogoSize } from "Api/Services/UploadApi";
import Api from "Api";

interface IAchievementsTable {
    fetch: (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ) => Promise<BasePaginationResponse<AchievementResponse>>;
    onRemove: (data: string[]) => Promise<void>;
}
export const AchievementsTable = ({ fetch, onRemove }: IAchievementsTable) => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();

    const { dialogState, openDialog, closeDialog } = useDialog();

    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });
    const [sorting, setSorting] = useState<SortingState>([{ id: "name", desc: false }]);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);

    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

    const [isFilters, setIsFilters] = useState(false);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const { data: ratingInfo } = useQuery(["achievements/filter-rating-info"], async () => {
        return await Api.Achievement.FilterRatingInfo();
    });

    const dataQuery = useQuery(
        ["achievements", "collection", pagination, sorting, filters],
        async () => {
            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "rating" || element === "overallQuantity" || element === "maxQuantity") {
                    if (filters[element]["minValue"]) {
                        if (isNaN(parseInt(filters[element]["minValue"]))) {
                            continue;
                        }

                        filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                    }
                    if (filters[element]["maxValue"]) {
                        if (isNaN(parseInt(filters[element]["maxValue"]))) {
                            continue;
                        }

                        filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                    }
                } else {
                    filtersData[element] = filters[element];
                }
            }

            return await fetch(pageIndex, pageSize, sorting, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const onRemoveHandler = useCallback(
        (data: AchievementResponse | string[]) => {
            const isChecked = Array.isArray(data);

            const ids: string[] = !isChecked ? [data.id] : data;

            const isMultiple = ids.length > 1;

            openDialog({
                title: isMultiple ? "Удаление ачивок" : "Удаление ачивки",
                description: isMultiple
                    ? `Выбрано ачивок: ${ids.length}`
                    : "«" + dataQuery.data!.Content.find((i) => i.id === ids[0])!.name + "»",
                content: isMultiple
                    ? "Вы действительно хотите удалить выделенные ачивки? Они пропадут у всех участников, которым были выданы."
                    : "Вы действительно хотите удалить ачивку? Она пропадет у всех участников, которым была выдана.",
                closeBtnText: "Отмена",
                submitBtnText: "Удалить",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: async () => {
                    await onRemove(ids);

                    setRowSelection({});
                    invalidate("achievements");

                    closeDialog();
                },
            });
        },
        [invalidate, closeDialog, openDialog, onRemove, dataQuery.data],
    );

    const columns = React.useMemo<ColumnDef<AchievementResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "Ачивка",
                accessorKey: "name",
                enableResizing: true,
                cell: (info) => {
                    return (
                        <div
                            className="group flex items-center space-x-3 cursor-pointer min-w-[620px]"
                            onClick={() => navigate(`/admin/settings/achievement/${info.row.original.id}`)}
                        >
                            {info.row.original.logoId && (
                                <img
                                    className="rounded-full w-9 h-9 object-cover"
                                    src={Api.Upload.GetLogo(info.row.original.logoId, LogoSize.THUMB_MICRO)}
                                    alt={info.row.original.name}
                                />
                            )}
                            {!info.row.original.logoId && <div className="rounded-full w-9 h-9 bg-gray-blue" />}
                            <span className="group-hover:text-blue">{String(info.getValue())}</span>
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Выдача",
                accessorKey: "enabled",
                enableResizing: true,
                cell: (info) => {
                    return (
                        <div className="sm:min-w-20 2xl:min-w-25">
                            {info.row.original.enabled ? "Разрешена" : "Запрещена"}
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Баллы",
                accessorKey: "rating",
                enableResizing: true,
                cell: (info) => {
                    return <div className="sm:min-w-15 2xl:min-w-20">{info.row.original.rating}</div>;
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Выдано",
                accessorKey: "overallQuantity",
                enableResizing: true,
                cell: (info) => {
                    return <div className="sm:min-w-15 2xl:min-w-20">{info.row.original.overallQuantity}</div>;
                },
                footer: (props) => props.column.id,
            },
            {
                header: "Лимит",
                accessorKey: "maxQuantity",
                enableResizing: true,
                cell: (info) => {
                    return (
                        <div className="sm:min-w-15 2xl:min-w-20">
                            {info.getValue() === "-1" ? "ꝏ" : String(info.getValue())}
                        </div>
                    );
                },
                footer: (props) => props.column.id,
            },
            {
                id: "buttons",
                header: "",
                accessor: "[row identifier to be passed to button]",
                enableResizing: true,
                size: 80,
                cell: (info) => {
                    return (
                        <div className="flex min-w-20" id={"achievementsTableGroupButton" + info.row.id}>
                            <Button
                                shape="round"
                                color="common"
                                icon={
                                    <Icon
                                        icon={Icons.Pencil}
                                        width="18px"
                                        height="18px"
                                        color="fill-blue-drk hover:fill-blue-hover"
                                    />
                                }
                                iconPlacement="center"
                                onClick={() => navigate(`/admin/settings/achievement/${info.row.original.id}/edit`)}
                            />
                            <AchievementsRowActionPopover onRemove={() => onRemoveHandler(info.row.original)} />
                        </div>
                    );
                },
            },
        ],
        [navigate, onRemoveHandler],
    );
    const filtersConfig = [
        {
            label: "Бонусные баллы",
            fields: [
                {
                    accessor: "rating",
                    type: "range",
                    min: ratingInfo?.minRating,
                    max: ratingInfo?.maxRating,
                },
            ],
        },
        {
            label: "Ачивок выдано",
            fields: [
                {
                    accessor: "overallQuantity",
                    type: "range",
                    min: ratingInfo?.minOverallQuantity,
                    max: ratingInfo?.maxOverallQuantity,
                },
            ],
        },
        {
            label: "Лимит выдачи на одного пользователя",
            fields: [
                {
                    accessor: "maxQuantity",
                    type: "range",
                    min: ratingInfo?.minLimitQuantity === -1 ? undefined : ratingInfo?.minLimitQuantity,
                    max: ratingInfo?.maxLimitQuantity === -1 ? undefined : ratingInfo?.maxLimitQuantity,
                },
            ],
        },
        {
            label: "Дополнительно",
            fields: [
                {
                    accessor: "userProfileAssignmentDisabled.equal",
                    label: "Запрещено выдавать в профиле пользователя",
                    type: "checkbox",
                    default: false,
                },
                {
                    accessor: "noQuantityLimit.equal",
                    label: "Без лимита",
                    type: "checkbox",
                    default: false,
                },
            ],
        },
    ];

    const controlButtons = (
        <div className="flex justify-end items-center space-x-4">
            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement="left"
                onClick={() => setIsFilters(true)}
                id="adminSettingsBtnFilter"
            >
                Фильтры
            </Button>
            <Button
                size="medium"
                className="rounded-lg whitespace-nowrap font-medium"
                icon={<Icon icon={Icons.PlusFilled} color="fill-white" width={20} height={20} />}
                iconPlacement="left"
                onClick={() => navigate("/admin/settings/achievement")}
                id="adminSettingsBtnCreateAchieve"
            >
                Создать ачивку
            </Button>
        </div>
    );
    const selectButtons = (
        <Button
            color="danger"
            onClick={() => onRemoveHandler(Object.keys(rowSelection))}
            id="adminSettingsBtnDeleteAchieve"
        >
            Удалить
        </Button>
    );

    return (
        <div className="h-full">
            <Confirmation {...dialogState} />
            <Filter
                isActive={isFilters}
                setIsActive={setIsFilters}
                configuration={filtersConfig}
                filters={filters}
                onChange={setFilters}
            />
            {dataQuery.data?.Content.length === 0 && isFiltersEmpty && (
                <div className="h-full flex items-center">
                    <Empty
                        title="Ачивки не созданы"
                        description={
                            <span>
                                Создавайте ачивки и награждайте пользователей системы
                                <br />
                                за их достижения
                            </span>
                        }
                    >
                        <Button
                            className="rounded-lg whitespace-nowrap font-medium"
                            size="medium"
                            icon={
                                <Icon
                                    className="mr-1.5"
                                    icon={Icons.PlusFilled}
                                    color="fill-white"
                                    width={17}
                                    height={17}
                                />
                            }
                            iconPlacement="left"
                            onClick={() => navigate("/admin/settings/achievement")}
                            id="adminSettingsBtnCreateAchieve"
                        >
                            Создать ачивку
                        </Button>
                    </Empty>
                </div>
            )}
            {(!isFiltersEmpty || dataQuery.data?.Content.length) && (
                <Table
                    columns={columns}
                    controlButtons={controlButtons}
                    columnFilters={columnFilters}
                    pageCount={dataQuery.data?.TotalPages}
                    pagination={pagination}
                    emptyMessage="По заданным параметрам результатов нет"
                    searchTitle="Поиск по названию"
                    isFetching={dataQuery.isFetching}
                    data={dataQuery.data?.Content}
                    rowSelection={rowSelection}
                    onPaginationChange={setPagination}
                    onRowSelectionChange={setRowSelection}
                    onColumnFiltersChange={setColumnFilters}
                    selectButtons={selectButtons}
                    sorting={sorting}
                    onSortingChange={setSorting}
                    id="settingsAchievementsAchieveTab"
                />
            )}
        </div>
    );
};
