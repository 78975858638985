import React from "react";
import { SideMenu } from "Uikit";
import { Courses as StatisticsCourses } from "./Group/Courses/Courses";
import { Materials as StatisticsMaterials } from "./Group/Materials/Materials";
import { Tests as StatisticsTests } from "./Group/Tests/Tests";
import { Tasks as StatisticsTasks } from "./Group/Tasks/Tasks";
import { Programs as StatisticsPrograms } from "./Group/Programs/Programs";
import { TestTeams as StatisticsTestTeams } from "./Group/Tests/teams/TestTeams";
import { TestAnswers as StatisticsTestAnswers } from "./Group/Tests/answers/TestAnswers";
import { TestUser as StatisticsTestUser } from "./Group/Tests/User/TestUser";
import { TaskUser as StatisticsTaskUser } from "./Group/Tasks/User/TaskUser";
import { CourseUser as StatisticsCourseUser } from "./Group/Courses/User/CourseUser";
import { ProgramUser as StatisticsProgramUser } from "./Group/Programs/User/ProgramUser";
import { UserComplexity as StatisticUserComplexity } from "./Group/Users/User/UserComplexity";
import { Rating as StatisticRating } from "./Group/Users/Rating/Rating";

export const Statistics = () => {
    return (
        <div className="h-full">
            <SideMenu id="statisticsMenu">
                <SideMenu.Group title="Программы" path="" content={""} open>
                    <SideMenu.Item title="Отчет по программам" path="programs" content={<StatisticsPrograms />} />
                    <SideMenu.Item title="Отчет по участнику" path="program-user" content={<StatisticsProgramUser />} />
                </SideMenu.Group>
                <SideMenu.Group title="Курсы" path="" content={""} open>
                    <SideMenu.Item title="Отчет по курсам" path="courses" content={<StatisticsCourses />} />
                    <SideMenu.Item title="Отчет по участнику" path="course-user" content={<StatisticsCourseUser />} />
                </SideMenu.Group>
                <SideMenu.Group title="Материалы" path="" content={""} open>
                    <SideMenu.Item title="Отчет по материалам" path="materials" content={<StatisticsMaterials />} />
                </SideMenu.Group>
                <SideMenu.Group title="Тесты" path="" content={""} open>
                    <SideMenu.Item title="Отчет по тестам" path="tests" content={<StatisticsTests />} />
                    <SideMenu.Item title="Отчет по ответам" path="test-answers" content={<StatisticsTestAnswers />} />
                    <SideMenu.Item title="Отчет по участнику" path="test-user" content={<StatisticsTestUser />} />
                    <SideMenu.Item title="Отчет по командам" path="test-teams" content={<StatisticsTestTeams />} />
                </SideMenu.Group>
                <SideMenu.Group title="Задания" path="" content={""} open>
                    <SideMenu.Item title="Отчет по заданиям" path="tasks" content={<StatisticsTasks />} />
                    <SideMenu.Item title="Отчет по участнику" path="task-user" content={<StatisticsTaskUser />} />
                </SideMenu.Group>
                <SideMenu.Group title="Участники" path="" content={""} open>
                    <SideMenu.Item
                        title="Отчет по участникам"
                        path="user-complexity"
                        content={<StatisticUserComplexity />}
                    />
                    <SideMenu.Item title="Отчет по рейтингу" path="rating" content={<StatisticRating />} />
                </SideMenu.Group>
            </SideMenu>
        </div>
    );
};
