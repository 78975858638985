import { SortingState } from "@tanstack/react-table";
import BaseApi from "../BaseApi";
import { BaseIdsRequest, BaseRequest } from "../BaseRequest";
import {
    AchievementRequest,
    AchievementUserCreateRequest,
    AchievementUserRemoveRequest,
} from "../Requests/AchievementRequest";
import { BasePaginationResponse, BaseResponse } from "../BaseResponse";
import {
    AchievementHistoryResponse,
    AchievementResponse,
    FilterRatingInfo,
    AchievementUserResponse,
    UserAchievementResponse,
} from "../Responses/AchievementResponse";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class AchievementApi extends BaseApi {
    List = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<AchievementResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size });
        return this.Get(new BasePaginationResponse(AchievementResponse), `/achievements?${params.toString()}`);
    };

    History = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<AchievementHistoryResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size }, false);
        return this.Get(
            new BasePaginationResponse(AchievementHistoryResponse),
            `/achievements/history?${params.toString()}`,
        );
    };

    Read = async (id: string): Promise<AchievementResponse> => {
        return await this.Get(AchievementResponse, `/achievements/${id}`);
    };

    Create = async (data: AchievementRequest): Promise<AchievementResponse> => {
        return await this.Post(AchievementResponse, "/achievements", data);
    };

    Edit = async (data: AchievementRequest): Promise<AchievementResponse> => {
        return await this.Put(AchievementResponse, "/achievements", data);
    };

    Validation = async (achieveTitle: string): Promise<string> => {
        return await this.Post("", "/achievements/validation", Object.setPrototypeOf({ achieveTitle }, BaseRequest));
    };

    Remove = async (data: BaseIdsRequest): Promise<BaseResponse> => {
        return await this.Delete(BaseResponse, "/achievements", data);
    };

    Users = (
        id: string,
        page: number,
        size: number,
        sort: SortingState,
    ): Promise<BasePaginationResponse<AchievementUserResponse>> => {
        const params = LMSFilterParams({ sort, page, size });
        return this.Get(
            new BasePaginationResponse(AchievementUserResponse),
            `/achievements/achievement/${id}?${params.toString()}`,
        );
    };

    UserList = (
        id: string,
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<UserAchievementResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size }, false);
        return this.Get(
            new BasePaginationResponse(UserAchievementResponse),
            `/achievements/users/${id}?${params.toString()}`,
        );
    };

    UserCreate = async (data: AchievementUserCreateRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, "/achievements/users/add", data);
    };

    UserRemove = async (data: AchievementUserRemoveRequest): Promise<BaseResponse> => {
        return await this.Delete(BaseResponse, "/achievements/users/delete", data);
    };

    FilterRatingInfo = async (): Promise<FilterRatingInfo> => {
        return await this.Get(FilterRatingInfo, "/achievements/filter-info");
    };
}
