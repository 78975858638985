import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TProgramCategory } from "types/Program";
import { ResourceType } from "Enums";
import { CategoryModal } from "Uikit/CategoryModalNew/CategoryModal";
import { Breadcrumbs } from "Uikit";
import { PromptModal } from "Uikit/Modal/PromptModal";
import { ContentLayout, TreeWrapperContext } from "Containers";
import { ProgramListRequest } from "Api/Requests/ProgramRequest";
import { useInvalidate } from "hooks/useInvalidate";
import Api from "Api";
import { ProgramCategoryResponse } from "Api/Responses/ProgramResponse";
import { ProgramsEmpty } from "./ProgramsEmpty";
import { ProgramsTable } from "./ProgramsTable";

export const urlPrograms = "/admin/programs";
export const urlProgram = "/admin/program";

const NEW_CATEGORY = {};

export const Programs = () => {
    const navigate = useNavigate();
    const invalidate = useInvalidate();
    const { setTreeProps } = useContext(TreeWrapperContext);

    const [isFetched, setIsFetched] = useState<boolean>(false);
    const [category, setCategory] = useState<Partial<TProgramCategory> | undefined>(undefined);
    const [categories, setCategories] = useState<ProgramCategoryResponse[]>([]);

    const [editingCategory, setEditingCategory] = useState<Partial<ProgramCategoryResponse> | undefined>(undefined);
    const [deletingCategory, setDeletingCategory] = useState<ProgramCategoryResponse | undefined>(undefined);

    const { id } = useParams();
    const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

    const handleCloseCategoryModal = () => {
        setEditingCategory(undefined);
    };

    const onOrderChange = async (nodes: any) => {
        try {
            await Api.Program.CategoryReorder({ categoryIds: nodes.map((item: any) => item.id) });

            const categories = await Api.Program.CategoryGet();
            setCategories(categories.Content);

            navigate(urlPrograms);
        } catch (error) {
            console.log(error);
        }
    };

    const onDeleteCategory = async () => {
        if (!deletingCategory) {
            return;
        }

        await Api.Program.CategoryDelete(deletingCategory.id);
        setDeletingCategory(undefined);

        const categories = await Api.Program.CategoryGet();
        setCategories(categories.Content);
        invalidate("programs");

        navigate(urlPrograms);
    };
    const onCreateCategoryHandler = () => {
        setEditingCategory(NEW_CATEGORY);
    };

    const onCategorySubmit = async (title: string) => {
        const result = { error: false };
        try {
            let categoryResponse;

            if (!editingCategory?.id) {
                categoryResponse = await Api.Program.CategoryCreate({ title });
            } else {
                categoryResponse = await Api.Program.CategoryEdit({ id: editingCategory.id, title });
            }

            const categories = await Api.Program.CategoryGet();
            setCategories(categories.Content);

            navigate(`${urlPrograms}/${categoryResponse.id}`);
            setEditingCategory(undefined);
        } catch (error: any) {
            result.error = true;
            console.log(error);
        }
        return result;
    };

    useEffect(() => {
        if (!setTreeProps) {
            return;
        }

        const treeProps = {
            nodeId: id,
            data: categories.map(({ id, title }) => {
                return {
                    id: id,
                    name: title,
                    nodeType: "PROJECT",
                    children: [],
                };
            }),
            searchable: true,
            editable: true,
            selectable: true,
            browseAll: {
                link: urlPrograms,
                title: "Все программы",
                onAddClick: () => navigate(urlProgram),
            },
            onNodeAddClick: (event: any, nodeId: string) => {
                event.stopPropagation();
                navigate(`${urlProgram}?categoryId=${nodeId}`);
            },
            onEditClick: (title: string, id: string) => setEditingCategory({ id, title }),
            onDeleteClick: (title: string, id: string) => {
                setDeletingCategory({ id, title });
            },
            onSelectNode: (node: any) => navigate(`${urlPrograms}/${node.id}`),
            getNodeLink: (id: any) => `${urlPrograms}/${id}`,
            onOrderChange: onOrderChange,
            onCheckedChange: (nodesIds: any) => setSelectedCategories(nodesIds),
            footer: {
                title: "Создать категорию",
                action: () => setEditingCategory({}),
            },
            id: "adminProgramsSideBar",
        };

        setTreeProps({
            ...treeProps,
            ...{
                selectedNode: category && id ? category : undefined,
            },
        });
        setIsFetched(true);

        return () => {
            setTreeProps?.({ data: null });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, invalidate, id, categories, setTreeProps, category]);

    useEffect(() => {
        if (id === undefined) {
            setCategory(undefined);
        } else {
            const category = categories.find((p) => p.id === id);

            if (category === undefined) {
                return;
            }

            setCategory(category);
        }
    }, [navigate, id, categories]);

    useEffect(() => {
        const fetch = async () => {
            const categories = await Api.Program.CategoryGet();
            setCategories(categories.Content);
        };
        fetch().then();
    }, []);

    const fetchPrograms = async (props: ProgramListRequest) => {
        let filters = props.filters as { [id: string]: string };
        filters = Object.keys(filters).reduce((acc: any, key) => {
            if (filters[key]) {
                acc[key] = filters[key];
            }

            return acc;
        }, {});

        props.filters = filters;

        return await Api.Program.List(props);
    };
    const onCopy = async (id: string) => navigate(`${urlProgram}/${id}/copy`);
    const onState = async (ids: string[], state: string, resourceType: ResourceType) => {
        await Api.Program.State(ids, state, resourceType);
    };

    return (
        <ContentLayout className="h-full">
            <CategoryModal
                isOpen={!!editingCategory}
                onClose={handleCloseCategoryModal}
                categoryId={editingCategory?.id}
                categoryName={editingCategory?.title}
                onSubmit={onCategorySubmit}
            />
            <PromptModal
                onConfirm={onDeleteCategory}
                onClose={() => {
                    setDeletingCategory(undefined);
                }}
                isOpen={!!deletingCategory}
                title="Удаление категории"
                dialogText={["Отмена", "Удалить"]}
            >
                <div className="space-y-3.5">
                    <div className="text-gray-text">&quot;{deletingCategory?.title}&quot;</div>
                    <div>Вы действительно хотите удалить категорию? Все вложенные в нее элементы попадут в Архив</div>
                </div>
            </PromptModal>
            <Breadcrumbs className="mb-2.5">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Программы обучения" url="/admin/programs" />
                {category && <Breadcrumbs.Link title={category?.title || ""} url={"/admin/programs/" + category.id} />}
            </Breadcrumbs>
            {isFetched && !categories.length && (
                <div className="h-full flex justify-center">
                    <ProgramsEmpty
                        isEmptyCategories={id === undefined}
                        onCreateCategoryClick={onCreateCategoryHandler}
                        onCreateProgramClick={() => navigate(urlProgram)}
                    />
                </div>
            )}
            {isFetched && categories.length > 0 && (
                <ProgramsTable
                    onCopy={onCopy}
                    onState={onState}
                    fetchPrograms={fetchPrograms}
                    selectedCategories={selectedCategories}
                    categoryId={id}
                    onCreateCategory={onCreateCategoryHandler}
                />
            )}
        </ContentLayout>
    );
};
