import React from "react";
import "react-day-picker/dist/style.css";
import { Icon, Icons, Tooltip } from "Uikit";
import { DateTimeSelector } from "Uikit/DateTimeSelector/DateTimeSelector";
import { numCapEnd } from "helpers/numCapEnd";
import dayjs from "dayjs";

interface InfoCardRootDateTimeInputProps {
    value: number;
    onChange: (value: number) => void;
    id: string;
    tooltip?: string;
}

const today = new Date();

export const InfoCardRootDateTimeInput = ({
    id,
    value,
    onChange,
    tooltip = "Доступно",
}: InfoCardRootDateTimeInputProps) => {
    const todayJs = dayjs(today);
    const capDayJs = dayjs(value * 1000);
    const daysLeft = todayJs.isBefore(capDayJs) ? Math.round(capDayJs.diff(todayJs, "days", true)) : 0;

    return (
        <div id={id} className="inline-flex gap-2 items-center">
            <div className="w-28 2xl:w-36">
                <DateTimeSelector
                    isPastTime={false}
                    dateTime={value}
                    onChange={(dateTime) => onChange(dateTime)}
                    showTimeInput={false}
                />
            </div>
            <div className="inline-flex gap-1">
                <span className="text-primary">
                    ({`${daysLeft}`} {numCapEnd({ one: "день", two: "дня", few: "дней" }, daysLeft)})
                </span>
                <Tooltip placement="top" content={tooltip}>
                    <Icon
                        icon={Icons.Question}
                        color="fill-gray-light"
                        width={14}
                        height={14}
                        className="cursor-pointer"
                    />
                </Tooltip>
            </div>
        </div>
    );
};
