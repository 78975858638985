import React, { useEffect, useState } from "react";
import { TeamMultiSelect } from "Components/Common/TeamMultiSelect";
import { Button, ComboBox, FormGroup, Label } from "Uikit";
import { getReportPeriodStart, periodOptions, ReportPeriod } from "./RatingComplexity";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { numWord } from "helpers/numWord";
import Api from "Api";
import { StatisticRatingRequest } from "Api/Requests/StatisticRequest";
import { ReportRatingTable } from "./ReportRatingTable";

const reportSettings = [
    {
        title: "Участник",
        key: "user",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "ФИО",
                value: "name",
                checked: true,
            },
            {
                title: "Логин",
                value: "login",
                checked: true,
            },
        ],
    },
    {
        title: "Ачивки",
        key: "achievement",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Всего получено",
                value: "total",
                checked: true,
            },
            {
                title: "Положительных",
                value: "positive",
                checked: true,
            },
            {
                title: "Отрицательных",
                value: "negative",
                checked: true,
            },
        ],
    },
    {
        title: "Рейтинг",
        key: "rating",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Всего баллов",
                value: "totalRating",
                checked: true,
            },
            {
                title: "Баллы за программы",
                value: "programRating",
                checked: true,
            },
            {
                title: "Баллы за курсы",
                value: "courseRating",
                checked: true,
            },
            {
                title: "Баллы за тесты",
                value: "quizRating",
                checked: true,
            },
            {
                title: "Баллы за задания",
                value: "exerciseRating",
                checked: true,
            },
            {
                title: "Баллы за ачивки",
                value: "achievementRating",
                checked: true,
            },
            {
                title: "Место в команде",
                value: "teamRank",
                checked: true,
            },
        ],
    },
];

export const RatingTeam = () => {
    const [selectedTeamsIds, setSelectedTeamsIds] = useState<string[]>([]);
    const [showReportsTable, setShowReportsTable] = useState(false);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [period, setPeriod] = useState<{ value: ReportPeriod; label: string }>(periodOptions[2]);
    const [usersCount, setUsersCount] = useState(0);

    useEffect(() => {
        if (!selectedTeamsIds[0]) {
            return;
        }

        const fetchTeamInfo = async () => {
            const teamInfo = await Api.Teams.GetTeamShortInfo(selectedTeamsIds[0]);
            setUsersCount(teamInfo.usersCount);
        };

        fetchTeamInfo();
    }, [selectedTeamsIds]);

    const createReport = () => {
        setShowReportsTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticRatingRequest = {
            teamIds: selectedTeamsIds,
        };

        if (period.value !== ReportPeriod.ALL) {
            payload.fromDate = getReportPeriodStart(period.value);
        }

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateRatingStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <>
            <FormGroup className="!mb-0">
                <Label>Команда</Label>
                <TeamMultiSelect
                    value={selectedTeamsIds}
                    onChange={(v) => {
                        setSelectedTeamsIds(v.map((teamId) => teamId.replace("root:", "")));
                        setShowReportsTable(false);
                    }}
                    checkable={false}
                    selectable={true}
                    isMulti={false}
                    showTeamsCounter={false}
                />
            </FormGroup>
            {selectedTeamsIds.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            <FormGroup className="!mb-0">
                                <Label>Период отчета</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setPeriod(value as { value: ReportPeriod; label: string });
                                        setShowReportsTable(false);
                                    }}
                                    value={period}
                                    options={periodOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="mt-9">
                        {showReportsTable ? (
                            <>
                                <ReportRatingTable
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                    queryPayload={createPayloadParams()}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                            >
                                {`Сформировать отчет по ${usersCount} ${numWord(usersCount, [
                                    "участнику",
                                    "участникам",
                                    "участникам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
