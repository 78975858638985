import React, { useCallback, useEffect, useRef, useState } from "react";
import { useBlocker, useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Unsubscribe } from "nanoevents";
import omit from "lodash/omit";
import { Formik, FormikHelpers } from "formik";
import { array, boolean, object, string } from "yup";
import { useMutation, useQueryClient } from "react-query";
import { Breadcrumbs, Button, Content, flash, Icon, Icons, Tabs, TabsWrapper, Toggle } from "Uikit";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { AccessDummy as Access, AccessRef } from "Components/Access/AccessDummy";
import { ContentLayout } from "Containers";
import { TaskSettings } from "./TaskSettings";
import { TaskContent } from "./TaskContent";
import Api from "Api";
import { BadRequestResponse, ErrorCode } from "Api/BaseResponse";
import { TaskReadResponse, TaskReadResponseItem } from "Api/Responses/TaskResponse";
import { TaskCategoryTreeResponse } from "Api/Responses/TaskCategoryResponse";
import { AccountableUser, CurrentUserResponse } from "Api/Responses/UserResponse";
import { TeamAccessRequest } from "Api/Requests/AccessRequest";
import { TaskCreateRequest, TaskEditRequest } from "Api/Requests/TaskRequest";
import { Complexity, FileUploadType, ResourceState, ResourceType, UIErrorMessages } from "Enums";
import { useFormEmitter } from "hooks/useFormEmitter";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import { CancelModal } from "Components/CancelModal/CancelModal";
import { formatStatusChangeTime } from "helpers/dateHelper";
import { TVoidFunction } from "types";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

interface IRatingOption {
    label: Complexity;
    value: number | string;
}

const urlTasks = "/admin/tasks";

interface ITaskProps {
    isEdit?: boolean;
    isCopy?: boolean;
}

export const Task = ({ isEdit, isCopy }: ITaskProps) => {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const location = useLocation();
    const formEmitter = useFormEmitter();
    const formRef = useRef();
    const taskContentRef = useRef();
    const { id } = useParams();
    const initTaskValues = {
        id: "",
        title: "",
        description: "",
        complexity: Complexity.MEDIUM,
        category: {
            id: "",
            title: "",
        },
        publicationTime: undefined,
        archivingTime: undefined,
        deadlineTime: undefined,
        passingLimit: 1,
        geoLocationRequired: false,
        mandatory: false,
        exerciseId: "",
        userId: "",
        state: ResourceState.ACTIVE,
        publicAccess: false,
        textContent: [],
        numericContent: [],
        imageContent: [],
        fileContent: [],
        singleChoiceContent: [],
        multipleChoiceContent: [],
        logoId: "",
        accountableUser: {
            id: "",
            login: "",
            avatarId: "",
            firstName: "",
            lastName: "",
            middleName: "",
            email: "",
            jobTitle: null,
            rating: 0,
        },
    };
    const [task, setTask] = useState<TaskReadResponse>(initTaskValues);
    const [initialTask, setInitialTask] = useState<TaskReadResponse>(initTaskValues);
    const fetchedTaskRef = useRef<TaskReadResponse | null>(null);
    const [initTitle, setInitTitle] = useState("Новое задание");
    const [initCategoryId, setInitCategoryId] = useState("");
    const [isFormDirty, setIsFormDirty] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams(location.search);
    const [cover, setCover] = useState<File | null>(null);
    const [contentErrors, setContentErrors] = useState(false);
    const [categories, setCategories] = useState<TaskCategoryTreeResponse[]>([]);
    const [rating, setRating] = useState<IRatingOption[]>([]);
    const [isInit, setIsInit] = useState(false);
    const [defaultLogos, setDefaultLogos] = useState<any>([]);
    const [isAccessFormDirty, setIsAccessFormDirty] = useState(false);
    const [isAccessTabActive, setIsAccessTabActive] = useState(false);
    const [accessError, setAccessError] = useState<Partial<string>>();
    const [teamIds, setTeamIds] = useState<any[]>([]);
    const [allTeamsAccess, setAllTeamsAccess] = useState(false);
    const invalidate = useInvalidate();
    const [currentTab, setCurrentTab] = useState(0);
    const [isPassingLimitEnabled, setIsPassingLimitEnabled] = useState(!!initTaskValues.passingLimit);
    const [isContentChanged, setIsContentChanged] = useState(false);

    const blocker = useBlocker((params) => {
        if ((isFormDirty || isAccessFormDirty) && params?.historyAction !== "REPLACE") {
            setIsCancel(true);
        } else if (params?.historyAction === "REPLACE") {
            return false;
        }

        return isFormDirty || isAccessFormDirty;
    });

    const [isCancel, setIsCancel] = useState<boolean>(false);
    const [isEditCancel, setIsEditCancel] = useState<boolean>(false);
    const accessRef = useRef<AccessRef>();

    const responsibleList = useResponsibleList(task.accountableUser.id);

    const tabs = ["settings", "content", "access"];

    const { dialogState, openDialog, closeDialog } = useDialog();

    const fetchTask = async (setFetchedData = true) => {
        if (!id) {
            return;
        }
        const fetchedTask = await Api.Task.Read({ uuid: id });
        fetchedTaskRef.current = fetchedTask;
        Object.freeze(fetchedTask);
        Object.freeze(fetchedTaskRef.current);

        if (setFetchedData) {
            setTask((prevState) => ({
                ...prevState,
                ...fetchedTask,
                state: isCopy ? ResourceState.HIDDEN : fetchedTask.state,
                title: isCopy ? "Копия 1 " + fetchedTask.title : fetchedTask.title,
            }));
            setInitialTask((prevState) => {
                return Object.freeze({
                    ...prevState,
                    ...fetchedTask,
                    state: isCopy ? ResourceState.HIDDEN : fetchedTask.state,
                    title: isCopy ? "Копия 1 " + fetchedTask.title : fetchedTask.title,
                });
            });
            setInitTitle(isCopy ? "Копия 1 " + fetchedTask.title : fetchedTask.title);
            setInitCategoryId(fetchedTask.category.id);
            setIsPassingLimitEnabled(!!fetchedTask.passingLimit);
        }

        return fetchedTask;
    };

    const initComponentData = useCallback(async () => {
        if (isInit) return;

        try {
            await fetchTask();

            const categoriesRes = await Api.TaskCategoryApi.List();

            setCategories(
                categoriesRes.Content.map((i) => ({
                    title: i.title,
                    id: i.id,
                })),
            );

            // rating
            const ratingRes = await Api.Rating.ReadByType(ResourceType.EXERCISE);

            setRating([
                {
                    label: Complexity.NONE,
                    value: 0,
                },
                {
                    label: Complexity.LOW,
                    value: ratingRes.EASY,
                },
                {
                    label: Complexity.MEDIUM,
                    value: ratingRes.MEDIUM,
                },
                {
                    label: Complexity.HIGH,
                    value: ratingRes.HARD,
                },
            ]);

            // logos
            const defaultLogosRes = await Api.Upload.GetDefaultLogos();

            setDefaultLogos(defaultLogosRes.Content);
            if (!id) {
                const currentUser: CurrentUserResponse | undefined = queryClient.getQueryData(["users", "current"]);

                setInitialTask((prev) => ({ ...prev, accountableUser: currentUser as AccountableUser }));
                setTask((prevState) => ({
                    ...prevState,
                    logoId: defaultLogosRes.Content[Math.floor(Math.random() * defaultLogosRes.Content.length)],
                    accountableUser: currentUser as AccountableUser,
                }));
            }
        } catch (e) {
            console.log(e);
        } finally {
            setIsInit(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, isInit, searchParams]);

    useEffect(() => {
        initComponentData();
    }, [initComponentData]);

    useEffect(() => {
        if (categories.length && !task.category.id) {
            const categoryId = searchParams.get("categoryId");
            if (categoryId) {
                const category = categories[categories.findIndex((p) => categoryId === p.id)];
                setInitialTask((prev) => ({ ...prev, category: { id: category.id, title: category.title } }));
                setTask((prevState) => ({
                    ...prevState,
                    category: { id: category.id, title: category.title },
                }));
            }
        }
    }, [categories, searchParams, task.category.id]);

    useEffect(() => {
        setIsFormDirty(!!isEdit || !!isCopy);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const requiredError = "Поле обязательно для заполнения";
    const invalidFieldsError = "Ошибка, не все поля заполнены правильно";
    const requiredMinOneQuestionError = "В задании должен быть хотя бы один вопрос";

    const handleOnChange = (task: TaskReadResponse, watchDirty = true) => {
        setTask({ ...task });
        if (watchDirty) {
            setIsFormDirty(true);
        }
    };

    const onChangeCover = (data: File | null) => {
        setCover(data);
        handleOnChange({ ...task });
    };

    useEffect(() => {
        const uploadCover = async () => {
            try {
                if (cover) {
                    const uploadFileResponse = await Api.File.UploadFile(
                        cover,
                        undefined,
                        undefined,
                        false,
                        FileUploadType.RESOURCE_LOGO,
                    );
                    handleOnChange({
                        ...task,
                        logoId: uploadFileResponse.id,
                    });
                }
            } catch (e: unknown) {
                const knownError = e as BadRequestResponse;
                let message = "Размер обложки слишком большой!";
                if (
                    [ErrorCode.CORRUPT_FILE_ERROR, ErrorCode.FILE_EXTENSION_ERROR].includes(
                        String(knownError.errorCode) as ErrorCode,
                    )
                ) {
                    message = UIErrorMessages.FILE_LOADING_ERROR;
                }
                flash.error(message);
                return;
            }
        };
        uploadCover().then();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cover]);

    const { mutateAsync: setTeamAccess } = useMutation((payload: TeamAccessRequest) => {
        return Api.LMSRoles.setTeamAccess(payload);
    });

    const resetTaskContentItems = (fetchedTask: TaskReadResponse) => {
        if (taskContentRef.current) {
            (
                taskContentRef.current as unknown as {
                    resetAllTaskItems: (taskItems: TaskReadResponseItem[]) => void;
                }
            ).resetAllTaskItems([]);
            (
                taskContentRef.current as unknown as {
                    resetAllTaskItems: (taskItems: TaskReadResponseItem[]) => void;
                }
            ).resetAllTaskItems(
                [
                    ...(fetchedTask?.textContent ?? []),
                    ...(fetchedTask?.numericContent ?? []),
                    ...(fetchedTask?.imageContent ?? []),
                    ...(fetchedTask?.fileContent ?? []),
                    ...(fetchedTask?.singleChoiceContent ?? []),
                    ...(fetchedTask?.multipleChoiceContent ?? []),
                ].sort((a, b) => a.orderNumber - b.orderNumber),
            );
        }
    };

    const handleOnSubmit = async (formikHelpers: FormikHelpers<TaskReadResponse>) => {
        const {
            hasUserAnswersOnReview,
            // title,
            description,
            // complexity,
            // publicationTime,
            // archivingTime,
            // deadlineTime,
            // passingLimit,
            accountableUser,
            // logoId,
            category,
            // textContent,
            // numericContent,
            // imageContent,
            // fileContent,
            // singleChoiceContent,
            // multipleChoiceContent,
        } = task;
        const { id: accountableUserId } = accountableUser;

        setIsFormDirty(false);
        if (taskContentRef?.current) {
            (taskContentRef.current as { collapseTaskItem: TVoidFunction }).collapseTaskItem();
        }

        // Проверка на копию
        if (id && !isCopy) {
            // Проверка на наличие ответов на проверке - в таком случае сбрасываются значения в "Настройки" и "Содержимое"
            if (hasUserAnswersOnReview) {
                const fetchedTask = await fetchTask(false);
                // не в MVP
                // task.geoLocationRequired = fetchedTask?.geoLocationRequired ?? task.geoLocationRequired;

                task.textContent = fetchedTask?.textContent ?? [];
                task.numericContent = fetchedTask?.numericContent ?? [];
                task.imageContent = fetchedTask?.imageContent ?? [];
                task.fileContent = fetchedTask?.fileContent ?? [];
                task.singleChoiceContent = fetchedTask?.singleChoiceContent ?? [];
                task.multipleChoiceContent = fetchedTask?.multipleChoiceContent ?? [];

                resetTaskContentItems(task);
            }

            const request = Object.assign(new TaskEditRequest(id), omit(task, "accountableUser"));
            request.accountableUserId = accountableUserId;
            request.categoryId = category?.id;
            request.description = description;
            request.id = id;
            if (!isPassingLimitEnabled) {
                request.passingLimit = null;
            }

            try {
                const response: TaskReadResponse = await Api.Task.Edit(request);
                setTask({ ...task, lastModified: response.lastModified });

                searchParams.delete("tab");
                searchParams.append("tab", tabs[currentTab]);
                setSearchParams(searchParams);
            } catch (e: any) {
                if (e.errorCode === ErrorCode.TITLE_ALREADY_EXISTS) {
                    formikHelpers.setFieldError("title", "Данное название уже используется");
                    flash.error(invalidFieldsError);
                } else {
                    flash.error("Ошибка, пожалуйста попробуйте позже");
                }

                return;
            }
        } else {
            const request = Object.assign(new TaskCreateRequest(), omit(task, "accountableUser"));
            request.accountableUserId = accountableUserId;
            request.categoryId = category?.id;
            request.description = description;
            if (!isPassingLimitEnabled) {
                request.passingLimit = null;
            }

            try {
                const { id } = await Api.Task.Create(request);

                if (isCopy) {
                    setSearchParams(searchParams);
                }

                navigate(`/admin/task/${id}?tab=${tabs[currentTab]}`, { replace: true });
            } catch (e: any) {
                if (e.errorCode === ErrorCode.TITLE_ALREADY_EXISTS) {
                    formikHelpers.setFieldError("title", "Данное название уже используется");
                    flash.error(invalidFieldsError);
                } else {
                    flash.error("Ошибка, пожалуйста попробуйте позже");
                }

                return;
            }
        }

        setInitTitle(task.title);
        setInitCategoryId(task.category.id);
        setInitialTask(task);

        if (
            task.hasUserAnswersOnReview &&
            currentTab !== 2 &&
            isContentChanged
            // (currentTab === 1 || (currentTab === 0 && settingsHasChanged && contentHasChanged))
        ) {
            flash.error(
                <span>
                    Изменения на вкладке «Содержимое» не сохранены, так как задание находится на{" "}
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`/admin?tab=tasks&task=${task.id}`}
                        className="text-white font-bold"
                    >
                        проверке
                    </a>
                </span>,
            );
            return;
        }

        if (!task.hasUserAnswersOnReview || (task.hasUserAnswersOnReview && currentTab === 0) || currentTab === 2) {
            flash.success("Все изменения сохранены!");
        }
    };

    const onSubmit = async (_values: TaskReadResponse, formikHelpers: FormikHelpers<TaskReadResponse>) => {
        if (contentErrors) {
            return;
        }

        const accessError = accessRef.current?.validateAccess();

        setAccessError(accessError);

        if (accessError) {
            flash.error(accessError);
            return;
        }

        if (task.publicationTime && task.archivingTime && task.archivingTime <= task.publicationTime) {
            return false;
        }

        // Сохранение доступов
        if (isAccessFormDirty) {
            await setTeamAccess(
                Object.assign(new TeamAccessRequest(), {
                    resourceId: id,
                    resourceType: ResourceType.EXERCISE,
                    teams: teamIds.filter((id) => !id.startsWith("root:")),
                    allTeams: allTeamsAccess,
                }),
            );
            invalidate("teamAccess", id);
            setIsAccessFormDirty(false);
        }

        task.publicAccess = allTeamsAccess;

        // Сохранение данных задания
        const isTaskActive = task.state === ResourceState.ACTIVE;

        initialTask.state !== task.state
            ? openDialog({
                  title: `Изменить статус на «${isTaskActive ? "Активен" : "Скрыт"}»`,
                  content: isTaskActive
                      ? `Все пользователи, у кого есть доступ, увидят данное задание`
                      : `У всех пользователей пропадет данное задание`,
                  closeBtnText: "Отмена",
                  submitBtnText: "Изменить",
                  submitBtnColor: "primary",
                  onRequestClose: () => closeDialog(),
                  onRequestSubmit: () => {
                      handleOnSubmit(formikHelpers).then(() => {
                          closeDialog();
                      });
                  },
              })
            : await handleOnSubmit(formikHelpers);
    };

    const validationSchema = object({
        title: string().required(requiredError),
        complexity: string().required(requiredError),
        category: object({
            id: string().required(requiredError),
        }),
        accountableUser: object({
            id: string().required(requiredError),
        }).nullable(),
        singleChoiceContent: array().of(
            object().shape({
                mandatory: boolean(),
                question: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
                reviewerNote: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
                answers: array().of(
                    object().shape({
                        answer: string().required(requiredError),
                    }),
                ),
            }),
        ),
        multipleChoiceContent: array().of(
            object().shape({
                mandatory: boolean(),
                question: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
                reviewerNote: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
                answers: array().of(
                    object().shape({
                        answer: string().required(requiredError),
                    }),
                ),
            }),
        ),
        numericContent: array().of(
            object().shape({
                mandatory: boolean(),
                question: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
                reviewerNote: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
            }),
        ),
        textContent: array().of(
            object().shape({
                mandatory: boolean(),
                // question: string().required(requiredError),
                // reviewerNote: string().required(requiredError),
                question: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
                reviewerNote: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
            }),
        ),
        imageContent: array().of(
            object().shape({
                mandatory: boolean(),
                question: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
                reviewerNote: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
            }),
        ),
        fileContent: array().of(
            object().shape({
                mandatory: boolean(),
                question: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
                reviewerNote: string().when("mandatory", {
                    is: true,
                    then: (schema) => schema.required(requiredError),
                    otherwise: (schema) => schema.notRequired(),
                }),
            }),
        ),
    }).test(
        ({
            title,
            complexity,
            category,
            accountableUser,
            textContent,
            numericContent,
            imageContent,
            fileContent,
            singleChoiceContent,
            multipleChoiceContent,
        }) => {
            // В задании должен быть хотя бы один вопрос
            const isRequiredMinOneQuestionError = [
                textContent,
                numericContent,
                imageContent,
                fileContent,
                singleChoiceContent,
                multipleChoiceContent,
            ].every((item) => !item?.length);
            setContentErrors(isRequiredMinOneQuestionError);
            if (isRequiredMinOneQuestionError) {
                flash.error(requiredMinOneQuestionError);
                return false;
            }
            // Хотя бы один вопрос должен быть обязательным
            const isRequiredContentQuestionError = [
                textContent,
                numericContent,
                imageContent,
                fileContent,
                singleChoiceContent,
                multipleChoiceContent,
            ].every((item) => {
                return item?.every((taskItem) => !taskItem?.mandatory);
            });
            if (isRequiredContentQuestionError) {
                flash.error("Хотя бы один вопрос должен быть обязательным");
                setContentErrors(true);
                return false;
            }
            if ([title, complexity, category?.id, accountableUser?.id].some((item) => !item)) {
                flash.error(invalidFieldsError);
                return false;
            }
            if (!isRequiredMinOneQuestionError && !isRequiredContentQuestionError) {
                setContentErrors(false);
            }
            // Незаполненные поля названий вопросов в вопросах
            if (
                [
                    ...(textContent ?? []),
                    ...(numericContent ?? []),
                    ...(imageContent ?? []),
                    ...(fileContent ?? []),
                    ...(singleChoiceContent ?? []),
                    ...(multipleChoiceContent ?? []),
                ]
                    .filter((taskItem) => taskItem?.mandatory)
                    .some((taskItem) => !taskItem?.question)
            ) {
                flash.error(invalidFieldsError);
            }

            return true;
        },
    );

    const addTabUrl = (tabUrl: string) => {
        searchParams.delete("tab");
        searchParams.append("tab", tabUrl);
        window.history.pushState(null, "", `${location.pathname}?${searchParams.toString()}`);
        setCurrentTab(tabs.findIndex((item) => item === tabUrl));
    };

    useEffect(() => {
        if (!searchParams.has("tab")) {
            addTabUrl("settings");
        } else {
            setCurrentTab(tabs.findIndex((item) => item === searchParams.get("tab")));
        }
        if (searchParams.has("categoryId")) {
            setInitCategoryId(searchParams.get("categoryId") as string);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const { current: emitter } = formEmitter;
        let unbind: Unsubscribe;

        if (emitter) {
            unbind = emitter?.on("dirty", (dirty: any) => setIsFormDirty(dirty));
        }

        return () => {
            unbind?.();
        };
    }, [formEmitter]);

    const onCancelChange = () => {
        task.id ? setIsEditCancel(true) : navigate(urlTasks);
    };

    const onCancelModalSubmit = useCallback(async () => {
        setIsFormDirty(false);

        if (isCancel && blocker.state === "blocked") {
            blocker.proceed();
        } else {
            setIsEditCancel(false);
            setIsAccessFormDirty(false);
            const fetchedTask = await fetchTask();
            const initialAccess = await Api.LMSRoles.getTeamAccess(id!);

            if (taskContentRef?.current) {
                resetTaskContentItems(fetchedTask as TaskReadResponse);
            }
            if (formRef?.current) {
                (
                    formRef.current as { resetPublicationToggles: (fetchedTask: TaskReadResponse) => void }
                ).resetPublicationToggles(fetchedTask as TaskReadResponse);
            }
            if (accessRef?.current) {
                (accessRef.current as { resetAccess: (data: any) => void }).resetAccess(initialAccess);
            }
        }
        setAccessError("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [formEmitter, blocker, isCancel, initialTask]);

    useEffect(() => {
        setIsInit(false);
    }, [id]);

    const isAccessEdited = isAccessTabActive && isAccessFormDirty;
    const isAccessEditedTooltip = isAccessEdited ? "Будет доступно после сохранения" : "";
    const isAccessTabDisabled = isFormDirty || (!isFormDirty && !id);

    return (
        <>
            <CancelModal
                id="taskPageCancelModal"
                isEdit={isEditCancel}
                isOpen={isCancel || isEditCancel}
                setIsOpen={isEditCancel ? setIsEditCancel : setIsCancel}
                onSubmit={onCancelModalSubmit}
            />

            <ContentLayout>
                <Breadcrumbs className="mb-2.5" id="adminNewTaskBreadcrumbs">
                    <Breadcrumbs.Link title="Администратор" />
                    <Breadcrumbs.Link title="Задания" url={urlTasks} />
                    {initCategoryId && (
                        <Breadcrumbs.Link
                            title={categories.find((p) => p.id === initCategoryId)?.title ?? ""}
                            url={"/admin/tasks/" + initCategoryId}
                        />
                    )}
                    <Breadcrumbs.Link title={initTitle} />
                </Breadcrumbs>
                <Formik
                    initialValues={initialTask}
                    validationSchema={validationSchema}
                    enableReinitialize
                    onSubmit={onSubmit}
                    validateOnChange={false}
                >
                    {({ handleSubmit, errors }) => {
                        const { title, complexity, category, accountableUser } = errors;

                        return (
                            <>
                                <div className="flex justify-between items-center mb-7.5 gap-4">
                                    <h1 id="adminNewTaskTitle">
                                        <MultiClumpTooltip
                                            label={initTitle}
                                            clamp={1}
                                            textClassName="!leading-8"
                                        ></MultiClumpTooltip>
                                    </h1>
                                    <div className="flex items-center">
                                        <Toggle
                                            className="mr-7.5 font-semibold"
                                            label={task.state === ResourceState.HIDDEN ? "Скрыт" : "Активен"}
                                            enabled={task.state ? task.state === ResourceState.ACTIVE : true}
                                            onChange={(p) => {
                                                const updatedTask = {
                                                    ...task,
                                                    state: p ? ResourceState.ACTIVE : ResourceState.HIDDEN,
                                                };
                                                if (p) {
                                                    updatedTask.publicationTime = undefined;
                                                    updatedTask.archivingTime = undefined;
                                                    updatedTask.deadlineTime = undefined;
                                                }
                                                handleOnChange(updatedTask);
                                            }}
                                            id="adminNewTaskToggleIsActive"
                                        />
                                        {isFormDirty || isAccessFormDirty || !task.id ? (
                                            <>
                                                <Button
                                                    className="mr-4"
                                                    variant="outline"
                                                    color="secondary"
                                                    onClick={onCancelChange}
                                                    id="adminNewTestBtnCancel"
                                                >
                                                    Отменить
                                                </Button>
                                                <Button className="mr-4" onClick={handleSubmit} id="adminNewTaskBtnOk">
                                                    Сохранить
                                                </Button>
                                            </>
                                        ) : (
                                            <Button
                                                className="w-10 h-10 !p-0"
                                                variant="outline"
                                                color="secondary"
                                                onClick={() => navigate(urlTasks)}
                                                id="adminNewTaskBtnCrest"
                                                shape="square"
                                            >
                                                <Icon
                                                    icon={Icons.Close}
                                                    width={24}
                                                    height={24}
                                                    color="fill-[#939393]"
                                                />
                                            </Button>
                                        )}
                                    </div>
                                </div>
                                <TabsWrapper selectedIndex={currentTab} onChange={setCurrentTab}>
                                    <TabsWrapper.Tabs
                                        classname="flex flex-grow justify-between items-center h-max"
                                        label={
                                            task.lastModified
                                                ? "Обновлено " +
                                                  formatStatusChangeTime(
                                                      new Date(Number(task.lastModified)).getTime(),
                                                      {
                                                          showTime: true,
                                                          formatTonight: false,
                                                          showYear: true,
                                                          delimiter: ",",
                                                      },
                                                  )
                                                : ""
                                        }
                                        id="adminNewTaskTabs"
                                    >
                                        <Tabs.Tab
                                            title="Настройка"
                                            error={[
                                                title,
                                                complexity,
                                                category?.id,
                                                accountableUser?.id,
                                                task.publicationTime &&
                                                    task.archivingTime &&
                                                    task.archivingTime <= task.publicationTime,
                                            ].some((error) => !!error)}
                                            tooltip={isAccessEditedTooltip}
                                            disabled={isAccessEdited}
                                            onClick={() => {
                                                addTabUrl("settings");
                                            }}
                                        />
                                        <Tabs.Tab
                                            title="Содержимое"
                                            error={contentErrors}
                                            tooltip={isAccessEditedTooltip}
                                            disabled={isAccessEdited}
                                            onClick={() => {
                                                addTabUrl("content");
                                            }}
                                        />
                                        <Tabs.Tab
                                            title="Доступ"
                                            disabled={isAccessTabDisabled}
                                            error={!!accessError}
                                            tooltip={isAccessTabDisabled ? "Будет доступно после сохранения" : ""}
                                            onClick={() => {
                                                setIsAccessTabActive(true);
                                                addTabUrl("access");
                                            }}
                                        />
                                    </TabsWrapper.Tabs>
                                    <TabsWrapper.Content>
                                        <Content.Body>
                                            <TaskSettings
                                                task={task}
                                                onChange={handleOnChange}
                                                onChangeCover={onChangeCover}
                                                ref={formRef}
                                                categories={categories}
                                                onChangeCategories={(category: any) =>
                                                    setCategories([...categories, category])
                                                }
                                                rating={rating}
                                                responsibles={responsibleList}
                                                defaultLogos={defaultLogos}
                                                isPassingLimitEnabled={isPassingLimitEnabled}
                                                setIsPassingLimitEnabled={(arg) => setIsPassingLimitEnabled(arg)}
                                            />
                                        </Content.Body>
                                        <Content.Body>
                                            <TaskContent
                                                task={task}
                                                onChange={(task: TaskReadResponse, watchDirty?: boolean) => {
                                                    handleOnChange(task, watchDirty);
                                                    setIsContentChanged(true);
                                                }}
                                                ref={taskContentRef}
                                            />
                                        </Content.Body>
                                        <Content.Body>
                                            <Access
                                                resourceId={id}
                                                resourceType={ResourceType.EXERCISE}
                                                setIsAccessFormDirty={setIsAccessFormDirty}
                                                setTeamIds={setTeamIds}
                                                allTeamsAccess={allTeamsAccess}
                                                setAllTeamsAccess={setAllTeamsAccess}
                                                nodeAddComponentLink={`/admin/members?teamId.in=`}
                                                ref={accessRef}
                                            />
                                        </Content.Body>
                                    </TabsWrapper.Content>
                                </TabsWrapper>
                            </>
                        );
                    }}
                </Formik>
            </ContentLayout>
            <Confirmation {...dialogState} />
        </>
    );
};
