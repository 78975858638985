import React, { useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Button, Icon, Icons, SelectMultiValueRemove, SelectValueContainer } from "Uikit";
import { Empty } from "Uikit/Page/Empty";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { Search } from "./Tests";
import { TestsCardList } from "./TestsCardList";
import { TestsCardListMobile } from "./TestsCardListMobile";
import { InfiniteList } from "Components/InfiniteList/InfiniteList";
import { TestUserListResponse } from "Api/Responses/TestResponse";
import { testsKeys, useChangeTestFavoriteStatus, useResultTests } from "./tests.hooks";
import { Preloader } from "Components/Preloader/Preloader";
import { useScreenSize } from "hooks/useMediaQuery";
import { SelectAvatarMultiValueLabel } from "../Tasks/TasksResults";
import Api from "Api";

export const TestsResults = () => {
    const [search, setSearch] = useState<string>("");
    const sorting = [{ id: "lastModified", desc: true }];

    const [isFilterShow, setIsFilterShow] = useState<boolean>(false);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});

    const [categories, setCategories] = useState<{ label: string; value: string }[]>([]);

    const parsedFiltersData = useMemo(() => {
        const filtersKeys = Object.keys(filters);
        const filtersData: any = {};

        for (const element of filtersKeys) {
            if (element === "ratingPoints") {
                if (filters[element]["minValue"]) {
                    filtersData[element + ".greaterThanOrEqual"] = filters[element]["minValue"];
                }
                if (filters[element]["maxValue"]) {
                    filtersData[element + ".lessThanOrEqual"] = filters[element]["maxValue"];
                }
            } else if (element === "approvedTime") {
                filtersData[element + ".greaterThanOrEqual"] = filters[element]["date"]["startDate"].toISOString();
                filtersData[element + ".lessThanOrEqual"] = filters[element]["date"]["endDate"].toISOString();
            } else if (element === "progressStatus") {
                filtersData[element + ".equal"] = filters[element].value;
            } else if (element === "categoryId") {
                filtersData[element + ".in"] = filters[element].map((p: any) => p.value).join(",");
            } else {
                filtersData[element] = filters[element];
            }
        }

        if (search) {
            filtersData["searchQuery.contains"] = search;
        }

        return filtersData;
    }, [filters, search]);

    const dataQuery = useResultTests(sorting, parsedFiltersData);

    const { mutate: mutateFavoriteStatus } = useChangeTestFavoriteStatus();

    const onFavoriteChange = (resource: TestUserListResponse, isFavorite: boolean) => {
        mutateFavoriteStatus({ resource, isFavorite, queryKeys: testsKeys.listResult(sorting, parsedFiltersData) });
    };

    useEffect(() => {
        const fetch = async () => {
            // Категория
            const categories = await Api.Test.CategoryGet();

            const categoriesOptions = categories.Content.map(({ id, title }) => {
                return { label: title, value: id };
            });

            setCategories(categoriesOptions);
        };

        fetch();
    }, []);

    const filtersConfig = [
        {
            label: "Пройден",
            fields: [
                {
                    accessor: "approvedTime",
                    type: "date-range",
                },
            ],
        },
        {
            label: "Награда, баллы",
            fields: [
                {
                    accessor: "ratingPoints",
                    type: "range",
                    min:
                        dataQuery.data?.pages.length !== 0
                            ? dataQuery.data?.pages
                                  .reduce((prev, curr) => [...prev, ...curr.Content], [] as TestUserListResponse[])
                                  .reduce((prev, curr) => (prev.ratingPoints < curr.ratingPoints ? prev : curr), {
                                      ratingPoints: 0,
                                  }).ratingPoints ?? 0
                            : 0,
                    max:
                        dataQuery.data?.pages.length !== 0
                            ? dataQuery.data?.pages
                                  .reduce((prev, curr) => [...prev, ...curr.Content], [] as TestUserListResponse[])
                                  .reduce((prev, curr) => (prev.ratingPoints > curr.ratingPoints ? prev : curr), {
                                      ratingPoints: 0,
                                  }).ratingPoints ?? 0
                            : 0,
                },
            ],
        },
        {
            label: "Результат",
            fields: [
                {
                    accessor: "progressStatus",
                    type: "select",
                    placeholder: "Выберите результат",
                    options: [
                        {
                            label: "Все",
                            value: "",
                        },
                        {
                            label: "Пройден",
                            value: "PASSED",
                        },
                        {
                            label: "Провален",
                            value: "FAILED",
                        },
                    ],
                },
            ],
        },
        {
            label: "Категория",
            fields: [
                {
                    accessor: "categoryId",
                    type: "multi-select",
                    default: "",
                    placeholder: "Выберите категорию",
                    options: categories,
                    components: {
                        MultiValueLabel: SelectAvatarMultiValueLabel(),
                        MultiValueRemove: SelectMultiValueRemove,
                        ValueContainer: SelectValueContainer,
                    },
                },
            ],
        },
    ];

    const { data, isFetching, isFetched, fetchNextPage } = dataQuery;
    const dataLength = data?.pages[0].Content?.length ?? 0;

    const { size } = useScreenSize();
    const isSmall = size === "small";

    return (
        <>
            {isSmall && (
                <div
                    className="absolute top-3.5 right-10 block sm:hidden z-[100]"
                    onClick={() => setIsFilterShow(true)}
                >
                    <Icon icon={Icons.Filter} width={18} height={18} color="stroke-blue-drk" />
                </div>
            )}
            <Filter
                isActive={isFilterShow}
                setIsActive={setIsFilterShow}
                configuration={filtersConfig as IFilterRow[]}
                filters={filters}
                onChange={setFilters}
            />
            {(dataLength !== 0 || Object.keys(filters).length !== 0 || search) && (
                <div className="hidden sm:flex justify-between mb-5 2xl:mb-6.25">
                    <Search value={search} onChange={(e) => setSearch(e ? e.target.value : "")} />
                    <Button
                        className="border-[#E6E9ED] rounded-lg font-medium"
                        variant="outline"
                        color="secondary"
                        size="medium"
                        icon={
                            <Icon
                                icon={Icons.Filter}
                                width={20}
                                height={20}
                                color="stroke-blue"
                                className="2xl:!w-6.25 2xl:!h-6.25"
                            />
                        }
                        iconPlacement="left"
                        onClick={() => setIsFilterShow(true)}
                        id="userTestsBtnFilter"
                    >
                        Фильтры
                    </Button>
                </div>
            )}
            {data && dataLength > 0 && (
                <InfiniteList
                    className="flex-center flex-col w-full sm:w-[1174px] 2xl:w-[1464px]"
                    isLoading={isFetching}
                    hasMore={!data.pages[data.pages.length - 1].Last}
                    onLoadMore={() => fetchNextPage()}
                    isSkeletonLoader={true}
                >
                    <div className="hidden sm:block w-full">
                        <TestsCardList
                            id="userTestsResults"
                            isFinished={true}
                            isReview={false}
                            showStatusChangeTime
                            data={data.pages.map((p) => p.Content).flat()}
                            onFavoriteChange={onFavoriteChange}
                        />
                    </div>
                    {isSmall && (
                        <div className="block sm:hidden w-full">
                            <TestsCardListMobile
                                id="userTestsResults"
                                isFinished={true}
                                isReview={false}
                                data={data.pages.map((p) => p.Content).flat()}
                            />
                        </div>
                    )}
                </InfiniteList>
            )}
            <div className="relative">
                <Preloader
                    className="sm:grid sm:grid-cols-[repeat(4,274px)] 2xl:grid-cols-[repeat(4,342px)] gap-y-5 sm:gap-x-6.5 2xl:gap-x-8 sm:gap-y-9"
                    isShow={!isFetched}
                >
                    {Array.from(Array(16).keys()).map((p) => {
                        return (
                            <div key={p}>
                                <div className="w-60 h-36 sm:w-[274px] sm:h-41 2xl:w-[342px] 2xl:h-51 rounded-2xl overflow-hidden leading-0">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                                <div className="leading-5 line-clamp-2 pt-3">
                                    <Skeleton className="rounded-2xl" width="100%" height="100%" />
                                </div>
                            </div>
                        );
                    })}
                </Preloader>
            </div>
            {isFetched && dataLength === 0 && Object.keys(filters).length === 0 && !search && (
                <Empty
                    title="Ничего не пройдено"
                    description={
                        <span>
                            Все, что было пройдено, будет храниться
                            <br />в этой вкладке
                        </span>
                    }
                    topElement={
                        <div className="flex-center mb-4 2xl:mb-5">
                            <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                <Icon
                                    icon={Icons.EmojiSad}
                                    width={36}
                                    height={36}
                                    color="fill-primary"
                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                />
                            </div>
                        </div>
                    }
                />
            )}
            {isFetched && dataLength === 0 && (Object.keys(filters).length !== 0 || search) && (
                <Empty
                    title="Ничего не найдено"
                    description="По заданным параметрам результатов нет"
                    topElement={
                        <div className="flex-center mb-4 2xl:mb-5">
                            <div className="flex-center w-16.5 h-16.5 2xl:w-20.5 2xl:h-20.5 rounded-full bg-blue-10">
                                <Icon
                                    icon={Icons.EmojiSad}
                                    width={"36px"}
                                    height={"36px"}
                                    color={"fill-primary"}
                                    className="2xl:!w-11.25 2xl:!h-11.25"
                                />
                            </div>
                        </div>
                    }
                />
            )}
        </>
    );
};
