import React, { useEffect } from "react";
import { SingleValue } from "react-select";
import { useSearchParams } from "react-router-dom";
import { Section } from "Uikit/Forms/Section";
import { ComboBox, FormGroup, Input, Label, Textarea, Toggle, CoverInput, flash } from "Uikit";
import { CategoryModal } from "Uikit/CategoryModalNew/CategoryModal";
import { CategoryMenuList, UsersOption, UsersSingleValue } from "Uikit/Forms/SelectComponents";
import { DateTimeSelector } from "Uikit/DateTimeSelector/DateTimeSelector";
import Api from "Api";
import { AccountableUser } from "Api/Responses/UserResponse";
import { ProgramReadResponse } from "Api/Responses/ProgramResponse";
import { ResourceState, UIErrorMessages } from "Enums";
import { IOption } from "types";

interface IProgramSettingsProps {
    errors: any;
    defaultLogos: string[];
    cover: File | null;
    onChangeCover: (data: File | null, imgBase64?: string) => void;
    program: ProgramReadResponse;
    onChange: (data: ProgramReadResponse) => void;
    categories: IOption[];
    onCategoriesChange: (category: any) => void;
    users: { label: string; value: string; payload: AccountableUser }[];
}

export const ProgramSettings = ({
    errors,
    defaultLogos,
    cover,
    onChangeCover,
    program,
    onChange,
    categories,
    onCategoriesChange,
    users,
}: IProgramSettingsProps) => {
    const [categoryOptions, setCategoryOptions] = React.useState<IOption[]>([]);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = React.useState(false);
    const [searchParams] = useSearchParams(location.search);

    const onDefaultImageChange = (id: string) => {
        onChange({
            ...program,
            logoId: id,
        });
    };

    const onAddCategoryClick = () => {
        setIsCategoryModalOpen(true);
    };

    const onCategorySubmit = async (title: string) => {
        try {
            const response = await Api.Program.CategoryCreate({ title: title });

            onCategoriesChange({ label: title, value: response.id });
            onChange({ ...program, categoryId: response.id });
        } catch (error) {
            return error;
        }
    };

    useEffect(() => {
        if (categories.length) {
            setCategoryOptions(categories);
            if (!program.categoryId) {
                const categoryId = searchParams.get("categoryId");
                if (categoryId) {
                    const category = categories[categories.findIndex((p) => categoryId === p.value)];
                    onChange({ ...program, categoryId: category.value });
                }
            }
        }
    }, [categories, onChange, program, searchParams]);

    const coverInputErrorHandler = () => {
        flash.error(UIErrorMessages.FILE_LOADING_ERROR);
    };

    return (
        <>
            <CategoryModal
                isOpen={isCategoryModalOpen}
                onClose={() => setIsCategoryModalOpen(false)}
                onSubmit={onCategorySubmit}
            />
            <Section label="Внешний вид">
                <FormGroup>
                    <Label>Обложка программы обучения</Label>
                    <CoverInput
                        btn={false}
                        logo={cover ?? program.logoId ?? null}
                        onChange={onChangeCover}
                        onChangeDefaultLogo={onDefaultImageChange}
                        defaultLogoIds={defaultLogos}
                        id="adminNewProgramCover"
                        onError={coverInputErrorHandler}
                    />
                </FormGroup>
                <FormGroup>
                    <Label isRequired={true}>Заголовок программы</Label>
                    <Input
                        isRequired={true}
                        placeholder="Введите заголовок"
                        value={program.title}
                        onChange={(e) => onChange({ ...program, title: e.target.value })}
                        error={errors["title"]}
                        maxLength={128}
                        id="adminNewProgramInputName"
                    />
                </FormGroup>
                <FormGroup>
                    <Label>Краткое описание</Label>
                    <Textarea
                        placeholder="Введите краткое описание"
                        value={program.description}
                        onChange={(e) => onChange({ ...program, description: e.target.value })}
                        error={errors["description"]}
                        maxLength={1024}
                        id="adminNewProgramInputDescription"
                    />
                </FormGroup>
            </Section>
            <Section label="Месторасположение">
                <FormGroup>
                    <Label isRequired={true}>Категория</Label>
                    <ComboBox
                        isSearchable
                        placeholder="Выберите категорию"
                        value={categoryOptions[categoryOptions.findIndex((p) => program.categoryId === p.value)]}
                        options={categories}
                        addOptionClick={onAddCategoryClick}
                        components={{ MenuList: CategoryMenuList, NoOptionsMessage: () => <div></div> }}
                        onChange={(option: SingleValue<any>) => onChange({ ...program, categoryId: option.value })}
                        error={errors["category"]}
                        id="adminNewProgramComboBoxCategory"
                    />
                </FormGroup>
            </Section>
            <Section label="Контроль">
                <FormGroup>
                    <Label isRequired={true}>Ответственный</Label>
                    <ComboBox
                        isSearchable
                        placeholder="Выберите ответственного"
                        value={users[users.findIndex((p) => p.value === program.managerUserId)]}
                        options={users}
                        onChange={(option: SingleValue<any>) => onChange({ ...program, managerUserId: option.value })}
                        error={errors["manager"]}
                        components={{
                            SingleValue: UsersSingleValue,
                            Option: UsersOption,
                        }}
                        id="adminNewProgramComboBoxManager"
                    />
                </FormGroup>
            </Section>
            <Section label="Публикация">
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        label="Назначить отложенную дату публикации"
                        enabled={!!program.publicationTime}
                        onChange={(p) => {
                            const defaultDate = new Date();

                            defaultDate.setHours(10);
                            defaultDate.setMinutes(0);

                            defaultDate.setSeconds(0);
                            defaultDate.setMilliseconds(0);

                            const updatedProgram = {
                                ...program,
                                publicationTime: p ? defaultDate.getTime() / 1000 + 86400 : null,
                            };
                            if (p && program.state === ResourceState.ACTIVE) {
                                updatedProgram.state = ResourceState.HIDDEN;
                            }
                            onChange(updatedProgram as ProgramReadResponse);
                        }}
                        id="adminNewProgramTogglePublicLater"
                    />
                    {!!program.publicationTime && (
                        <DateTimeSelector
                            isPastTime={false}
                            error={
                                program.publicationTime &&
                                program.expirationTime &&
                                program.expirationTime <= program.publicationTime
                                    ? "Дата снятия с публикации не может быть раньше даты публикации"
                                    : ""
                            }
                            dateTime={program.publicationTime}
                            onChange={(dateTime) => onChange({ ...program, publicationTime: dateTime })}
                            showTimeInput
                        />
                    )}
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        enabled={!!program.expirationTime}
                        onChange={(p) => {
                            const defaultDate = new Date();

                            defaultDate.setHours(10);
                            defaultDate.setMinutes(0);

                            defaultDate.setSeconds(0);
                            defaultDate.setMilliseconds(0);

                            const updatedTask = {
                                ...program,
                                expirationTime: p ? defaultDate.getTime() / 1000 + 86400 : undefined,
                            };
                            if (p && program.state === ResourceState.HIDDEN) {
                                updatedTask.state = !program.publicationTime
                                    ? ResourceState.ACTIVE
                                    : ResourceState.HIDDEN;
                            }
                            onChange(updatedTask);
                        }}
                        label="Назначить дату снятия с публикации"
                        id="adminNewProgramTogglePublicEnd"
                    />
                    {!!program.expirationTime && (
                        <DateTimeSelector
                            isPastTime={false}
                            error={
                                program.publicationTime &&
                                program.expirationTime &&
                                program.expirationTime <= program.publicationTime
                                    ? "Дата снятия с публикации не может быть раньше даты публикации"
                                    : ""
                            }
                            dateTime={program.expirationTime}
                            onChange={(dateTime) => onChange({ ...program, expirationTime: dateTime })}
                            showTimeInput
                        />
                    )}
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        enabled={!!program.deadlineTime}
                        onChange={(p) => {
                            const defaultDate = new Date();

                            defaultDate.setHours(10);
                            defaultDate.setMinutes(0);

                            onChange({
                                ...program,
                                deadlineTime: p ? defaultDate.getTime() / 1000 + 86400 : undefined,
                            });
                        }}
                        label="Назначить дедлайн"
                        tip="Программа получит статус «Просрочена», но будет доступна для прохождения"
                        id="adminNewProgramToggleDeadLine"
                    />
                    {!!program.deadlineTime && (
                        <DateTimeSelector
                            isPastTime={true}
                            dateTime={program.deadlineTime}
                            onChange={(dateTime) => onChange({ ...program, deadlineTime: dateTime })}
                            showTimeInput
                        />
                    )}
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        enabled={program.isRequired}
                        onChange={(p) => {
                            onChange({
                                ...program,
                                isRequired: p,
                            });
                        }}
                        label="Программа обязательная к прохождению"
                        id="adminNewProgramToggleRequired"
                    />
                </FormGroup>
            </Section>
            <Section label="Прочее" className="!border-0">
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        enabled={program.issueCertificate}
                        onChange={(p) => {
                            onChange({
                                ...program,
                                issueCertificate: p,
                            });
                        }}
                        label="Выдать сертификат после прохождения"
                    />
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        enabled={program.hideAvgReviewScore}
                        onChange={(p) => {
                            onChange({
                                ...program,
                                hideAvgReviewScore: p,
                            });
                        }}
                        label="Скрыть оценку программы обучения"
                    />
                </FormGroup>
                <FormGroup className="flex items-center !mb-3.5 h-10">
                    <Toggle
                        className="mr-auto"
                        enabled={program.hideUserReviews}
                        onChange={(p) => {
                            onChange({
                                ...program,
                                hideUserReviews: p,
                            });
                        }}
                        label="Скрыть отзывы для участников"
                    />
                </FormGroup>
            </Section>
        </>
    );
};
