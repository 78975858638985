import React, { useState } from "react";
import { Button, Modal, SubmitButton } from "Uikit";
import { UsersTable } from "./UsersTable";
import { Except } from "type-fest";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { TVoidFunction } from "types";

interface UsersModalProps {
    isOpen: boolean;
    onClose: TVoidFunction;
    // onUsersLoaded: (users: UserListResponse[]) => void;
    onSubmit: (users: MembersListResponse[]) => void;
    allUsers: MembersListResponse[];
    selectedUsersDefault: MembersListResponse[];
    title?: string;
    isSingleSelect?: boolean;
    showStatusFilter?: boolean;
}

export const UsersModal = ({
    isOpen,
    onClose,
    title = "Выбор участников",
    isSingleSelect = false,
    showStatusFilter = false,
    /* onUsersLoaded,  */
    ...rest
}: UsersModalProps) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} title={title} className="!w-142 !max-w-142 !overflow-visible">
            <ModalContent
                onClose={onClose}
                /* onUsersLoaded={onUsersLoaded} */
                isSingleSelect={isSingleSelect}
                showStatusFilter={showStatusFilter}
                {...rest}
            />
        </Modal>
    );
};

const ModalContent = ({
    onClose,
    // onUsersLoaded,
    onSubmit,
    allUsers,
    selectedUsersDefault,
    isSingleSelect = false,
    showStatusFilter = false,
}: Except<UsersModalProps, "isOpen">) => {
    const [selectedUsers, setSelectedUsers] = useState<MembersListResponse[]>(selectedUsersDefault);

    const handleSubmit = async () => {
        onSubmit(selectedUsers);
        onClose();
    };

    const handleUsersChanged = (selected: MembersListResponse[]) => {
        setSelectedUsers(selected);
    };

    return (
        <>
            <Modal.Body>
                <UsersTable
                    onChange={handleUsersChanged}
                    selectedUsers={selectedUsers}
                    // onUsersLoaded={onUsersLoaded}
                    allUsers={allUsers}
                    isSingleSelect={isSingleSelect}
                    showStatusFilter={showStatusFilter}
                />
            </Modal.Body>

            <Modal.Footer>
                {!isSingleSelect && <div className="grow self-center text-gray">Выбрано {selectedUsers.length}</div>}
                <Button
                    key={1}
                    onClick={onClose}
                    variant="outline"
                    size={"medium"}
                    color={"secondary"}
                    className="border-[#EAEDF3]"
                >
                    Отмена
                </Button>
                <SubmitButton key={2} onClick={handleSubmit} disabled={selectedUsers.length === 0}>
                    Выбрать
                </SubmitButton>
            </Modal.Footer>
        </>
    );
};
