import React from "react";
import uniqueId from "lodash/uniqueId";
import { Widget } from "Components/Widget";

interface ILegendLineProps {
    text: string;
    value: number;
    color: "blue" | "gray" | "red" | "light-gray";
}

interface IStatsItem {
    size: number;
    color: "stroke-primary" | "stroke-gray-light" | "stroke-red" | "stroke-gray-stroke";
    zIndex: number;
}

interface IPersonalWidgetProps {
    title: string;
    stubText: string | null;
    stats: IStatsItem[];
    count: number;
    legendLines: ILegendLineProps[];
}

export const PersonalWidget = ({ title, stats, count, legendLines, stubText = null }: IPersonalWidgetProps) => {
    const getPassedPercents = () => {
        const passedCount = stats[0].size;
        const passedPercents = Math.round((passedCount / count) * 100);

        return `${passedPercents}%`;
    };

    return (
        <Widget title={title}>
            <div className="flex items-stretch gap-5">
                {count === 0 ? (
                    <p className="text-xs 2xl:text-2sm text-blue-dark">{stubText}</p>
                ) : (
                    <>
                        <Widget.ChartSimple
                            charts={stats.map((statsItem: IStatsItem) => {
                                return {
                                    ...statsItem,
                                    size: Math.round((statsItem.size / count) * 100) / 100,
                                };
                            })}
                            count={getPassedPercents()}
                        />
                        <div className="flex flex-col justify-center gap-2 2xl:gap-2.5 py-1.5 grow">
                            {legendLines.map(({ text, value, color }) => {
                                return (
                                    <Widget.LegendLine
                                        key={uniqueId(`${text}__${value}`)}
                                        text={text}
                                        value={value}
                                        color={color}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </Widget>
    );
};
