import React, { useEffect, useState, useMemo, useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Outlet,
    useLocation,
    useNavigate,
    Navigate,
} from "react-router-dom";
import { IReducer } from "./store";
import { setIsAuth, setIsRoot } from "./slices/authSlice";
import { Button, CustomToastContainer, Icon, Icons } from "./Uikit";
import { Menu, Wrapper } from "./Containers";
import { Course } from "./Pages/User/Course/Course";
import { Home } from "./Pages/User/Home/Home";
import { MobileMenu } from "./Pages/User/Menu/Menu";
import { MobileNotifications } from "./Pages/User/MobileNotifications/MobileNotifications";
import { Personal } from "./Pages/User/Personal/Personal";
import { PersonalAchievements } from "./Pages/User/Personal/PersonalAchievements";
import { PersonalStatistics } from "./Pages/User/Personal/PersonalStatistics";
import { PersonalProgress } from "./Pages/User/Personal/PersonalProgress";
import { Program } from "./Pages/User/Program/Program";
import { ProgramMain } from "./Pages/User/Program/ProgramMain";
import { ProgramComplete } from "./Pages/User/Program/ProgramComplete";
import { Rating } from "./Pages/User/Rating/Rating";
import { Task } from "./Pages/User/Task/Task";
import { TaskPassing } from "./Pages/User/Task/TaskPassing";
import { TaskSended } from "./Pages/User/Task/TaskSended";
import { TaskChanged } from "./Pages/User/Task/TaskChanged";
import { TaskStatistics } from "./Pages/User/Task/TaskStatistics";
import { Tasks } from "./Pages/User/Tasks/Tasks";
import { Team } from "./Pages/User/Team/Team";
import { TeamMentors } from "./Pages/User/Team/TeamMentors";
import { TeamNested } from "./Pages/User/Team/TeamNested";
import { Teams } from "./Pages/User/Teams/Teams";
import { Test } from "./Pages/User/Test/Test";
import { TestStatistics } from "./Pages/User/Test/TestStatistics";
import { Tests } from "./Pages/User/Tests/Tests";
import { Testing } from "./Pages/User/Test/Testing";
import { Training } from "./Pages/User/Training/Training";
import { Archive as AdminArchive } from "./Pages/Admin/Archive/Archive";
import { Course as AdminCourse } from "./Pages/Admin/Course/Course";
import { Courses as AdminCourses } from "./Pages/Admin/Courses/Courses";
// import { Interview as AdminInterview } from "./Pages/Admin/Interview/Interview";
// import { Interviews as AdminInterviews } from "./Pages/Admin/Interviews/Interviews";
// import { InterviewsEmpty as AdminInterviewsEmpty } from "./Pages/Admin/Interviews/InterviewsEmpty";
// import { InterviewsEmptyCategory as AdminInterviewsEmptyCategory } from "./Pages/Admin/Interviews/InterviewsEmptyCategory";
// import { InterviewsPage as AdminInterviewsPage } from "./Pages/Admin/Interviews/InterviewsPage";
import { Material as AdminMaterial } from "./Pages/Admin/Material/Material";
import { Materials as AdminMaterials } from "./Pages/Admin/Materials/Materials";
import { Members as AdminMembers } from "./Pages/Admin/Members/Members";
import { Calendar as AdminCalendar } from "./Pages/Admin/Calendar/Calendar";
import { Program as AdminProgram } from "./Pages/Admin/Program/Program";
import { Programs as AdminPrograms } from "./Pages/Admin/Programs/Programs";
import { Settings as AdminSettings } from "./Pages/Admin/Settings/Settings";
import { Task as AdminTask } from "./Pages/Admin/Task/Task";
import { Tasks as AdminTasks } from "./Pages/Admin/Tasks/Tasks";
import { Test as AdminTest } from "./Pages/Admin/Test/Test";
import { Tests as AdminTests } from "./Pages/Admin/Tests/Tests";
import { Validations as AdminValidations } from "./Pages/Admin/Validations/Validations";
// import { Library as AdminLibrary } from "./Pages/Admin/Library/Library";
// import { LibraryEmpty as AdminLibraryEmpty } from "./Pages/Admin/Library/LibraryEmpty";
// import { LibraryRoot as AdminLibraryRoot } from "./Pages/Admin/Library/LibraryRoot";
// import { LibraryAccess as AdminLibraryAccess } from "./Pages/Admin/Library/LibraryAccess";
import { Statistics as AdminStatistics } from "./Pages/Admin/Statistics/Statistics";
import { Authorization } from "./Pages/Authorization";
import { MemberPage } from "./Pages/Admin/Members/MemberPage";
import { MembersImport as AdminMembersImport } from "Pages/Admin/Members/MembersImport";
import { Achievement as AdminAchievement } from "./Pages/Admin/Settings/Group/Achievements/Achievement";
import { TaskPage } from "./Pages/Admin/Validations/Tabs/Tasks/Page/TaskPage";
import { TestPage } from "./Pages/Admin/Validations/Tabs/Tests/Page/TestPage";
import { RemarkPage } from "./Pages/Admin/Validations/Tabs/Remarks/RemarkPage";
import Api from "./Api";
import SidebarListWrapper from "Uikit/SidebarList/SidebarList";
import { setCurrentUser } from "slices/userSlice";
import { instanceToPlain } from "class-transformer";
import { KitPage } from "./Pages/KitPage";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import timezone from "dayjs/plugin/timezone";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";
import { Search } from "Pages/Search/Search";
import { TestingFinish } from "./Pages/User/Test/TestingFinish";
import { useInterval } from "hooks/useInterval";
import { useQuery, useQueryClient } from "react-query";
import { removeCookie } from "typescript-cookie";
import { RouterErrorBoundary } from "./RouterErrorBoundary";
import { CourseMain } from "Pages/User/Course/CourseMain";
import { CourseMaterial } from "Pages/User/Course/CourseMaterial";
import { CourseComplete } from "Pages/User/Course/CourseComplete";
import { PersonalEdit } from "./Pages/User/Personal/PersonalEdit";
import { ScormPlayer } from "./Pages/Admin/Material/components/Scorm/ScormPlayer";
import { PrivacyPolicy } from "./Pages/PrivacyPolicy";
import { TermsOfUse } from "./Pages/TermsOfUse";
import clsx from "clsx";
import { SkytrainerPage } from "./Pages/User/Skytrainer/SkytrainerPage";
import { Empty } from "Uikit/Page/Empty";
import { UserRole } from "types/User";
import { Favorite } from "Pages/User/Favorite/Favorite";
import { RoleItemResponse } from "Api/Responses/RoleResponse";
import { PersonalCertificatesMobile } from "Pages/User/Personal/PersonalCertificatesMobile";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);

interface IRouterWrapperProps {
    isInterfaceElementsHidden?: boolean;
}

const RouterWrapper = ({ isInterfaceElementsHidden = false }: IRouterWrapperProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const { testingURL } = useContext(GlobalContext);

    useEffect(() => {
        if (testingURL) {
            navigate(testingURL);
        }
    }, [testingURL, navigate]);

    if (location.pathname.startsWith("/trainer")) {
        return <Outlet />;
    }

    return (
        <Menu isInterfaceElementsHidden={isInterfaceElementsHidden}>
            <SidebarListWrapper>
                <Wrapper isInterfaceElementsHidden={isInterfaceElementsHidden}>
                    <Outlet />
                </Wrapper>
            </SidebarListWrapper>
        </Menu>
    );
};

const AdminBlocker = () => {
    const navigate = useNavigate();

    return (
        <div className="h-full flex justify-center">
            <Empty
                className="!mt-0"
                title="Нет доступа"
                description="К сожалению, у вас нет доступа к этой странице"
                topElement={
                    <div className="flex-center mb-4">
                        <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                            <Icon icon={Icons.EmojiSad} width={"36px"} height={"36px"} color={"fill-primary"} />
                        </div>
                    </div>
                }
            >
                <Button onClick={() => navigate("/")} id="adminBlockerBtnGoMain">
                    На главную
                </Button>
            </Empty>
        </div>
    );
};

const AdminWrapper = () => {
    const location = useLocation();
    const { data: user, isFetching } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());
    const isAdmin = user && [UserRole.ROOT, UserRole.SUPER_ADMIN, UserRole.ADMIN].includes(user.role as UserRole);

    const [roleData, setRoleData] = useState<RoleItemResponse>();
    useEffect(() => {
        if (isAdmin) {
            const fetchRoleData = async () => {
                const data = await Api.Role.Id(user.id);
                setRoleData(data);
            };

            fetchRoleData().then();
        }
    }, [user, isAdmin]);

    const hasSectionPermission = useMemo(() => {
        if (roleData && user && user.role === UserRole.ADMIN) {
            const path = location.pathname;
            const searchParams = new URLSearchParams(location.search);
            const tab = searchParams.get("tab");

            if (path.startsWith("/admin/course")) {
                return roleData.courses;
            } else if (path.startsWith("/admin/program")) {
                return roleData.programs;
            } else if (path.startsWith("/admin/test")) {
                return roleData.tests;
            } else if (path.startsWith("/admin/task")) {
                return roleData.exercise;
            } else if (path.startsWith("/admin/material")) {
                return roleData.materials;
            } else if (path.startsWith("/admin/statistics")) {
                return roleData.statistic;
            } else if (path.startsWith("/admin/member")) {
                return roleData.userControl;
            } else if (path.startsWith("/admin/validations") || (path === "/admin" && tab)) {
                return roleData.tests || roleData.exercise;
            } else if (path.startsWith("/admin/archive")) {
                return roleData.courses || roleData.materials || roleData.tests || roleData.exercise;
            } else return path === "/admin";
        } else return !(user && user.role === UserRole.ADMIN);
    }, [location, roleData, user]);

    if (isAdmin && hasSectionPermission) {
        return <Outlet />;
    }

    return !isFetching ? <AdminBlocker /> : null;
};

export const GlobalContext = React.createContext({
    visitedUrls: [],
    setVisitedUrls: () => {},

    formDataChanged: false,
    setFormDataChanged: () => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIsFullScreenViewEnabled: (arg: boolean) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setIsInterfaceElementsHidden: (arg: boolean) => {},
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setTestingURL: (arg: string | null) => {},
    testingURL: null,
});

function App() {
    const dispatch = useDispatch();
    const queryClient = useQueryClient();

    const isAuth = useSelector((state: IReducer) => state.auth.isAuth);
    const isRoot = useSelector((state: IReducer) => state.auth.isRoot);

    const [isFetched, setIsFetched] = useState(false);
    const [isFullScreenViewEnabled, setIsFullScreenViewEnabled] = useState(false);
    const [isInterfaceElementsHidden, setIsInterfaceElementsHidden] = useState(false);
    const [testingURL, setTestingURL] = useState<string | null>(null);

    const [visitedUrls, setVisitedUrls] = useState([]);
    const [formDataChanged, setFormDataChanged] = useState(false);

    const globalStateProvided = useMemo<any>(
        () => ({
            visitedUrls,
            setVisitedUrls,
            formDataChanged,
            setFormDataChanged,
            setIsFullScreenViewEnabled,
            setIsInterfaceElementsHidden,
            testingURL,
            setTestingURL,
        }),
        [visitedUrls, formDataChanged, testingURL],
    );

    const checkTesting = useCallback(async () => {
        if (!isAuth) {
            return;
        }

        if (isAuth && window.location.pathname.includes("testing")) {
            return;
        }

        try {
            await Api.Test.UserStarted();
            setTestingURL("/testing");
            // window.location.pathname = "/testing"; // закомментировано и переведено на работу с глоб. контекстом из-за проблем в FF (бесконечная перезагрузка страницы)
        } catch {
            setTestingURL(null);
            return;
        }
    }, [isAuth]);

    useInterval(checkTesting, 30000);
    useInterval(() => {
        const images = document.querySelectorAll("img");

        images.forEach((image) => {
            if (image.complete || image.onload || image.src.indexOf("base64") !== -1) {
                return;
            }

            image.style.transition = "all 0.3s ease-in-out";
            image.style.opacity = "0";

            image.onload = () => (image.style.opacity = "1");
        });
    }, 50);

    useEffect(() => {
        const fetch = async () => {
            try {
                const checkIsAuth = await Api.Auth.CheckIsAuth();

                if (!checkIsAuth) {
                    removeCookie("USER_SESSION", { path: "/", domain: window.location.hostname });
                    localStorage.removeItem("companyId");
                }

                if (checkIsAuth) {
                    const currentUser = await Api.User.GetCurrentUser();

                    if (currentUser.companyId && currentUser.role !== "ROOT") {
                        localStorage.setItem("companyId", currentUser.companyId);
                    }

                    if (currentUser.role === "ROOT") {
                        if (window.location.pathname === "/admin/settings/licences/create") {
                            dispatch(setIsAuth(true));
                        } else if (localStorage.getItem("companyId")) {
                            dispatch(setIsAuth(true));
                            dispatch(setIsRoot(false));
                        } else {
                            dispatch(setIsAuth(false));
                            dispatch(setIsRoot(true));
                        }
                    } else {
                        dispatch(setIsAuth(true));
                    }

                    dispatch(setCurrentUser(instanceToPlain(currentUser)));
                }

                setIsFetched(true);
            } catch {
                setIsFetched(true);
            }
        };
        if (!isAuth && !isRoot) {
            fetch().then();
        } else {
            checkTesting().then();
        }
    }, [dispatch, isAuth, checkTesting, queryClient, isRoot]);

    //истечение сессии при бездействии
    useEffect(() => {
        let lastActivityTime = Date.now();

        const listener = () => {
            lastActivityTime = Date.now();
        };

        window.addEventListener("click", listener);
        window.addEventListener("wheel", listener);
        window.addEventListener("mousemove", listener);

        const interval = setInterval(async () => {
            if (Date.now() >= lastActivityTime + 14400000) {
                await Api.Auth.Logout();
                removeCookie("USER_SESSION", { path: "/", domain: window.location.hostname });
                dispatch(setIsRoot(false));
                localStorage.removeItem("companyId");
                document.location.reload();
            }
        }, 60000);

        return () => {
            window.removeEventListener("click", listener);
            window.removeEventListener("wheel", listener);
            window.removeEventListener("mousemove", listener);
            clearInterval(interval);
        };
    }, [dispatch]);

    if (!isFetched) {
        return null;
    }

    const router = createBrowserRouter(
        createRoutesFromElements(
            <React.Fragment>
                {!isAuth && <Route path="*" element={<Authorization />} />}
                {isAuth && (
                    <Route
                        path="/"
                        element={<RouterWrapper isInterfaceElementsHidden={isInterfaceElementsHidden} />}
                        errorElement={<RouterErrorBoundary />}
                    >
                        <Route path="" element={<Home />} />
                        <Route path="/trainer/:id?" element={<SkytrainerPage />} />
                        <Route path="/menu" element={<MobileMenu />} />
                        <Route path="/notifications" element={<MobileNotifications />} />
                        <Route path="training/course/" element={<Course />}>
                            <Route path=":courseId" element={<CourseMain />} />
                            <Route path=":courseId/statistics" element={<CourseMain showStatistics />} />
                            <Route path=":courseId/:componentId/:resourceId" element={<CourseMaterial />} />
                            <Route path=":courseId/complete" element={<CourseComplete />} />
                        </Route>
                        <Route path="training/program/" element={<Program />}>
                            <Route path=":programId" element={<ProgramMain />} />
                            <Route path=":programId/statistics" element={<ProgramMain showStatistics />} />
                            <Route path=":programId/:courseId" element={<CourseMain />} />
                            <Route path=":programId/:courseId/:testId" element={<Test />} />
                            <Route path=":programId/:courseId/:componentId/:resourceId" element={<CourseMaterial />} />
                            <Route path=":programId/:courseId/complete" element={<CourseComplete />} />
                            <Route path=":programId/complete" element={<ProgramComplete />} />
                        </Route>
                        <Route path="personal" element={<Personal />} />
                        <Route path="personal/achievements" element={<PersonalAchievements />} />
                        <Route path="personal/statistics" element={<PersonalStatistics />} />
                        <Route path="personal/progress" element={<PersonalProgress />} />
                        <Route path="personal/certificates" element={<PersonalCertificatesMobile />} />
                        <Route path="personal/edit" element={<PersonalEdit />} />
                        <Route path="personal/:id" element={<Personal />} />
                        <Route path="personal/:id/achievements" element={<PersonalAchievements />} />
                        <Route path="personal/:id/statistics" element={<PersonalStatistics />} />
                        <Route path="personal/:id/progress" element={<PersonalProgress />} />
                        <Route path="personal/:id/certificates" element={<PersonalCertificatesMobile />} />
                        <Route path="program/:id" element={<Program />} />
                        <Route path="rating" element={<Rating />} />
                        <Route path="task/:id" element={<Task />} />
                        <Route path="task/:id/passing" element={<TaskPassing />} />
                        <Route path="task/:id/sended" element={<TaskSended />} />
                        <Route path="task/:id/changed" element={<TaskChanged />} />
                        <Route path="task/:id/statistics" element={<TaskStatistics />} />
                        <Route path="tasks" element={<Tasks />} />
                        <Route path="team/:id" element={<Team />} />
                        <Route path="team/:id/mentors" element={<TeamMentors />} />
                        <Route path="team/:id/nested" element={<TeamNested />} />
                        <Route path="teams" element={<Teams />} />
                        <Route path="testing" element={<Testing />} />
                        <Route path="training/coursing/finish" element={<TestingFinish />} />
                        <Route path="testing/finish" element={<TestingFinish />} />
                        <Route path="training/:courseId/:testId" element={<Test />} />
                        <Route path="test/:testId" element={<Test />} />
                        <Route path="training/:courseId/:testId/statistics" element={<TestStatistics />} />
                        <Route path="test/:testId/statistics" element={<TestStatistics />} />
                        <Route path="tests" element={<Tests />} />
                        <Route path="training" element={<Training />} />
                        <Route path="search" element={<Search type="USER" />} />
                        <Route path="/scorm/:fileId" element={<ScormPlayer />} />
                        <Route path="kit" element={<KitPage />} />
                        <Route path="favorite" element={<Favorite />} />
                        <Route element={<AdminWrapper />}>
                            <Route path="admin/archive" element={<AdminArchive />} />
                            <Route path="admin/archive/:tab" element={<AdminArchive />} />
                            <Route path="admin/course/:id/edit" element={<AdminCourse isEdit={true} />} />
                            <Route path="admin/course/:id" element={<AdminCourse />} />
                            <Route path="admin/course/:id/copy" element={<AdminCourse isCopy={true} />} />
                            <Route path="admin/course" element={<AdminCourse />} />
                            <Route path="admin/courses/:id" element={<AdminCourses />} />
                            <Route path="admin/courses" element={<AdminCourses />} />
                            {/* <Route path="admin/interview/:id" element={<AdminInterview />} /> */}
                            {/* <Route path="admin/interviews" element={<AdminInterviews />} /> */}
                            {/* <Route path="admin/interviews/add" element={<AdminInterviewsPage type={"add"} />} /> */}
                            {/* <Route path="admin/interviews-empty" element={<AdminInterviewsEmpty />} /> */}
                            {/* <Route path="admin/interviews-empty-category" element={<AdminInterviewsEmptyCategory />} /> */}
                            <Route path="admin/material/add/:type" element={<AdminMaterial action={"CREATE"} />} />
                            <Route path="admin/material/:type/:id" element={<AdminMaterial action={"EDIT"} />} />
                            <Route path="admin/material/copy/:type/:id" element={<AdminMaterial action={"COPY"} />} />
                            <Route path="admin/materials" element={<AdminMaterials />} />
                            <Route path="admin/member/:id/edit" element={<MemberPage isEdit={true} />} />
                            <Route path="admin/member/:id" element={<MemberPage isEdit={false} />} />
                            <Route path="admin/member" element={<MemberPage isEdit={false} />} />
                            <Route path="admin/members" element={<AdminMembers />} />
                            <Route path="admin/members/import" element={<AdminMembersImport />} />
                            <Route path="admin/calendar" element={<AdminCalendar />} />
                            <Route path="admin/program/:id" element={<AdminProgram />} />
                            <Route path="admin/program/:id/edit" element={<AdminProgram isEdit={true} />} />
                            <Route path="admin/program/:id/copy" element={<AdminProgram isCopy={true} />} />
                            <Route path="admin/program" element={<AdminProgram />} />
                            <Route path="admin/programs" element={<AdminPrograms />} />
                            <Route path="admin/programs/:id" element={<AdminPrograms />} />
                            <Route path="admin/settings" element={<Navigate to="/admin/settings/teams" />} />
                            <Route path="admin/settings/*" element={<AdminSettings />} />
                            <Route path="admin/settings/achievement" element={<AdminAchievement />} />
                            <Route
                                path="admin/settings/achievement/:id/edit"
                                element={<AdminAchievement isEdit={true} />}
                            />
                            <Route path="admin/settings/achievement/:id" element={<AdminAchievement />} />
                            <Route path="admin/task" element={<AdminTask />} />
                            <Route path="admin/task/:id" element={<AdminTask />} />
                            <Route path="admin/task/:id/edit" element={<AdminTask isEdit={true} />} />
                            <Route path="admin/task/:id/copy" element={<AdminTask isCopy={true} />} />
                            <Route path="admin/tasks" element={<AdminTasks />} />
                            <Route path="admin/tasks/:categoryId" element={<AdminTasks />} />
                            <Route path="admin/test/:id/edit" element={<AdminTest isEdit={true} />} />
                            <Route path="admin/test/:id/copy" element={<AdminTest isCopy={true} />} />
                            <Route path="admin/test/:id" element={<AdminTest />} />
                            <Route path="admin/test" element={<AdminTest />} />
                            <Route path="admin/tests/:id" element={<AdminTests />} />
                            <Route path="admin/tests" element={<AdminTests />} />
                            <Route path="admin" element={<AdminValidations />} />
                            <Route path="admin/validations" element={<AdminValidations />} />
                            <Route path="admin/validations/task/:id" element={<TaskPage />} />
                            <Route path="admin/validations/test/:id" element={<TestPage />} />
                            <Route path="admin/validations/remark/:id" element={<RemarkPage />} />
                            {/* <Route path="admin/library" element={<AdminLibraryRoot />} /> */}
                            {/* <Route path="admin/library-folder" element={<AdminLibrary />} /> */}
                            {/* <Route path="admin/library-empty" element={<AdminLibraryEmpty />} /> */}
                            {/* <Route path="admin/library-access" element={<AdminLibraryAccess />} /> */}
                            {/* <Route path="admin/library-access-changed" element={<AdminLibraryAccess />} /> */}
                            <Route path="admin/statistics/*" element={<AdminStatistics />} />
                            <Route path="admin/search" element={<Search type="ADMIN" />} />
                        </Route>
                    </Route>
                )}
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms-of-use" element={<TermsOfUse />} />
            </React.Fragment>,
        ),
    );

    return (
        <div className={clsx("app h-screen", isFullScreenViewEnabled && "overflow-hidden")}>
            <GlobalContext.Provider value={globalStateProvided}>
                <RouterProvider router={router} />
                <CustomToastContainer autoClose={5000} hideProgressBar={true} />
            </GlobalContext.Provider>
        </div>
    );
}

export default App;
