import React from "react";
import { Modal, Button, FormField, InputField } from "Uikit";
import { Formik } from "formik";
import { object, string } from "yup";
import { ProgramReadSection } from "Api/Responses/ProgramResponse";
import { TVoidFunction } from "types";

const validationSchema = object().shape({
    name: string()
        .test(
            "name-is-not-empty",
            () => `Название секции не может быть пустой строкой!`,
            (value) => String(value)?.trim() !== "",
        )
        .required("Обязательно для ввода"),
});

interface FormValues {
    name: string;
}

interface IProgramSectionModal {
    isOpen: boolean;
    section?: ProgramReadSection;
    onSubmit: (e: any) => void;
    onClose: TVoidFunction;
}
export const ProgramSectionModal = ({ isOpen, section, onSubmit, onClose }: IProgramSectionModal) => {
    if (!isOpen) {
        return null;
    }

    const initialValues: FormValues = { name: section?.title ?? "" };

    return (
        <Formik validateOnChange initialValues={initialValues} onSubmit={onSubmit} validationSchema={validationSchema}>
            {({ handleSubmit, values }) => {
                return (
                    <Modal
                        isOpen={isOpen}
                        setIsOpen={(_unused) => {}}
                        onClose={onClose}
                        title={section ? "Редактирование секции" : "Добавление секции"}
                        id="adminNewProgramAddSectionModal"
                    >
                        <Modal.Body>
                            <div className="space-y-3.5">
                                <FormField name="name" label="Название секции" isRequired>
                                    <InputField
                                        placeholder="Введите название"
                                        maxLength={128}
                                        id="adminNewProgramSectionNameInput"
                                    />
                                </FormField>
                                <div className="text-gray-light">
                                    Название секции отображается в пользовательском интерфейсе
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button
                                variant="outline"
                                color="secondary"
                                onClick={onClose}
                                id="adminNewProgramModalBtnCancel"
                            >
                                Отмена
                            </Button>
                            <Button
                                onClick={handleSubmit}
                                disabled={!validationSchema.isValidSync(values)}
                                id="adminNewProgramModalBtnOk"
                            >
                                {section ? "Сохранить" : "Добавить"}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                );
            }}
        </Formik>
    );
};
