import React, { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useMutation, useQuery } from "react-query";
import Api from "Api";
import { TestListResponse, TestPublicVerificatedResponse } from "Api/Responses/TestResponse";
import { AccountableUser, UserListResponse } from "Api/Responses/UserResponse";
import { TestVerificatedCheckMultipleRequest } from "Api/Requests/TestRequest";
import {
    Button,
    Checkbox,
    flash,
    Icon,
    Icons,
    ModalTree,
    OptionsDropdownIndicator,
    SelectAvatarMultiValueLabel,
    SelectMultiValueRemove,
    SelectValueContainer,
} from "Uikit";
import { Table } from "Uikit/Table/Table";
import { Filter, IFilterRow } from "Components/Filter/Filter";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";
import { useInvalidate } from "hooks/useInvalidate";
import { Empty } from "Uikit/Page/Empty";
import { ResourceType } from "Enums";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";
import { LogoSize } from "Api/Services/UploadApi";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { useResponsibleList } from "Api/Hooks/useResponsibleList";

const getTreeNode = ({ id, logoId, title }: TestListResponse, checked = false) => {
    return {
        id: id,
        logoId: logoId,
        type: ResourceType.QUIZ,
        name: title,
        nodeType: "SECTION",
        children: null,
        state: {
            checked,
        },
    };
};

const structurizeTestTreeData = (
    treeDataItems: TestListResponse[],
    checkedNodes: { id: string; checked: boolean }[] = [],
) => {
    const treeData: any[] = [];
    try {
        treeDataItems.forEach((p) => {
            let categoryIndex = treeData.findIndex((p1) => p1.id === p.category.id);

            if (categoryIndex === -1) {
                treeData.push({
                    id: p.category.id,
                    nodeType: "PROJECT",
                    name: p.category.title,
                    children: [],
                    state: {
                        open: false,
                        checked: false,
                    },
                });

                categoryIndex = treeData.length - 1;
            }

            treeData[categoryIndex].children.push(
                getTreeNode(p, checkedNodes.find(({ id }) => id === p.id)?.checked /* ?? false */),
            );
        });
    } catch (e) {
        /**/
    }

    if (treeData.length !== 0) {
        treeData[0].state.open = true;
    }

    return treeData;
};

export const TestTab = () => {
    const [rowSelection, setRowSelection] = useState({});
    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const navigate = useNavigate();
    const location = useLocation();
    const invalidate = useInvalidate();
    const [selectedTest, setSelectedTest] = useState<TestPublicVerificatedResponse[]>([]);
    const [sorting, setSorting] = useState<SortingState>([{ id: "statusChangeTime", desc: false }]);
    const [filters, setFilters] = useState<{ [id: string]: any }>({});
    const [isModalTreeOpen, setIsModalTreeOpen] = useState(false);
    const [testsTree, setTestsTree] = useState<any[]>([]);
    const [selectedItems, setSelectedItems] = useState<any[]>([]);
    const [selectedAcceptedItems, setSelectedAcceptedItems] = useState<any[]>([]);
    const [selectedCount, setSelectedCount] = useState(0);
    const onFilterChangeRef = useRef<((name: string, value: any) => void) | null>(null);

    const { dialogState, openDialog, closeDialog } = useDialog();

    const [isFilterShow, setIsFilterShow] = useState(false);

    const isFiltersEmpty = useMemo(() => {
        if (Object.keys(filters).indexOf("searchQuery.contains") !== -1) {
            return false;
        }

        return Object.keys(filters).every((f) => !filters[f]);
    }, [filters]);

    const [{ pageIndex, pageSize }, setPagination] = React.useState<PaginationState>({
        pageIndex: 0,
        pageSize: 15,
    });

    const pagination = React.useMemo(
        () => ({
            pageIndex,
            pageSize,
        }),
        [pageIndex, pageSize],
    );

    const dataQuery = useQuery(
        ["tests", "collection", pagination, sorting, filters],
        async () => {
            const filtersKeys = Object.keys(filters);
            const filtersData: any = {};

            for (const element of filtersKeys) {
                if (element === "statusChangeTime") {
                    filtersData[element + ".greaterThanOrEqual"] = Math.round(
                        filters[element]["date"]["startDate"].getTime() / 1000,
                    );
                    filtersData[element + ".lessThanOrEqual"] = Math.round(
                        filters[element]["date"]["endDate"].getTime() / 1000,
                    );
                } else if (element === "user") {
                    filtersData[element + ".in"] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (element === "accountableUser.in") {
                    filtersData[element] = filters[element].map((p: any) => p.value.id).join(",");
                } else if (element === "team") {
                    filtersData[element + ".in"] = filters[element].join(",");
                } else if (element === "quiz") {
                    filtersData[element + ".in"] = filters[element].map((p: any) => p.value).join(",");
                } else if (element === "searchQuery") {
                    filtersData[element + ".contains"] = filters[element];
                } else {
                    filtersData[element] = filters[element];
                }
            }
            const sort =
                sorting.map((s: any) => `${s.id},${s.desc ? "desc" : "asc"}`).join(";") || "statusChangeTime,desc";

            return await Api.Test.ReviewList(pageIndex, pageSize, sort, filtersData);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );
    const { mutateAsync: reviewOperation } = useMutation((payload: TestVerificatedCheckMultipleRequest) => {
        return Api.Test.VerificatedCheckMultiple(payload);
    });

    const onSearch = (searchQuery: string) => {
        setFilters((prevState) => ({ ...prevState, "searchQuery.contains": searchQuery }));
    };

    // all tests request
    const testTreeDataQuery = useQuery(
        ["testsTree"],
        async () => {
            return await Api.Test.List(0, 999, [{ id: "modifyTime", desc: true }], {});
        },
        {
            keepPreviousData: true,
        },
    );

    const { data: testTreeData } = testTreeDataQuery;

    // Setting testTree data
    useEffect(() => {
        if (testTreeData?.Content) {
            setTestsTree(structurizeTestTreeData(testTreeData?.Content));
        }
    }, [testTreeData]);

    const onSubmitSection = () => {
        setSelectedAcceptedItems(selectedItems);
        if (onFilterChangeRef.current) {
            onFilterChangeRef.current(
                "quiz",
                selectedItems.map(({ name: itemName, id }) => {
                    return {
                        label: itemName,
                        value: id,
                    };
                }),
            );
        }
        setIsModalTreeOpen(false);
    };

    const loadActiveUserOptions = async (
        inputValue: string,
        prevOptions: unknown,
        { page }: { page: number },
    ): Promise<any> => {
        if (inputValue === "") {
            return {
                options: [],
                hasMore: false,
            };
        }

        const users = await Api.User.GetActiveListOptions(inputValue, page);
        const usersSelectOptions: { label: string; value: AccountableUser | UserListResponse | undefined }[] =
            users.Content.map((p) => ({ label: p.lastName + " " + p.firstName, value: p }));

        return {
            options: usersSelectOptions,
            hasMore: users.TotalPages > page,
            additional: {
                page: page + 1,
            },
        };
    };

    const responsibleList = useResponsibleList();
    const loadResponsibleListOptions = useCallback(
        async (inputValue: string): Promise<any> => {
            if (inputValue === "") {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const selectOptions = responsibleList
                .filter((p) => p.label.toLowerCase().indexOf(inputValue.toLowerCase()) !== -1)
                .map((p) => ({ label: p.label, value: p.payload }));

            return {
                options: selectOptions,
                hasMore: false,
            };
        },
        [responsibleList],
    );

    const formatUserOptionsLabel = ({
        label,
        value,
    }: {
        label: string;
        value: AccountableUser | UserListResponse | undefined;
    }): React.ReactElement => {
        return (
            <div className="flex gap-2">
                {value?.avatarId ? (
                    <img
                        src={`/service/lms-upload/api/file/download/${value.avatarId}`}
                        className="w-5 h-5 rounded-full"
                    />
                ) : (
                    <div
                        className={`flex-center w-5 h-5 rounded-full`}
                        style={{ backgroundColor: value?.defaultAvatarColor }}
                    >
                        <span className="text-white text-xxs">
                            {value?.firstName[0]}
                            {value?.lastName[0]}
                        </span>
                    </div>
                )}
                <div>{label}</div>
            </div>
        );
    };

    useEffect(() => {
        setSelectedCount(selectedItems.length);
    }, [selectedItems]);

    useEffect(() => {
        if (selectedAcceptedItems) {
            setTestsTree(
                structurizeTestTreeData(
                    testTreeData?.Content as unknown as TestListResponse[],
                    selectedAcceptedItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedItems]);

    useEffect(() => {
        if (testTreeData) {
            const selectedAcceptedItemsCur = filters.quiz
                ? (testTreeData.Content as unknown as TestListResponse[])
                      .filter(({ id: testId }) => filters.quiz.find(({ value }: { value: string }) => value === testId))
                      .map((item) => {
                          return getTreeNode(item, true);
                      })
                : [];

            setSelectedAcceptedItems(selectedAcceptedItemsCur);
            setSelectedItems(selectedAcceptedItemsCur);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const TestsColumns = React.useMemo<ColumnDef<TestPublicVerificatedResponse>[]>(
        () => [
            {
                id: "select",
                enableResizing: true,
                size: 16,
                header: ({ table }) => (
                    <Checkbox
                        checked={table.getIsAllRowsSelected()}
                        indeterminate={table.getIsSomeRowsSelected()}
                        onChange={table.getToggleAllRowsSelectedHandler()}
                    />
                ),
                cell: ({ row }) => (
                    <Checkbox
                        checked={row.getIsSelected()}
                        indeterminate={row.getIsSomeSelected()}
                        onChange={row.getToggleSelectedHandler()}
                    />
                ),
            },
            {
                header: "название",
                footer: (props) => props.column.id,
                cell: (info) => {
                    const {
                        row: {
                            original: { logoId, title },
                        },
                    } = info;
                    return (
                        <div className="group flex items-center space-x-3">
                            <img
                                className="rounded-md w-[54px] h-9"
                                src={Api.Upload.GetLogo(logoId, LogoSize.THUMB_MICRO)}
                            />
                            <span className="group-hover:text-blue">{title}</span>
                        </div>
                    );
                },
                accessorKey: "title",
            },
            {
                header: "участник",
                enableResizing: true,
                size: 250,
                cell: (info) => {
                    const {
                        row: {
                            original: {
                                user: { avatarId, firstName, lastName, defaultAvatarColor },
                            },
                        },
                    } = info;
                    return (
                        <div className="group flex items-center space-x-3">
                            <UserAvatar
                                avatarId={avatarId}
                                color={defaultAvatarColor}
                                userInitials={`${firstName?.slice(0, 1)}${lastName?.slice(0, 1)}`}
                                size={36}
                                // className="absolute bottom-6 left-6 ring-white ring-4 rounded-full"
                            />
                            <span className="group-hover:text-blue">{`${lastName} ${firstName}`}</span>
                        </div>
                    );
                },
                accessorKey: "user.firstName,user.lastName",
                footer: (props) => props.column.id,
            },
            {
                header: "отправлено",
                enableResizing: true,
                size: 200,
                cell: (info) => {
                    const {
                        row: {
                            original: { statusChangeTime },
                        },
                    } = info;
                    return (
                        <div className="flex items-center space-x-3 truncate">
                            <p className="font-normal">
                                {!!statusChangeTime &&
                                    new Date(statusChangeTime * 1000).toLocaleDateString("ru-ru", {
                                        year: "numeric",
                                        month: "long",
                                        day: "numeric",
                                        hour: "numeric",
                                        minute: "numeric",
                                    })}
                            </p>
                        </div>
                    );
                },
                accessorKey: "statusChangeTime",
                footer: (props) => props.column.id,
            },
        ],
        [],
    );

    const TestsControlButtons = (
        <div className="flex justify-end items-center space-x-4" id="validationsTestControlButtons">
            <Button
                variant="outline"
                color="secondary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                icon={<Icon icon={Icons.Filter} width={20} height={20} color="stroke-blue" />}
                iconPlacement={"left"}
                onClick={() => setIsFilterShow(!isFilterShow)}
                id="adminValidationsTestTabBtnFilter"
            >
                Фильтры
            </Button>
        </div>
    );

    const filtersConfig = [
        {
            label: "Дата отправки",
            fields: [
                {
                    accessor: "statusChangeTime",
                    type: "date-range",
                },
            ],
        },
        {
            label: "Команда",
            fields: [
                {
                    accessor: "team",
                    type: "team-multi-select",
                },
            ],
        },
        {
            label: "Участники",
            fields: [
                {
                    accessor: "user",
                    type: "async-multi-select",
                    placeholder: "Выберите участника",
                    loadOptions: loadActiveUserOptions,
                    formatOptionLabel: formatUserOptionsLabel,
                    components: {
                        MultiValueRemove: SelectMultiValueRemove,
                        ValueContainer: SelectValueContainer({}),
                    },
                    lazy: true,
                },
            ],
        },
        {
            label: "Тесты",
            fields: [
                {
                    accessor: "quiz",
                    type: "multi-select",
                    default: "",
                    placeholder: "Выберите тест",
                    options: [],
                    components: {
                        MultiValueLabel: SelectAvatarMultiValueLabel({ withAvatar: false }),
                        MultiValueRemove: SelectMultiValueRemove,
                        /*
                        ValueContainer: SelectValueContainer({
                            onClick: (e) => {
                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                    setIsModalTreeOpen((prevIsOpen) => {
                                        return !prevIsOpen;
                                    });
                                }
                            },
                        }),
                        */
                        ValueContainer: StackedValueContainer,
                        DropdownIndicator: OptionsDropdownIndicator({
                            onClick: (e) => {
                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                    setIsModalTreeOpen((prevIsOpen) => {
                                        return !prevIsOpen;
                                    });
                                }
                            },
                        }),
                    },
                    onClick: (e: SyntheticEvent) => {
                        if (!(e.target as HTMLElement).closest(".ui-combo-box__multi-value__remove")) {
                            setIsModalTreeOpen((prevIsOpen) => {
                                return !prevIsOpen;
                            });
                        }
                    },
                    menuIsOpen: false,
                },
            ],
        },
        {
            label: "Ответственный",
            fields: [
                {
                    accessor: "accountableUser.in",
                    type: "async-multi-select",
                    placeholder: "Выберите ответственного",
                    loadOptions: loadResponsibleListOptions,
                    formatOptionLabel: formatUserOptionsLabel,
                    components: {
                        MultiValueRemove: SelectMultiValueRemove,
                        ValueContainer: SelectValueContainer({}),
                    },
                    lazy: true,
                },
            ],
        },
    ];

    const selectedRowButtons = (
        <div className="flex justify-end items-center space-x-5" id="validationsTestSelectedRowButtons">
            <Button
                onClick={() => {
                    onAcceptHandler(selectedTest).then();
                }}
                color="primary"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                id="adminValidationsTestTabBtnOk"
            >
                Принять тесты
            </Button>
            <Button
                onClick={() => {
                    onDeclineHandler(selectedTest).then();
                }}
                color="danger"
                size="medium"
                className="border-[#E6E9ED] rounded-lg font-medium"
                id="adminValidationsTaskTabBtnCancel"
            >
                Отклонить тесты
            </Button>
        </div>
    );

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const testTitle = queryParams.get("testTitle");
        const testId = queryParams.get("testId");

        if (testTitle && testId) {
            setFilters({ quiz: [{ label: testTitle, value: testId }] });
        }
    }, [location.search]);

    const onAcceptHandler = useCallback(
        async (data: TestPublicVerificatedResponse[]) => {
            openDialog({
                title: data.length > 1 ? "Принять ответы" : "Принять ответ",
                description: data.length > 1 ? `Выбрано элементов: ${data.length}` : "«" + data[0].title + "»",
                content:
                    data.length > 1
                        ? "Вы уверены, что хотите принять все ответы? Вы подтверждаете, что пользователи все выполнили верно"
                        : "Вы уверены, что хотите принять ответ? Вы подтверждаете, что пользователи все выполнили верно",
                closeBtnText: "Отмена",
                submitBtnText: "Принять",
                submitBtnColor: "primary",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    reviewOperation({
                        solutionIds: selectedTest.map(({ id }) => id),
                        approved: true,
                    }).then(() => {
                        invalidate("tests");
                        invalidate("review");
                        setRowSelection({});
                        closeDialog();
                        flash.success(data.length > 1 ? "Ответы приняты!" : "Ответ принят!");
                    });
                },
            });
        },
        [invalidate, closeDialog, openDialog, selectedTest, reviewOperation],
    );
    const onDeclineHandler = useCallback(
        async (data: TestPublicVerificatedResponse[]) => {
            openDialog({
                title: data.length > 1 ? "Отклонить ответы" : "Отклонить ответ",
                description: data.length > 1 ? `Выбрано элементов: ${data.length}` : "«" + data[0].title + "»",
                content: (
                    <>
                        {
                            <>
                                {data.length > 1
                                    ? "Вы уверены, что хотите отклонить все ответы? Они будут помечены у пользователя, как неправильные"
                                    : "Вы уверены, что хотите отклонить ответ? Он будет помечен у пользователя как неправильный"}
                            </>
                        }
                    </>
                ),
                closeBtnText: "Отмена",
                submitBtnText: "Отклонить",
                submitBtnColor: "danger",
                onRequestClose: () => closeDialog(),
                onRequestSubmit: () => {
                    reviewOperation({
                        solutionIds: selectedTest.map(({ id }) => id),
                        approved: false,
                    }).then(() => {
                        invalidate("tests");
                        invalidate("review");

                        closeDialog();
                        flash.success(data.length > 1 ? "Ответы отклонены!" : "Ответ отклонён!");
                    });
                },
            });
        },
        [invalidate, closeDialog, openDialog, selectedTest, reviewOperation],
    );

    return (
        <div className="h-full flex flex-col grow" id="validationsTestTab">
            <Confirmation {...dialogState} />

            {isFiltersEmpty && dataQuery.data?.Content && dataQuery.data?.Content.length === 0 && (
                <Empty
                    title="Все тесты проверены!"
                    description="Любые тесты, отправленные на проверку участниками, отражаются на данной вкладке"
                    topElement={
                        <div className="flex-center mb-4">
                            <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                                <Icon icon={Icons.EmojiHappy} width={"36px"} height={"36px"} color={"fill-primary"} />
                            </div>
                        </div>
                    }
                ></Empty>
            )}

            {!isFiltersEmpty || (dataQuery.data?.Content && dataQuery.data?.Content.length > 0) ? (
                <>
                    <Table
                        id="adminValidationsTestTab"
                        columns={TestsColumns}
                        searchTitle="Поиск по названию"
                        data={dataQuery.data?.Content}
                        isFetching={dataQuery.isFetching}
                        pagination={pagination}
                        onPaginationChange={setPagination}
                        pageCount={dataQuery.data?.TotalPages}
                        rowSelection={rowSelection}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        onRowClick={(row) => {
                            navigate(`/admin/validations/test/${row.original.id}`, {
                                replace: true,
                                state: row.original,
                            });
                        }}
                        columnFilters={columnFilters}
                        controlButtons={TestsControlButtons}
                        selectButtons={selectedRowButtons}
                        setSelectedRows={setSelectedTest}
                        emptyMessage={"По заданным параметрам результатов нет"}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        bodyCellClassName={"cursor-pointer"}
                        onSearch={onSearch}
                    />
                    <Filter
                        isActive={isFilterShow}
                        setIsActive={setIsFilterShow}
                        configuration={filtersConfig as IFilterRow[]}
                        filters={filters}
                        onChange={setFilters}
                        onFilterChangeRef={onFilterChangeRef}
                    />
                    <ModalTree
                        isOpen={isModalTreeOpen}
                        title={`Выбор теста`}
                        setIsOpen={(open: boolean) => {
                            setSelectedItems(selectedAcceptedItems);
                            setTestsTree(
                                structurizeTestTreeData(
                                    testTreeData?.Content as unknown as TestListResponse[],
                                    selectedAcceptedItems.map(({ id, state }) => {
                                        return {
                                            id,
                                            checked: state.checked,
                                        };
                                    }),
                                ),
                            );

                            setIsModalTreeOpen(open);
                        }}
                        treeData={testsTree}
                        checkedChange={(selectedNodes) => {
                            setSelectedItems(selectedNodes);
                        }}
                        onSubmit={onSubmitSection}
                        submitButtonTitle="Выбрать"
                        selectedCount={selectedCount}
                        watchSelected={false}
                    />
                </>
            ) : null}
        </div>
    );
};
