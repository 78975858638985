import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { ProgressStatus } from "Enums";
import { Tooltip } from "Uikit/Tooltip";
import { Button, Icon, Icons } from "Uikit/index";
import { ProgramSectionItem } from "Api/Responses/ProgramResponse";
import Api from "Api/index";
import { LogoSize } from "Api/Services/UploadApi";
import { formatLeadTime } from "../Tasks/utils";

interface ProgramCourseCardProps {
    course: ProgramSectionItem;
    programId: string;
    showStatistics?: boolean;
}

const ProgressBar = ({ value: progress }: { value?: number }) => {
    if (!progress) {
        return null;
    }

    const factor = (progress ?? 0) > 1 ? 1 : 100;
    const percentProgress = progress && Math.min(100, Math.abs(Math.round(progress * factor)));
    const rightBorderRadius = (percentProgress ?? 0) >= 98 ? "rounded-br-xl" : "";

    return (
        <div className={"rounded-b-xl h-1.75 left-0 w-full absolute bottom-0 bg-blue-gray"}>
            <div
                style={{ width: `${percentProgress}%` }}
                className={clsx("rounded-bl-xl h-full bg-blue", rightBorderRadius)}
            />
        </div>
    );
};

export const ProgramCourseCard = ({ course, programId, showStatistics = false }: ProgramCourseCardProps) => {
    const navigate = useNavigate();

    const [isTooltip, setIsTooltip] = useState(false);

    const blocked = course.progressStatus === ProgressStatus.BLOCKED;
    const blockedClass = blocked ? "opacity-50" : "";

    const handleClick = async () => {
        if (blocked) {
            return;
        }

        navigate(
            showStatistics ? `/training/course/${course.id}/statistics` : `/training/program/${programId}/${course.id}`,
        );
    };

    return (
        <Tooltip
            visible={blocked && isTooltip}
            content="Будет доступно после прохождения предыдущих материалов"
            maxWidth={274}
        >
            <div className={clsx("w-full sm:w-68.5 2xl:w-[342px]", !blocked && "cursor-pointer")} onClick={handleClick}>
                <div className="relative h-[60vw] sm:h-41 2xl:h-[205px] mb-3 bg-green-light rounded-2xl 2xl:rounded-3xl overflow-hidden">
                    <div
                        className={clsx(
                            "flex absolute left-3 top-3 2xl:top-3.75 2xl:left-3.75 space-x-1.5 2xl:space-x-2 w-full h-5.25 2xl:h-6.5",
                            blockedClass,
                        )}
                    >
                        {course.progressStatus === ProgressStatus.PASSED && (
                            <div className="flex items-center justify-center rounded-md 2xl:rounded-lg bg-primary py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 text-white p3 2xl:text-2sm">
                                <Icon
                                    className="mr-1 2xl:mr-1.25 2xl:!w-4.5 2xl:!h-4.5"
                                    icon={Icons.TickAll}
                                    width={14}
                                    height={14}
                                    color="fill-white"
                                />
                                Пройдено
                            </div>
                        )}
                        {course.progressStatus === ProgressStatus.FAILED && (
                            <div className="flex items-center justify-center rounded-md 2xl:rounded-lg bg-red py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 text-white p3 2xl:text-2sm">
                                Провален
                            </div>
                        )}
                        {course.progressStatus === ProgressStatus.ON_REVIEW && (
                            <div className="flex items-center justify-center rounded-md 2xl:rounded-lg bg-red py-0.5 2xl:py-0.75 px-1.5 2xl:px-2 text-white p3 2xl:text-2sm">
                                На проверке
                            </div>
                        )}
                        <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 text-white 2xl:text-2sm">
                            Курс
                        </div>
                    </div>
                    {blocked && (
                        <Button
                            className="absolute top-3 2xl:top-3.75 right-3 2xl:right-3.75 !bg-black-50 py-0.5 px-0.5 2xl:w-8 2xl:h-8"
                            shape="round"
                            size="tiny"
                        >
                            <Icon
                                icon={Icons.LockClose}
                                width={19}
                                height={19}
                                color="fill-white"
                                onMouseLeave={() => setIsTooltip(false)}
                                onMouseEnter={() => setIsTooltip(true)}
                                className="2xl:!w-6 2xl:!h-6"
                            />
                        </Button>
                    )}
                    {course.logoId && (
                        <img
                            className={clsx("rounded-2xl 2xl:rounded-3xl w-full h-full object-cover", blockedClass)}
                            src={Api.Upload.GetLogo(course.logoId, LogoSize.THUMB_MINI)}
                            alt={course.title}
                        />
                    )}
                    <div
                        className={clsx(
                            "flex absolute left-3 2xl:left-3.75 bottom-3 2xl:bottom-3.75 right-3 2xl:right-3.75 h-5.25 2xl:h-6.5",
                            blockedClass,
                        )}
                    >
                        {/* <div className="flex items-center rounded-2sm bg-black-50 p3 px-1.5 py-0.5 text-white">
                            <Icon icon={Icons.Star} width={14} height={14} className="mr-1" color="fill-white" />
                            {course.avgReviewRating}
                        </div> */}
                        <div className="flex items-center rounded-md 2xl:rounded-lg bg-black-50 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 text-white 2xl:text-2sm">
                            <Icon
                                icon={Icons.TrophyFilled}
                                width={14}
                                height={14}
                                className="mr-1 2xl:mr-1.25"
                                color="fill-white"
                            />
                            {course.ratingPoints}
                        </div>
                        <div className="flex items-center ml-auto rounded-md 2xl:rounded-lg bg-black-50 p3 px-1.5 2xl:px-2 py-0.5 2xl:py-0.75 text-white 2xl:text-2sm">
                            {formatLeadTime(course.approxCompletionMinutes * 60)}
                        </div>
                    </div>

                    <ProgressBar value={course.progressPercent} />
                </div>
                <MultiClumpTooltip label={course.title} clamp={1}></MultiClumpTooltip>
            </div>
        </Tooltip>
    );
};
