import React, { useEffect, useState } from "react";
import { UsersModal } from "Pages/Admin/Statistics/Modal/UsersModal";
import { Breadcrumbs, Button, ComboBox, FormGroup, Label, OptionsDropdownIndicator } from "Uikit";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { useQuery } from "react-query";
import Api from "Api";
import { ResourceType, StatisticsAttempts } from "Enums";
import { attemptsOptions } from "../TestWithoutCourse";
import { numWord } from "helpers/numWord";
import { IRequestSettingsItem, ReportSettings } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { ReportTestUserTable } from "./ReportTestUserTable";
import { StatisticTestUserRequest } from "Api/Requests/StatisticRequest";
import { SingleUserContainer } from "../../SingleUserContainer";

export const reportSettings = [
    {
        title: "Тест",
        key: "quiz",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Название курса",
                value: "courseName",
                checked: true,
            },
            {
                title: "Статус видимости курса",
                value: "courseVisibility",
                checked: true,
            },
            {
                title: "Категория теста",
                value: "category",
                checked: true,
            },
            {
                title: "Название теста",
                value: "name",
                checked: true,
            },
            {
                title: "Статус прохождения теста",
                value: "status",
                checked: true,
            },
            {
                title: "Статус видимости теста",
                value: "visibility",
                checked: true,
            },
        ],
    },
    {
        title: "Прохождение",
        key: "passing",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Дата начала",
                value: "startDate",
                checked: true,
            },
            {
                title: "Дата завершения",
                value: "endDate",
                checked: true,
            },
            {
                title: "Дедлайн",
                value: "deadlineDate",
                checked: true,
            },
            {
                title: "Затрачено времени",
                value: "spendTime",
                checked: true,
            },
            {
                title: "Номер попытки",
                value: "attemptNumber",
                checked: true,
            },
            {
                title: "Набранные баллы",
                value: "rating",
                checked: true,
            },
            {
                title: "Максимальное количество баллов",
                value: "maxRating",
                checked: true,
            },
            {
                title: "Набранный % правильных ответов",
                value: "percentageRating",
                checked: true,
            },
            {
                title: "Необходимый % правильных ответов",
                value: "passPercentage",
                checked: true,
            },
        ],
    },
];

export const TestUser = () => {
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [showReportsTable, setShowReportsTable] = useState(false);
    const [attempts, setAttempts] = useState(attemptsOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [userTestsCount, setUserTestsCount] = useState(0);

    // all users request
    useQuery(
        ["users", "collection"],
        () =>
            Api.User.GetMembersList(0, 2000, [{ id: "firstName,lastName", desc: false }], {
                "state.in": "ACTIVE,BLOCKED",
            }),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setAllUsers(data.Content);
            },
        },
    );

    useEffect(() => {
        if (!users[0]) {
            return;
        }

        const fetchTestsCount = async () => {
            const testsCount = await Api.Statistic.GetUserAvailableResourceCount(ResourceType.QUIZ, users[0].id);
            setUserTestsCount(+testsCount);
        };

        fetchTestsCount();
    }, [users]);

    const createReport = () => {
        setShowReportsTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTestUserRequest = {
            userIds: users.map(({ id }) => id),
            attemptNumber: attempts.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateTestUserStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Тесты" />
                <Breadcrumbs.Link title="Отчет по участнику" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по участнику</h1>
                <div className="w-full h-full mt-5">
                    <ComboBox
                        placeholder="Выберите участника"
                        value={users[0] ?? null}
                        components={{
                            DropdownIndicator: OptionsDropdownIndicator({
                                onClick: (e) => {
                                    if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                        setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                    }
                                },
                            }),
                            ValueContainer: SingleUserContainer,
                        }}
                        onMenuOpen={() => setShowUsersModal((prevIsOpen) => !prevIsOpen)}
                        isSearchable={false}
                        isCreatable={false}
                        isClearable={false}
                        menuIsOpen={false}
                    />
                </div>
                {/* Выбор участника - модалка */}
                <UsersModal
                    isOpen={showUsersModal}
                    onClose={() => {
                        setShowUsersModal(false);
                    }}
                    onSubmit={(users) => {
                        setUsers(
                            users.map((user) => {
                                const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                return {
                                    ...user,
                                    value: {
                                        avatarId,
                                        firstName,
                                        lastName,
                                        defaultAvatarColor,
                                    },
                                    label: `${lastName} ${firstName}`,
                                };
                            }),
                        );
                        setShowReportsTable(false);
                    }}
                    selectedUsersDefault={users}
                    allUsers={allUsers}
                    title="Выбор участника"
                    isSingleSelect={true}
                />
                {users.length > 0 && (
                    <>
                        <div className="mt-7 flex-wrap relative z-[70]">
                            <div className="grid items-center gap-6.5 grid-cols-2">
                                <FormGroup className="!mb-0">
                                    <Label>Собрать статистику</Label>
                                    <ComboBox
                                        onChange={(value) => {
                                            setAttempts(value as { value: StatisticsAttempts; label: string });
                                            setShowReportsTable(false);
                                        }}
                                        value={attempts}
                                        options={attemptsOptions}
                                        isSearchable={false}
                                        isCreatable={false}
                                        isClearable={false}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                        <div className="mt-9">
                            {showReportsTable ? (
                                <>
                                    <ReportTestUserTable
                                        queryPayload={createPayloadParams()}
                                        onClickLoadReportFile={() => setShowReportSettings(true)}
                                    />
                                    <ReportSettings
                                        isActive={showReportSettings}
                                        setIsActive={setShowReportSettings}
                                        settings={reportSettings}
                                        onSubmit={onSubmit}
                                        isReportFileFetching={false}
                                    />
                                </>
                            ) : (
                                <Button
                                    key={1}
                                    onClick={() => createReport()}
                                    variant="standard"
                                    size={"medium"}
                                    color={"primary"}
                                    className="w-full"
                                >
                                    {`Сформировать отчет по ${userTestsCount} ${numWord(userTestsCount, [
                                        "тесту",
                                        "тестам",
                                        "тестам",
                                    ])}`}
                                </Button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};
