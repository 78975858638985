import React, { useEffect, useState } from "react";
import { Button, ComboBox, FormGroup, Label, OptionsDropdownIndicator, SelectAvatarMultiValueLabel } from "Uikit";
import Api from "Api";
import { TasksModalTree } from "../../Tasks/TasksModalTree";
import { CourseListResponse } from "Api/Responses/CourseResponse";
import { IStatisticValue, structurizeCourseTreeData, structurizeTestTreeData } from "../../utils";
import { TTaskFilterValue } from "../TestWithoutCourse";
import { useQuery } from "react-query";
import {
    ProgressStatus,
    ResourceState,
    StatisticsAttempts,
    StatisticsReportAccess,
    StatisticsUsersStatus,
} from "Enums";
import { TestListResponse } from "Api/Responses/TestResponse";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { UsersModal } from "Pages/Admin/Statistics/Modal/UsersModal";
import { accessOptions } from "../TestWithoutCourse";
import { progressOptions } from "./TestAnswersWithoutCourse";
import { attemptsOptions } from "../TestWithoutCourse";
import { usersStatusOptions } from "../TestWithoutCourse";
import { numWord } from "helpers/numWord";
import { ReportAnswersTable } from "./ReportAnswersTable";
import { StatisticTestAnswersRequest } from "Api/Requests/StatisticRequest";
import { TestAnswersReportSettings } from "./TestAnswersReportSettings";
import { IRequestSettingsItem } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";
import { SingleValueContainer } from "../../SingleValueContainer";

const reportSettings = [
    {
        title: "Участник",
        key: "user",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Фамилия, Имя участника",
                value: "name",
                checked: true,
            },
            {
                title: "Логин",
                value: "login",
                checked: true,
            },
            {
                title: "Электронная почта",
                value: "email",
                checked: true,
            },
            {
                title: "Должность",
                value: "job",
                checked: true,
            },
            {
                title: "Офис",
                value: "office",
                checked: true,
            },
            {
                title: "Статус участника",
                value: "status",
                checked: true,
            },
            {
                title: "Команда",
                value: "command",
                checked: true,
            },
            {
                title: "Путь к команде",
                value: "commandPath",
                checked: true,
            },
            {
                title: "Наставник",
                value: "mentor",
                checked: true,
            },
            {
                title: "Доступ к курсу на текущий момент",
                value: "courseAccess",
                checked: true,
            },
        ],
    },
    {
        title: "Тест",
        key: "quiz",
        items: [
            {
                title: "Все",
                value: "all",
                checked: true,
            },
            {
                title: "Название теста",
                value: "name",
                checked: true,
            },
            {
                title: "Дата начала",
                value: "startDate",
                checked: true,
            },
            {
                title: "Дата завершения",
                value: "endDate",
                checked: true,
            },
            {
                title: "Дедлайн",
                value: "deadlineDate",
                checked: true,
            },
            {
                title: "Затрачено времени",
                value: "spendTime",
                checked: true,
            },
            {
                title: "Номер попытки",
                value: "attemptNumber",
                checked: true,
            },
            {
                title: "Набранные баллы",
                value: "rating",
                checked: true,
            },
            {
                title: "Максимальное количество баллов",
                value: "maxRating",
                checked: true,
            },
            {
                title: "Набранный % правильных ответов",
                value: "percentageRating",
                checked: true,
            },
            {
                title: "Необходимый % правильных ответов",
                value: "requiredPercentage",
                checked: true,
            },
            {
                title: "Статус проходждения теста",
                value: "status",
                checked: true,
            },
            {
                title: "Правильных ответов",
                value: "correctAnswers",
                checked: true,
            },
            {
                title: "Всего вопросов",
                value: "totalQuestions",
                checked: true,
            },
        ],
    },
];

export const TestAnswersWithinCourse = () => {
    const [selectedCourseItems, setSelectedCourseItems] = useState<any[]>([]);
    const [selectedAcceptedCourseItems, setSelectedAcceptedCourseItems] = useState<any[]>([]);
    const [coursesValues, setCoursesValues] = useState<IStatisticValue[]>([]);
    const [showReportQuestionsTable, setShowReportQuestionsTable] = useState(false);
    const [isModalCourseTreeOpen, setIsModalCourseTreeOpen] = useState(false);
    const [coursesTree, setCoursesTree] = useState<any[]>([]);
    const [isCourseFilterEmpty, setIsCourseFilterEmpty] = useState(true);
    const [selectedCoursesCount, setSelectedCoursesCount] = useState(0);
    const [selectedTestItems, setSelectedTestItems] = useState<any[]>([]);
    const [selectedAcceptedTestItems, setSelectedAcceptedTestItems] = useState<any[]>([]);
    const [testsValues, setTestsValues] = useState<IStatisticValue[]>([]);
    const [isModalTestTreeOpen, setIsModalTestTreeOpen] = useState(false);
    const [isTestFilterEmpty, setIsTestFilterEmpty] = useState(true);
    const [testsTree, setTestsTree] = useState<any[]>([]);
    const [selectedTestsCount, setSelectedTestsCount] = useState(0);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [testQuestionsCount, setTestQuestionsCount] = useState(0);
    const [access, setAccess] = useState(accessOptions[0]);
    const [progress, setProgress] = useState<{ value: ProgressStatus; label: string }>(progressOptions[0]);
    const [attempts, setAttempts] = useState(attemptsOptions[0]);
    const [usersStatus, setUsersStatus] = useState(usersStatusOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);

    // all courses request
    const coursesDataQuery = useQuery(
        ["coursesTree"],
        async () => {
            return await Api.Course.List(0, 999, [{ id: "modifyTimestamp", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: courseTreeData } = coursesDataQuery;

    // all tests request
    const testsDataQuery = useQuery(
        ["testsTreeFailedPassed", coursesValues],
        async () => {
            return await Api.Test.List(0, 999, [{ id: "modifyTime", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
                "courseId.in": [coursesValues.map(({ id }) => id)],
                "progressStatus.in": [ProgressStatus.PASSED, ProgressStatus.FAILED],
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: testTreeData } = testsDataQuery;

    // all users request
    useQuery(
        ["users", "collection"],
        () =>
            Api.User.GetMembersList(0, 2000, [{ id: "firstName,lastName", desc: false }], {
                "state.in": "ACTIVE,BLOCKED",
            }),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setAllUsers(data.Content);
            },
        },
    );

    const onSubmitCourseSection = () => {
        setSelectedAcceptedCourseItems(selectedCourseItems);
        setIsModalCourseTreeOpen(false);
        setShowReportQuestionsTable(false);
    };

    const onSubmitTestSection = () => {
        setSelectedAcceptedTestItems(selectedTestItems);
        setIsModalTestTreeOpen(false);
        setShowReportQuestionsTable(false);
    };

    const createReport = () => {
        setShowReportQuestionsTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTestAnswersRequest = {
            courseIds: coursesValues.map(({ id }) => id),
            quizIds: testsValues.map(({ id }) => id),
            userIds: users.map(({ id }) => id),
            access: access.value,
            status: progress.value,
            attemptNumber: attempts.value,
            userStatus: usersStatus.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked?: IRequestSettingsItem[]) => {
        setShowReportSettings(false);

        const request: any = {};

        request.params = createPayloadParams();

        if (settingsChecked) {
            settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
                request[key] = items
                    .filter((item) => item.value !== "all")
                    .reduce((prev, cur) => {
                        return {
                            ...prev,
                            [cur.value]: cur.checked,
                        };
                    }, {});
            });

            await Api.Statistic.GenerateTestAnswersStatisticReportFile(request);
        } else {
            await Api.Statistic.GenerateTestAnswersStatisticSummaryReportFile(request);
        }
    };

    // Setting courseTree data
    useEffect(() => {
        if (courseTreeData?.Content && selectedAcceptedCourseItems.length === 0) {
            setCoursesTree(
                structurizeCourseTreeData(
                    courseTreeData?.Content,
                    selectedAcceptedCourseItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                            selected: true,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseTreeData]);

    useEffect(() => {
        setSelectedCoursesCount(selectedCourseItems.length);
    }, [selectedCourseItems]);

    useEffect(() => {
        setCoursesTree(
            structurizeCourseTreeData(
                courseTreeData?.Content as unknown as CourseListResponse[],
                selectedAcceptedCourseItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
            ),
        );

        setCoursesValues(
            selectedAcceptedCourseItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setSelectedAcceptedTestItems([]);
        setSelectedTestItems([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedCourseItems]);

    // Setting testTree data
    useEffect(() => {
        if (testTreeData?.Content && selectedAcceptedTestItems.length === 0) {
            setTestsTree(
                structurizeTestTreeData(
                    testTreeData?.Content,
                    selectedAcceptedTestItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                            selected: true,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testTreeData]);

    useEffect(() => {
        setSelectedTestsCount(selectedTestItems.length);
    }, [selectedTestItems]);

    useEffect(() => {
        setTestsTree(
            structurizeTestTreeData(
                testTreeData?.Content as unknown as TestListResponse[],
                selectedAcceptedTestItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
            ),
        );

        setTestsValues(
            selectedAcceptedTestItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        if (testTreeData?.Content && selectedAcceptedTestItems.length !== 0) {
            const selectedTest = testTreeData.Content.find((item) => item.id === selectedAcceptedTestItems[0].id);
            setTestQuestionsCount(selectedTest ? selectedTest.questionsCount : 0);
        }

        if (selectedAcceptedTestItems.length === 0) {
            setUsers(allUsers);
            setAccess(accessOptions[0]);
            setProgress(progressOptions[0]);
            setAttempts(attemptsOptions[0]);
            setUsersStatus(usersStatusOptions[0]);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedTestItems]);

    useEffect(() => {
        setUsers(allUsers);
        setAllUsersCount(allUsers.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers]);

    return (
        <>
            <div className="grid items-center gap-6.5 grid-cols-2">
                {/* Выбор курса */}
                <ComboBox
                    placeholder="Выберите курс"
                    onChange={(value) => {
                        setSelectedAcceptedCourseItems([value]);
                        setShowReportQuestionsTable(false);
                    }}
                    value={coursesValues[0] ?? null}
                    components={{
                        DropdownIndicator: OptionsDropdownIndicator({
                            onClick: (e) => {
                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                    setIsModalCourseTreeOpen((prevIsOpen) => !prevIsOpen);
                                }
                            },
                        }),

                        ValueContainer: SingleValueContainer,
                    }}
                    onMenuOpen={() => setIsModalCourseTreeOpen((prevIsOpen) => !prevIsOpen)}
                    /*
                    allPlaceholder={
                        ((courseTreeData?.Content as unknown as CourseListResponse[]) ?? []).length > 0 &&
                        coursesValues.length === courseTreeData?.Content.length
                    }
                    */
                    isSearchable={false}
                    isCreatable={false}
                    isClearable={false}
                    menuIsOpen={false}
                />
                {/* Выбор теста */}
                {selectedAcceptedCourseItems.length > 0 && (
                    <ComboBox
                        placeholder="Выберите тест"
                        onChange={(value) => {
                            setSelectedAcceptedTestItems([value]);
                            setShowReportQuestionsTable(false);
                        }}
                        value={testsValues[0] ?? null}
                        components={{
                            DropdownIndicator: OptionsDropdownIndicator({
                                onClick: (e) => {
                                    if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                        setIsModalTestTreeOpen((prevIsOpen) => !prevIsOpen);
                                    }
                                },
                            }),

                            ValueContainer: SingleValueContainer,
                        }}
                        onMenuOpen={() => setIsModalTestTreeOpen((prevIsOpen) => !prevIsOpen)}
                        /*
                            allPlaceholder={
                                ((courseTreeData?.Content as unknown as CourseListResponse[]) ?? []).length > 0 &&
                                coursesValues.length === courseTreeData?.Content.length
                            }
                            */
                        isSearchable={false}
                        isCreatable={false}
                        isClearable={false}
                        menuIsOpen={false}
                    />
                )}
            </div>
            {/* Выбор курса - модалка */}
            <TasksModalTree
                isOpen={isModalCourseTreeOpen}
                title="Выбор курса"
                setIsOpen={(open: boolean) => {
                    setSelectedCourseItems(selectedAcceptedCourseItems);
                    setCoursesTree(
                        structurizeCourseTreeData(
                            courseTreeData?.Content as unknown as CourseListResponse[],
                            selectedAcceptedCourseItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );

                    setIsModalCourseTreeOpen(open);
                    setIsCourseFilterEmpty(true);
                }}
                treeData={coursesTree}
                /*
                checkedChange={(selectedNodes) => {
                    setSelectedCourseItems(selectedNodes);
                }}
                */
                onSelectNode={(node) => setSelectedCourseItems([node])}
                checkable={false}
                selectable={true}
                checkOnNameClick={true}
                showSelectedCounter={false}
                onSubmit={onSubmitCourseSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedCoursesCount}
                disableRootSelection
                onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                    setCoursesTree(
                        structurizeCourseTreeData(
                            (courseTreeData?.Content as unknown as CourseListResponse[]).filter(({ state, title }) => {
                                return (
                                    (!query ||
                                        title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                        query.trim() === "") &&
                                    (state === value || value === "ALL")
                                );
                            }),
                            selectedCourseItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );
                    setIsCourseFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={
                    isCourseFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"
                }
            />
            {/* Выбор теста - модалка */}
            <TasksModalTree
                isOpen={isModalTestTreeOpen}
                title="Выбор теста"
                setIsOpen={(open: boolean) => {
                    setSelectedTestItems(selectedAcceptedTestItems);
                    setTestsTree(
                        structurizeTestTreeData(
                            testTreeData?.Content as unknown as TestListResponse[],
                            selectedAcceptedTestItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );

                    setIsModalTestTreeOpen(open);
                    setIsTestFilterEmpty(true);
                }}
                treeData={testsTree}
                /*
                checkedChange={(selectedNodes) => {
                    setSelectedCourseItems(selectedNodes);
                }}
                */
                onSelectNode={(node) => setSelectedTestItems([node])}
                checkable={false}
                selectable={true}
                checkOnNameClick={true}
                showSelectedCounter={false}
                onSubmit={onSubmitTestSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedTestsCount}
                disableRootSelection
                onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                    setTestsTree(
                        structurizeTestTreeData(
                            (testTreeData?.Content as unknown as TestListResponse[]).filter(({ state, title }) => {
                                return (
                                    (!query ||
                                        title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                        query.trim() === "") &&
                                    (state === value || value === "ALL")
                                );
                            }),
                            selectedTestItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );
                    setIsTestFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={isTestFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"}
            />
            {selectedAcceptedTestItems.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            {/* Выбор участников */}
                            <FormGroup className="!mb-0">
                                <Label>Участники</Label>
                                <ComboBox
                                    placeholder="Выберите одного или нескольких участников"
                                    onChange={(options, action) => {
                                        if (action && action.action === "clear") {
                                            setUsers([]);
                                        }
                                        if (action && action.action === "remove-value") {
                                            setUsers(options as MembersListResponse[]);
                                        }
                                        setShowReportQuestionsTable(false);
                                    }}
                                    value={users}
                                    components={{
                                        DropdownIndicator: OptionsDropdownIndicator({
                                            onClick: (e) => {
                                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                                    setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                                }
                                            },
                                        }),
                                        // ValueContainer: SelectValueContainer({
                                        //     onClick: (e) => {
                                        //         if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                        //             setShowUsersModal((prevIsOpen) => {
                                        //                 return !prevIsOpen;
                                        //             });
                                        //         }
                                        //     },
                                        //     // oneRow: true
                                        //     allPlaceholder: users.length > 0 && allUsersCount === users.length,
                                        // }),
                                        ValueContainer: StackedValueContainer,
                                        MultiValueLabel: SelectAvatarMultiValueLabel({}),
                                    }}
                                    onClick={(e) => {
                                        if (!(e.target as HTMLElement).closest(".ui-combo-box__multi-value__remove")) {
                                            setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                        }
                                    }}
                                    allPlaceholder={users.length > 0 && allUsersCount === users.length}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                    menuIsOpen={false}
                                    isMulti
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Доступ к курсу на текущий момент</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAccess(value as { value: StatisticsReportAccess; label: string });
                                        setShowReportQuestionsTable(false);
                                    }}
                                    value={access}
                                    options={accessOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус прохождения теста</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setProgress(value as { value: ProgressStatus; label: string });
                                        setShowReportQuestionsTable(false);
                                    }}
                                    value={progress}
                                    options={progressOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Собрать статистику</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAttempts(value as { value: StatisticsAttempts; label: string });
                                        setShowReportQuestionsTable(false);
                                    }}
                                    value={attempts}
                                    options={attemptsOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус участников</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setUsersStatus(value as { value: StatisticsUsersStatus; label: string });
                                        setShowReportQuestionsTable(false);
                                    }}
                                    value={usersStatus}
                                    options={usersStatusOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    {/* Выбор участников - модалка */}
                    <UsersModal
                        isOpen={showUsersModal}
                        onClose={() => {
                            setShowUsersModal(false);
                        }}
                        onSubmit={(users) => {
                            setUsers(
                                users.map((user) => {
                                    const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                    return {
                                        ...user,
                                        value: {
                                            avatarId,
                                            firstName,
                                            lastName,
                                            defaultAvatarColor,
                                        },
                                        label: `${lastName} ${firstName}`,
                                    };
                                }),
                            );
                            setShowReportQuestionsTable(false);
                        }}
                        selectedUsersDefault={users}
                        allUsers={allUsers}
                    />
                    <div className="mt-9">
                        {showReportQuestionsTable ? (
                            <>
                                <ReportAnswersTable
                                    queryPayload={createPayloadParams()}
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                />
                                <TestAnswersReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                                disabled={!users.length}
                            >
                                {`Сформировать отчет по ${testQuestionsCount} ${numWord(testQuestionsCount, [
                                    "вопросу",
                                    "вопросам",
                                    "вопросам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
