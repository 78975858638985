import React from "react";
import { components, GroupBase, ValueContainerProps } from "react-select";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { UserAvatar } from "Uikit/UserAvatar/UserAvatar";

export const SingleUserContainer = ({
    children,
    getValue,
    ...rest
}: ValueContainerProps<MembersListResponse, true, GroupBase<any>>) => {
    const [value] = getValue();

    if (value) {
        return (
            <components.ValueContainer getValue={getValue} {...rest}>
                <div className="flex space-x-2 items-center">
                    <UserAvatar
                        avatarId={value?.avatarId}
                        color={value?.defaultAvatarColor}
                        userInitials={`${value?.firstName?.slice(0, 1)}${value?.lastName?.slice(0, 1)}`}
                        size={20}
                    />
                    <div>{children}</div>
                </div>
            </components.ValueContainer>
        );
    }

    return (
        <components.ValueContainer getValue={getValue} {...rest}>
            <div className="flex items-center">{children}</div>
        </components.ValueContainer>
    );
};
