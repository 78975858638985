import { BaseRequest } from "../BaseRequest";
import dayjs from "dayjs";

export class UserRequest extends BaseRequest {
    public id?: string;
    public avatarId?: string;
    public jobTitleId?: string;
    public officeId?: string;
    public role: string;
    public roleSections: string[];
    public roleTeams: string[];
    public allTeams?: boolean | null;
    public teamIds: string[];
    public mentorTeamIds: string[];
    public contentManagerTeamIds: string[];
    public login: string;
    public password: string;
    public firstName: string;
    public lastName: string;
    public middleName: string;
    public email: string;
    public phone: string;
    public defaultAvatarColor?: string;
    public enabledNotifications: string[];
    public enabledCommenting: boolean;
    public lastModificationDate?: number;
    public companyId?: string;

    getLastModificationDate = () => {
        return this.lastModificationDate ? dayjs(this.lastModificationDate) : null;
    };
}

export class UserPhoneValidationRequest {
    public userID?: string;
    public phone: string;
}

export interface IEditUserRequest {
    email?: string | null;
    phone?: string;
    allowPush?: boolean;
    allowEmail?: boolean;
    oldPassword?: string;
    newPassword?: string;
    avatarId?: string | null;
}

export class EditUserRequest extends BaseRequest implements IEditUserRequest {
    public email?: string;
    public phone?: string;
    public allowPush?: boolean;
    public allowEmail?: boolean;
    public oldPassword?: string;
    public newPassword?: string;
}

export class UserLoginValidationRequest {
    public login: string;

    constructor(login: string) {
        this.login = login;
    }
}
