import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useQuery } from "react-query";

import { useCurrentUser } from "hooks/useCurrentUser";
import { Button } from "Uikit";
import { useInvalidate } from "hooks/useInvalidate";
import { setIsFavorite, setIsHidden } from "slices/headerSlice";
import Api from "Api";
import { setCurrentMaterial } from "slices/programSlice";
import { ResourceType } from "Enums";
import { FeedbackForm } from "Components/FeedbackForm/FeedbackForm";
import { formatLeadTime } from "../Tasks/utils";
import { useGetUICourse, useGetUICourseProgress } from "./Course.hooks";
import { numCapEnd } from "helpers/numCapEnd";

export const CourseComplete = () => {
    const dispatch = useDispatch();
    const { courseId = "", programId = "" } = useParams<{ courseId: string; programId: string }>();
    const currentUser = useCurrentUser();

    const navigate = useNavigate();
    const invalidate = useInvalidate();

    const { data: course } = useGetUICourse(courseId, { enabled: false });
    const { data: courseProgress } = useGetUICourseProgress(courseId);
    const { data: programNextCourseData } = useQuery(
        ["programNextCourse", programId],
        async () => {
            return await Api.ProgramProgress.StartProgram(programId);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
            enabled: !!programId,
        },
    );

    const getButtonTitle = () => {
        if (programId) {
            if (programNextCourseData?.nextCourse?.id) {
                return "Следующий курс";
            }
            return "Завершить программу";
        }

        return "Вернуться в раздел";
    };

    const onSubmit = async () => {
        if (!course) {
            return;
        }

        invalidate("ui/course", course.resourceId);
        if (programId) {
            if (programNextCourseData?.nextCourse?.id) {
                navigate(`/training/program/${programId}/${programNextCourseData.nextCourse?.id}`, { replace: true });
                return;
            }
            navigate(`/training/program/${programId}/complete`, { replace: true });

            return;
        }
        navigate(`/training`, { replace: true });
    };

    useEffect(() => {
        dispatch(setIsHidden(true));
        dispatch(setIsFavorite(false));
        dispatch(
            setCurrentMaterial({
                id: "",
                title: "",
            }),
        );
    }, [dispatch]);

    if (!course) {
        return null;
    }

    return (
        <div className="flex flex-col items-center pt-15 min-h-full sm:pb-[246px] 2xl:pb-[262px]">
            <h1
                className="text-center pb-10 sm:pb-4 2xl:pb-5 pt-12.5 sm:pt-0 2xl:!text-4xl 2xl:!leading-[41px]"
                id="userCourseFinishTitle"
            >
                <div className="text-primary">
                    Поздравляем! <br className="block sm:hidden" />
                    Вы прошли курс
                </div>
                <div className="sm:max-w-[560px] 2xl:max-w-[700px] break-anywhere">«{course.title}»</div>
            </h1>
            <div
                className="flex flex-col sm:flex-row text-gray pb-12 2xl:pb-15 items-center sm:text-xs 2xl:text-base"
                id="userCourseFinishInfo"
            >
                <div className="sm:pr-3 2xl:pr-3.75 sm:mr-3 2xl:mr-3.75 mb-2 sm:mb-0 sm:border-r sm:border-gray">
                    Получено:{" "}
                    <span className="text-primary">
                        {course?.maxRatingPoints || 0}{" "}
                        {numCapEnd({ one: "балл", two: "балла", few: "баллов" }, Number(course?.maxRatingPoints || 0))}
                    </span>
                </div>
                <div className="sm:pr-3 2xl:pr-3.75 sm:mr-3 2xl:mr-3.75 mb-2 sm:mb-0 sm:border-r sm:border-gray">
                    Пройдено материалов: {courseProgress?.passedCount}
                </div>
                <div>Затрачено времени: {formatLeadTime(courseProgress?.timeSpent ?? 0)}</div>
            </div>
            {!course?.hideAvgReviewScore && (
                <FeedbackForm
                    resourceId={courseId}
                    type={ResourceType.COURSE}
                    currentUserId={currentUser?.data?.id as string}
                />
            )}

            <div className="absolute bottom-14 left-0 sm:left-0 sm:right-0 sm:bottom-0 flex flex-center w-full bg-white sm:bg-background-light px-2.5 sm:px-0 py-2.5 sm:py-22.5 mt-auto">
                <Button
                    className="w-full text-center px-14 py-5 sm:!px-5.5 sm:!py-2.5 2xl:!px-7 2xl:!py-3 sm:text-md 2xl:!text-lg sm:w-[272px] 2xl:w-[340px] sm:!h-[66px] 2xl:!h-[82px] sm:!rounded-xxl 2xl:!rounded-2.5xl"
                    size="xl"
                    onClick={onSubmit}
                    id="userCourseFinishReturnBtn"
                >
                    {getButtonTitle()}
                </Button>
            </div>
        </div>
    );
};
