import React from "react";
import { Breadcrumbs, TabsWrapper, Tabs, Content } from "Uikit";
import { RatingComplexity } from "./RatingComplexity";
import { RatingTeam } from "./RatingTeam";

export const Rating = () => {
    return (
        <div className="px-6 w-full h-full sm:overflow-y-scroll custom-scrollbar">
            <Breadcrumbs className="mb-1">
                <Breadcrumbs.Link title="Администратор" />
                <Breadcrumbs.Link title="Статистика" />
                <Breadcrumbs.Link title="Участники" />
                <Breadcrumbs.Link title="Отчет по рейтингу" />
            </Breadcrumbs>
            <div>
                <h1>Отчет по рейтингу</h1>
                <TabsWrapper className="mt-5 h-fit">
                    <TabsWrapper.Tabs
                        classname="h-max space-x-4 flex-grow !border-0 rounded-lg p-1 bg-background-light"
                        childrenWrapperClassName="flex gap-1.5"
                    >
                        <Tabs.Tab
                            title="По рейтингу (общий)"
                            className={"rounded-md flex-initial basis-[50%] !py-2 !px-2.5 !m-0"}
                            selectedClassName={"!border-0 bg-gray-blue"}
                        />
                        <Tabs.Tab
                            title="По рейтингу по командам"
                            className={"rounded-md flex-initial basis-[50%] !py-2 !px-2.5 !m-0"}
                            selectedClassName={"!border-0 bg-gray-blue"}
                        />
                    </TabsWrapper.Tabs>
                    <TabsWrapper.Content>
                        <Content.Body className="mt-5 h-fit">
                            <RatingComplexity />
                        </Content.Body>
                        <Content.Body className="mt-5 h-fit">
                            <RatingTeam />
                        </Content.Body>
                    </TabsWrapper.Content>
                </TabsWrapper>
            </div>
        </div>
    );
};
