import React, { useMemo, useState } from "react";
import { ColumnDef, SortingState } from "@tanstack/react-table";
import { Table } from "Uikit/Table/Table";
import clsx from "clsx";
import { useInfiniteQuery, useQuery } from "react-query";
import Api from "Api/index";
import { ProgressItem } from "Api/Responses/StatisticResponse";
import { useNavigate, useParams } from "react-router-dom";
import { ResourceState, ResourceType } from "Enums";
import { InfiniteList } from "Components/InfiniteList/InfiniteList";
import flatten from "lodash/flatten";
import { CurrentUserResponse } from "Api/Responses/UserResponse";
import { MEDIA_QUERY_2XL, MEDIA_QUERY_SM, useMediaQuery } from "hooks/useMediaQuery";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { LogoSize } from "Api/Services/UploadApi";
import { formatDateTime } from "helpers/dateHelper";

export const PersonalTable = ({ user }: { user: CurrentUserResponse }) => {
    const navigate = useNavigate();
    const { id: idParam } = useParams();
    const { data: visitor } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());
    const isMentor = visitor?.isMentorOf(user);
    const isMe = user.id === visitor?.id;
    const matches2XL = useMediaQuery(MEDIA_QUERY_2XL);
    const matchesSM = useMediaQuery(MEDIA_QUERY_SM);
    const size = matches2XL ? "large" : matchesSM ? "medium" : "small";
    const isLarge = size === "large";

    const columns = useMemo<ColumnDef<ProgressItem>[]>(() => {
        const result: ColumnDef<ProgressItem>[] = [
            {
                header: "Название",
                accessorKey: "title",
                enableResizing: true,
                size: 500,
                cell: ({
                    row: {
                        original: { logoId, title, state, ...rest },
                    },
                }) => {
                    const handleClick = () => {
                        if (rest.type === ResourceType.COURSE) {
                            navigate(`/training/course/${rest.resourceId}/statistics`);
                        } else if (rest.type === ResourceType.PROGRAM) {
                            navigate(`/training/program/${rest.resourceId}/statistics`);
                        } else if (rest.type === ResourceType.EXERCISE) {
                            navigate(`/task/${rest.userResourceId}/statistics`);
                        } else if (rest.type === ResourceType.QUIZ) {
                            navigate(`/test/${rest.resourceId}/statistics`);
                        }
                    };

                    return (
                        <div className="w-full relative min-w-80">
                            <div className="group flex items-center space-x-3 w-full">
                                <img
                                    className={`rounded 2xl:rounded-lg w-[${isLarge ? 65 : 54}px] h-[${
                                        isLarge ? 45 : 36
                                    }px] flex-shrink-0 object-cover`}
                                    width={isLarge ? 65 : 54}
                                    height={isLarge ? 45 : 36}
                                    src={Api.Upload.GetLogo(logoId, LogoSize.THUMB_MICRO)}
                                    alt=""
                                />
                                <MultiClumpTooltip
                                    className="group-hover:text-blue"
                                    clamp={1}
                                    label={title}
                                    onClick={handleClick}
                                />
                                {rest.passingNumber > 0 && (
                                    <div className="min-w-24 text-white p4 2xl:p3 px-2 py-0.5 rounded-md bg-[#13253280] border-solid border border-gray-stroke">
                                        <span>Прохождение {rest.passingNumber}</span>
                                    </div>
                                )}
                                {state === ResourceState.ARCHIVED && (
                                    <div className="text-gray minw p4 2xl:p3 px-2 py-0.5 rounded-md border-solid border border-gray-stroke">
                                        <span>Архив</span>
                                    </div>
                                )}
                            </div>
                        </div>
                    );
                },
            },
            {
                header: "Тип",
                accessorKey: "type",
                enableResizing: true,
                cell: ({ row: { original } }) => {
                    return <div className="flex min-w-25">{original.typeString}</div>;
                },
            },
            {
                header: "Прогресс",
                accessorKey: "progressPercentage",
                enableResizing: true,
                cell: ({ row: { original } }) => {
                    return <div className="flex min-w-25">{original.progressString}</div>;
                },
            },
            {
                header: "Баллы",
                accessorKey: "points",
                enableResizing: true,
                cell: ({ row: { original } }) => {
                    return <div className="flex min-w-25">{original.rating ?? "—"}</div>;
                },
            },
            {
                header: "Просрочен",
                accessorKey: "expired",
                enableResizing: true,
                cell: ({ row: { original } }) => {
                    return (
                        <div className={clsx("flex min-w-25", original.isFailedDeadlineTime && "text-red")}>
                            {original.isFailedDeadlineTime ? "Да" : "Нет"}
                        </div>
                    );
                },
            },
            {
                header: "Последняя активность",
                accessorKey: "lastActivityTime",
                enableResizing: true,
                cell: ({
                    row: {
                        original: { lastActivityTime },
                    },
                }) => {
                    return (
                        <div className="min-w-50">
                            {lastActivityTime ? formatDateTime(lastActivityTime * 1000) : "—"}
                        </div>
                    );
                },
            },
        ];
        return isMentor || isMe
            ? result
            : result.filter(
                  (column) => !["Прогресс", "Просрочен", "Последняя активность"].includes(column.header as string),
              );
    }, [navigate, isMentor, isMe, isLarge]);

    const [sorting, setSorting] = useState<SortingState>([
        {
            id: "lastActivityTime",
            desc: true,
        },
    ]);

    const filters = {};

    const {
        data: progressList,
        isLoading,
        hasNextPage,
        fetchNextPage,
        isFetching,
    } = useInfiniteQuery(
        ["user-progress", "collection", user.id, sorting],
        async ({ pageParam = 0 }) => {
            const isCurrentUser = visitor?.id === idParam;
            const result = await Api.Statistic.GetUserProgress(
                !isCurrentUser ? idParam ?? null : null,
                pageParam,
                15,
                sorting,
                filters,
            );
            return result.Content;
        },
        {
            getNextPageParam: (lastPage, allPages) => {
                const nextPage = allPages.length;

                return lastPage?.length === 15 ? nextPage : undefined;
            },
        },
    );

    const tableData = useMemo(() => {
        return flatten(progressList?.pages ?? []);
    }, [progressList]);

    if (isLoading) {
        return null;
    }

    return (
        <InfiniteList
            isLoading={isFetching}
            onLoadMore={fetchNextPage}
            hasMore={hasNextPage}
            isSkeletonLoaderTable={true}
        >
            <Table
                id="profileProgress"
                columns={columns}
                data={tableData}
                pageCount={progressList?.pages?.length}
                sorting={sorting}
                onSortingChange={setSorting}
                rowSelection={{}}
                withoutLines={true}
                searchRowClassName="!mb-0"
                tableWrapperClassName="-ml-3"
                emptyTitle="Прогресс недоступен"
                emptyMessage="Прогресс пользователя пока что недоступен, так как вы еще не начали ни одного прохождения."
            />
        </InfiniteList>
    );
};
