import React, { SyntheticEvent, useEffect, useState } from "react";
import Api from "Api/index";
import { useQuery } from "react-query";
import { ProgressStatus, ResourceState, StatisticsReportAccess, StatisticsUsersStatus } from "Enums";
import {
    Button,
    ComboBox,
    FormGroup,
    Label,
    OptionsDropdownIndicator,
    SelectAvatarMultiValueLabel,
    SelectImgMultiValueLabel,
} from "Uikit";
import { TasksModalTree } from "../Tasks/TasksModalTree";
import { ProgramListResponse } from "Api/Responses/ProgramResponse";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import { CourseListResponse } from "Api/Responses/CourseResponse";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { UsersModal } from "../../Modal/UsersModal";
import { accessOptions, progressOptions, reportSettings, usersStatusOptions } from "./CoursesWithoutProgram";
import { numWord } from "helpers/numWord";
import { ReportUsersTable } from "./ReportUsersTable";
import { IRequestSettingsItem, ReportSettings } from "../../ReportSettings/ReportSettings";
import { StatisticCourseRequest } from "Api/Requests/StatisticRequest";
import { structurizeCourseTreeData, structurizeProgramTreeData } from "../utils";
import { SingleValueContainer } from "../SingleValueContainer";
import { IStatisticValue } from "../utils";

export type TTaskFilterValue = ResourceState | "ALL";

export const CoursesWithinProgram = () => {
    const [selectedProgramItems, setSelectedProgramItems] = useState<any[]>([]);
    const [selectedAcceptedProgramItems, setSelectedAcceptedProgramItems] = useState<any[]>([]);
    const [programTree, setProgramTree] = useState<any[]>([]);
    const [selectedProgramsCount, setSelectedProgramsCount] = useState(0);
    const [showReportUsersTable, setShowReportUsersTable] = useState(false);
    const [programsValues, setProgramsValues] = useState<IStatisticValue[]>([]);
    const [isModalProgramTreeOpen, setIsModalProgramTreeOpen] = useState(false);
    const [isProgramFilterEmpty, setIsProgramFilterEmpty] = useState(true);
    const [selectedAcceptedCourseItems, setSelectedAcceptedCourseItems] = useState<any[]>([]);
    const [courseValues, setCourseValues] = useState<IStatisticValue[]>([]);
    const [isModalCourseTreeOpen, setIsModalCourseTreeOpen] = useState(false);
    const [selectedCourseItems, setSelectedCourseItems] = useState<any[]>([]);
    const [courseTree, setCourseTree] = useState<any[]>([]);
    const [isCourseFilterEmpty, setIsCourseFilterEmpty] = useState(true);
    const [selectedCourseCount, setSelectedCourseCount] = useState(0);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [access, setAccess] = useState(accessOptions[0]);
    const [progress, setProgress] = useState<{ value: ProgressStatus; label: string }>(progressOptions[0]);
    const [usersStatus, setUsersStatus] = useState(usersStatusOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);

    // all users request
    useQuery(
        ["users", "collection"],
        () =>
            Api.User.GetMembersList(0, 2000, [{ id: "firstName,lastName", desc: false }], {
                "state.in": "ACTIVE,BLOCKED",
            }),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setAllUsers(data.Content);
            },
        },
    );

    // all programs request
    const programsDataQuery = useQuery(
        ["programsTree"],
        async () => {
            return await Api.Program.List({
                page: 0,
                size: 999,
                sort: "modifyTime,desc",
                filters: {
                    "state.notEqual": ResourceState.ARCHIVED,
                },
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: programTreeData } = programsDataQuery;

    const { data: programsCategories } = useQuery(
        ["programCategories"],
        async () => {
            return await Api.Program.CategoryGet();
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    // all courses request
    const testsDataQuery = useQuery(
        ["coursesTree", programsValues],
        async () => {
            return await Api.Course.List(0, 999, [{ id: "modifyTimestamp", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
                "programId.in": [programsValues.map(({ id }) => id)],
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: testTreeData } = testsDataQuery;

    const onSubmitProgramSection = () => {
        setSelectedAcceptedProgramItems(selectedProgramItems);
        setIsModalProgramTreeOpen(false);
        setShowReportUsersTable(false);
    };

    const onSubmitCourseSection = () => {
        setSelectedAcceptedCourseItems(selectedCourseItems);
        setIsModalCourseTreeOpen(false);
        setShowReportUsersTable(false);
    };

    const createReport = () => {
        setShowReportUsersTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticCourseRequest = {
            programIds: programsValues.map(({ id }) => id),
            courseIds: courseValues.map(({ id }) => id),
            userIds: users.map(({ id }) => id),
            teamsIds: Array.from(
                new Set(
                    users.reduce((prev, { teams }) => {
                        return [...prev, ...teams.map(({ id }) => id)];
                    }, [] as string[]),
                ),
            ),
            officeIds: users.map(({ officeId }) => officeId).filter((item) => !!item),
            jobIds: users.map(({ jobTitle }) => jobTitle?.id).filter((item) => !!item) as string[],
            access: access.value,
            status: progress.value,
            userStatus: usersStatus.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateCourseStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    // Setting programTree data
    useEffect(() => {
        if (programTreeData?.Content && selectedAcceptedProgramItems.length === 0) {
            setProgramTree(
                structurizeProgramTreeData(
                    programTreeData?.Content,
                    selectedAcceptedProgramItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                            selected: true,
                        };
                    }),
                    programsCategories,
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [programTreeData]);

    useEffect(() => {
        setSelectedProgramsCount(selectedProgramItems.length);
    }, [selectedProgramItems]);

    useEffect(() => {
        setProgramTree(
            structurizeProgramTreeData(
                programTreeData?.Content as unknown as ProgramListResponse[],
                selectedAcceptedProgramItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
                programsCategories,
            ),
        );

        setProgramsValues(
            selectedAcceptedProgramItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setSelectedAcceptedCourseItems([]);
        setSelectedCourseItems([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedProgramItems]);

    // Setting courseTree data
    useEffect(() => {
        if (testTreeData?.Content && selectedAcceptedCourseItems.length === 0) {
            setCourseTree(
                structurizeCourseTreeData(
                    testTreeData?.Content,
                    selectedAcceptedCourseItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testTreeData]);

    useEffect(() => {
        setSelectedCourseCount(selectedCourseItems.length);
    }, [selectedCourseItems]);

    useEffect(() => {
        setCourseTree(
            structurizeCourseTreeData(
                testTreeData?.Content as unknown as CourseListResponse[],
                selectedAcceptedCourseItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
            ),
        );

        setCourseValues(
            selectedAcceptedCourseItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        if (selectedAcceptedCourseItems.length === 0) {
            setUsers(allUsers);
            setAccess(accessOptions[0]);
            setProgress(progressOptions[0]);
            setUsersStatus(usersStatusOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedCourseItems]);

    useEffect(() => {
        setUsers(allUsers);
        setAllUsersCount(allUsers.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers]);

    return (
        <>
            <div className="grid items-center gap-6.5 grid-cols-2">
                {/* Выбор программы */}
                <ComboBox
                    placeholder="Выберите программу"
                    onChange={(value) => {
                        setSelectedAcceptedProgramItems([value]);
                        setShowReportUsersTable(false);
                    }}
                    value={programsValues[0] ?? null}
                    components={{
                        DropdownIndicator: OptionsDropdownIndicator({
                            onClick: (e) => {
                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                    setIsModalProgramTreeOpen((prevIsOpen) => !prevIsOpen);
                                }
                            },
                        }),

                        ValueContainer: SingleValueContainer,
                    }}
                    onMenuOpen={() => setIsModalProgramTreeOpen((prevIsOpen) => !prevIsOpen)}
                    /*
                    allPlaceholder={
                        ((courseTreeData?.Content as unknown as CourseListResponse[]) ?? []).length > 0 &&
                        coursesValues.length === courseTreeData?.Content.length
                    }
                    */
                    isSearchable={false}
                    isCreatable={false}
                    isClearable={false}
                    menuIsOpen={false}
                />
                {/* Выбор курсов */}
                {selectedAcceptedProgramItems.length > 0 && (
                    <ComboBox
                        placeholder="Выберите один или несколько курсов"
                        onChange={(options, action) => {
                            if (action && action.action === "clear") {
                                setSelectedAcceptedCourseItems([]);
                            }
                            if (action && action.action === "remove-value") {
                                setSelectedAcceptedCourseItems((prevItems) =>
                                    prevItems.filter(({ id }) => id !== action.removedValue.id),
                                );
                            }
                            setShowReportUsersTable(false);
                        }}
                        value={courseValues}
                        components={{
                            DropdownIndicator: OptionsDropdownIndicator({
                                onClick: (e) => {
                                    if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                        setIsModalCourseTreeOpen((prevIsOpen) => !prevIsOpen);
                                    }
                                },
                            }),
                            ValueContainer: StackedValueContainer,
                            MultiValueLabel: SelectImgMultiValueLabel({}),
                        }}
                        onClick={(e: SyntheticEvent) => {
                            if (!(e.target as HTMLElement).closest(".ui-combo-box__multi-value__remove")) {
                                setIsModalCourseTreeOpen((prevIsOpen) => !prevIsOpen);
                            }
                        }}
                        allPlaceholder={
                            ((testTreeData?.Content as unknown as CourseListResponse[]) ?? []).length > 0 &&
                            courseValues.length === testTreeData?.Content.length
                        }
                        isSearchable={false}
                        isCreatable={false}
                        isClearable={false}
                        menuIsOpen={false}
                        isMulti
                    />
                )}
            </div>
            {/* Выбор программ - модалка */}
            <TasksModalTree
                isOpen={isModalProgramTreeOpen}
                title="Выбор программы"
                setIsOpen={(open: boolean) => {
                    setSelectedProgramItems(selectedAcceptedProgramItems);
                    setProgramTree(
                        structurizeProgramTreeData(
                            programTreeData?.Content as unknown as ProgramListResponse[],
                            selectedAcceptedProgramItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                            programsCategories,
                        ),
                    );

                    setIsModalProgramTreeOpen(open);
                    setIsProgramFilterEmpty(true);
                }}
                treeData={programTree}
                /*
                checkedChange={(selectedNodes) => {
                    setSelectedCourseItems(selectedNodes);
                }}
                */
                onSelectNode={(node) => setSelectedProgramItems([node])}
                checkable={false}
                selectable={true}
                checkOnNameClick={true}
                showSelectedCounter={false}
                onSubmit={onSubmitProgramSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedProgramsCount}
                disableRootSelection
                onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                    setProgramTree(
                        structurizeProgramTreeData(
                            (programTreeData?.Content as unknown as ProgramListResponse[]).filter(
                                ({ state, title }) => {
                                    return (
                                        (!query ||
                                            title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                            query.trim() === "") &&
                                        (state === value || value === "ALL")
                                    );
                                },
                            ),
                            selectedProgramItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                            programsCategories,
                        ),
                    );
                    setIsProgramFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={
                    isProgramFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"
                }
            />
            {/* Выбор курсов - модалка */}
            <TasksModalTree
                isOpen={isModalCourseTreeOpen}
                title="Выбор курсов"
                setIsOpen={(open: boolean) => {
                    setSelectedCourseItems(selectedAcceptedCourseItems);
                    setCourseTree(
                        structurizeCourseTreeData(
                            testTreeData?.Content as unknown as CourseListResponse[],
                            selectedAcceptedCourseItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );

                    setIsModalCourseTreeOpen(open);
                    setIsCourseFilterEmpty(true);
                }}
                treeData={courseTree}
                checkedChange={(selectedNodes) => {
                    setSelectedCourseItems(selectedNodes);
                }}
                onSubmit={onSubmitCourseSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedCourseCount}
                onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                    setCourseTree(
                        structurizeCourseTreeData(
                            (testTreeData?.Content as unknown as CourseListResponse[]).filter(({ state, title }) => {
                                return (
                                    (!query ||
                                        title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                        query.trim() === "") &&
                                    (state === value || value === "ALL")
                                );
                            }),
                            selectedCourseItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );
                    setIsCourseFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={
                    isCourseFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"
                }
            />
            {selectedAcceptedCourseItems.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            {/* Выбор участников */}
                            <FormGroup className="!mb-0">
                                <Label>Участники</Label>
                                <ComboBox
                                    placeholder="Выберите одного или нескольких участников"
                                    onChange={(options, action) => {
                                        if (action && action.action === "clear") {
                                            setUsers([]);
                                        }
                                        if (action && action.action === "remove-value") {
                                            setUsers(options as MembersListResponse[]);
                                        }
                                        setShowReportUsersTable(false);
                                    }}
                                    value={users}
                                    components={{
                                        DropdownIndicator: OptionsDropdownIndicator({
                                            onClick: (e) => {
                                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                                    setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                                }
                                            },
                                        }),
                                        // ValueContainer: SelectValueContainer({
                                        //     onClick: (e) => {
                                        //         if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                        //             setShowUsersModal((prevIsOpen) => {
                                        //                 return !prevIsOpen;
                                        //             });
                                        //         }
                                        //     },
                                        //     // oneRow: true
                                        //     allPlaceholder: users.length > 0 && allUsersCount === users.length,
                                        // }),
                                        ValueContainer: StackedValueContainer,
                                        MultiValueLabel: SelectAvatarMultiValueLabel({}),
                                    }}
                                    onClick={(e) => {
                                        if (!(e.target as HTMLElement).closest(".ui-combo-box__multi-value__remove")) {
                                            setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                        }
                                    }}
                                    allPlaceholder={users.length > 0 && allUsersCount === users.length}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                    menuIsOpen={false}
                                    isMulti
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Доступ к программе на текущий момент</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAccess(value as { value: StatisticsReportAccess; label: string });
                                        setShowReportUsersTable(false);
                                    }}
                                    value={access}
                                    options={accessOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус прохождения курса</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setProgress(value as { value: ProgressStatus; label: string });
                                        setShowReportUsersTable(false);
                                    }}
                                    value={progress}
                                    options={progressOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус участников</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setUsersStatus(value as { value: StatisticsUsersStatus; label: string });
                                        setShowReportUsersTable(false);
                                    }}
                                    value={usersStatus}
                                    options={usersStatusOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    {/* Выбор участников - модалка */}
                    <UsersModal
                        isOpen={showUsersModal}
                        onClose={() => {
                            setShowUsersModal(false);
                        }}
                        onSubmit={(users) => {
                            setUsers(
                                users.map((user) => {
                                    const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                    return {
                                        ...user,
                                        value: {
                                            avatarId,
                                            firstName,
                                            lastName,
                                            defaultAvatarColor,
                                        },
                                        label: `${lastName} ${firstName}`,
                                    };
                                }),
                            );
                            setShowReportUsersTable(false);
                        }}
                        selectedUsersDefault={users}
                        allUsers={allUsers}
                    />
                    <div className="mt-9">
                        {showReportUsersTable ? (
                            <>
                                <ReportUsersTable
                                    singleCourseChoosed={selectedAcceptedCourseItems.length === 1}
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                    queryPayload={createPayloadParams()}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                                disabled={!users.length}
                            >
                                {`Сформировать отчет по ${users.length} ${numWord(users.length, [
                                    "участнику",
                                    "участникам",
                                    "участникам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
