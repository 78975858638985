import { MaterialCategoryTreeResponse } from "Api/Responses/MaterialCategoryResponse";
import BaseApi from "../BaseApi";
import { BaseIdRequest } from "../BaseRequest";
import { BaseArrayResponse, BaseResponse } from "../BaseResponse";
import {
    TaskCategoryCreateRequest,
    TaskCategoryEditRequest,
    TaskCategoryReorderRequest,
} from "../Requests/TaskCategoryRequest";
import { TaskCategoryTreeResponse } from "../Responses/TaskCategoryResponse";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class TaskCategoryApi extends BaseApi {
    List = (): Promise<BaseArrayResponse<TaskCategoryTreeResponse>> => {
        const params = LMSFilterParams();
        return this.Get(new BaseArrayResponse(TaskCategoryTreeResponse), `/all?${params.toString()}`);
    };

    Create = async (data: TaskCategoryCreateRequest): Promise<TaskCategoryTreeResponse> => {
        return await this.Post(new MaterialCategoryTreeResponse(), "", data);
    };

    Edit = async (data: TaskCategoryEditRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/edit", data);
    };

    Remove = async (data: BaseIdRequest): Promise<BaseResponse> => {
        return await this.Delete(BaseResponse, "/delete/" + data.uuid);
    };

    CheckDeletionPossibility = async (data: BaseIdRequest): Promise<string[]> => {
        return await this.Post([], "/delete/" + data.uuid + "/check");
    };

    Reorder = async (data: TaskCategoryReorderRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/reorder", data);
    };
}
