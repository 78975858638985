import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import "react-day-picker/dist/style.css";
import { Button, flash } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import { AvatarEditor, TAvatarEditorType } from "Uikit/AvatarEditor/AvatarEditor";
// import { Tooltip } from "Uikit/Tooltip";
import { Confirmation } from "Components/Confirmation/Confirmation";
import { useDialog } from "hooks/useDialog";
import Api from "Api";
import { CompanyReadResponse } from "Api/Responses/CompanyResponse";
import { CompanyEditRequest } from "Api/Requests/CompanyRequest";
import { daysBetween, daysLeftStr, formatDate } from "helpers/dateHelper";
import { FileUploadType, RoleName, UIErrorMessages } from "Enums";
import { InfoCard } from "./InfoCard";
import { InfoCardList } from "./InfoCardList";
import { InfoCardLogoInput } from "./InfoCardLogoInput";
import { InfoCardExpandableInput } from "./InfoCardExpandableInput";
import { InfoCardRootDateTimeInput } from "./InfoCardRootDateTimeInput";
import { InfoCardSupAdminContent } from "./InfoCardSupAdminContent";
import { InfoCardRootInput } from "./InfoCardRootInput";
import dayjs from "dayjs";
import { useInvalidate } from "hooks/useInvalidate";
import { companyNamePattern } from "types/Company";
import { getFileSizeErrorMessage } from "helpers/file";

// доступный компании объем памяти зависит от лимита пользователей из расчета 2ГБ за пользователя
//const discVolumePerUser = 2; // Gb

export const LicenseGeneral = () => {
    const { data: user } = useQuery(["users", "current"], () => Api.User.GetCurrentUser());
    const [company, setCompany] = useState<CompanyReadResponse | null>(null);
    const initCompanyRef = useRef<CompanyReadResponse | null>(null);
    const companyId = localStorage.getItem("companyId");
    const { data: companyData } = useQuery(
        ["companyData", "collection"],
        () => Api.CompanyApi.Read({ id: companyId! }),
        {
            enabled: !!companyId,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
        },
    );
    const [errors, setErrors] = useState<any>({});
    const [isChanged, setIsChanged] = useState(false);
    const { dialogState, openDialog, closeDialog } = useDialog();
    const [avatarEditor, setAvatarEditor] = useState<string | undefined>(undefined);
    const [avatarEditorType, setAvatarEditorType] = useState<TAvatarEditorType>("square");
    const [logoSquare, setLogoSquare] = useState<File | null>(null);
    const [logoRectangle, setLogoRectangle] = useState<File | null>(null);
    const [isCompanyNameEdit, setIsCompanyNameEdit] = useState(false);
    const [isContractNumberEdit, setIsContractNumberEdit] = useState(false);
    // const [isSubmitBtnDisabledMsg, setIsSubmitBtnDisabledMsg] = useState<string[]>([]);
    const invalidate = useInvalidate();

    const onChangeAvatar = (data: File | null, imgBase64?: string, type: TAvatarEditorType = "square") => {
        if (type === "square") {
            setLogoSquare(data);
            if (company) {
                setCompany({ ...company, logoSquareId: data ? imgBase64 : undefined });
            }
        } else {
            setLogoRectangle(data);
            if (company) {
                setCompany({ ...company, logoRectangleId: data ? imgBase64 : undefined });
            }
        }

        setIsChanged(true);
    };

    const onAvatarChange = (event: any) => {
        const files = (event?.target as HTMLInputElement)?.files;

        if (!files || files.length === 0) {
            return;
        }

        if (files.length > 1) {
            flash.error("Выбрано более 1 файла");
            return;
        }

        if (!RegExp(/^image\/(jpg|jpeg|png)/).exec(files[0].type)) {
            flash.error(UIErrorMessages.FILE_EXTENSION_ERROR);
            return;
        }

        if (files[0].size > 2097152) {
            flash.error(getFileSizeErrorMessage("2 МБ"));
            return;
        }

        const reader = new FileReader();

        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            setAvatarEditor(reader.result!.toString());
            event.target.value = "";
        };
    };

    const onValidate = (): boolean => {
        const errors: Partial<CompanyReadResponse> = {};
        const name = (company?.name ?? "").trim();

        const errorMsgIsRequired = "Поле обязательно для заполнения";
        errors.name = name === "" ? errorMsgIsRequired : undefined;
        errors.contractNumber = !company?.contractNumber ? errorMsgIsRequired : undefined;

        if (name.length > 256) {
            errors.name = "Невозможно ввести символов  больше разрешенной длины";
        }

        if (RegExp(companyNamePattern).exec(name)) {
            errors.name = "В поле нельзя ввести недопустимые символы";
        }

        if (Object.keys(errors).filter((p) => errors[p as keyof typeof errors]).length !== 0) {
            setErrors(errors);
            return false;
        }

        setErrors({});
        return true;
    };

    // Сохранение данных компании
    const { mutateAsync: editCompanyHandler } = useMutation((payload: CompanyEditRequest) => {
        return Api.CompanyApi.Edit(payload);
    });

    useEffect(() => {
        if (companyData) {
            setCompany(companyData);
            initCompanyRef.current = companyData;
        }
    }, [companyData, setIsChanged]);

    const isRoot = user?.role === RoleName.ROOT;
    const isSuperAdmin = user?.role === RoleName.SUPER_ADMIN;

    const onChangeCompany = (params: { [k: string]: string | number | Date }, isChanged = true) => {
        setCompany((prevCompany: CompanyReadResponse | null) => {
            return prevCompany
                ? {
                      ...prevCompany,
                      ...(params as unknown as CompanyReadResponse),
                  }
                : null;
        });
        setIsChanged(isChanged);
    };

    const onCancelChange = () => {
        onChangeCompany(initCompanyRef.current as unknown as { [k: string]: string | number | Date }, false);
        setIsCompanyNameEdit(false);
        setIsContractNumberEdit(false);
    };

    // Сохранение данных компании
    const onSubmit = async () => {
        const {
            id,
            maxUsers,
            licenseExpirationDate,
            name,
            contractNumber,
            logoSquareId,
            logoRectangleId,
            maxMemoryGb,
        } = company as CompanyReadResponse;
        const validationResult = onValidate();
        if (!validationResult) {
            flash.error("Ошибка, не все поля формы заполнены правильно");
            return;
        }

        const logoValues: Pick<CompanyEditRequest, "logoSquareId" | "logoRectangleId"> = {
            logoSquareId,
            logoRectangleId,
        };
        try {
            if (logoSquare) {
                const uploadFileResponse = await Api.File.UploadFile(
                    logoSquare,
                    undefined,
                    undefined,
                    false,
                    FileUploadType.COMPANY_LOGO,
                );
                logoValues.logoSquareId = uploadFileResponse.id;
            }
        } catch {
            flash.error("Размер логотипа 1:1 слишком большой!");
            return;
        }

        try {
            if (logoRectangle) {
                const uploadFileResponse = await Api.File.UploadFile(
                    logoRectangle,
                    undefined,
                    undefined,
                    false,
                    FileUploadType.COMPANY_LOGO,
                );
                logoValues.logoRectangleId = uploadFileResponse.id;
            }
        } catch {
            flash.error("Размер логотипа 1:3 слишком большой!");
            return;
        }

        const success = await editCompanyHandler({
            id,
            maxUsers,
            licenseExpirationDate,
            name,
            contractNumber,
            maxMemoryGb,
            ...logoValues,
        });

        if (!success) {
            flash.error("Неизвестная ошибка при сохранении данных компании");
            return;
        }
        flash.success("Данные компании успешно сохранены");
        invalidate("companyData");
        setIsContractNumberEdit(false);
        setIsCompanyNameEdit(false);
        setIsChanged(false);
    };

    const onSubmitHandler = () => {
        openDialog({
            title: "Сохранение",
            content: "Вы уверены, что хотите сохранить изменения?",
            closeBtnText: "Отмена",
            submitBtnText: "Сохранить",
            submitBtnColor: "primary",
            onRequestClose: () => {
                closeDialog();
                setIsContractNumberEdit(false);
                setIsCompanyNameEdit(false);
            },
            onRequestSubmit: () => {
                onSubmit().then(() => {
                    closeDialog();
                });
            },
        });
    };

    // console.log(company);

    return (
        <>
            {!companyData && (
                <div className="flex-center w-full h-full">
                    <Loader />
                </div>
            )}
            {company && (
                <>
                    <AvatarEditor
                        isOpen={avatarEditor !== undefined}
                        type={avatarEditorType}
                        title="Загрузка логотипа компании"
                        img={avatarEditor ?? ""}
                        onDismiss={() => {
                            setAvatarEditor(undefined);
                            setAvatarEditorType("square");
                        }}
                        onSubmit={(img: string, blob: Blob) => {
                            onChangeAvatar(new File([blob], "avatar.png"), img, avatarEditorType);
                            setAvatarEditorType("square");
                        }}
                    />
                    <div className="flex flex-col gap-10 relative">
                        {isChanged && (
                            <div className="absolute flex items-center right-0 top-0.5 -translate-y-0.5">
                                <Button
                                    className="mr-4"
                                    variant="outline"
                                    color="secondary"
                                    onClick={onCancelChange}
                                    id="adminLicenceBtnCancel"
                                >
                                    Отменить
                                </Button>
                                <Button onClick={onSubmitHandler} id="adminLicenceBtnSubmit">
                                    Сохранить
                                </Button>
                                {/* {isSubmitBtnDisabledMsg.length > 0
                                    ? (
                                        <Tooltip className="" content={<div>{
                                            isSubmitBtnDisabledMsg.map((item) => {
                                                return (
                                                    <p key={item}>{item}</p>
                                                )
                                            })
                                        }</div>}>
                                            <Button onClick={onSubmitHandler} id="adminLicenceBtnSubmit" disabled>
                                                Сохранить
                                            </Button>
                                        </Tooltip>
                                    )
                                    : (
                                        <Button onClick={onSubmitHandler} id="adminLicenceBtnSubmit">
                                            Сохранить
                                        </Button>
                                    )
                                } */}
                            </div>
                        )}
                        <InfoCardList title="Информация о компании">
                            {/* Логотип 1:1 */}
                            <InfoCardLogoInput
                                value={company.logoSquareId}
                                onClick={() => {
                                    setAvatarEditorType("square");
                                }}
                                onAvatarChange={onAvatarChange}
                                onChangeAvatar={() => onChangeAvatar(null)}
                                label="Логотип 1:1"
                                tooltip={
                                    "Загрузите изображение в формате .png, .jpg, .jpeg весом до 2 МБ, от 120x120 px"
                                }
                                isRoot={isRoot}
                                loadOnImageClick={false}
                            />
                            {/* Логотип 1:3 */}
                            <InfoCardLogoInput
                                value={company.logoRectangleId}
                                onClick={() => {
                                    setAvatarEditorType("rectangle");
                                }}
                                onAvatarChange={onAvatarChange}
                                onChangeAvatar={() => onChangeAvatar(null, undefined, "rectangle")}
                                label="Логотип 1:3"
                                tooltip={
                                    "Загрузите изображение в формате .png, .jpg, .jpeg весом до 2 МБ, от 120x360 px"
                                }
                                format="rectangle"
                                isRoot={isRoot}
                                loadOnImageClick={false}
                            />
                            <InfoCardExpandableInput
                                value={company.contractNumber.toUpperCase()}
                                isRoot={isRoot}
                                isEdit={isContractNumberEdit}
                                error={errors["contractNumber"]}
                                label="Номер договора"
                                placeholder="Введите номер договора"
                                onEdit={() => setIsContractNumberEdit(true)}
                                onChange={(value) => {
                                    onChangeCompany({
                                        contractNumber: value,
                                    });
                                }}
                            />
                            <InfoCardExpandableInput
                                value={company.name}
                                isRoot={isRoot}
                                isEdit={isCompanyNameEdit}
                                error={errors["name"]}
                                label="Название компании"
                                placeholder="Введите название компании"
                                onEdit={() => setIsCompanyNameEdit(true)}
                                maxLength={256}
                                cutRegExp={companyNamePattern}
                                onChange={(value) => {
                                    onChangeCompany({
                                        name: value,
                                    });
                                }}
                            />
                        </InfoCardList>
                        <InfoCardList title="Лицензии">
                            <InfoCard title="Использовано, шт">
                                {/* Root */}
                                {isRoot && (
                                    <InfoCardRootInput
                                        limit={100000}
                                        actual={company.activeUsers}
                                        value={company.maxUsers}
                                        onChange={(value) => {
                                            onChangeCompany({
                                                ...company,
                                                maxUsers: value,
                                                maxMemoryGb: value * 2,
                                            });
                                            // setIsSubmitBtnDisabledMsg((prev) => {
                                            //     const msg = "Количество использованных лицензий не может быть больше допустимого";
                                            //     let next = [...prev];
                                            //     if (10 > value) {
                                            //         if (!prev.includes(msg)) {
                                            //             next.push(msg);
                                            //         }
                                            //     } else {
                                            //         const set = new Set(prev);
                                            //         set.delete(msg);
                                            //         next = Array.from(set);
                                            //     }

                                            //     return next;
                                            // });
                                        }}
                                        id="adminLicensesUsed"
                                    />
                                )}
                                {/* Super-Admin */}
                                {isSuperAdmin && (
                                    <InfoCardSupAdminContent
                                        actual={company.activeUsers}
                                        summ={`из ${company.maxUsers}`}
                                        available={`${company.maxUsers - company.activeUsers}`}
                                    />
                                )}
                            </InfoCard>
                            <InfoCard title="Дата окончания">
                                {/* Root */}
                                {isRoot && (
                                    <InfoCardRootDateTimeInput
                                        value={company.licenseExpirationDate}
                                        onChange={(value) => {
                                            onChangeCompany({
                                                licenseExpirationDate: value,
                                            });
                                        }}
                                        id="adminLicensesUsed"
                                        tooltip="Осталось"
                                    />
                                )}
                                {/* Super-Admin */}
                                {isSuperAdmin && (
                                    <InfoCardSupAdminContent
                                        actual={formatDate(company.licenseExpirationDate * 1000)}
                                        available={daysLeftStr(
                                            daysBetween(undefined, dayjs(company.licenseExpirationDate * 1000)),
                                        )}
                                        tooltip="Осталось"
                                    />
                                )}
                            </InfoCard>
                        </InfoCardList>
                        <InfoCardList title="Память">
                            <InfoCard title="Использовано, ГБ">
                                {/* Root */}
                                {isRoot && (
                                    <InfoCardRootInput
                                        limit={200000}
                                        actual={company.memoryUsedGb}
                                        value={company.maxMemoryGb}
                                        onChange={(value) => {
                                            onChangeCompany({
                                                maxMemoryGb: value,
                                            });
                                            // setIsSubmitBtnDisabledMsg((prev) => {
                                            //     const msg = "Количество использованной памяти не может быть больше допустимого";
                                            //     let next = [...prev];
                                            //     if (10 > value) {
                                            //         if (!prev.includes(msg)) {
                                            //             next.push(msg);
                                            //         }
                                            //     } else {
                                            //         const set = new Set(prev);
                                            //         set.delete(msg);
                                            //         next = Array.from(set);
                                            //     }

                                            //     return next;
                                            // });
                                        }}
                                        id="adminVolumeUsed"
                                    />
                                )}
                                {/* Super-Admin */}
                                {isSuperAdmin && (
                                    <InfoCardSupAdminContent
                                        actual={`${company.memoryUsedGb}`}
                                        summ={`из ${company.maxMemoryGb}`}
                                        available={`${company.maxMemoryGb - company.memoryUsedGb}`}
                                    />
                                )}
                            </InfoCard>
                        </InfoCardList>
                    </div>
                    <Confirmation {...dialogState} />
                </>
            )}
        </>
    );
};
