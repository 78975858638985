import React from "react";
import { Button, Icon, Icons } from "Uikit";
import { useNavigate } from "react-router-dom";
import { Empty } from "Uikit/Page/Empty";

interface INoAccessProps {
    className?: string;
}

export const NoAccess = ({ className = "" }: INoAccessProps) => {
    const navigate = useNavigate();

    return (
        <div className={`h-full flex justify-center ${className}`}>
            <Empty
                className="!mt-0"
                title="Материал не найден"
                description={
                    <span>
                        Материал был удалён или у вас нет к нему доступа. Обратитесь к администратору для
                        <br />
                        дополнительной информации
                    </span>
                }
                topElement={
                    <div className="flex-center mb-4">
                        <div className="flex-center w-16 h-16 rounded-full bg-blue-10">
                            <Icon icon={Icons.EmojiSad} width={"36px"} height={"36px"} color={"fill-primary"} />
                        </div>
                    </div>
                }
            >
                <Button onClick={() => navigate("/")} id="adminBlockerBtnGoMain">
                    На главную
                </Button>
            </Empty>
        </div>
    );
};
