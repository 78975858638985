import React, { useMemo, useState } from "react";
import { StatisticTaskUserRequest } from "Api/Requests/StatisticRequest";
import { Loader } from "Uikit/Loader/Loader";
import { TVoidFunction } from "types/TVoidFunction";
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState } from "@tanstack/react-table";
import { useQuery } from "react-query";
import Api from "Api";
import { Button, Table } from "Uikit";
import { StatisticTaskUserResponseData } from "Api/Responses/StatisticResponse";
import { LogoSize } from "Api/Services/UploadApi";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";
import { formatDateTime } from "helpers/dateHelper";

interface IReportTaskUserTableProps {
    onClickLoadReportFile: TVoidFunction;
    queryPayload: StatisticTaskUserRequest;
}

export const ReportTaskUserTable = ({ onClickLoadReportFile, queryPayload }: IReportTaskUserTableProps) => {
    const [sorting, setSorting] = useState<SortingState>([{ id: "exerciseName", desc: false }]);
    const [{ pageIndex, pageSize }, setPagination] = useState<PaginationState>({ pageIndex: 0, pageSize: 15 });

    const [selectedRows, setSelectedRows] = useState<any>();
    const [rowSelection, setRowSelection] = useState({});

    const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>([]);
    const [search, setSearch] = useState<string>("");

    console.log(selectedRows);

    const pagination = useMemo(() => ({ pageIndex, pageSize }), [pageIndex, pageSize]);
    const dataQuery = useQuery(
        ["reportTaskUser", "collection", pagination, sorting, search, queryPayload],
        async () => {
            const payload: StatisticTaskUserRequest = { ...queryPayload, searchQuery: search };
            return await Api.Statistic.CreateTaskUserReport(payload, pageIndex, pageSize, sorting);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const columns = React.useMemo<ColumnDef<StatisticTaskUserResponseData>[]>(
        () => [
            {
                header: "Задание",
                accessorKey: "exerciseName",
                className: "w-[40%]",
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { exercise },
                    },
                }) => {
                    return (
                        <a
                            className="group inline-flex items-center space-x-3.5 cursor-pointer text-black hover:no-underline"
                            href={`/admin/task/${exercise.id}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            {exercise.logoId && (
                                <img
                                    className="rounded-2sm w-[54px] h-9 object-cover flex-shrink-0"
                                    src={Api.Upload.GetLogo(exercise.logoId, LogoSize.THUMB_MICRO)}
                                    alt={exercise.name}
                                />
                            )}
                            <MultiClumpTooltip
                                key={exercise.id}
                                clamp={1}
                                label={exercise.name}
                                textClassName="group-hover:text-blue"
                            />
                        </a>
                    );
                },
            },
            {
                header: "Прохождение",
                accessorKey: "passingNumber",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { passingNumber, passingLimit },
                    },
                }) => {
                    return (
                        <>
                            {passingNumber === null && "—"}
                            {passingNumber !== null && passingLimit
                                ? `${passingNumber} из ${passingLimit}`
                                : passingNumber}
                        </>
                    );
                },
            },
            {
                header: "Дата завершения",
                accessorKey: "completionTime",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { completionTime },
                    },
                }) => {
                    return <>{completionTime === null ? "—" : formatDateTime(completionTime * 1000)}</>;
                },
            },
            {
                header: "Номер попытки",
                accessorKey: "attemptNumber",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { attemptNumber },
                    },
                }) => {
                    return <>{attemptNumber ?? "—"}</>;
                },
            },
            {
                header: "Набранные баллы",
                accessorKey: "rating",
                enableResizing: true,
                size: 200,
                footer: (props) => props.column.id,
                cell: ({
                    row: {
                        original: { rating },
                    },
                }) => {
                    return <>{rating ?? "—"}</>;
                },
            },
        ],

        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const controlButtons = (
        <div className="flex justify-end items-center col-start-1 row-start-1">
            <Button
                className="rounded-lg whitespace-nowrap font-medium w-full"
                size="medium"
                onClick={() => {
                    onClickLoadReportFile();
                }}
            >
                Выгрузить отчет в файл
            </Button>
        </div>
    );

    const isEmpty = dataQuery.data?.data.Content.length === 0 && search === "";

    return (
        <>
            {dataQuery.isFetching && !dataQuery.data?.data.Content && (
                <div className="w-full flex justify-center">
                    <Loader />
                </div>
            )}
            {dataQuery.data?.data.Content && (
                <div>
                    <Table
                        columns={columns}
                        controlButtons={controlButtons}
                        columnFilters={columnFilters}
                        pageCount={dataQuery.data.data.TotalPages}
                        pagination={pagination}
                        emptyTitle="Нет данных"
                        emptyMessage={
                            isEmpty
                                ? "По заданным параметрам данных для выгрузки нет"
                                : "По заданным параметрам результатов нет"
                        }
                        searchTitle="Поиск по заданию"
                        data={dataQuery.data.data.Content}
                        isFetching={dataQuery.isFetching}
                        rowSelection={rowSelection}
                        onPaginationChange={setPagination}
                        onRowSelectionChange={setRowSelection}
                        onColumnFiltersChange={setColumnFilters}
                        setSelectedRows={setSelectedRows}
                        sorting={sorting}
                        onSortingChange={setSorting}
                        onSearch={setSearch}
                        searchRowClassName={`gap-6.5 !grid grid-cols-2 ${isEmpty && "!grid-cols-1"}`}
                        searchClassName={"!w-full"}
                        searchBlockClassName={`col-start-2 col-end-2 ${isEmpty && "hidden"}`}
                    />
                </div>
            )}
        </>
    );
};
