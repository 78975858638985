import React, { Fragment, useEffect, useState } from "react";
import { Transition } from "@headlessui/react";
import { Button, Icon, Icons, Checkbox, RadioGroup } from "Uikit";
import { IRequestSettingsItem, ISettingsItem } from "Pages/Admin/Statistics/ReportSettings/ReportSettings";

enum TestAnswersReportType {
    CONSOLIDATED,
    BY_USERS,
}

interface IReportSettings {
    isActive: boolean;
    setIsActive: (show: boolean) => void;
    settings: {
        title: string;
        key: string;
        items: ISettingsItem[];
    }[];
    onSubmit: (settingsChecked?: IRequestSettingsItem[]) => void;
    isReportFileFetching: boolean;
}

export const TestAnswersReportSettings = ({
    isActive,
    setIsActive,
    settings,
    onSubmit,
    isReportFileFetching,
}: IReportSettings) => {
    const [reportType, setReportType] = useState(TestAnswersReportType.CONSOLIDATED);
    const [isNextStep, setIsNextStep] = useState(false);
    const [settingsChecked, setSettingsChecked] = useState(
        settings.map(({ key, items }) => {
            return {
                key,
                items,
            };
        }),
    );

    const handleChangeReportType = (value: TestAnswersReportType) => {
        setReportType(value);
    };

    const handleReset = () => {
        setReportType(TestAnswersReportType.CONSOLIDATED);
        setIsNextStep(false);
    };

    const isDisabled = (): boolean => {
        for (let i = 0; i < settingsChecked.length; i++) {
            const settingField = settingsChecked[i];
            for (let j = 0; j < settingField.items.length; j++) {
                if (settingField.items[j].checked) {
                    return false;
                }
            }
        }

        return true;
    };

    useEffect(() => {
        handleReset();
    }, [isActive]);

    return (
        <>
            {isActive && (
                <div className="fixed top-0 left-0 w-full h-full z-[101]" onClick={() => setIsActive(false)}></div>
            )}
            <Transition
                as={Fragment}
                show={isActive}
                enter="transition ease-out duration-150"
                enterFrom="opacity-0 translate-x-full"
                enterTo="opacity-100 translate-x-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-x-0"
                leaveTo="opacity-0 translate-x-full"
            >
                <div
                    className="fixed top-0 right-0 z-[102] h-[100vh] w-[480px] bg-white rounded-l-[6px] shadow-[0px_8px_20px_rgba(0,0,0,0.12)] flex flex-col"
                    onClick={(e) => e.stopPropagation()}
                >
                    <div className="flex items-center justify-between px-6 pt-6 pb-2">
                        <p className="text-[#262626] text-xl font-medium">Настройка отчета</p>
                        <Button
                            shape="round"
                            color="common"
                            icon={<Icon icon={Icons.Close} width={32} height={32} color="fill-[#878E9C]" />}
                            iconPlacement="center"
                            onClick={() => setIsActive(false)}
                        />
                    </div>
                    <div className="flex flex-col grow pt-6 pb-6 gap-4 px-6 overflow-auto custom-scrollbar">
                        {!isNextStep && (
                            <RadioGroup
                                value={reportType}
                                onChange={handleChangeReportType}
                                listClassName="!bg-transparent"
                            >
                                <RadioGroup.Button
                                    label="Отчет по ответам (сводный)"
                                    value={TestAnswersReportType.CONSOLIDATED}
                                />
                                <RadioGroup.Button
                                    label="Отчет по ответам участников"
                                    value={TestAnswersReportType.BY_USERS}
                                />
                            </RadioGroup>
                        )}
                        {isNextStep && (
                            <>
                                {settings.map(({ title, items }, itemIndex) => {
                                    const indeterminate =
                                        settingsChecked[itemIndex].items.some(
                                            ({ checked }: ISettingsItem) => checked,
                                        ) &&
                                        settingsChecked[itemIndex].items.some(({ checked }: ISettingsItem) => !checked);
                                    return (
                                        <Fragment key={title}>
                                            <>
                                                <p className="text-gray">{title}</p>
                                                {items.map(({ title, value }, subItemIndex) => {
                                                    return (
                                                        <div key={`${title}__${value}`}>
                                                            <Checkbox
                                                                checked={
                                                                    settingsChecked[itemIndex].items[subItemIndex]
                                                                        .checked
                                                                }
                                                                indeterminate={value === "all" && indeterminate}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                }}
                                                                onChange={(e) => {
                                                                    e.stopPropagation();
                                                                    const { checked } = e.target;
                                                                    console.log(checked);
                                                                    setSettingsChecked((prevSettings) => {
                                                                        const newSettings = [...prevSettings];
                                                                        if (value === "all") {
                                                                            newSettings[itemIndex].items = newSettings[
                                                                                itemIndex
                                                                            ].items.map((item) => {
                                                                                return {
                                                                                    ...item,
                                                                                    checked,
                                                                                };
                                                                            });
                                                                            return newSettings;
                                                                        }
                                                                        newSettings[itemIndex].items[
                                                                            subItemIndex
                                                                        ].checked = checked;

                                                                        return newSettings;
                                                                    });
                                                                }}
                                                                label={title}
                                                                className="cursor-pointer"
                                                            />
                                                        </div>
                                                    );
                                                })}
                                            </>
                                        </Fragment>
                                    );
                                })}
                            </>
                        )}
                    </div>
                    <div className=" pt-6 pb-6 gap-4 px-6 border-t border-gray-blue">
                        {reportType === TestAnswersReportType.BY_USERS && !isNextStep && (
                            <Button
                                className="rounded-lg whitespace-nowrap font-medium w-full"
                                size="medium"
                                color="gray"
                                onClick={() => {
                                    setIsNextStep(true);
                                }}
                            >
                                Далее
                            </Button>
                        )}
                        {/*кнопка выгрузки общего отчета*/}
                        {reportType === TestAnswersReportType.CONSOLIDATED && (
                            <Button
                                className="rounded-lg whitespace-nowrap font-medium w-full"
                                size="medium"
                                onClick={() => {
                                    onSubmit();
                                }}
                            >
                                Выгрузить
                            </Button>
                        )}
                        {/*кнопка выгрузки отчета по участникам*/}
                        {reportType === TestAnswersReportType.BY_USERS && isNextStep && (
                            <Button
                                className="rounded-lg whitespace-nowrap font-medium w-full"
                                size="medium"
                                onClick={() => {
                                    onSubmit(settingsChecked);
                                }}
                                disabled={isReportFileFetching || isDisabled()}
                            >
                                Выгрузить
                            </Button>
                        )}
                    </div>
                </div>
            </Transition>
        </>
    );
};
