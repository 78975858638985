import React, { useState } from "react";
import Cropper from "cropperjs";
import { Loader } from "Uikit/Loader/Loader";
import { Modal, Button } from "Uikit";
import { useInterval } from "hooks/useInterval";
import { TVoidFunction } from "types";

export type TAvatarEditorType = "circle" | "rectangle" | "square";

interface IAvatarEditor {
    isOpen: boolean;
    type: TAvatarEditorType;
    title: string;
    img: string;
    onDismiss: TVoidFunction;
    onSubmit: (img: string, blob: Blob) => void;
}
export const AvatarEditor = ({ isOpen, type = "circle", title, img, onDismiss, onSubmit }: IAvatarEditor) => {
    const MAX_HEIGHT = 1080;

    const [isLoaded, setIsLoaded] = useState(false);
    const [cropper, setCropper] = useState<any>(null);

    const afterOpenModal = () => {
        setIsLoaded(false);
        const image = document.getElementById("image") as HTMLImageElement;

        if (image != null) {
            image.setAttribute("src", img);

            const cropperTmp = new Cropper(image, {
                aspectRatio: type === "rectangle" ? 16 / 5 : 1,
                viewMode: 1,
            });

            setCropper(cropperTmp);
        }

        setIsLoaded(true);
    };
    const getScaledRoundedCanvas = (sourceCanvas: any) => {
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        if (context === null) {
            return;
        }

        let height = sourceCanvas.height;
        let width = sourceCanvas.width;

        if (height > MAX_HEIGHT) {
            height = MAX_HEIGHT;
            width =
                sourceCanvas.width !== sourceCanvas.height
                    ? Math.round(sourceCanvas.width * (height / sourceCanvas.height))
                    : MAX_HEIGHT;
        }

        canvas.width = width;
        canvas.height = height;

        context.imageSmoothingEnabled = true;
        context.drawImage(sourceCanvas, 0, 0, width, height);

        context.beginPath();

        if (type === "circle") {
            context.fillStyle = "white";
            context.arc(width / 2, height / 2, Math.min(width, height) / 2, 0, 2 * Math.PI);
            context.rect(width, 0, width * -1, height);
        }

        context.fill();
        return canvas;
    };

    const onCloseClick = () => {
        setIsLoaded(false);
        onDismiss();
    };
    const onSubmitClick = () => {
        if (cropper === null) {
            return;
        }

        const croppedCanvas = cropper.getCroppedCanvas();
        const roundedCanvas = getScaledRoundedCanvas(croppedCanvas);

        if (roundedCanvas && onSubmit) {
            roundedCanvas.toBlob((blob: Blob | null) => {
                onSubmit(roundedCanvas.toDataURL("image/png", 0.7), blob ?? new Blob());
            }, "image/png");
        }

        onCloseClick();
    };

    useInterval(() => {
        if (isOpen && !isLoaded && document.getElementById("image")) {
            afterOpenModal();
        }
    }, 500);

    return (
        <Modal isOpen={isOpen} title={title} onClose={onCloseClick}>
            <div className="flex flex-col pt-7 h-full overflow-hidden">
                <div className="flex mx-auto max-w-160 h-125">
                    {!isLoaded && (
                        <div className="top-1/2 left-1/2 absolute -translate-x-1/2 -translate-y-1/2">
                            <Loader />
                        </div>
                    )}
                    <div style={{ opacity: isLoaded ? 1 : 0 }}>
                        <img id="image" className="max-w-full" height={480} src="/img/article-card-cover.jpg" alt="" />
                    </div>
                </div>
                <div className="flex self-end p-4">
                    <Button className="mr-4" color="secondary" variant="outline" onClick={onCloseClick}>
                        Отмена
                    </Button>
                    <Button color="primary" onClick={onSubmitClick}>
                        Отправить
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
