import React, { useEffect } from "react";
import { useNavigate, useParams /* , useLocation */ } from "react-router-dom";
import { useQuery } from "react-query";
// import clsx from "clsx";
import { useDispatch } from "react-redux";

import { numCapEnd } from "helpers/numCapEnd";
import { Button } from "Uikit";
import { Loader } from "Uikit/Loader/Loader";
import Api from "Api";
import { useCurrentUser } from "hooks/useCurrentUser";
import { ResourceType } from "Enums";
import { FeedbackForm } from "Components/FeedbackForm/FeedbackForm";
import { setCurrentCourse } from "slices/programSlice";
import { formatLeadTime } from "../Tasks/utils";

export const ProgramComplete = () => {
    const navigate = useNavigate();
    const { data: currentUser } = useCurrentUser();
    const { programId = "" } = useParams();
    // const location = useLocation();
    const dispatch = useDispatch();

    // const isAdmin = location["pathname"].indexOf("admin") !== -1;

    const { data: program, isFetching: isProgramFetching } = useQuery(
        ["program"],
        async () => {
            return await Api.Program.ReadUI(programId as string);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: programProgress, isFetching: isProgramProgressFetching } = useQuery(
        ["programProgress", programId, currentUser],
        async () => {
            if (!currentUser) {
                return;
            }

            return await Api.ProgramProgress.ReadProgress(programId, currentUser.id);
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const onSubmit = async () => {
        navigate(`/training`, { replace: true });
    };

    useEffect(() => {
        dispatch(
            setCurrentCourse({
                id: "",
                title: "",
            }),
        );
    }, [dispatch]);

    return (
        <>
            {isProgramFetching && isProgramProgressFetching ? (
                <div className="w-full h-full flex-center px-10">
                    <Loader />
                </div>
            ) : (
                <div className="flex flex-col items-center pt-15 min-h-full sm:pb-[246px] 2xl:pb-[262px]">
                    <h1
                        className="text-center pb-10 sm:pb-4 2xl:pb-5 pt-12.5 sm:pt-0 2xl:!text-4xl 2xl:!leading-[41px]"
                        id="userProgramFinishTitle"
                    >
                        <div className="text-primary">
                            Поздравляем! <br className="block sm:hidden" />
                            Вы прошли программу
                        </div>
                        <div className="sm:max-w-[560px] 2xl:max-w-[700px] break-anywhere">«{program?.title}»</div>
                    </h1>
                    <div
                        className="flex flex-col sm:flex-row text-gray pb-12 2xl:pb-15 items-center sm:text-xs 2xl:text-base"
                        id="userProgramFinishInfo"
                    >
                        <div className="sm:pr-3 2xl:pr-3.75 sm:mr-3 2xl:mr-3.75 mb-2 sm:mb-0 sm:border-r sm:border-gray">
                            Получено:{" "}
                            <span className="text-primary">
                                {program?.ratingPoints ?? 0}{" "}
                                {numCapEnd({ one: "балл", two: "балла", few: "баллов" }, program?.ratingPoints ?? 0)}
                            </span>
                        </div>
                        <div className="sm:pr-3 2xl:pr-3.75 sm:mr-3 2xl:mr-3.75 mb-2 sm:mb-0 sm:border-r sm:border-gray">
                            Пройдено курсов: {programProgress?.passedCount}
                        </div>
                        <div>Затрачено времени: {formatLeadTime(programProgress?.timeSpent ?? 0)}</div>
                    </div>
                    {!program?.hideAvgReviewScore && (
                        <FeedbackForm
                            resourceId={programId as string}
                            type={ResourceType.PROGRAM}
                            currentUserId={currentUser?.id as string}
                        />
                    )}

                    <div className="absolute bottom-14 left-0 sm:left-0 sm:right-0 sm:bottom-0 flex flex-center w-full bg-white sm:bg-background-light px-2.5 sm:px-0 py-2.5 sm:py-22.5 mt-auto">
                        <Button
                            className="w-full text-center px-14 py-5 sm:!px-5.5 sm:!py-2.5 2xl:!px-7 2xl:!py-3 sm:text-md 2xl:!text-lg sm:w-[272px] 2xl:w-[340px] sm:!h-[66px] 2xl:!h-[82px] sm:!rounded-xxl 2xl:!rounded-2.5xl"
                            size="xl"
                            onClick={onSubmit}
                            id="userProgramFinishReturnBtn"
                        >
                            Вернуться в раздел
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
