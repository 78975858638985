import React, { ReactNode, useCallback, useEffect, useState } from "react";
import {
    SelectionState,
    IntegratedSelection,
    TreeDataState,
    CustomTreeData,
    SearchState,
    IntegratedFiltering,
    Sorting,
    RowDetailState,
} from "@devexpress/dx-react-grid";
import isEqual from "lodash/isEqual";
import { Grid, Table, TableHeaderRow, TableTreeColumn } from "@devexpress/dx-react-grid-material-ui";
import IconButton from "@mui/material/IconButton";
import { Icon, Icons } from "../Icon/Icon";
import clsx from "clsx";
import { TeamTreeAllBasicInfoResponse } from "Api/Responses/TeamResponse";
import { Input } from "Uikit/Forms/Input";
import { MultiClumpTooltip } from "Components/MultiClumpTooltip/MultiClumpTooltip";

export type TreeTable = {
    data: any;
    columns: any;
    isRowSelectable?: boolean;
    isAllRowSelectable?: boolean;
    rightSideToolbar?: ReactNode;
    childrenProp: string;
    hasHeaderRow?: boolean;
    hasLayoutBorders?: boolean;
    hasSearch?: boolean;
    hasToolbar?: boolean;
    id: string;
    sorting?: Sorting[];
    onSortingChange?: (sorting: Sorting[]) => void;
};

export const TreeTable = ({
    data = [],
    columns,
    isRowSelectable = true,
    isAllRowSelectable = true,
    rightSideToolbar,
    childrenProp,
    hasHeaderRow = true,
    hasLayoutBorders = true,
    hasSearch = true,
    hasToolbar = true,
    id,
    sorting,
    onSortingChange,
}: TreeTable) => {
    const [expandedRowIds, setExpandedRowIds] = useState<string[]>([]);
    const [searchValue, setSearchValue] = useState("");
    const [oldSearchValue, setOldSearchValue] = useState("");
    const firstColumn = columns[0];
    const tableColumnExtensions = columns.map((column: any) => {
        return { columnName: column.name, width: column.width };
    });

    const searchTree = useCallback(
        ({ treeData, queryString }: { treeData: []; queryString: string; propNames: string[] }) => {
            if (!queryString || queryString === "") {
                return treeData;
            }
            const matchingNodes: any = [];

            function traverse(node: any, ancestors: any) {
                if (node?.name.toLowerCase().includes(String(queryString).trim().toLowerCase())) {
                    matchingNodes.push({
                        ...node,
                        ancestors,
                        children: node[childrenProp]
                            ? node[childrenProp].map((child: any) => traverse(child, [...ancestors, node]))
                            : null,
                    });
                } else if (node[childrenProp]) {
                    node[childrenProp].forEach((child: any) => traverse(child, [...ancestors, node]));
                }
                return node;
            }

            treeData.forEach((node: any) => traverse(node, []));

            return matchingNodes;
        },
        [childrenProp],
    );

    const TableHeaderRowCellComponent = ({ column, children, ...restProps }: any) => {
        return (
            <TableHeaderRow.Cell
                column={column}
                {...restProps}
                className={clsx(column.name === firstColumn.name ? "!pl-0" : null, "!text-base !border-t-0 !pb-2")}
            >
                {children}
            </TableHeaderRow.Cell>
        );
    };

    const TableHeaderRowContentComponent = ({ column, children, ...restProps }: any) => {
        const headerLeftComponent = column.headerLeftComponent?.() ?? null;

        return (
            <TableHeaderRow.Content {...restProps} className="!mb-0">
                {column.name === "" || column.title === "" ? null : (
                    <div
                        className="flex cursor-pointer"
                        onClick={() => {
                            if (!onSortingChange) {
                                return;
                            }

                            if (sorting && sorting[0].columnName === column.name && sorting[0].direction === "desc") {
                                onSortingChange([{ columnName: column.name, direction: "asc" }]);
                            } else if (
                                sorting &&
                                (sorting[0].columnName !== column.name ||
                                    (sorting[0].columnName === column.name && sorting[0].direction === "asc"))
                            ) {
                                onSortingChange([{ columnName: column.name, direction: "desc" }]);
                            }
                        }}
                    >
                        {headerLeftComponent}
                        <div className="inline-flex items-center p4-table font-sans uppercase text-gray-text">
                            {children}
                        </div>
                        {sorting && sorting[0].columnName !== column.name && (
                            <Icon className="ml-2 flex my-auto" icon={Icons.Sort} width={14} height={14} />
                        )}
                        {sorting && sorting[0].columnName === column.name && sorting[0].direction === "asc" && (
                            <Icon className="ml-2 flex my-auto" icon={Icons.ChevronUp} width={9} height={9} />
                        )}
                        {sorting && sorting[0].columnName === column.name && sorting[0].direction === "desc" && (
                            <Icon className="ml-2 flex my-auto" icon={Icons.ChevronDown} width={9} height={9} />
                        )}
                    </div>
                )}
            </TableHeaderRow.Content>
        );
    };

    const TableCellComponent = ({ value, row, column, children, ...restProps }: any) => {
        const isLast = columns.findIndex((item: any) => isEqual(item, column)) === columns.length - 1;

        return (
            <Table.Cell
                value={value}
                row={row}
                column={column}
                tableColumn={restProps.tableColumn}
                tableRow={restProps.tableRow}
                className={clsx(
                    "!text-base !py-0 !h-8 group-hover:bg-blue-lightest",
                    hasLayoutBorders ? "" : "!border-b-0",
                    isLast ? "rounded-r-md" : "",
                )}
            >
                {columns.find(
                    (column: any) => column.name === restProps.tableColumn.column.name && column.cellFormatter,
                ) ? (
                    columns
                        .find(
                            (column: any) => column.name === restProps.tableColumn.column.name && column.cellFormatter,
                        )
                        .cellFormatter(value, children, row)
                ) : (
                    <span className={clsx(value ? "text-primary" : "text-gray-dark", "!text-lg")}>{value || "—"}</span>
                )}
            </Table.Cell>
        );
    };

    const TableComponent = ({ style, ...restProps }: any) => (
        <Table.Table
            {...restProps}
            style={{
                ...style,
            }}
            className="!pl-0 first:!pl-0 !text-base !border-t-0 !mb-0"
            id={id + "Table"}
        ></Table.Table>
    );

    const getRowDepth = (rowId: string, rows: any) => {
        let depth = 0;
        rows.forEach((rowItem: any) => {
            const getDepth = (row: any, rowDepth = 0) => {
                if (row.id === rowId) {
                    depth = rowDepth;
                }
                if (row.subTeams && row.subTeams.length !== 0) {
                    rowDepth++;
                }
                row.subTeams.forEach((teamItem: any) => {
                    getDepth(teamItem, rowDepth);
                });
            };
            return getDepth(rowItem, depth);
        });
        return depth;
    };

    const TableTreeColumnCellComponent = ({ value, children, ...restProps }: any) => {
        const isChild = !!restProps.row.parentId;
        const rowDepth = getRowDepth(restProps.row.id, data);

        return (
            <TableTreeColumn.Cell
                {...restProps}
                className={clsx(
                    "!p-0 !m-0 !text-base !h-8 !font-sans !p2 group-hover:bg-blue-lightest rounded-l-md first:!pl-2 relative",
                    hasLayoutBorders ? "" : "!border-b-0",
                )}
            >
                {isChild && (
                    <>
                        {Array.from(Array(rowDepth).keys()).map((p) => {
                            return (
                                <div
                                    key={p}
                                    className={clsx("mx-3 w-0.25 h-full bg-gray-blue absolute")}
                                    style={{
                                        left: `${4 + p * 24}px`,
                                    }}
                                />
                            );
                        })}
                    </>
                )}
                {columns.find(
                    (column: any) => column.name === restProps.tableColumn.column.name && column.cellFormatter,
                )
                    ? columns
                          .find(
                              (column: any) =>
                                  column.name === restProps.tableColumn.column.name && column.cellFormatter,
                          )
                          .cellFormatter(value, children, restProps)
                    : children}
            </TableTreeColumn.Cell>
        );
    };

    const TableTreeColumnContentComponent = ({ children, ...restProps }: any) => {
        return (
            <TableTreeColumn.Content
                {...restProps}
                className="!text-base !py-0 !leading-[22px] text-gray-dark !font-sans !p2"
            >
                <span className={"overflow-hidden text-ellipsis whitespace-break-spaces"}>
                    <MultiClumpTooltip label={children} clamp={1} />
                </span>
            </TableTreeColumn.Content>
        );
    };

    const ExpandButton = (props: any) => {
        const { expanded, visible, onToggle } = props;
        return (
            <span
                onClick={(e) => {
                    e.stopPropagation();
                    onToggle();
                }}
            >
                {visible ? (
                    <IconButton className="relative z-[10] !pl-0 !py-1" disableRipple>
                        {expanded ? (
                            <Icon icon={Icons.ChevronDown} color="fill-[#888E9C]" />
                        ) : (
                            <Icon icon={Icons.ChevronRight} color="fill-[#888E9C]" />
                        )}
                    </IconButton>
                ) : null}
            </span>
        );
    };

    {
        /*
    const ToolbarRootComponent = (props: any) => (
        <div className="flex w-full align-center ">
            <div className="w-full">
                <Toolbar.Root {...props} className="!p-0 w-full !border-0" />
            </div>
            {rightSideToolbar}
        </div>
    );
    */
    }

    const RowComponent = ({ children, ...rest }: any) => {
        const isSearchMatch =
            searchValue && String(rest.row.name).toLowerCase().includes(String(searchValue).trim().toLowerCase());

        return <tr className={clsx(isSearchMatch && "bg-background", "group")}>{children}</tr>;
    };

    const getChildRows = (currentRow: any, rootRows: any) => {
        if (!currentRow) {
            return rootRows;
        }
        return currentRow[childrenProp]?.length ? currentRow[childrenProp] : null;
    };

    const getRowsIds = useCallback(() => {
        const parseRows = (rows: TeamTreeAllBasicInfoResponse[]) => {
            let response: string[] = [];

            for (const element of rows) {
                response.push(element.id);
                response = response.concat(parseRows(element.subTeams));
            }

            return response;
        };

        return parseRows(data);
    }, [data]);

    useEffect(() => {
        if (oldSearchValue === searchValue) {
            return;
        }

        const searchResult: any[] = searchTree({
            treeData: data,
            queryString: searchValue.toLowerCase(),
            propNames: ["Title"],
        });
        const rowIds: any = [];

        searchResult.forEach((p: any) => {
            if (p.ancestors) {
                p.ancestors.map((a: any) => rowIds.push(a.id));
            }
        });

        setExpandedRowIds(searchValue ? rowIds : []);

        return () => {
            setOldSearchValue(searchValue);
        };
    }, [searchValue, searchTree, data, getRowsIds, oldSearchValue]);

    return (
        <div className="w-full" id={id}>
            <div className="flex mt-3 mb-3">
                {hasSearch && <SearchPanelInputComponent id={id} value={searchValue} onChange={setSearchValue} />}
                {hasToolbar && <div>{rightSideToolbar}</div>}
            </div>
            <Grid rows={data} columns={columns}>
                <SearchState />
                <IntegratedFiltering />
                <SelectionState />
                <TreeDataState
                    expandedRowIds={expandedRowIds.map((p) => getRowsIds().indexOf(p))}
                    onExpandedRowIdsChange={(expandedRowIds) => {
                        const rowsIds = getRowsIds();

                        setExpandedRowIds(expandedRowIds.map((p) => rowsIds[+p]));
                    }}
                />
                <RowDetailState expandedRowIds={expandedRowIds} />
                <CustomTreeData getChildRows={getChildRows} />
                <IntegratedSelection />
                <Table
                    columnExtensions={tableColumnExtensions}
                    cellComponent={TableCellComponent}
                    tableComponent={TableComponent}
                    rowComponent={RowComponent}
                />
                {hasHeaderRow ? (
                    <TableHeaderRow
                        cellComponent={TableHeaderRowCellComponent}
                        contentComponent={TableHeaderRowContentComponent}
                    />
                ) : null}
                <TableTreeColumn
                    for={columns[0].name}
                    expandButtonComponent={ExpandButton}
                    showSelectionControls={isRowSelectable}
                    showSelectAll={isAllRowSelectable}
                    cellComponent={TableTreeColumnCellComponent}
                    contentComponent={TableTreeColumnContentComponent}
                    // TableHeaderRow={TableHeaderRow}
                />
                {/* <TableRowDetail /> */}

                {/*
                {hasToolbar ? <Toolbar rootComponent={ToolbarRootComponent} /> : null}

                {hasSearch ? <SearchPanel inputComponent={SearchPanelInputComponent} /> : null}
                */}
            </Grid>
        </div>
    );
};

interface ISearchPanelInputComponent {
    id?: string;
    value: string;
    onChange: (value: string) => void;
}

const SearchPanelInputComponent = ({ id, value, onChange }: ISearchPanelInputComponent) => {
    return (
        <div id="searchWrapper" className="relative w-full !p-0">
            <div className="flex justify-between relative grow max-w-[350px] w-full">
                <span className="z-20 absolute inset-y-0 left-1 flex items-center pl-2">
                    <button className="z-20 p-1 focus:outline-none focus:shadow-outline">
                        <Icon
                            icon={Icons.Search}
                            width={18}
                            height={18}
                            color="fill-[#939393]"
                            className="2xl:!w-5.5 2xl:!h-5.5"
                        />
                    </button>
                </span>
                <Input
                    id={id + "SearchInput"}
                    className="z-10 block !w-[350px] 2xl:!w-105 border border-[#E6E9ED] placeholder:text-[#939393] pl-10 2xl:pl-11"
                    placeholder="Поиск..."
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                    autoFocus
                    autoComplete="off"
                />
                {value && (
                    <div
                        id="searchClearIcon"
                        className="absolute top-2/4 -translate-y-2/4 right-3 cursor-pointer z-20"
                        onClick={() => {
                            if (onChange) {
                                onChange("");
                            }
                        }}
                    >
                        <Icon icon={Icons.Close} width={20} height={20} color="fill-blue-drk" />
                    </div>
                )}
            </div>
        </div>
    );
};
