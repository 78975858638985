import React from "react";
import uniqueId from "lodash/uniqueId";
import { Widget } from "Components/Widget";

interface ILegendLineProps {
    text: string;
    value?: number;
    color: "blue" | "gray" | "red" | "light-gray";
}

interface IStatsItem {
    size: number;
    color: "stroke-primary" | "stroke-gray-light" | "stroke-red" | "stroke-gray-stroke";
    zIndex: number;
}

interface ITeamWidgetProps {
    title: string;
    stubText: string | null;
    stats: IStatsItem[];
    count: number;
    legendLines: ILegendLineProps[];
}

export const TeamWidget = ({ title, stats, count, legendLines, stubText = null }: ITeamWidgetProps) => {
    //записываем значения категорий
    let val1 = 0;
    let val2 = 0;

    const getLegendValue = (value: number | undefined, index: number) => {
        if (!value) {
            return 0;
        }
        if (index === 0) {
            const newVal = Math.round(value);
            val1 = newVal;
            return `${newVal}%`;
        }
        if (index === 1) {
            const newVal = Math.round(value);
            if (newVal + val1 > 100) {
                val2 = 100 - val1;
            } else {
                val2 = newVal;
            }
            return `${val2}%`;
        }
        if (index === 2) {
            return `${100 - (val1 + val2)}%`;
        }

        return 0;
    };

    return (
        <Widget title={title}>
            <div className="flex items-stretch gap-5">
                {count === 0 ? (
                    <p className="text-xs 2xl:text-2sm text-blue-dark">{stubText}</p>
                ) : (
                    <>
                        <Widget.ChartSimple
                            charts={stats.map((statsItem: IStatsItem) => {
                                return {
                                    ...statsItem,
                                    size: statsItem.size / 100,
                                };
                            })}
                            count={`${count} шт.`}
                        />
                        <div className="flex flex-col justify-center gap-2 2xl:gap-2.5 py-1.5 grow">
                            {legendLines.map(({ text, value, color }, index) => {
                                return (
                                    <Widget.LegendLine
                                        key={uniqueId(`${text}__${value}`)}
                                        text={text}
                                        value={getLegendValue(value, index)}
                                        color={color}
                                    />
                                );
                            })}
                        </div>
                    </>
                )}
            </div>
        </Widget>
    );
};
