import React, { SyntheticEvent, useEffect, useState } from "react";
import { useQuery } from "react-query";
import {
    Button,
    ComboBox,
    FormGroup,
    Label,
    OptionsDropdownIndicator,
    SelectAvatarMultiValueLabel,
    SelectImgMultiValueLabel,
} from "Uikit";
import { StackedValueContainer } from "Uikit/Forms/SelectCustomComponents/StackedValueContainer";
import Api from "Api";
import { CourseListResponse } from "Api/Responses/CourseResponse";
import {
    ProgressStatus,
    ResourceState,
    StatisticsAttempts,
    StatisticsReportAccess,
    StatisticsUsersStatus,
} from "Enums";
import { TasksModalTree } from "../Tasks/TasksModalTree";
import { TestListResponse } from "Api/Responses/TestResponse";
import { MembersListResponse } from "Api/Responses/UserResponse";
import { UsersModal } from "../../Modal/UsersModal";
import {
    accessOptions,
    attemptsOptions,
    progressOptions,
    reportSettings,
    usersStatusOptions,
} from "./TestWithoutCourse";
import { numWord } from "helpers/numWord";
import { ReportUsersTable } from "./ReportUsersTable";
import { IRequestSettingsItem, ReportSettings } from "../../ReportSettings/ReportSettings";
import { StatisticTestRequest } from "Api/Requests/StatisticRequest";
import { IStatisticValue, structurizeCourseTreeData, structurizeTestTreeData } from "../utils";
import { SingleValueContainer } from "../SingleValueContainer";

export type TTaskFilterValue = ResourceState | "ALL";

export const TestWithinCourse = () => {
    const [selectedCourseItems, setSelectedCourseItems] = useState<any[]>([]);
    const [selectedAcceptedCourseItems, setSelectedAcceptedCourseItems] = useState<any[]>([]);
    const [coursesValues, setCoursesValues] = useState<IStatisticValue[]>([]);
    const [showReportUsersTable, setShowReportUsersTable] = useState(false);
    const [isModalCourseTreeOpen, setIsModalCourseTreeOpen] = useState(false);
    const [coursesTree, setCoursesTree] = useState<any[]>([]);
    const [selectedCoursesCount, setSelectedCoursesCount] = useState(0);
    const [selectedAcceptedTestItems, setSelectedAcceptedTestItems] = useState<any[]>([]);
    const [testsValues, setTestsValues] = useState<IStatisticValue[]>([]);
    const [isModalTestTreeOpen, setIsModalTestTreeOpen] = useState(false);
    const [selectedTestItems, setSelectedTestItems] = useState<any[]>([]);
    const [testsTree, setTestsTree] = useState<any[]>([]);
    const [selectedTestCount, setSelectedTestCount] = useState(0);
    const [users, setUsers] = useState<MembersListResponse[]>([]);
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [allUsersCount, setAllUsersCount] = useState(0);
    const [allUsers, setAllUsers] = useState<MembersListResponse[]>([]);
    const [access, setAccess] = useState(accessOptions[0]);
    const [progress, setProgress] = useState<{ value: ProgressStatus; label: string }>(progressOptions[0]);
    const [attempts, setAttempts] = useState(attemptsOptions[0]);
    const [usersStatus, setUsersStatus] = useState(usersStatusOptions[0]);
    const [showReportSettings, setShowReportSettings] = useState(false);
    const [isCourseFilterEmpty, setIsCourseFilterEmpty] = useState(true);
    const [isTestFilterEmpty, setIsTestFilterEmpty] = useState(true);

    // all users request
    useQuery(
        ["users", "collection"],
        () =>
            Api.User.GetMembersList(0, 2000, [{ id: "firstName,lastName", desc: false }], {
                "state.in": "ACTIVE,BLOCKED",
            }),
        {
            keepPreviousData: true,
            onSuccess: (data) => {
                setAllUsers(data.Content);
            },
        },
    );

    // all courses request
    const coursesDataQuery = useQuery(
        ["coursesTree"],
        async () => {
            return await Api.Course.List(0, 999, [{ id: "modifyTimestamp", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: courseTreeData } = coursesDataQuery;

    // all tests request
    const testsDataQuery = useQuery(
        ["testsTree", coursesValues],
        async () => {
            return await Api.Test.List(0, 999, [{ id: "modifyTime", desc: true }], {
                "state.notEqual": ResourceState.ARCHIVED,
                "courseId.in": [coursesValues.map(({ id }) => id)],
            });
        },
        {
            keepPreviousData: true,
            refetchOnWindowFocus: false,
        },
    );

    const { data: testTreeData } = testsDataQuery;

    const onSubmitCourseSection = () => {
        setSelectedAcceptedCourseItems(selectedCourseItems);
        setIsModalCourseTreeOpen(false);
        setShowReportUsersTable(false);
    };

    const onSubmitTestSection = () => {
        setSelectedAcceptedTestItems(selectedTestItems);
        setIsModalTestTreeOpen(false);
        setShowReportUsersTable(false);
    };

    const createReport = () => {
        setShowReportUsersTable(true);
    };

    const createPayloadParams = () => {
        const payload: StatisticTestRequest = {
            courseIds: coursesValues.map(({ id }) => id),
            quizIds: testsValues.map(({ id }) => id),
            userIds: users.map(({ id }) => id),
            teamsIds: Array.from(
                new Set(
                    users.reduce((prev, { teams }) => {
                        return [...prev, ...teams.map(({ id }) => id)];
                    }, [] as string[]),
                ),
            ),
            officeIds: users.map(({ officeId }) => officeId).filter((item) => !!item),
            jobIds: users.map(({ jobTitle }) => jobTitle?.id).filter((item) => !!item) as string[],
            access: access.value,
            status: progress.value,
            attemptNumber: attempts.value,
            userStatus: usersStatus.value,
        };

        return payload;
    };

    const onSubmit = async (settingsChecked: IRequestSettingsItem[]) => {
        const request: any = {};

        request.params = createPayloadParams();

        settingsChecked.forEach(({ key, items }: IRequestSettingsItem) => {
            request[key] = items
                .filter((item) => item.value !== "all")
                .reduce((prev, cur) => {
                    return {
                        ...prev,
                        [cur.value]: cur.checked,
                    };
                }, {});
        });

        setShowReportSettings(false);

        try {
            await Api.Statistic.GenerateTestStatisticReportFile(request);
        } catch (error: any) {
            console.log(error);
        }
    };

    // Setting courseTree data
    useEffect(() => {
        if (courseTreeData?.Content && selectedAcceptedCourseItems.length === 0) {
            setCoursesTree(
                structurizeCourseTreeData(
                    courseTreeData?.Content,
                    selectedAcceptedCourseItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                            selected: true,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseTreeData]);

    useEffect(() => {
        setSelectedCoursesCount(selectedCourseItems.length);
    }, [selectedCourseItems]);

    useEffect(() => {
        setCoursesTree(
            structurizeCourseTreeData(
                courseTreeData?.Content as unknown as CourseListResponse[],
                selectedAcceptedCourseItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
            ),
        );

        setCoursesValues(
            selectedAcceptedCourseItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        setSelectedAcceptedTestItems([]);
        setSelectedTestItems([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedCourseItems]);

    // Setting testTree data
    useEffect(() => {
        if (testTreeData?.Content && selectedAcceptedTestItems.length === 0) {
            setTestsTree(
                structurizeTestTreeData(
                    testTreeData?.Content,
                    selectedAcceptedTestItems.map(({ id, state }) => {
                        return {
                            id,
                            checked: state.checked,
                        };
                    }),
                ),
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [testTreeData]);

    useEffect(() => {
        setSelectedTestCount(selectedTestItems.length);
    }, [selectedTestItems]);

    useEffect(() => {
        setTestsTree(
            structurizeTestTreeData(
                testTreeData?.Content as unknown as TestListResponse[],
                selectedAcceptedTestItems.map(({ id, state }) => {
                    return {
                        id,
                        checked: state.checked,
                    };
                }),
            ),
        );

        setTestsValues(
            selectedAcceptedTestItems.map(({ id, name, logoId }) => {
                return {
                    label: name,
                    value: id,
                    name,
                    id,
                    logoId,
                };
            }),
        );

        if (selectedAcceptedTestItems.length === 0) {
            setUsers(allUsers);
            setAccess(accessOptions[0]);
            setProgress(progressOptions[0]);
            setAttempts(attemptsOptions[0]);
            setUsersStatus(usersStatusOptions[0]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedAcceptedTestItems]);

    useEffect(() => {
        setUsers(allUsers);
        setAllUsersCount(allUsers.length);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allUsers]);

    return (
        <>
            <div className="grid items-center gap-6.5 grid-cols-2">
                {/* Выбор курса */}
                <ComboBox
                    placeholder="Выберите курс"
                    onChange={(value) => {
                        setSelectedAcceptedCourseItems([value]);
                        setShowReportUsersTable(false);
                    }}
                    value={coursesValues[0] ?? null}
                    components={{
                        DropdownIndicator: OptionsDropdownIndicator({
                            onClick: (e) => {
                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                    setIsModalCourseTreeOpen((prevIsOpen) => !prevIsOpen);
                                }
                            },
                        }),

                        ValueContainer: SingleValueContainer,
                    }}
                    onMenuOpen={() => setIsModalCourseTreeOpen((prevIsOpen) => !prevIsOpen)}
                    /*
                    allPlaceholder={
                        ((courseTreeData?.Content as unknown as CourseListResponse[]) ?? []).length > 0 &&
                        coursesValues.length === courseTreeData?.Content.length
                    }
                    */
                    isSearchable={false}
                    isCreatable={false}
                    isClearable={false}
                    menuIsOpen={false}
                />
                {/* Выбор тестов */}
                {selectedAcceptedCourseItems.length > 0 && (
                    <ComboBox
                        placeholder="Выберите один или несколько тестов"
                        onChange={(options, action) => {
                            if (action && action.action === "clear") {
                                setSelectedAcceptedTestItems([]);
                            }
                            if (action && action.action === "remove-value") {
                                setSelectedAcceptedTestItems((prevItems) =>
                                    prevItems.filter(({ id }) => id !== action.removedValue.id),
                                );
                            }
                            setShowReportUsersTable(false);
                        }}
                        value={testsValues}
                        components={{
                            DropdownIndicator: OptionsDropdownIndicator({
                                onClick: (e) => {
                                    if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                        setIsModalTestTreeOpen((prevIsOpen) => !prevIsOpen);
                                    }
                                },
                            }),
                            ValueContainer: StackedValueContainer,
                            MultiValueLabel: SelectImgMultiValueLabel({}),
                        }}
                        onClick={(e: SyntheticEvent) => {
                            if (!(e.target as HTMLElement).closest(".ui-combo-box__multi-value__remove")) {
                                setIsModalTestTreeOpen((prevIsOpen) => !prevIsOpen);
                            }
                        }}
                        allPlaceholder={
                            ((testTreeData?.Content as unknown as TestListResponse[]) ?? []).length > 0 &&
                            testsValues.length === testTreeData?.Content.length
                        }
                        isSearchable={false}
                        isCreatable={false}
                        isClearable={false}
                        menuIsOpen={false}
                        isMulti
                    />
                )}
            </div>
            {/* Выбор курсов - модалка */}
            <TasksModalTree
                isOpen={isModalCourseTreeOpen}
                title="Выбор курса"
                setIsOpen={(open: boolean) => {
                    setSelectedCourseItems(selectedAcceptedCourseItems);
                    setCoursesTree(
                        structurizeCourseTreeData(
                            courseTreeData?.Content as unknown as CourseListResponse[],
                            selectedAcceptedCourseItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );

                    setIsModalCourseTreeOpen(open);
                    setIsCourseFilterEmpty(true);
                }}
                treeData={coursesTree}
                /*
                checkedChange={(selectedNodes) => {
                    setSelectedCourseItems(selectedNodes);
                }}
                */
                onSelectNode={(node) => setSelectedCourseItems([node])}
                checkable={false}
                selectable={true}
                checkOnNameClick={true}
                showSelectedCounter={false}
                onSubmit={onSubmitCourseSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedCoursesCount}
                disableRootSelection
                onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                    setCoursesTree(
                        structurizeCourseTreeData(
                            (courseTreeData?.Content as unknown as CourseListResponse[]).filter(({ state, title }) => {
                                return (
                                    (!query ||
                                        title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                        query.trim() === "") &&
                                    (state === value || value === "ALL")
                                );
                            }),
                            selectedCourseItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );
                    setIsCourseFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={
                    isCourseFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"
                }
            />
            {/* Выбор тестов - модалка */}
            <TasksModalTree
                isOpen={isModalTestTreeOpen}
                title="Выбор тестов"
                setIsOpen={(open: boolean) => {
                    setSelectedTestItems(selectedAcceptedTestItems);
                    setTestsTree(
                        structurizeTestTreeData(
                            testTreeData?.Content as unknown as TestListResponse[],
                            selectedAcceptedTestItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );

                    setIsModalTestTreeOpen(open);
                    setIsTestFilterEmpty(true);
                }}
                treeData={testsTree}
                checkedChange={(selectedNodes) => {
                    setSelectedTestItems(selectedNodes);
                }}
                onSubmit={onSubmitTestSection}
                submitButtonTitle="Выбрать"
                selectedCount={selectedTestCount}
                onSearchFilterChange={(value: TTaskFilterValue, query?: string) => {
                    setTestsTree(
                        structurizeTestTreeData(
                            (testTreeData?.Content as unknown as TestListResponse[]).filter(({ state, title }) => {
                                return (
                                    (!query ||
                                        title.toLowerCase().includes(query.trim().toLowerCase()) ||
                                        query.trim() === "") &&
                                    (state === value || value === "ALL")
                                );
                            }),
                            selectedTestItems.map(({ id, state }) => {
                                return {
                                    id,
                                    checked: state.checked,
                                };
                            }),
                        ),
                    );
                    setIsTestFilterEmpty(value === "ALL" && (!query || query.trim() === ""));
                }}
                emptyMessage={isTestFilterEmpty ? "Нет элементов для выбора" : "По заданным параметрам результатов нет"}
            />
            {selectedAcceptedTestItems.length > 0 && (
                <>
                    <div className="mt-7 flex-wrap relative z-[70]">
                        <div className="grid items-center gap-6.5 grid-cols-2">
                            {/* Выбор участников */}
                            <FormGroup className="!mb-0">
                                <Label>Участники</Label>
                                <ComboBox
                                    placeholder="Выберите одного или нескольких участников"
                                    onChange={(options, action) => {
                                        if (action && action.action === "clear") {
                                            setUsers([]);
                                        }
                                        if (action && action.action === "remove-value") {
                                            setUsers(options as MembersListResponse[]);
                                        }
                                        setShowReportUsersTable(false);
                                    }}
                                    value={users}
                                    components={{
                                        DropdownIndicator: OptionsDropdownIndicator({
                                            onClick: (e) => {
                                                if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                                    setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                                }
                                            },
                                        }),
                                        // ValueContainer: SelectValueContainer({
                                        //     onClick: (e) => {
                                        //         if (!e.target.closest(".ui-combo-box__multi-value__remove")) {
                                        //             setShowUsersModal((prevIsOpen) => {
                                        //                 return !prevIsOpen;
                                        //             });
                                        //         }
                                        //     },
                                        //     // oneRow: true
                                        //     allPlaceholder: users.length > 0 && allUsersCount === users.length,
                                        // }),
                                        ValueContainer: StackedValueContainer,
                                        MultiValueLabel: SelectAvatarMultiValueLabel({}),
                                    }}
                                    onClick={(e) => {
                                        if (!(e.target as HTMLElement).closest(".ui-combo-box__multi-value__remove")) {
                                            setShowUsersModal((prevIsOpen) => !prevIsOpen);
                                        }
                                    }}
                                    allPlaceholder={users.length > 0 && allUsersCount === users.length}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                    menuIsOpen={false}
                                    isMulti
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Доступ на текущий момент</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAccess(value as { value: StatisticsReportAccess; label: string });
                                        setShowReportUsersTable(false);
                                    }}
                                    value={access}
                                    options={accessOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус прохождения теста</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setProgress(value as { value: ProgressStatus; label: string });
                                        setShowReportUsersTable(false);
                                    }}
                                    value={progress}
                                    options={progressOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Собрать статистику</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setAttempts(value as { value: StatisticsAttempts; label: string });
                                        setShowReportUsersTable(false);
                                    }}
                                    value={attempts}
                                    options={attemptsOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                            <FormGroup className="!mb-0">
                                <Label>Статус участников</Label>
                                <ComboBox
                                    onChange={(value) => {
                                        setUsersStatus(value as { value: StatisticsUsersStatus; label: string });
                                        setShowReportUsersTable(false);
                                    }}
                                    value={usersStatus}
                                    options={usersStatusOptions}
                                    isSearchable={false}
                                    isCreatable={false}
                                    isClearable={false}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    {/* Выбор участников - модалка */}
                    <UsersModal
                        isOpen={showUsersModal}
                        onClose={() => {
                            setShowUsersModal(false);
                        }}
                        onSubmit={(users) => {
                            setUsers(
                                users.map((user) => {
                                    const { firstName, lastName, avatarId, defaultAvatarColor } = user;
                                    return {
                                        ...user,
                                        value: {
                                            avatarId,
                                            firstName,
                                            lastName,
                                            defaultAvatarColor,
                                        },
                                        label: `${lastName} ${firstName}`,
                                    };
                                }),
                            );
                            setShowReportUsersTable(false);
                        }}
                        selectedUsersDefault={users}
                        allUsers={allUsers}
                    />
                    <div className="mt-9">
                        {showReportUsersTable ? (
                            <>
                                <ReportUsersTable
                                    singleTestChoosed={selectedAcceptedTestItems.length === 1}
                                    onClickLoadReportFile={() => setShowReportSettings(true)}
                                    queryPayload={createPayloadParams()}
                                />
                                <ReportSettings
                                    isActive={showReportSettings}
                                    setIsActive={setShowReportSettings}
                                    settings={reportSettings}
                                    onSubmit={onSubmit}
                                    isReportFileFetching={false}
                                />
                            </>
                        ) : (
                            <Button
                                key={1}
                                onClick={() => createReport()}
                                variant="standard"
                                size={"medium"}
                                color={"primary"}
                                className="w-full"
                                disabled={!users.length}
                            >
                                {`Сформировать отчет по ${users.length} ${numWord(users.length, [
                                    "участнику",
                                    "участникам",
                                    "участникам",
                                ])}`}
                            </Button>
                        )}
                    </div>
                </>
            )}
        </>
    );
};
