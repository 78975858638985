import { SortingState } from "@tanstack/react-table";
import BaseApi from "../BaseApi";
import { BaseArrayResponse, BasePaginationResponse, BaseResponse } from "../BaseResponse";
import {
    TestCategoryRequest,
    TestRequest,
    TestUnArchiveRequest,
    TestUserValidateRequest,
    TestVerificatedCheckMultipleRequest,
    TestVerificatedCheckRequest,
    TestCategoryReorderRequest,
} from "../Requests/TestRequest";
import {
    TestCategoryResponse,
    TestListResponse,
    TestResponse,
    TestUserCountResponse,
    TestUserListResponse,
    TestUserResponse,
    TestUserStartedResponse,
    TestUserValidateResponse,
    TestUserFinishResponse,
    TestPublicVerificatedResponse,
    TestVerificatedReadResponse,
    TestReviewSkipResponse,
    TestStatisticsResponse,
    FilterRatingInfo,
} from "../Responses/TestResponse";
import { BaseRequest } from "Api/BaseRequest";
import { ResourceType } from "Enums";
import { ArchiveResponse } from "Api/Responses/ArchiveResponse";
import { LMSFilterParams } from "models/LMSFilterParams";

export default class TestApi extends BaseApi {
    CategoryGet = (): Promise<BaseArrayResponse<TestCategoryResponse>> => {
        const params = LMSFilterParams();
        return this.Get(new BaseArrayResponse(TestCategoryResponse), `/content/quiz-category/all?${params.toString()}`);
    };

    CategoryCreate = async (data: TestCategoryRequest): Promise<TestCategoryResponse> => {
        return await this.Post(new TestCategoryResponse(), "/content/quiz-category", data);
    };

    CategoryEdit = async (data: TestCategoryRequest): Promise<TestCategoryResponse> => {
        return await this.Post(new TestCategoryResponse(), "/content/quiz-category/edit", data);
    };

    CategoryDelete = async (id: string): Promise<BaseResponse> => {
        return await this.Delete(new BaseResponse(), "/content/quiz-category/delete/" + id);
    };

    CategoryCheckDeletionPossibility = async (id: string): Promise<string[]> => {
        return await this.Post([], "/content/quiz-category/delete/" + id + "/check");
    };

    CategoryReorder = async (data: TestCategoryReorderRequest): Promise<BaseResponse> => {
        return await this.Post(new BaseResponse(), "/content/quiz-category/reorder", data);
    };

    List = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: any },
    ): Promise<BasePaginationResponse<TestListResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size });
        return this.Get(new BasePaginationResponse(TestListResponse), `/content/quiz/all?${params.toString()}`);
    };

    Copy = async (id: string): Promise<BaseResponse> => {
        return await this.Get(new BaseResponse(), `/content/quiz/copy/${id}`);
    };

    StateCheck = async (ids: string[], state: string, resourceType: ResourceType): Promise<string[]> => {
        return await this.Post([], "/resource-state/check", {
            resourceIds: ids,
            action: state,
            resourceType,
        } as BaseRequest);
    };

    State = async (ids: string[], state: string, resourceType: ResourceType): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/resource-state", {
            resourceIds: ids,
            state: state,
            resourceType,
        } as BaseRequest);
    };

    Read = async (id: string): Promise<TestResponse> => {
        return await this.Get(TestResponse, `/content/quiz/${id}`);
    };

    Create = async (data: TestRequest): Promise<TestResponse> => {
        return await this.Post(TestResponse, "/content/quiz", data);
    };

    Edit = async (data: TestRequest): Promise<TestResponse> => {
        return await this.Post(TestResponse, "/content/quiz/edit", data);
    };

    Statistics = async (id: string): Promise<TestStatisticsResponse> => {
        return await this.Get(TestStatisticsResponse, `/content/quiz-user/${id}/statistics`);
    };

    Stats = async (resourceId: string): Promise<TestStatisticsResponse> => {
        return await this.Get(TestStatisticsResponse, `/content/quiz-user/${resourceId}/stats`);
    };

    ArchiveList = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<ArchiveResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size });
        return this.Get(new BasePaginationResponse(ArchiveResponse), `/content/quiz/archive/list?${params.toString()}`);
    };

    UnArchive = async (data: TestUnArchiveRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/content/quiz/unarchive", data);
    };

    UserCount = async (): Promise<TestUserCountResponse> => {
        return await this.Get(TestUserCountResponse, "/content/quiz-user/new/count/tabs");
    };

    UserList = (filter: { [id: string]: string }): Promise<TestUserListResponse[]> => {
        const params = LMSFilterParams({ filter });
        return this.Get([], `/content/quiz-user/all/available?${params.toString()}`);
    };

    UserReview = async (filter: { [id: string]: string }): Promise<TestUserListResponse[]> => {
        const params = LMSFilterParams({ filter });
        return await this.Get([], `/content/quiz-user/all/review?${params.toString()}`);
    };

    UserFinished = (
        page: number,
        size: number,
        sort: SortingState,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<TestUserListResponse>> => {
        const params = LMSFilterParams({ filter, sort, page, size });

        return this.Get(
            new BasePaginationResponse(TestUserListResponse),
            `/content/quiz-user/all/finished?${params.toString()}`,
        );
    };

    UserRead = async (id: string): Promise<TestUserResponse> => {
        return await this.Get(TestUserResponse, `/content/quiz-user/${id}/info`);
    };

    UserStart = async (id: string): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, "/content/quiz-solution/start", { quizId: id } as BaseRequest);
    };

    UserStarted = async (): Promise<TestUserStartedResponse> => {
        return this.Get(TestUserStartedResponse, "/content/quiz-solution/started");
    };

    UserValidate = async (data: TestUserValidateRequest): Promise<TestUserValidateResponse> => {
        return await this.Post(TestUserValidateResponse, `/content/quiz-solution/question/answer`, data);
    };

    UserFinish = async (id: string): Promise<TestUserFinishResponse> => {
        return await this.Post(TestUserFinishResponse, "/content/quiz-solution/finish", { quizId: id } as BaseRequest);
    };

    // Проверка тестов
    ReviewList = async (
        page: number,
        size: number,
        // sort: SortingState,
        sort: string,
        filter: { [id: string]: string },
    ): Promise<BasePaginationResponse<TestPublicVerificatedResponse>> => {
        const params = LMSFilterParams({ filter, page, size });
        params.append("sort", sort);

        return await this.Get(
            new BasePaginationResponse(TestPublicVerificatedResponse),
            `/content/quiz-solution/review-list?${params.toString()}`,
        );
    };

    // Массовые операции с проверяемыми тестами (в таблице)
    VerificatedCheckMultiple = async (payload: TestVerificatedCheckMultipleRequest): Promise<BaseResponse> => {
        return await this.Post(BaseResponse, `/content/quiz-solution/check-multiple`, payload);
    };

    // Получение данных проверяемого теста
    VerificatedRead = async (id: string, review = true): Promise<TestVerificatedReadResponse> => {
        return await this.Get(TestVerificatedReadResponse, `/content/quiz-solution/${id}?review=${review}`);
    };

    // "Отправить результат" проверяемого теста
    VerificatedCheck = async (payload: TestVerificatedCheckRequest): Promise<TestVerificatedReadResponse> => {
        return await this.Post(TestVerificatedReadResponse, `/content/quiz-solution/review`, payload);
    };

    // Сохранение проверяемого теста
    VerificatedSave = async (payload: TestVerificatedCheckRequest): Promise<BaseResponse> => {
        return await this.Put(BaseResponse, `/content/quiz-solution/review`, payload);
    };

    // Пропуск проверяемого теста
    VerificatedSkip = async (payload: TestVerificatedCheckRequest): Promise<TestReviewSkipResponse> => {
        return await this.Post(TestReviewSkipResponse, `/content/quiz-solution/review/skip`, payload);
    };

    FilterRatingInfo = async (): Promise<FilterRatingInfo> => {
        return await this.Get(FilterRatingInfo, "/content/quiz/filter-rating-info");
    };
}
