import React from "react";
import { Button, Icon, Icons } from "Uikit";
import { TVoidFunction } from "types";

interface ITestingFooter {
    type: string;
    questionsCount: number;
    answeredQuestionsCount: number;
    current: number;
    hasAnswer: boolean;
    isShowError: boolean;
    isTimeout: boolean;
    time: number | null;
    canSubmit: boolean;
    onPrevious: TVoidFunction;
    onSkip: TVoidFunction;
    onNext: TVoidFunction;
    onSubmit: TVoidFunction;
}
export const TestingFooter = ({
    type,
    questionsCount,
    answeredQuestionsCount,
    current,
    hasAnswer,
    isShowError,
    isTimeout,
    time,
    canSubmit,
    onPrevious,
    onSkip,
    onNext,
    onSubmit,
}: ITestingFooter) => {
    return (
        <div className="sm:fixed sm:bottom-0 sm:left-0 flex justify-center w-full sm:h-20 2xl:h-25 sm:bg-background-light">
            <div className="flex justify-between items-center py-3 sm:p-0 w-full sm:w-175 2xl:w-[875px]">
                <div className="flex items-center gap-4 sm:gap-2 2xl:gap-2.5 w-full sm:w-auto">
                    {type === "LOOSE" && current !== 0 && (
                        <Button
                            className="hidden sm:block !p-0 sm:!px-5.5 sm:!py-3 min-w-12 h-12 sm:h-10 sm:w-full sm:auto"
                            variant="outline"
                            color="secondary"
                            onClick={onPrevious}
                            id="userTestingFooterBackBtn"
                        >
                            <span className="hidden sm:block">Назад</span>
                            <Icon
                                className="block sm:hidden"
                                icon={Icons.ArrowLeft}
                                width={18}
                                height={18}
                                color="fill-blue-drk"
                            />
                        </Button>
                    )}
                    {!isShowError &&
                        type === "LOOSE" &&
                        !hasAnswer &&
                        answeredQuestionsCount + 1 !== questionsCount && (
                            <Button
                                className={`${canSubmit ? "hidden" : "block"} sm:block w-full sm:auto h-12 sm:h-10`}
                                variant="outline"
                                color="secondary"
                                onClick={onSkip}
                                id="userTestingFooterSkipBtn"
                            >
                                Пропустить
                            </Button>
                        )}
                    {!isShowError && !hasAnswer && (
                        <Button
                            className={`${
                                type !== "LOOSE" || canSubmit ? "block" : "hidden"
                            } sm:block w-full sm:auto h-12 sm:h-10`}
                            variant="standard"
                            color="primary"
                            disabled={!canSubmit}
                            onClick={onSubmit}
                            id="userTestingFooterAnswerBtn"
                        >
                            Ответить
                        </Button>
                    )}
                    {(isShowError || hasAnswer) && (
                        <Button
                            className="w-full sm:auto h-12 sm:h-10"
                            variant="standard"
                            color="primary"
                            onClick={onNext}
                            id="userTestingFooterNextBtn"
                        >
                            Далее
                        </Button>
                    )}
                </div>
                {time !== null && (
                    <div className="hidden sm:flex flex-col" id="userTestingFooterTimeLeft">
                        <span className="p4 2xl:p2 text-gray">Времени осталось:</span>
                        <div className="flex justify-end items-center sm:gap-1.25 2xl:gap-1.5">
                            <Icon
                                icon={Icons.Time}
                                width={20}
                                height={20}
                                color={isTimeout ? "fill-red" : "fill-gray-dark"}
                                className="2xl:!w-6.25 2xl:!h-6.25"
                            />
                            <h3
                                className={`2xl:!text-lg 2xl:!leading-[30px] ${
                                    isTimeout ? "ml-1 text-red" : "ml-1 text-gray-dark"
                                }`}
                            >
                                {new Date(time * 1000).toISOString().slice(11, 19)}
                            </h3>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};
