import React from "react";
import cx from "./loader.module.scss";

export const Loader = ({ size = 48 }: { size?: number }) => {
    return (
        <div className={cx.ldsRing} style={{ width: size, height: size }} title={"Загрузка"}>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    );
};
